import { Badge } from '@/modules/ui/components/badge';
import { PhoneIncoming, PhoneOutgoing } from 'lucide-react';
import { humanize } from 'inflection';
import React from 'react';
interface DirectionBadgeProps {
  direction: 'inbound' | 'outbound';
}

export function DirectionBadge({ direction }: DirectionBadgeProps) {
  return (
    <Badge variant="outline" className="w-max flex items-center gap-2">
      {direction === 'inbound' ? (
        <PhoneIncoming className="w-4 h-4" />
      ) : (
        <PhoneOutgoing className="w-4 h-4" />
      )}
      {humanize(direction)}
    </Badge>
  );
}
