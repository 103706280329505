import React from 'react';
import {
  useRecordContext,
  useDataProvider,
  RecordContextProvider,
} from 'ra-core';
import { Badge } from '@/modules/ui/components/badge';
import { get } from 'lodash';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { MonitoringActivityEditDialog } from '@/modules/monitoring-activity/components/MonitoringActivityEditDialog';
import INoteAction from 'healthicons-react/dist/filled/INoteAction';
import { monitoringActivityTypesExtended } from '@/modules/monitoring-activity/constants/monitoringActivityTypeExtended';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import { serviceInstanceTypesAbbreviated } from '../../patients/constants/serviceInstanceTypesAbbreviated';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FunctionField, ReferenceField } from 'react-admin';
import { Separator } from '@/modules/ui/components/separator';

export function MonitoringActivitiesListAside() {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const pagination = {
    page: 1,
    perPage: 10,
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['monitoring-activities', { id: record?.id }],
      queryFn: ({ pageParam = pagination.page }) =>
        dataProvider
          .getList('monitoring-activities', {
            pagination: {
              page: pageParam,
              perPage: pagination.perPage,
            },
            sort: { field: 'reviewed_on', order: 'DESC' },
            filter: {
              'patient_id[eq]': record?.id,
            },
          })
          .then(({ data, total, pageInfo }) => ({
            data,
            total,
            pageInfo,
            pageParam,
          })),
      initialPageParam: pagination.page,
      enabled: !!record?.id,
      getNextPageParam: (lastLoadedPage) => {
        if (lastLoadedPage.pageInfo) {
          return lastLoadedPage.pageInfo.hasNextPage
            ? lastLoadedPage.pageParam + 1
            : undefined;
        }
        const totalPages = Math.ceil(
          (lastLoadedPage.total || 0) / pagination.perPage,
        );

        return lastLoadedPage.pageParam < totalPages
          ? Number(lastLoadedPage.pageParam) + 1
          : undefined;
      },
    });

  if (!record || isLoading) {
    return null;
  }

  const activities = data?.pages.flatMap((page) => page.data) || [];

  if (activities.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-gray-500">
        <INoteAction height="3em" width="3em" className="opacity-70 mb-2" />
        <p className="text-sm">
          No monitoring activities found for this patient
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 overflow-y-auto ">
      {activities.map((activity) => (
        <div
          key={activity.id}
          className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
        >
          <RecordContextProvider value={activity}>
            <div className="flex justify-between items-start ">
              <div className="flex justify-start gap-2 ">
                <span className="text-sm font-medium text-gray-600">
                  {get(
                    monitoringActivityTypesExtended,
                    activity.type,
                    activity.type || '',
                  )}
                </span>
                <ReferenceField
                  source="service_instance_id"
                  reference="service-instances"
                  label="Care Program"
                >
                  <FunctionField
                    render={(record) => (
                      <Badge variant="outline">{`${get(
                        serviceInstanceTypesAbbreviated,
                        record.type,
                        record.type,
                      )}`}</Badge>
                    )}
                  />
                </ReferenceField>
              </div>
              <MonitoringActivityEditDialog
                tenant_id={record?.user?.tenant_id}
              />
            </div>
            <div className="flex flex-wrap justify-between items-center text-md text-gray-500 mb-1">
              <span>{activity.practitioner_name}</span>
              <span>
                Duration: {formatDurationAbbreviated(activity.duration)}
              </span>
            </div>
            <div className="text-sm mb-1">
              <DateTimeProviderTZField
                source="reviewed_on"
                showTime
                showTZ
                tenant_id={record?.user?.tenant_id}
              />
            </div>
            <Separator className="my-2 w-full" />

            <p className="text-sm mt-1 whitespace-pre-wrap break-words">
              {activity.description}
            </p>
          </RecordContextProvider>
        </div>
      ))}
      {hasNextPage && (
        <button
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          className="w-full text-sm text-blue-600 hover:text-blue-800 mt-2"
        >
          {isFetchingNextPage ? 'Loading more...' : 'Load more'}
        </button>
      )}
    </div>
  );
}
