import { Button } from "@/modules/ui/components/button";
import { cn } from "@/modules/ui/utils/cn";
import { Plus } from "lucide-react";
import React, { cloneElement } from "react";
import { useCreatePath, useResourceContext, useTranslate } from "react-admin";
import { Link } from "react-router-dom";

export function CreateButton(props) {
	const {
		icon = <Plus />,
		label = null,
		size = "default",
		className = null,
	} = props;
	const resource = useResourceContext(props);
	const createPath = useCreatePath();

	const translate = useTranslate();
	return (
		<Button asChild size={size} className={cn("h-8", className)}>
			<Link to={createPath({ resource, type: "create" })}>
				{cloneElement(icon, { className: "mr-2 h-4 w-4" })}
				{label ?? translate("ra.action.create")}
			</Link>
		</Button>
	);
}
