import { get } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  DateInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  RecordContextProvider,
  ReferenceInput,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  useChoicesContext,
  usePermissions,
} from 'react-admin';
import { ColoredChipField } from '@/modules/ra-ui/components/ColoredChipField';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { useOrganization } from '@/modules/organizations/components/OrganizationProvider';
import { ICD10ArrayInput } from '@/modules/icd10/components/ICD10Input';
import { filterTenantName } from '@/utils/filterTenantName';
import { validCPTCodes } from '../contants/validCPTCodes';
import { filterPatientName } from '@/utils/filterPatientName';
import { ServiceProgressField } from '@/modules/patients/components/ServiceProgressField';

export const CreateClaimItemDialog = () => {
  const { organization } = useOrganization();
  const { permissions } = usePermissions();

  const transform = (data) => {
    return {
      ...data,
      tenant_id: data.tenant_id || get(organization, 'id'),
    };
  };

  if (!!!get(permissions, 'claim_items.create')) {
    return null;
  }

  return (
    <CreateInDialogButton
      title="Create claim item"
      resource="claim-items"
      variant="default"
      transform={transform}
      label="Claim"
      notifyMessage="Claim created."
    >
      <div className="flex flex-col gap-0">
        {get(permissions, 'is_internal') === true && (
          <ReferenceInput
            label="Provider"
            source="tenant_id"
            reference="providers"
          >
            <AutocompleteInput
              label="Provider"
              optionText="name"
              parse={(v) => (v === '' ? null : v)}
              helperText={false}
              validate={required()}
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        )}
        {get(permissions, 'is_internal') === true ? (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              get(formData, 'tenant_id') && (
                <ReferenceInput
                  source="patient_id"
                  reference="patients"
                  filter={{
                    'tenant_id[eq]': formData.tenant_id,
                  }}
                >
                  <AutocompleteInput
                    filterToQuery={filterPatientName}
                    label="Patient"
                    optionText="user.full_name"
                    parse={(v) => (v === '' ? null : v)}
                    helperText={false}
                    validate={required()}
                  />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
        ) : (
          <ReferenceInput source="patient_id" reference="patients">
            <AutocompleteInput
              filterToQuery={filterPatientName}
              label="Patient"
              optionText="user.full_name"
              parse={(v) => (v === '' ? null : v)}
              helperText={false}
              validate={required()}
            />
          </ReferenceInput>
        )}

        <RadioButtonGroupInput
          source="source"
          defaultValue="manual"
          choices={[
            { id: 'manual', name: 'Manual' },
            { id: 'based_on_service', name: 'Based on Service' },
          ]}
          validate={required()}
          helperText={false}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.source === 'manual' ? (
              <>
                <DateInput
                  source="date_of_service"
                  validate={required()}
                  helperText={false}
                />
                <DateInput source="end_date_of_service" helperText={false} />
                <SelectInput
                  source="cpt_code"
                  label="CPT code"
                  validate={required()}
                  helperText={false}
                  choices={validCPTCodes}
                />
                <NumberInput
                  source="units"
                  validate={required()}
                  helperText={false}
                />
                <TextInput
                  source="note"
                  label="Note / Reason"
                  helperText={false}
                  validate={required()}
                />
                <ICD10ArrayInput />
                <TextInput source="billing_provider" helperText={false} />
              </>
            ) : (
              <>
                {formData.patient_id ? (
                  <ReferenceInput
                    reference="services"
                    source="service_id"
                    filter={{
                      'patient_id[eq]': formData.patient_id,
                    }}
                    debounce={500}
                  >
                    <ServiceSelect />
                  </ReferenceInput>
                ) : null}
                <DateInput
                  source="date_of_service"
                  validate={required()}
                  helperText={false}
                />
                <DateInput source="end_date_of_service" helperText={false} />
                <NumberInput
                  source="units"
                  validate={required()}
                  helperText={false}
                />
              </>
            )
          }
        </FormDataConsumer>
      </div>
    </CreateInDialogButton>
  );
};

const ServiceSelect = () => {
  const { selectedChoices } = useChoicesContext();

  return (
    <>
      <SelectInput optionText="cpt_code" validate={required()} />
      {get(selectedChoices, '0.id', false) && (
        <RecordContextProvider value={get(selectedChoices, '0')}>
          <SimpleShowLayout>
            <LuxonDateField source="date" />
            <ColoredChipField source="status" />
            <TextField source="cpt_code" label="CPT code" />
            <ServiceProgressField source="progress" />
            <BooleanField source="claimable" />
          </SimpleShowLayout>
        </RecordContextProvider>
      )}
    </>
  );
};
