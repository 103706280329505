import { serviceInstanceTypesAbbreviated } from "@/modules/patients/constants/serviceInstanceTypesAbbreviated";
import EmptyDatagrid from "@/modules/ra-ui/components/empty-datagrid-section";
import { formatDurationAbbreviated } from "@/utils/formatDurationAbbreviated";
import INoteAction from "healthicons-react/dist/filled/INoteAction";
import { get } from "lodash";
import {
	Datagrid,
	FunctionField,
	ListContextProvider,
	Pagination,
	ReferenceField,
	TextField,
	useGetList,
	useRecordContext,
} from "react-admin";
import { MonitoringActivityEditDialog } from "./MonitoringActivityEditDialog";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import DataTableDateRangeFilter from "@/modules/data-table/components/date-table-date-range-filter";
import { DataTableFilter } from "@/modules/data-table/components/data-table-filter";
import { map } from "lodash";
import { Badge } from "@/modules/ui/components/badge";
import { monitoringActivityTypesExtended } from "../constants/monitoringActivityTypeExtended";
import { DateTimeProviderTZField } from "@/modules/ra-ui/components/DateTimeProviderTZField";
import { DataTableFilterSingle } from "@/modules/data-table/components/data-table-filter-single";
import { usePatientTableState } from "@/modules/common/hooks/usePatientTableState";
import { useFeatureFlag } from "@/modules/feature-flags/hooks/useFeatureFlag";

const monitoringActivityTypeChoices = map(
	monitoringActivityTypesExtended,
	(v, k) => ({ label: v, value: k }),
);

export function MonitoringActivitiesListDataGrid() {
	const record = useRecordContext();
	const { isEnabled } = useFeatureFlag("clinical_activities");
	const {
		page,
		perPage,
		sort,
		filterValues,
		setPage,
		setPerPage,
		setSort,
		setFilters,
	} = usePatientTableState("monitoring-activities", record?.id);

	const { data, total, isLoading } = useGetList("monitoring-activities", {
		pagination: { page, perPage },
		filter: {
			"patient_id[eq]": record?.id,
			...filterValues,
		},
		sort,
	});
	if (!record) {
		return null;
	}

	return (
		<>
			<ListContextProvider
				// @ts-ignore
				value={{
					filterValues,
					setFilters,
					displayedFilters: {},
					page,
					perPage,
					setPage,
					setPerPage,
					total,
				}}
			>
				<DataTableToolbar showViewOptions={false} search={false} className="">
					<DataTableDateRangeFilter column="reviewed_on" title="Date range" />
					<DataTableFilter
						column="type[in]"
						title="Type"
						options={monitoringActivityTypeChoices}
					/>
					<DataTableFilter
						column="service_instance_id[in]"
						title="Care Program"
						options={record.service_instances.map((serviceInstance) => ({
							label:
								serviceInstance.type &&
								serviceInstanceTypesAbbreviated[serviceInstance.type],
							value: serviceInstance.id.toString(),
						}))}
					/>
					<DataTableFilterSingle
						column="patient_contacted[eq]"
						title="Patient Two-Way Interaction"
						options={[
							{ label: "Yes", value: "true" },
							{ label: "No", value: "false" },
						]}
					/>
				</DataTableToolbar>
				<Datagrid
					data={data}
					total={total}
					isLoading={isLoading}
					sort={sort}
					bulkActionButtons={false}
					setSort={setSort}
					expand={<ExpandedRow tenant_id={record?.user?.tenant_id} />}
					rowClick="expand"
					empty={
						<EmptyDatagrid
							resourceName="Monitoring Activities"
							textFormatString="No monitoring activities found for this patient"
							icon={
								<INoteAction height="5em" width="5em" className="opacity-70" />
							}
						/>
					}
				>
					<TextField label="Performed by" source="practitioner_name" />
					<DateTimeProviderTZField
						source="reviewed_on"
						label="Performed on"
						showTime
						showTZ
						tenant_id={record?.user?.tenant_id}
					/>
					<FunctionField
						label="Type"
						source="type"
						render={(record) => (
							<p className="text-sm font-medium">{`${get(monitoringActivityTypesExtended, record.type, record.type || "")}`}</p>
						)}
					/>

					<FunctionField
						source="duration"
						label="Duration"
						render={(record) => formatDurationAbbreviated(record.duration)}
					/>
					<ReferenceField
						source="service_instance_id"
						reference="service-instances"
						label="Care Program"
						emptyText="N/A"
					>
						<FunctionField
							render={(record) => (
								<Badge variant="outline">{`${get(
									serviceInstanceTypesAbbreviated,
									record.type,
									record.type,
								)}`}</Badge>
							)}
						/>
					</ReferenceField>
					<FunctionField
						source="patient_contacted"
						label="2-Way Interaction"
						render={(record) => (
							<p className="text-sm font-medium">{`${record.patient_contacted ? "Yes" : "No"}`}</p>
						)}
					/>
					{isEnabled && (
						<FunctionField
							source="clinical_activity_instance_id"
							label="Has Clinical Activity"
							render={(record) => (
								<p className="text-sm font-medium">{`${record.clinical_activity_instance_id ? "Yes" : "No"}`}</p>
							)}
						/>
					)}

					<MonitoringActivityEditDialog tenant_id={record?.user?.tenant_id} />
				</Datagrid>
				<Pagination />
			</ListContextProvider>
		</>
	);
}
export function ExpandedRow({ tenant_id }: { tenant_id: string }) {
	const record = useRecordContext();

	if (!record) {
		return null;
	}

	return (
		<div className="space-y-2">
			<div className="text-xs text-muted-foreground w-full flex flex-row items-center gap-1">
				Created by{" "}
				<p className="font-bold">
					<ReferenceField
						source="created_by_id"
						reference="users"
						link={false}
						emptyText="System"
					>
						<TextField source="full_name" />
					</ReferenceField>
				</p>
				{" on "}
				<DateTimeProviderTZField
					source="created_on"
					showTime
					showTZ
					tenant_id={tenant_id}
				/>
			</div>
			<p className="whitespace-pre-wrap">{record.description}</p>
		</div>
	);
}
