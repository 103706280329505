import { get, find } from 'lodash';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Badge } from '@/modules/ui/components/badge';
const MappedChipField = ({ source, mapping, ...rest }) => {
  const record = useRecordContext();

  const value = get(record, source);
  const mappedValue =
    find(mapping, { id: value }).value ||
    find(mapping, { id: value }).name ||
    value;

  return (
    <Badge {...rest} variant="outline">
      {mappedValue}
    </Badge>
  );
};

export default MappedChipField;
