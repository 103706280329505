import { Button } from '@/modules/ui/components/button';
import { Check, Loader2, X } from 'lucide-react';
import { useCallback, useState } from 'react';
import {
  useDataProvider,
  useDelete,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useDropzone } from 'react-dropzone';

import { formatDate } from 'date-fns';
import type { ESignRequest } from '../types/ESignRequest';
import { ESignButton } from './ESignButton';
import { useNavigate } from 'react-router-dom';

export function ConsentForm() {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  // Local state for file upload
  const [showDropZone, setShowDropZone] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const isUploaded = Boolean(record?.consent_form_document_id);

  // eSign request object

  // Loading indicator for async tasks
  const [isLoading, setIsLoading] = useState(false);

  const { data: pendingEsignRequests, refetch: refetchEsignRequest } =
    useGetList<ESignRequest>(
      'esign-request',
      {
        pagination: { page: 1, perPage: 1 },
        filter: {
          'patient_id[eq]': record.id,
          'document_type[eq]': 'consent_form',
          'status[eq]': 'in_progress',
        },
        sort: { field: 'requested_on', order: 'DESC' },
      },
      {
        enabled: !!record?.id,
      },
    );
  const [deleteEsignRequest, { isLoading: isDeleting }] = useDelete();
  const pendingEsign = pendingEsignRequests?.[0];
  // --------------- FILE DROPZONE ---------------
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/pdf': [] },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024,
  });

  const cancelUpload = () => {
    setShowDropZone(false);
    setFile(null);
  };

  const uploadFile = () => {
    setIsLoading(true);
    dataProvider
      .uploadFile('patient-documents/upload', {
        file,
        meta: {
          patient_id: record.id,
          document_type: 'consent_form',
        },
      })
      .then(() => {
        setShowDropZone(false);
        setFile(null);
        notify('Consent form uploaded successfully', { type: 'success' });
        refresh();
        setIsLoading(false);
      })
      .catch((error) => {
        notify('Error uploading consent form: ' + error.message, {
          type: 'error',
        });
        setIsLoading(false);
      });
  };

  const viewFile = () => {
    dataProvider
      .getOne('patient-documents', { id: record.consent_form_document_id })
      .then((response) => {
        if (!response.data.signed_link) {
          notify('Error viewing consent form: No signed link found', {
            type: 'error',
          });
          return;
        }

        navigate('/pdf', {
          state: {
            url: response.data.signed_link,
          },
        });
      })
      .catch((error) => {
        notify('Error viewing consent form: ' + error.message, {
          type: 'error',
        });
      });
  };

  // --------------- E-SIGN LOGIC ---------------

  /**
   * Rescinds an e-sign request.
   */
  const handleRescind = () => {
    if (!pendingEsign) {
      notify('No eSign request to rescind.', { type: 'warning' });
      return;
    }

    deleteEsignRequest(
      'esign-request',
      {
        id: pendingEsign.id,
      },
      {
        onSuccess: () => {
          notify('E-sign request rescinded.', { type: 'success' });
          refetchEsignRequest();
        },
        onError: (error: any) => {
          notify(`Error rescinding eSign: ${error?.message}`, {
            type: 'error',
          });
        },
      },
    );
  };

  // --------------- UI RENDER ---------------
  return (
    <div className="p-2">
      {/* Confirmation Dialog */}

      {pendingEsign ? (
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center">
            <X className="w-6 h-6 text-red-500" />
            <div className="flex flex-row gap-3 items-center">
              <p>Consent form pending</p>
              <p className="text-sm text-gray-500">
                (Started on{' '}
                {formatDate(pendingEsign?.requested_on, 'MM/dd/yyyy')})
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <Button
              className="h-8"
              onClick={() =>
                window.open(
                  `https://docuseal.com/s/${pendingEsign.slug}`,
                  '_blank',
                )
              }
            >
              Resume eSign
            </Button>
            <Button
              variant="destructive"
              className="h-8"
              onClick={handleRescind}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              ) : (
                'Rescind'
              )}
            </Button>
          </div>
        </div>
      ) : showDropZone ? (
        <div className="flex flex-row items-center justify-stretch gap-2">
          <div className="flex flex-col items-center justify-stretch gap-2 w-full">
            <div
              {...getRootProps()}
              className="border-dashed border-4 border-gray-200 rounded-lg p-10 text-center cursor-pointer w-full"
            >
              <input {...getInputProps()} />
              <p>Drag and drop your consent form here, or click and browse</p>
            </div>
            <span className="flex flex-row items-center justify-center gap-1 mb-2">
              {file && (
                <p className="w-20">
                  <i>Uploading:</i>{' '}
                </p>
              )}
              {file && <p className="w-40 truncate">{file.name}</p>}
            </span>
          </div>
          <div className="flex flex-col items-center justify-stretch mr-2 gap-2">
            <Button
              className="h-8 flex items-center justify-center"
              onClick={uploadFile}
              disabled={!file || isLoading}
            >
              {isLoading ? (
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              ) : (
                'Confirm'
              )}
            </Button>
            <Button
              className="h-8"
              variant="destructive"
              onClick={cancelUpload}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : isUploaded ? (
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center">
            <Check className="w-6 h-6 text-green-500" />
            <p>Consent form uploaded</p>
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <Button className="h-8" onClick={viewFile}>
              View
            </Button>
            <ESignButton label="New eSign" variant="outline" />
            <Button
              className="h-8"
              variant="outline"
              onClick={() => setShowDropZone(true)}
            >
              Upload New
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center">
            <X className="w-6 h-6 text-red-500" />
            <p>Consent form not uploaded</p>
          </div>
          <div className="flex flex-row items-center justify-between gap-2">
            <ESignButton />
            <Button className="h-8" onClick={() => setShowDropZone(true)}>
              Upload
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
