import { useSidebar } from '@/modules/ui/components/sidebar';
import { cn } from '@/modules/ui/utils/cn';
import React from 'react';

interface ListUIRootProps {
  children: React.ReactNode;
  className?: string;
}

export const ListUIRoot: React.FC<ListUIRootProps> = ({
  children,
  className,
}) => {
  const { open } = useSidebar();

  return (
    <div
      id="list-ui-root"
      className={cn(
        open ? 'max-w-open-drawer' : 'max-w-closed-drawer',
        'px-4',
        className,
      )}
    >
      {children}
    </div>
  );
};
