import { Input } from '@/modules/ui/components/input';
import { type ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { debounce } from 'lodash';
import { useRecordContext, useUpdate } from 'react-admin';
import { cn } from '@/modules/ui/utils/cn';
export const STORE_KEY = 'enrollmentQueue';

interface EditableCellProps {
  fieldName: string;
  resource: string;
  className?: string;
}

export const EditableCell = ({
  fieldName,
  resource,
  className,
}: EditableCellProps) => {
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [updated, setUpdated] = useState<boolean>(false);
  const [update] = useUpdate();
  const record = useRecordContext();
  const latestValueRef = useRef<string>('');

  useEffect(() => {
    // Only set initial value from record if we haven't edited yet
    if (!latestValueRef.current) {
      setValue(record[fieldName]);
      latestValueRef.current = record[fieldName];
    }
  }, [record, fieldName]);

  const debouncedSubmit = useMemo(
    () =>
      debounce(async (newValue: string) => {
        // Only proceed if this is still the latest value
        if (newValue !== latestValueRef.current) {
          return;
        }

        try {
          await update(
            resource,
            { id: record.id, data: { [fieldName]: newValue } },
            {
              onSuccess: () => {
                // Only update UI state if this is still the latest value
                if (newValue === latestValueRef.current) {
                  setUpdated(true);
                  setTimeout(() => setUpdated(false), 2000);
                }
              },
            },
          );
          setError('');
        } catch (err) {
          if (newValue === latestValueRef.current) {
            setError(err.message || 'Failed to update value');
          }
        }
      }, 1000),
    [update, resource, record, fieldName],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    latestValueRef.current = newValue;
    debouncedSubmit(newValue);
  };

  return (
    <div className="flex flex-col gap-1" onClick={(e) => e.stopPropagation()}>
      <Input
        type="text"
        value={value}
        onChange={handleChange}
        className={cn(
          'w-fit',
          updated ? 'border-green-500 border-2' : '',
          className,
        )}
        aria-label={fieldName}
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};
