import CreateInDialogButton from "@/modules/ra-ui/components/create-dialog-button";
import { Separator } from "@/modules/ui/components/separator";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import { DateTimeInput } from "@/modules/ui/inputs/date-time-input";
import { NumberInput } from "@/modules/ui/inputs/number-input";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { SwitchInput } from "@/modules/ui/inputs/switch-input";
import { filterLocationName } from "@/utils/filterLocationName";
import { DateTime } from "luxon";
import React from "react";
import {
	FormDataConsumer,
	maxValue,
	minValue,
	number,
	required,
} from "react-admin";
function filterUserName(searchText) {
	return {
		q: { field: "full_name", value: searchText.trim() },
	};
}

export const AttendanceCreateButton = () => {
	const localTimezone = DateTime.now().zoneName;
	return (
		<CreateInDialogButton
			title="Add manual attendance"
			label="Add attendance"
			dialogVariant="shadcn"
			closeOnClickOutside={false}
			resource="attendances"
			refreshOnSuccess={true}
		>
			<div className="flex flex-col space-y-4">
				<AutocompleteInput
					label="User"
					optionText="full_name"
					filterToQuery={filterUserName}
					modal={true}
					source="user_id"
					reference="users"
					validate={[required()]}
					filter={{ "attendance_tracking_enabled[eq]": true }}
				/>

				<SelectInput
					source="attendance_type"
					choices={[
						{ name: "Office", id: "office" },
						{ name: "Training", id: "training" },
					]}
					validate={[required()]}
				/>

				<DateTimeInput
					source="check_in_time"
					label="Check-in time"
					timezone={localTimezone}
				/>
				<DateTimeInput
					source="check_out_time"
					label="Check-out time"
					timezone={localTimezone}
				/>

				<Separator />
				<AutocompleteInput
					label="Location"
					optionText="name"
					filterToQuery={filterLocationName}
					modal={true}
					source="location_id"
					reference="locations"
				/>
				<SwitchInput
					source="additional_data.incentive_compensation"
					label="Incentive compensation"
				/>
				<FormDataConsumer>
					{({ formData }) =>
						formData.additional_data?.incentive_compensation && (
							<NumberInput
								source="additional_data.incentive_compensation_amount (USD)"
								label="Incentive compensation amount"
								validate={[number(), minValue(0), maxValue(25)]}
								defaultValue={15}
								decimalScale={2}
								prefix="$"
							/>
						)
					}
				</FormDataConsumer>
			</div>
		</CreateInDialogButton>
	);
};
