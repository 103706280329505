import React, { useState } from "react";
import { MonitoringActivitiesListAside } from "./MonitoringActivitiesListAside";
import { MonitoringActivityCreateAside } from "./MonitoringActivityCreateAside";
import { SplitButton } from "@/modules/ui/components/split-button";
import { usePermissions } from "react-admin";
import { get } from "lodash";
import { Plus } from "lucide-react";
import { useFeatureFlag } from "@/modules/feature-flags/hooks/useFeatureFlag";
export const MonitoringActivityAside = () => {
	const [mode, setMode] = useState<"list" | "create" | "edit">("list");
	const [noteType, setNoteType] = useState<"default" | "emr">("default");
	const { permissions } = usePermissions();
	const { isEnabled } = useFeatureFlag("clinical_activities");

	const handleCreateClick = (noteType: "default" | "emr") => {
		setMode("create");
		setNoteType(noteType);
	};

	const handleCreateClose = () => {
		setMode("list");
	};

	const options = [];
	const hasPrivilegedRole =
		["admin", "superadmin", "internal_admin"].includes(
			get(permissions, "role", ""),
		) === true;
	const showButton = !(isEnabled && !hasPrivilegedRole);

	if (hasPrivilegedRole) {
		options.push({
			label: "EMR Review",
			onClick: () => handleCreateClick("emr"),
		});
	}

	return (
		<div className="flex flex-col gap-1 h-[90vh] overflow-y-auto">
			<div className="flex justify-end px-2 mb-4">
				{mode === "list" && showButton && (
					<SplitButton
						mainOption={{
							label: "Monitoring",
							icon: <Plus />,
							onClick: () => handleCreateClick("default"),
						}}
						dropdownOptions={options}
					/>
				)}
			</div>
			{mode === "list" && <MonitoringActivitiesListAside />}
			{mode === "create" && (
				<MonitoringActivityCreateAside
					handleCancelCreate={handleCreateClose}
					noteType={noteType}
				/>
			)}
		</div>
	);
};
