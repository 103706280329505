import TwoFactorEnforceDialog from '@/modules/account/components/TwoFactorEnforceDialog';
import { AttendanceProvider } from '@/modules/attendance/hooks/UserCheckedIn';
import { FeatureFlagProvider } from '@/modules/feature-flags/components/FeatureFlagProvider';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { SidebarProvider } from '@/modules/ui/components/sidebar';
import { InProgressCall } from '@/modules/voice-calls/components/InProgressCall';
import { useTelnyxToken } from '@/modules/voice-calls/hooks/useTelnyxToken';
import * as Sentry from '@sentry/react';
import { TelnyxRTCProvider } from '@telnyx/react-client';
import { useGetIdentity } from 'ra-core';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { OrganizationProvider } from '../../organizations/components/OrganizationProvider';
import { AppSidebar } from './AppSidebar';
import { ErrorCapture } from './Error';
import { InactivityHandler } from './InactivityDialog';

export function Layout({ children }) {
  const { identity } = useGetIdentity();
  const { token } = useTelnyxToken();

  Sentry.setUser({
    email: identity?.email,
    id: identity?.id,
  });

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vironix</title>
        <link rel="canonical" href="https://rpm.vironix.ai" />
      </Helmet>
      <FeatureFlagProvider>
        <OrganizationProvider>
          <AttendanceProvider>
            <TelnyxRTCProvider credential={{ login_token: token || 'temp' }}>
              <SidebarProvider>
                <AppSidebar />
                <div className="flex flex-row h-screen w-full">
                  <main className="w-full flex flex-row">
                    <div
                      id="main-content"
                      className="border-l px-1 w-full overflow-x-auto "
                    >
                      <div>
                        <div id="vironix-app-title" />
                        <InactivityHandler />
                        <TwoFactorEnforceDialog />
                        <Sentry.ErrorBoundary
                          fallback={({ error, resetError }) => (
                            <ErrorCapture
                              error={error as Error}
                              resetErrorBoundary={resetError}
                            />
                          )}
                        >
                          <Suspense
                            fallback={
                              <div>
                                <LoadingSpinner />
                              </div>
                            }
                          >
                            {children}
                          </Suspense>
                          <InProgressCall />
                        </Sentry.ErrorBoundary>
                      </div>
                    </div>
                  </main>
                </div>
              </SidebarProvider>
            </TelnyxRTCProvider>
          </AttendanceProvider>
        </OrganizationProvider>
      </FeatureFlagProvider>
    </div>
  );
}
