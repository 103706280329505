import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { Badge } from '@/modules/ui/components/badge';
import { cptCodeInfo } from '@/modules/patients/constants/cptCodeInfo';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import React from 'react';

export const CPTCodeField = (props) => {
  const record = useRecordContext();
  const codeInfo = get(cptCodeInfo, record.cpt_code);

  if (!record) {
    return null;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Badge variant="outline">{get(record, 'cpt_code', '')}</Badge>
        </TooltipTrigger>
        {codeInfo && (
          <TooltipContent>
            <p>{get(codeInfo, 'description')}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
