import { Button } from "@/modules/ui/components/button";
import { Building2 } from "lucide-react";
import React from "react";
import {
	Form,
	required,
	usePermissions,
	useRefresh,
	useRecordContext,
	useDataProvider,
	useNotify,
} from "react-admin";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from "@/modules/ui/components/dialog";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import { SaveButton } from "./save-button";
import { filterTenantName } from "@/utils/filterTenantName";

export const AssignToProviderButton = (props) => {
	const { resource, label = "Set Provider", allowNullOption = false } = props;

	const record = useRecordContext();
	const { permissions } = usePermissions();

	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { mutate, isPending } = useMutation({
		mutationFn: (value) =>
			dataProvider.updatePatch(resource, {
				id: `${record?.id}/set-owner`,
				data: value,
			}),
	});

	const [open, setOpen] = React.useState(false);

	const onSubmit = (value) => {
		mutate(value, {
			onSuccess: () => {
				refresh();
				setOpen(false);
				notify("Successfully updated the provider", {
					type: "info",
				});
			},
			onError: (error) => {
				notify(
					get(error, "message") ||
						"An error occurred while updating the record",
					{
						type: "error",
					},
				);
			},
		});
	};

	if (!get(permissions, `${resource}.set_owner`)) {
		return null;
	}

	return (
		<div>
			<Button
				onClick={() => setOpen(true)}
				disabled={isPending}
				variant="outline"
				size="sm"
				className="text-black"
				type="button"
			>
				<Building2 className="w-4 h-4 mr-2" />
				{label}
			</Button>

			<Dialog open={open} onOpenChange={setOpen}>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle>Select a provider</DialogTitle>
					</DialogHeader>

					<Form onSubmit={onSubmit}>
						<div className="space-y-4 py-4">
							<AutocompleteInput
								source="tenant_id"
								reference="providers"
								label="Provider"
								optionText="name"
								validate={allowNullOption ? required() : null}
								filterToQuery={filterTenantName}
							/>
						</div>

						<DialogFooter>
							<SaveButton />
						</DialogFooter>
					</Form>
				</DialogContent>
			</Dialog>
		</div>
	);
};
