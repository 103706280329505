import { useEffect, useRef, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { debounce } from 'lodash';
import { usePatientClinicalActivity, type FormValues } from '../context/PatientClinicalActivityContext';
import { useUpdate } from 'ra-core';

interface UseActivityAutosaveOptions {
  enabled?: boolean;
  debounceMs?: number;
  onAutosave?: (data: FormValues) => void;
  onAutosaveError?: (error: Error) => void;
}

/**
 * Hook to handle autosaving of clinical activity forms
 */
export const useActivityAutosave = (
  form: UseFormReturn<FormValues>,
  options: UseActivityAutosaveOptions = {}
) => {
  const {
    enabled = true,
    debounceMs = 1000,
    onAutosave,
    onAutosaveError,
  } = options;

  const patientClinicalActivityContext = usePatientClinicalActivity();
  const [lastSavedAt, setLastSavedAt] = useState<Date | null>(null);
  const [update, { isLoading: isSaving }] = useUpdate();



  const { inProgressActivity } = patientClinicalActivityContext || { inProgressActivity: null };

  // Autosave function
  const autosaveForm =  (data: FormValues) => {
    if (!enabled || !inProgressActivity) return;
    
    update(
      'clinical-activity-instances',
      {
        id: inProgressActivity.id,
        data: {
          data_json: data
        },
      },
      {
        onSuccess: () => {
          console.log('Form autosaved successfully');
          setLastSavedAt(new Date());
          if (onAutosave) {
            onAutosave(data);
          }
        },
        onError: (error) => {
          if (onAutosaveError) {
            onAutosaveError(error);
          }
        },
      } 
      )
  };
  
  // Create debounced version of autosave function
  const debouncedAutosave = useRef(
    debounce((data: FormValues) => {
      autosaveForm(data);
    }, debounceMs)
  ).current;
  
  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedAutosave.cancel();
    };
  }, [debouncedAutosave]);
  
  // Watch for form changes and trigger debounced autosave
  useEffect(() => {
    if (!enabled) return;
    
    const subscription = form.watch((formData) => {
      debouncedAutosave(formData as FormValues);
    });
    
    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, [form, debouncedAutosave, enabled]);

  if (!patientClinicalActivityContext) {
    return {
      lastSavedAt: null,
      isSaving: false,
      saveNow: () => {},
    };
  }

  return {
    lastSavedAt,
    isSaving,
    // Expose a manual save function that bypasses the debounce
    saveNow: () => autosaveForm(form.getValues()),
  };
}; 