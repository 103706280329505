import type { FC } from "react";
import {
	FunctionField,
	List,
	ListContextProvider,
	ReferenceField,
	TextField,
	usePermissions,
	useRecordContext,
	useStore,
} from "react-admin";

import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import { LuxonDateField } from "@/modules/ra-ui/components/LuxonDateField";

import DataTableProviderFilter from "@/modules/data-table/components/data-table-provider-filter";
import { MonitoringProgressIndicator } from "@/modules/home/components/PatientMonitoringProgressList";
import AppTitle from "@/modules/layout/components/app-title";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/modules/ui/components/select";
import { Toggle } from "@/modules/ui/components/toggle";
import clsx from "clsx";

import { DataGridScroll } from "@/modules/data-table/components/data-grid-scroll";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import { MultiSearch } from "@/modules/data-table/components/data-table-multi-search";
import DataTableReferenceFilter from "@/modules/data-table/components/data-table-reference-filter";
import { ListUIRoot } from "@/modules/data-table/components/list-ui-root";
import { Separator } from "@/modules/ui/components/separator";
import { formatDuration } from "@/utils/formatDuration";
import { titleize } from "inflection";
import { get } from "lodash";
import { Hourglass } from "lucide-react";
import { DateTime } from "luxon";
import { DataTableFilter } from "@/modules/data-table/components/data-table-filter";
import { patientStatus } from "@/modules/patients/constants/patientStatus";

export const MonitoringProgress: FC = () => {
	return (
		<div className="pb-2">
			<MonitoringProgressTable />
		</div>
	);
};

const Toolbar = () => (
	<DataTableToolbar
		showViewOptions={false}
		searchProps={{
			source: "user.full_name",
			placeholder: "Search...",
		}}
		search={
			<MultiSearch
				searchFields={[
					{ value: "user.full_name", label: "Name" },
					{ value: "id", label: "ID" },
				]}
			/>
		}
	>
		<DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
		<DataTableFilter
			column="patient.patient_status[in]"
			title="Patient Status"
			options={patientStatus.map((status) => ({
				label: status.name,
				value: status.id,
			}))}
		/>
	</DataTableToolbar>
);

const MonitoringProgressTable = () => {
	const [month, setMonth] = useStore(
		"monitoring-progress.list.month",
		new Date().getMonth().toString(),
	);
	const [year, setYear] = useStore(
		"monitoring-progress.list.year",
		new Date().getFullYear().toString(),
	);

	const [view, setView] = useStore(
		"monitoring-progress.list.viewType",
		"simple",
	);

	const [filterValues, setFilters] = useStore(
		"monitoring-progress.list.carefilter",
		{} as any,
	);

	const [underCare, setUnderCare] = useStore(
		"monitoring-progress.list.under_care",
		false,
	);

	return (
		<ListUIRoot>
			<div className="flex items-center">
				<div className="flex flex-row items-center  mb-1 gap-2">
					<Toggle
						variant="outline"
						aria-label="Toggle under care"
						onClick={() => setUnderCare(!underCare)}
						size="lg"
						className={clsx(
							" h-8 bg-white",

							underCare ? "border-primary" : "bg-white",
						)}
						defaultChecked={underCare}
						disabled={filterValues?.under_care_id ? true : false}
					>
						{filterValues?.under_care_id
							? "Showing Filtered Patients"
							: underCare === true
								? "Showing Patients in my Care"
								: "Showing all Patients"}
					</Toggle>
					<ListContextProvider value={{ filterValues, setFilters } as any}>
						<DataTableReferenceFilter
							resource="users"
							column="under_care_id"
							title="Care Team Member"
							optionText="full_name"
							customFilter={(name) => ({
								...(name &&
									name.length > 1 && {
										q: { field: "full_name", value: name },
									}),
								"role[ne]": `patient`,
								"is_provider[eq]": true,
							})}
						/>
					</ListContextProvider>
					<Separator orientation="vertical" className="h-8" />

					<Select value={view} onValueChange={setView}>
						<SelectTrigger className="w-fit h-8">
							<SelectValue placeholder="Select view" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="simple">Simple</SelectItem>
							<SelectItem value="rpm">RPM</SelectItem>
							<SelectItem value="ccm">CCM</SelectItem>
							<SelectItem value="pcm">PCM</SelectItem>
						</SelectContent>
					</Select>
					<Separator orientation="vertical" className="h-8" />
					<Select value={month} onValueChange={setMonth}>
						<SelectTrigger className="w-fit h-8">
							<SelectValue placeholder="Select month" />
						</SelectTrigger>
						<SelectContent>
							{Array.from({ length: 12 }, (_, i) => i).map((monthIndex) => (
								<SelectItem key={monthIndex} value={monthIndex.toString()}>
									{DateTime.local(2000, monthIndex + 1, 1).monthLong}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<Select value={year} onValueChange={setYear}>
						<SelectTrigger className="w-fit h-8">
							<SelectValue placeholder="Select year" />
						</SelectTrigger>
						<SelectContent>
							{Array.from(
								{ length: new Date().getFullYear() - 2021 },
								(_, i) => i,
							).map((yearOffset) => {
								const yearOption = 2022 + yearOffset;
								return (
									<SelectItem key={yearOption} value={yearOption.toString()}>
										{yearOption}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
			</div>
			<AppTitle title="Monitoring Progress" />
			<List
				actions={<Toolbar />}
				title="Monitoring Progress"
				resource="monitoring-progress"
				sort={{ field: "progress_all", order: "ASC" }}
				filter={{
					"month[eq]": Number(month) + 1,
					"year[eq]": Number(year),
				}}
				exporter={false}
				queryOptions={{
					meta: {
						under_care: filterValues?.under_care_id || underCare,
					},
				}}
				sx={{
					width: "100%",
					"& .RaList-main": {
						width: "100%",
					},
				}}
				empty={
					<>
						<div className="w-full flex justify-center items-center h-full">
							<EmptyState
								title="No monitoring activities found for this range."
								icons={[Hourglass]}
							/>
						</div>
					</>
				}
			>
				<MonitoringProgressView type={view} />
			</List>
		</ListUIRoot>
	);
};

const MonitoringProgressView = ({ type }) => {
	const { permissions } = usePermissions();

	const onClickRow = (a, b, record) => {
		if (get(permissions, "patients.queue")) {
			return `/patients/${record.patient_id}/show/clinical/care-services`;
		}
		return `/patients/${record.patient_id}/show`;
	};

	const headers = {
		simple: [],
		rpm: [
			"99457_qualified_count",
			"99457_optimal_count",
			"99458_qualified_count",
			"99458_optimal_count",
			"99457_and_99458_time",
			"99457_and_99458_remaining_time",
			"99457_and_99458_progress",
			"99091_qualified_count",
			"99091_optimal_count",
			"99091_time",
			"99091_remaining_time",
			"99453_qualified_count",
			"99453_optimal_count",
			"99453_progress",
		],
		ccm: [
			"99491_qualified_count",
			"99491_optimal_count",
			"99437_qualified_count",
			"99437_optimal_count",
			"99491_and_99437_time",
			"99491_and_99437_remaining_time",
			"99490_qualified_count",
			"99490_optimal_count",
			"99439_qualified_count",
			"99439_optimal_count",
			"99490_and_99439_time",
			"99490_and_99439_remaining_time",
			"99490_and_99439_progress",
		],
		pcm: [
			"99424_qualified_count",
			"99424_optimal_count",
			"99425_qualified_count",
			"99425_optimal_count",
			"99424_and_99425_time",
			"99424_and_99425_remaining_time",
			"99426_qualified_count",
			"99426_optimal_count",
			"99427_qualified_count",
			"99427_optimal_count",
			"99426_and_99427_time",
			"99426_and_99427_remaining_time",
			"99426_and_99427_progress",
		],
	}[type];

	return (
		<DataGridScroll
			isRowSelectable={() => false}
			bulkActionButtons={false}
			rowClick={onClickRow}
			preferenceKey={"monitoring-progress." + type}
			omit={[
				"blood_glucose",
				"peak_expiratory_flow_rate",
				"forced_expiratory_volume_1",
			]}
		>
			<ReferenceField
				reference="patients"
				source="patient_id"
				label="Patient"
				link={false}
				sortBy="user.last_name"
			>
				<TextField source="user.full_name" />
			</ReferenceField>
			{type !== "simple" && (
				<FunctionField
					label={`${type.toUpperCase()} Progress (%)`}
					source={`progress_${type}`}
					render={(record) => (
						<MonitoringProgressIndicator
							value={record[`progress_${type}`]}
							type={type}
							data={record.data}
						/>
					)}
				/>
			)}
			{type === "simple" && (
				<FunctionField
					label="RPM Progress (%)"
					source="progress_rpm"
					render={(record) => (
						<MonitoringProgressIndicator
							value={record.progress_rpm}
							type="rpm"
							data={record.data}
						/>
					)}
				/>
			)}
			{type === "simple" && (
				<FunctionField
					label="CCM Progress (%)"
					source="progress_ccm"
					render={(record) => (
						<MonitoringProgressIndicator
							value={record.progress_ccm}
							type="ccm"
							data={record.data}
						/>
					)}
				/>
			)}
			{type === "simple" && (
				<FunctionField
					label="PCM Progress (%)"
					source="progress_pcm"
					render={(record) => (
						<MonitoringProgressIndicator
							value={record.progress_pcm}
							type="pcm"
							data={record.data}
						/>
					)}
				/>
			)}
			<LuxonDateField
				source="enrolled_on"
				label="Enrolled on"
				sortBy="user.enrolled_on"
				showTime={false}
			/>
			<LuxonDateField
				source="last_updated"
				label="Last Updated"
				showTime={true}
			/>
			{headers.map((header) => (
				<TableField
					key={header}
					source={`data.${type}.${header}`}
					label={titleize(header)}
					sortable={false}
				/>
			))}
		</DataGridScroll>
	);
};

const TableField = (props) => {
	const { source, emptyText = null } = props;
	const record = useRecordContext();
	let classes = "";

	let value = get(record, source);

	if (value === null || value === "") {
		return emptyText ? <span>{emptyText}</span> : null;
	}

	if (source.endsWith("remaining_time") || source.endsWith("remaining_count")) {
		if (value > 0) {
			classes = "border-b-4 border-yellow-500 pr-2";
		}
	}

	if (source.endsWith("_time")) {
		value = formatDuration(value);
	}

	return <span className={classes}>{value}</span>;
};
