import React, { cloneElement } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DeleteButton,
  RecordContextProvider,
  ReferenceInput,
  TextField,
  TextInput,
  required,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useRecordContext,
  useCreate,
  useRefresh,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/modules/ui/components/accordion';

import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { Skeleton } from '@/modules/ui/components/skeleton';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import { cn } from '@/modules/ui/utils/cn';
import { humanize } from 'inflection';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/modules/ui/components/alert-dialog';

const carePlanSectionNames = [
  'diagnosis',
  'symptom_management',
  'lifestyle_modifications',
  'medication_adherence',
  'physiologic_health_indicator_monitoring',
  'education',
  'psychosocial_support',
  'emergency_plan',
  'patient_engagement',
  'collaborative_care',
  'documentation_and_communication',
  'monitoring_and_follow_ups',
];

const CarePlanSectionEdit = (props) => {
  const { section, label } = props;

  return (
    <AccordionItem value={section}>
      <AccordionTrigger>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="text-sm font-semibold">
            <span className="font-semibold">
              {label ? label : humanize(section)}
            </span>
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <BooleanInput
          source={`content.${section}.is_active`}
          label="Enable section"
          helperText={false}
        />
        <div className="flex flex-col gap-1">
          <TextInput
            source={`content.${section}.caregiver_instructions`}
            helperText={false}
            multiline
            label="Caregiver Instructions"
          />
          <TextInput
            source={`content.${section}.patient_goals`}
            helperText={false}
            label="Patient Goals"
            multiline
          />
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

const CarePlanSectionShow = (props) => {
  const { section, label } = props;

  return (
    <AccordionItem value={section}>
      <AccordionTrigger>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="text-sm font-semibold">
            <span className="font-semibold">
              {label ? label : humanize(section)}
            </span>
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="flex flex-col gap-1">
          <div className="flex flex-col">
            <label className="font-semibold">Caregiver Instructions</label>
            <TextField source={`content.${section}.caregiver_instructions`} />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold">Patient Goals</label>
            <TextField source={`content.${section}.patient_goals`} />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

function CarePlanEdit(props) {
  const { button = null, showDelete = true } = props;

  return (
    <EditInDialogButton
      title="Edit care plan"
      resource="care-plans"
      variant="outlined"
      deleteButton={
        showDelete ? (
          <DeleteButton
            resource="care-plans"
            redirect={false}
            confirmTitle="Delete Care Plan?"
          />
        ) : null
      }
      refreshOnSuccess={true}
      editButton={button}
      dialogContentClassName="min-w-[400px] lg:min-w-[800px]"
      closeOnClickOutside={false}
    >
      <TextInput
        source="plan_name"
        label="Care Plan Name"
        helperText="This is visible to the patient"
        validate={required()}
      />
      <BooleanInput source="is_active" label="Active" helperText={false} />
      <Accordion type="single" collapsible>
        {carePlanSectionNames.map((section) => (
          <CarePlanSectionEdit key={section} section={section} />
        ))}
      </Accordion>
    </EditInDialogButton>
  );
}

function CarePlanView(props) {
  const { button = null } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const record = useRecordContext();

  return (
    <>
      {cloneElement(button, { onClick: handleClickOpen })}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          onClose={handleClose}
          className={cn(
            'min-w-[400px] lg:min-w-[800px] overflow-y-auto max-h-[90vh]',
          )}
        >
          <DialogHeader>
            <DialogTitle>Care Plan: {record.plan_name}</DialogTitle>
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex-grow">
                <CardDescription>
                  <span>
                    Plan started on{' '}
                    {DateTime.fromISO(record.activated_at).toLocaleString()}
                  </span>
                  {record.deactivated_at && (
                    <span>
                      Plan ended on{' '}
                      {DateTime.fromISO(record.deactivated_at).toLocaleString()}
                    </span>
                  )}
                </CardDescription>
              </div>
              {record.is_active && (
                <div>
                  <Badge color="green">Active</Badge>
                </div>
              )}
            </div>
          </DialogHeader>
          <Accordion type="single" collapsible>
            {carePlanSectionNames.map((section) => (
              <CarePlanSectionShow key={section} section={section} />
            ))}
          </Accordion>
        </DialogContent>
      </Dialog>
    </>
  );
}

function CarePlanRevise(props) {
  const { button = null, carePlan } = props;

  return (
    <CreateInDialogButton
      title="Revise care plan"
      resource="care-plans"
      variant="outline"
      refreshOnSuccess={true}
      createButton={button}
      dialogContentClassName="min-w-[400px] lg:min-w-[800px]"
      closeOnClickOutside={false}
      initialData={{
        ...carePlan,
        care_plan_template_id: null,
      }}
      saveButtonProps={{
        label: 'Revise Plan',
      }}
      enableLogAction
      logActionLabel={(data) => {
        return `Care plan "${data.plan_name}" revised for patient.`;
      }}
    >
      <TextInput
        source="plan_name"
        label="Care Plan Name"
        validate={required()}
        helperText="This is visible to the patient"
      />
      <Accordion type="single" collapsible>
        {carePlanSectionNames.map((section) => (
          <CarePlanSectionEdit key={section} section={section} />
        ))}
      </Accordion>
    </CreateInDialogButton>
  );
}

function ReviewCarePlanButton({ carePlan }) {
  const [create] = useCreate();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);

  const handleReview = () => {
    create(
      `care-plans/${carePlan.id}/review`,
      {
        data: {},
      },
      {
        onSuccess: () => {
          refresh();
        },
      },
    );
  };

  return (
    <>
      <Button variant="outline" onClick={() => setOpen(true)}>
        Review without Changes
      </Button>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Review Care Plan</AlertDialogTitle>
            <AlertDialogDescription>
              Do you acknowledge that you have reviewed this care plan and no
              changes are needed?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleReview}>
              Confirm Review
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

function RecentCarePlansDataGrid({ view = 'all' }) {
  const record = useRecordContext();

  const { data } = useGetList(
    'care-plans',
    {
      pagination: { page: 1, perPage: 10 },
      filter: { 'patient_id[eq]': record?.id },
      sort: {
        field: 'created_at',
        order: 'DESC' as 'ASC' | 'DESC',
      },
    },
    {
      enabled: !!record?.id,
    },
  );
  if (!record || !data) {
    return null;
  }

  const CarePlanComponent = view === 'all' ? CarePlanView : CarePlanEdit;

  return (
    <div className="flex flex-col gap-2 p-4 pt-0 ">
      {data.map((item) => (
        <RecordContextProvider value={item} key={item.id}>
          <CarePlanComponent
            button={
              <button
                key={item.id}
                className={cn(
                  'flex flex-col items-start min-w-[400px] gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
                )}
              >
                <div className="div flex flex-row items-center justify-between w-full">
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex items-center">
                      <div className="flex items-center gap-2">
                        <div className="font-semibold">{item.plan_name}</div>
                      </div>
                      <div className={cn('ml-auto text-xs')}></div>
                    </div>
                    <div className="text-xs font-medium">
                      Created on{' '}
                      {DateTime.fromISO(item.created_at).toLocaleString()}
                    </div>
                    {item.deactivated_at && (
                      <div className="text-xs font-medium">
                        Deactivated on{' '}
                        {DateTime.fromISO(item.deactivated_at).toLocaleString()}
                      </div>
                    )}
                    {!item.deactivated_at && item.activated_at && (
                      <div className="text-xs font-medium">
                        Activated on{' '}
                        {DateTime.fromISO(item.activated_at).toLocaleString()}
                      </div>
                    )}
                  </div>
                  <div className=" text-xs text-muted-foreground">
                    {item.is_active ? (
                      <Badge variant="default">Active</Badge>
                    ) : (
                      <Badge variant="secondary">Inactive</Badge>
                    )}
                  </div>
                </div>
              </button>
            }
          />
        </RecordContextProvider>
      ))}
    </div>
  );
}

export const CarePlanCreateButton = () => {
  const patientRecord = useRecordContext();
  const { identity } = useGetIdentity();

  const transform = (data) => ({
    ...data,
    patient_id: patientRecord.id,
    added_by: identity.id,
  });

  return (
    <CreateInDialogButton
      title="Add care plan"
      transform={transform}
      variant="default"
      label="Care Plan"
      resource="care-plans"
      enableLogAction
      logActionLabel={(data) => {
        return `Care plan "${data.plan_name}" added for patient.`;
      }}
    >
      <ul className="list-none list-inside pb-4">
        <li>
          <span>
            Select a care plan template to create a new care plan for this
            patient.
          </span>
        </li>
        <li>
          <span>
            You'll have a chance to review and modify the care plan before it is
            activated.
          </span>
        </li>
      </ul>
      <ReferenceInput
        source="care_plan_template_id"
        reference="care-plan-templates"
        filter={{
          'status:[eq]': 'active',
        }}
        sort={{ field: 'created_on', order: 'DESC' }}
      >
        <AutocompleteInput
          optionText="name"
          label="Care Plan Template"
          validate={required()}
          filterToQuery={(searchText) => ({
            'name:like': `%${searchText}%`,
          })}
          helperText={false}
        />
      </ReferenceInput>
      <TextInput
        source="plan_name"
        label="Care Plan Name"
        validate={required()}
        helperText="This is visible to the patient"
      />
    </CreateInDialogButton>
  );
};

const NoActiveCarePlan = () => {
  return (
    <div className="w-fit mx-auto">
      <h2 className="text-2xl font-semibold text-gray-900 text-center">
        No Active Care Plan
      </h2>
      <div className="flex flex-col gap-8 items-center">
        <div className="text-justify">
          <p>There is currently no active care plan for this patient.</p>
          <p>Please activate an existing care plan or create a new one.</p>
        </div>
        <CarePlanCreateButton />
        <div className="pt-4">
          <RecentCarePlansDataGrid view="no_active" />
        </div>
      </div>
    </div>
  );
};

const ActiveCarePlan = ({ carePlan }) => {
  if (!carePlan) {
    return null;
  }

  return (
    <div>
      <CardHeader className="py-0 pb-2 px-2">
        <div className="flex flex-row items-center justify-between">
          <div>
            <CardTitle>{carePlan.plan_name}</CardTitle>
            <CardDescription className="py-1">
              <p>
                Plan started on{' '}
                {DateTime.fromISO(carePlan.activated_at).toLocaleString()}
              </p>
            </CardDescription>
          </div>
          <div className="flex gap-2">
            <ReviewCarePlanButton carePlan={carePlan} />
            <CarePlanRevise
              button={<Button variant="default">Revise Plan</Button>}
              carePlan={carePlan}
            />
          </div>
        </div>
      </CardHeader>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-2  ">
        {carePlanSectionNames.map(
          (section) =>
            get(carePlan, ['content', section, 'is_active']) && (
              <div
                key={section}
                className="w-full mx-auto bg-slate-50/40 shadow-inner rounded-lg p-6 mb-1"
              >
                <h3 className="text-lg font-semibold mb-4">
                  {humanize(section)}
                </h3>
                <div className="flex flex-col gap-4">
                  <div>
                    <span className="text-sm font-semibold text-gray-500 block mb-1 underline">
                      Caregiver Instructions
                    </span>
                    <p className="text-md whitespace-pre-wrap">
                      {get(carePlan, [
                        'content',
                        section,
                        'caregiver_instructions',
                      ])}
                    </p>
                  </div>
                  <div>
                    <span className="text-sm font-semibold text-gray-500 block mb-1 underline">
                      Patient Goals
                    </span>
                    <p className="text-md whitespace-pre-wrap">
                      {carePlan.content[section].patient_goals}
                    </p>
                  </div>
                </div>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default function CarePlansSection() {
  const record = useRecordContext();

  const dataProvider = useDataProvider();

  const { data: carePlan, isLoading } = useQuery({
    queryKey: [`patients/${record?.id}/active-care-plan`],
    queryFn: () =>
      dataProvider.getPatientResource('active-care-plan', {
        patient_id: `${record?.id}`,
      }),
    enabled: !!record,
  });

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y- mb-8">
      <div className="pb-2">
        <h3 className="text-xl font-semibold">Care Plans</h3>
        <p className="text-sm text-gray-500">
          A care plan is used to facilitate and organize the care of a patient.
          It is a set of actions the care team will take to help the patient
          manage their health.
        </p>
      </div>
      <div className="flex ">
        {isLoading ? (
          <Skeleton className="w-[300px] xl:w-[600px] h-[300px] rounded" />
        ) : (
          <Card className=" mb-8 py-4 min-w-full">
            <CardContent>
              <div className="flex flex-row items-center justify-between mb-2">
                <div className="text-sm text-muted-foreground">
                  Care plan last reviewed on{' '}
                  <span className="font-semibold text-base ">
                    <LuxonDateField
                      source="care_plan_last_reviewed"
                      showTime
                      emptyText="Never"
                    />
                  </span>
                </div>
              </div>
              <Tabs defaultValue="active">
                {carePlan?.data && (
                  <TabsList className="w-fit mx-auto">
                    <TabsTrigger value="active">Active Care Plan</TabsTrigger>
                    <TabsTrigger value="all">All Care Plans</TabsTrigger>
                  </TabsList>
                )}
                <div className="flex   flex-col space-y-2">
                  <TabsContent value="active">
                    {carePlan?.data ? (
                      <ActiveCarePlan carePlan={carePlan.data} />
                    ) : (
                      <NoActiveCarePlan />
                    )}
                  </TabsContent>

                  <TabsContent value="all">
                    {carePlan?.data ? (
                      <RecentCarePlansDataGrid />
                    ) : (
                      <NoActiveCarePlan />
                    )}
                  </TabsContent>
                </div>
              </Tabs>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
