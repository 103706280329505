import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardDescription,
  CardTitle,
} from '@/modules/ui/components/card';

export const CardSectionLayout = (props) => {
  const { title, helpText, children } = props;
  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          {helpText && <CardDescription>{helpText}</CardDescription>}
        </CardHeader>
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
};
