import { Identifier, useStore } from 'react-admin';
import React from 'react';

// Configuration constants
const TABLE_STATE_KEY_SUFFIX = 'patientTableState';
const MAX_RECENT_PATIENTS = 10;

// Default state object to ensure we always have valid values
const DEFAULT_STATE = {
    page: 1,
    perPage: 10,
    filters: {},
    filterValues: {},  // Add filterValues explicitly
    displayedFilters: {},  // Add displayedFilters explicitly
};

interface TableStateDefaults {
    defaultSort?: { field: string | null; order: 'ASC' | 'DESC' | null };
    defaultFilterValues?: Record<string, any>;
}

export const usePatientTableState = (
    tableName: string,
    patientId: string | number | Identifier,
    defaults: TableStateDefaults = {
        defaultSort: { field: null, order: null },
        defaultFilterValues: {}
    }
) => {
    const { defaultSort, defaultFilterValues } = defaults;
    const storageKey = `preferences.${tableName}.${TABLE_STATE_KEY_SUFFIX}`;
    
    const [tableStorage, setTableStorage] = useStore<{
        recent: string[];
        states: Record<string, any>;
    }>(storageKey, {
        recent: [],
        states: {}
    });

    // Get or initialize patient state with default values
    const patientState = React.useMemo(() => ({
        ...DEFAULT_STATE,
        sort: defaultSort,
        filterValues: defaultFilterValues,
        ...tableStorage.states?.[String(patientId)],
    }), [patientId, tableStorage.states, defaultSort, defaultFilterValues]);

    // Update storage with ordered pruning logic
    const updateStorage = (newState: Partial<typeof patientState>) => {
        setTableStorage(prev => {
            const patientIdStr = String(patientId);
            const newRecent = [
                patientIdStr,
                ...(prev.recent || []).filter(id => id !== patientIdStr)
            ].slice(0, MAX_RECENT_PATIENTS);

            const newStates = {
                ...(prev.states || {}),
                [patientIdStr]: {
                    ...patientState,  // Use current state as base
                    ...newState
                }
            };

            // Maintain order based on recent array when pruning
            const prunedStates = newRecent.reduce((acc, id) => {
                if (newStates[id]) {
                    acc[id] = newStates[id];
                }
                return acc;
            }, {} as typeof newStates);

            return {
                recent: newRecent,
                states: prunedStates
            };
        });
    };

    // Initialize if doesn't exist
    React.useEffect(() => {
        const patientIdStr = String(patientId);
        if (!tableStorage.states?.[patientIdStr]) {
            updateStorage(patientState);
        }
    }, [patientId, tableStorage.states]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        ...patientState,
        setPage: (page: number) => updateStorage({ page }),
        setPerPage: (perPage: number) => updateStorage({ perPage }),
        setSort: (sort: { field: string | null; order: 'ASC' | 'DESC' | null }) => updateStorage({ sort }),
        setFilters: (filters: Record<string, any>) => updateStorage({ filters, filterValues: filters }), // Update both filters and filterValues
        setDisplayedFilters: (displayedFilters: Record<string, boolean>) => updateStorage({ displayedFilters })
    };
}; 