import AppTitle from "@/modules/layout/components/app-title";
import { PatientNotes } from "@/modules/patients/components/PatientNotes";
import { Badge } from "@/modules/ui/components/badge";
import { Card } from "@/modules/ui/components/card";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/modules/ui/components/dropdown-menu";
import { Skeleton } from "@/modules/ui/components/skeleton";
import { cn } from "@/modules/ui/utils/cn";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { titleize } from "inflection";
import { find, get } from "lodash";
import { MoreVertical } from "lucide-react";
import React from "react";
import {
	EditButton,
	FunctionField,
	Link,
	Button as RAButton,
	ReferenceField,
	usePermissions,
	useRecordContext,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { monitoringConditionColor } from "../../../utils/color";
import { dateFormatter } from "../../../utils/formatedDate";
import { ColoredChipField } from "../../ra-ui/components/ColoredChipField";
import { conditionNames } from "../constants/conditionNames";
import { patientStatus as patientStatusList } from "../constants/patientStatus";
import { usePatientStatus } from "../hooks/patient-status";
import DiagnosisBadge from "./DiagnosisBadge";
import { InfoBlock } from "./InfoBlock";
import { PatientPhoneNumber } from "./PatientPhoneNumberOverview";
import { PatientStatusDialog } from "./PatientStatusDialog";
import { UnenrollPatientDialog } from "./UnenrollPatientDialog";
import { PatientEmailShow } from "./PatientEmailShow";
import { useAttendance } from "@/modules/attendance/hooks/UserCheckedIn";
import { MonitoringActivityCreateDepreciatedButton } from "@/modules/monitoring-activity/components/MonitoringActivityCreateDepreciatedButton";
const PatientToolbarDropDown = () => {
	const [openPatientStatusDialog, setOpenPatientStatusDialog] =
		React.useState(false);
	const [openUnenrollPatientDialog, setOpenUnenrollPatientDialog] =
		React.useState(false);

	const record = useRecordContext();
	return (
		<>
			<PatientStatusDialog
				open={openPatientStatusDialog}
				setOpen={setOpenPatientStatusDialog}
			/>
			<UnenrollPatientDialog
				open={openUnenrollPatientDialog}
				setOpen={setOpenUnenrollPatientDialog}
			/>
			<DropdownMenu>
				<DropdownMenuTrigger>
					<MoreVertical />
				</DropdownMenuTrigger>
				<DropdownMenuContent className="flex flex-col" align="end">
					<DropdownMenuItem onClick={() => setOpenPatientStatusDialog(true)}>
						Set Patient Status
					</DropdownMenuItem>
					{record.patient_status === "disabled" ? null : (
						<DropdownMenuItem
							onClick={() => setOpenUnenrollPatientDialog(true)}
							className="bg-destructive text-destructive-foreground focus:bg-destructive/90 focus:text-destructive-foreground"
						>
							Unenroll Patient
						</DropdownMenuItem>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
		</>
	);
};

const PatientStatusIndicator = () => {
	const patientStatus = usePatientStatus();
	const patientStatusValue = find(patientStatusList, ["id", patientStatus]);

	return (
		<Badge
			variant="secondary"
			className={cn(
				"text-md",
				"pointer-events-none",
				"whitespace-nowrap	",
				patientStatusValue?.className,
			)}
		>
			{titleize(patientStatusValue?.name || patientStatus)}
		</Badge>
	);
};

const PatientDOBShow = () => {
	const record = useRecordContext();
	const { attendanceEnforced } = useAttendance();

	if (attendanceEnforced && record.patient_status === "pending_enrollment") {
		return (
			<InfoBlock
				title="Date of Birth"
				component={<div className="w-24 h-6 bg-gray-200 blur-sm rounded " />}
			/>
		);
	}

	return (
		<InfoBlock
			title="Date of Birth"
			text={dateFormatter(get(record, "birthdate"))}
		/>
	);
};

const NameToolbar = () => {
	const record = useRecordContext();
	const { permissions } = usePermissions();
	const location = useLocation();
	const patientStatus = usePatientStatus();

	return (
		<div className="flex items-center justify-between mb-2">
			<div className="flex flex-wrap items-center">
				<div className="mr-4">
					<h1 className="text-4xl font-semibold">
						{get(record, "user.full_name")}
					</h1>
				</div>
				<PatientStatusIndicator />
			</div>
			<div className="flex flex-row gap-2">
				{location.pathname.includes("clinical") ? (
					<MonitoringActivityCreateDepreciatedButton />
				) : null}
				{location.pathname.includes("clinical") ? (
					<Link to={`/patients/${record.id}/show`}>
						<RAButton variant="text" label="Profile">
							<AccountBoxIcon />
						</RAButton>
					</Link>
				) : get(permissions, "patients.queue") &&
					!["pending_enrollment"].includes(patientStatus) ? (
					<Link to={`/patients/${record.id}/show/clinical`}>
						<RAButton variant="text" label="Clinical">
							<MonitorHeartIcon />
						</RAButton>
					</Link>
				) : null}

				{get(permissions, "patients.update") && (
					<>
						<EditButton variant="text" label="Edit" />
						<PatientToolbarDropDown />
					</>
				)}
			</div>
		</div>
	);
};

export const PatientOverview = () => {
	const record = useRecordContext();
	const location = useLocation();

	const heightValue = get(record, "patient.profile.demographics.height.value");
	const heightUnit = get(
		record,
		"patient.profile.demographics.height.unit.text",
	);
	const height = !heightValue
		? "N/A"
		: heightUnit === "in"
			? `${Math.floor(heightValue / 12)}' ${Math.round(heightValue % 12)}"`
			: `${Math.round(heightValue)} ${heightUnit}`;
	const weightValue = get(record, "patient.profile.demographics.weight.value");
	const weightUnit = get(
		record,
		"patient.profile.demographics.weight.unit.text",
	);
	const weight = weightValue
		? `${Math.round(weightValue)} ${weightUnit}`
		: "N/A";

	if (!record) {
		return (
			<div className="flex flex-col gap-2">
				<Skeleton className="w-full sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[50vw] h-[50px]" />
				<Skeleton className="w-full sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[50vw] h-[170px]" />
			</div>
		);
	}

	return (
		<div className="mb-2 w-full">
			<AppTitle title={`Patient`} onlyHeader />
			<NameToolbar />
			<Card className="flex flex-wrap px-4 py-2">
				<div className="w-full lg:w-3/4 xl:w-4/5 ">
					<div className="mb-2 grid grid-cols-2  2xl:grid-cols-3 gap-1 xl:gap-2">
						<InfoBlock
							title="Monitoring Condition"
							component={
								<ColoredChipField
									source="primary_condition"
									colorScheme={monitoringConditionColor}
									mapping={conditionNames}
								/>
							}
						/>

						<InfoBlock
							title="Age, Sex"
							text={`${get(
								record,
								"patient.profile.demographics.age",
								"",
							)}, ${get(record, "patient.profile.demographics.gender", "")}`}
						/>
						<PatientDOBShow />

						<InfoBlock
							title="Enrolled on"
							text={dateFormatter(get(record, "user.enrolled_on")) || "Not set"}
						/>

						<PatientPhoneNumber />

						<InfoBlock title="Email" component={<PatientEmailShow />} />
						<InfoBlock
							title="Provider"
							component={
								<ReferenceField
									label="Provider"
									source="user.tenant_id"
									reference="providers"
									link={false}
								>
									<FunctionField
										render={(record) => (
											<p className="text-base font-medium overflow-auto whitespace-normal text-wrap break-words	">
												{get(record, "name", "")}
											</p>
										)}
									/>
								</ReferenceField>
							}
						/>

						<InfoBlock
							title="Care Setting"
							text={titleize(get(record, "care_setting", ""))}
						/>
						<InfoBlock title="Diagnoses" component={<DiagnosisBadge />} />

						<InfoBlock
							title="Primary Billing Provider"
							component={
								<ReferenceField
									label="Provider"
									source="billing_provider_id"
									reference="users"
									link={false}
								>
									<FunctionField
										render={(record) => (
											<p className="text-base font-medium overflow-auto whitespace-normal text-wrap break-words">
												{get(record, "full_name", "")}
											</p>
										)}
									/>
								</ReferenceField>
							}
						/>

						<InfoBlock
							title="Location"
							component={
								<ReferenceField
									label="Location"
									source="facility_location_id"
									reference="locations"
									link={false}
								>
									<FunctionField
										render={(record) => (
											<p className="text-base font-medium overflow-auto whitespace-normal text-wrap break-words">
												{get(record, "name", "N/A")}
											</p>
										)}
									/>
								</ReferenceField>
							}
						/>

						{location.pathname.includes("clinical") && (
							<>
								<InfoBlock title="Height" text={height} />
								<InfoBlock title="Weight" text={weight} />
								<InfoBlock
									title="Smoking Status"
									text={`${get(record, "patient.profile.smoking") ?? "N/A"}`}
								/>
							</>
						)}
					</div>
				</div>
				<div className="w-full md:w-1/4 xl:w-1/5 ">
					<PatientNotes />
				</div>
			</Card>
		</div>
	);
};
