import React from 'react';
import {
  BooleanInput,
  DateInput,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { MedicationSearchInput } from './MedicationSearchInput';
import { MedicationDosageInput } from './MedicationDosageInput';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { DateTime } from 'luxon';

const periodUnits = [
  { id: 'd', name: 'Day' },
  { id: 'wk', name: 'Week' },
  { id: 'mo', name: 'Month' },
  { id: 'a', name: 'Year' },
];

export const MedicationsCreateButton = (props) => {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      ...data,
      source: 'vironix',
      category: 'outpatient',
      status: 'active',
      // Import DateTime from luxon at the top of the file
      start_date: data.start_date
        ? DateTime.fromISO(data.start_date).startOf('day').toUTC().toISO({
            includeOffset: false,
          })
        : undefined,
      end_date: data.end_date
        ? DateTime.fromISO(data.end_date).startOf('day').toUTC().toISO({
            includeOffset: false,
          })
        : undefined,
    };
  };

  return (
    <CreateInDialogButton
      title="Add new medication"
      resource={`patients/${record?.id}/medication-statements`}
      transform={transform}
      variant="default"
      label="Medication"
      notifyMessage="Medication added"
      saveAndAddAnother
      {...props}
    >
      <div className="flex flex-col gap-1">
        <MedicationSearchInput />
        <div className="flex flex-col">
          <p>Dosing</p>
          <MedicationDosageInput />
          <div className="flex flex-row gap-1 items-center">
            <TextInput
              source="dosage[0].timing.repeat.frequency"
              label="Frequency"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              validate={required()}
            />
            <SelectInput
              source="dosage[0].timing.repeat.periodUnit"
              choices={periodUnits}
              label="Period Unit"
              validate={required()}
            />
          </div>
          <BooleanInput source="dosage[0].as_needed" label="As Needed" />
        </div>
        <div className="flex flex-col">
          <p>Dosage Period</p>
          <div className="flex flex-row gap-1">
            <DateInput
              source="start_date"
              label="Start Date"
              validate={required()}
            />
            <DateInput source="end_date" label="End Date" />
          </div>
        </div>
        <TextInput
          source="additional_info"
          label="Notes"
          multiline
          fullWidth
          minRows={3}
        />
      </div>
    </CreateInDialogButton>
  );
};
