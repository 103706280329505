import { cn } from '@/modules/ui/utils/cn';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';
import React, {
  useEffect,
  useRef,
  useState,
  type FC,
  useCallback,
  useMemo,
} from 'react';
import { useController } from 'react-hook-form';
import { Button } from './button';
import { Calendar } from './calendar';
import { DateInput } from './date-input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';

export interface DateRangePickerProps {
  column: string;
  initialDateFrom?: Date | string;
  initialDateTo?: Date | string;
  initialCompareFrom?: Date | string;
  initialCompareTo?: Date | string;
  align?: 'start' | 'center' | 'end';
  locale?: string;
  placeHolderText?: string;
  presets?: Preset[];
}

const formatDate = (date: Date, locale: string = 'en-us'): string => {
  return (
    date?.toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }) ?? ''
  );
};

interface DateRange {
  from: Date;
  to: Date | undefined;
}

interface Preset {
  name: string;
  label: string;
  getRange: () => DateRange;
}

// Define default presets
const DEFAULT_PRESETS: Preset[] = [
  {
    name: 'last7',
    label: 'Last 7 days',
    getRange: () => {
      const to = new Date();
      const from = new Date(to);
      from.setDate(from.getDate() - 6);
      return { from, to };
    },
  },
  {
    name: 'last14',
    label: 'Last 14 days',
    getRange: () => {
      const to = new Date();
      const from = new Date(to);
      from.setDate(from.getDate() - 13);
      return { from, to };
    },
  },
  {
    name: 'last30',
    label: 'Last 30 days',
    getRange: () => {
      const to = new Date();
      const from = new Date(to);
      from.setDate(from.getDate() - 29);
      return { from, to };
    },
  },
  {
    name: 'thisMonth',
    label: 'This Month',
    getRange: () => {
      const now = new Date();
      const from = new Date(now.getFullYear(), now.getMonth(), 1);
      const to = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return { from, to };
    },
  },
  {
    name: 'lastMonth',
    label: 'Last Month',
    getRange: () => {
      const now = new Date();
      const from = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const to = new Date(now.getFullYear(), now.getMonth(), 0);
      return { from, to };
    },
  },
];

export const DateRangePicker: FC<DateRangePickerProps> & {
  filePath: string;
} = ({
  column,
  align = 'end',
  locale = 'en-US',
  placeHolderText = 'Select range',
  presets = DEFAULT_PRESETS,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { field } = useController({ name: column });

  const initRange = useCallback((newRange: DateRange | null) => {
    if (newRange === null) return null;
    return {
      from: new Date(newRange.from),
      to: newRange.to ? new Date(newRange.to) : undefined,
    };
  }, []);

  const [range, setRange] = useState<DateRange | null>(initRange(field.value));

  const onUpdate = useCallback(
    (values: DateRange | null) => {
      field.onChange(values);
    },
    [field],
  );

  const openedRangeRef = useRef<DateRange | null>(null);

  const [selectedPreset, setSelectedPreset] = useState<string | undefined>(
    undefined,
  );

  const [isSmallScreen, setIsSmallScreen] = useState(
    typeof window !== 'undefined' ? window.innerWidth < 960 : false,
  );

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setPreset = useCallback(
    (preset: string): void => {
      const selectedPreset = presets.find((p) => p.name === preset);
      if (selectedPreset) {
        setRange(selectedPreset.getRange());
      }
    },
    [presets],
  );

  const checkPreset = useCallback(() => {
    if (!range) return;

    const matchedPreset = presets.find((preset) => {
      const presetRange = preset.getRange();
      return (
        range.from.toDateString() === presetRange.from.toDateString() &&
        range.to?.toDateString() === presetRange.to?.toDateString()
      );
    });

    setSelectedPreset(matchedPreset?.name);
  }, [range, presets]);

  const resetValues = useCallback((): void => {
    setRange(null);
    onUpdate(null);
  }, [onUpdate]);

  useEffect(() => {
    checkPreset();
  }, [range, checkPreset]);

  useEffect(() => {
    if (field.value === null) {
      setRange(null);
      setSelectedPreset(undefined);
    }
  }, [field.value]);

  useEffect(() => {
    const newRange = initRange(field.value);
    setRange(newRange);
  }, [field.value, initRange]);

  const PresetButton = useCallback(
    ({
      preset,
      label,
      isSelected,
    }: {
      preset: string;
      label: string;
      isSelected: boolean;
    }) => (
      <Button
        className={cn(isSelected && 'pointer-events-none')}
        variant="ghost"
        onClick={() => setPreset(preset)}
      >
        <>
          <span className={cn('pr-2 opacity-0', isSelected && 'opacity-70')}>
            <Check width={18} height={18} />
          </span>
          {label}
        </>
      </Button>
    ),
    [setPreset],
  );

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range;
    }
  }, [isOpen, range]);

  const rangeDisplay = useMemo(() => {
    if (!range) return placeHolderText;
    return `${formatDate(range.from, locale)}${
      range.to ? ' - ' + formatDate(range.to, locale) : ''
    }`;
  }, [range, locale, placeHolderText]);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(open: boolean) => {
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button size="default" className="h-8" variant="outline">
          <div className="text-right">{rangeDisplay}</div>
          <div className="pl-1 opacity-60 -mr-2 scale-125">
            {isOpen ? (
              <ChevronUp className="w-12" />
            ) : (
              <ChevronDown className="w-12" />
            )}
          </div>
        </Button>
      </PopoverTrigger>

      <PopoverContent align={align} className="w-auto">
        <div className="flex py-2">
          <div className="flex">
            <div className="flex flex-col">
              <div className="flex flex-col lg:flex-row gap-2 px-3 justify-end items-center lg:items-start pb-4 lg:pb-0">
                <div className="flex items-center space-x-2 pr-4 py-1"></div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <DateInput
                      value={range?.from || ''}
                      onChange={(date) => {
                        const toDate =
                          !range?.to || date > range.to ? date : range.to;
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: date,
                          to: toDate,
                        }));
                      }}
                    />
                    <div className="py-1">-</div>
                    <DateInput
                      value={range?.to || ''}
                      onChange={(date) => {
                        const fromDate = date < range.from ? date : range.from;
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: fromDate,
                          to: date,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              {isSmallScreen && (
                <Select
                  defaultValue={selectedPreset}
                  onValueChange={(value) => {
                    setPreset(value);
                  }}
                >
                  <SelectTrigger className="w-[180px] mx-auto mb-2">
                    <SelectValue placeholder="Select..." />
                  </SelectTrigger>
                  <SelectContent>
                    {presets.map((preset) => (
                      <SelectItem key={preset.name} value={preset.name}>
                        {preset.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
              <div>
                <Calendar
                  mode="range"
                  onSelect={(value: { from?: Date; to?: Date } | undefined) => {
                    if (value?.from) {
                      setRange({ from: value.from, to: value?.to });
                    }
                  }}
                  selected={range}
                  numberOfMonths={isSmallScreen ? 1 : 2}
                  defaultMonth={
                    new Date(
                      new Date().setMonth(
                        new Date().getMonth() - (isSmallScreen ? 0 : 1),
                      ),
                    )
                  }
                />
              </div>
            </div>
          </div>
          {!isSmallScreen && (
            <div className="flex flex-col items-end gap-1 pr-2 pl-6 pb-6">
              <div className="flex w-full flex-col items-end gap-1 pr-2 pl-6 pb-6">
                {presets.map((preset) => (
                  <PresetButton
                    key={preset.name}
                    preset={preset.name}
                    label={preset.label}
                    isSelected={selectedPreset === preset.name}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 py-2 pr-4">
          <Button
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
            variant="ghost"
          >
            Clear
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              onUpdate?.(range);
            }}
          >
            Update
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

DateRangePicker.displayName = 'DateRangePicker';
DateRangePicker.filePath =
  'libs/shared/ui-kit/src/lib/date-range-picker/date-range-picker.tsx';
