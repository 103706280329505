import { Button } from "@/modules/ui/components/button";
import {
	Command,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/modules/ui/components/command";
import { FormLabel } from "@/modules/ui/components/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/modules/ui/components/popover";
import { Skeleton } from "@/modules/ui/components/skeleton";
import { inputBaseClassname } from "@/modules/ui/inputs/input-base-classname";
import { cn } from "@/modules/ui/utils/cn";
import { useQuery } from "@tanstack/react-query";
import { Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { useController, useWatch } from "react-hook-form";

export const MedicationSearchInput = ({
	source,
	modal = true,
	className,
}: {
	modal?: boolean;
	className?: string;
	source: string;
}) => {
	const dataProvider = useDataProvider();
	const [medicationSearch, setMedicationSearch] = useState("");
	const [open, setOpen] = useState(false);
	const field = useController({
		name: source,
		rules: { required: true },
	});

	const watchSelf = useWatch({
		name: source,
	});

	// Preload search with watchSelf.name if it exists and medicationSearch is empty
	useEffect(() => {
		if (!medicationSearch && watchSelf) {
			setMedicationSearch(watchSelf);
		}
	}, [watchSelf, medicationSearch]);

	const { data: medicationResult, isLoading } = useQuery({
		queryKey: ["medicationsNames", medicationSearch],
		queryFn: () =>
			dataProvider.getCustom(`medications/search`, {
				meta: { q: medicationSearch },
			}),
		enabled: !!medicationSearch,
	});

	const searchMedications = (searchText: string) => {
		if (searchText && searchText.length >= 3) {
			setMedicationSearch(searchText);
		}
	};

	const choices = medicationResult
		? medicationResult.data.map((foundMedication) => ({
				display: foundMedication.name,
				code: foundMedication.rxcui,
				system: "rxnorm",
			}))
		: [];

	return (
		<div className={cn(inputBaseClassname, className)}>
			<FormLabel>Medication</FormLabel>
			<Popover open={open} onOpenChange={setOpen} modal={modal}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className="w-full justify-between h-10"
					>
						{field.field.value?.display || "Select medication..."}
						<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[300px] p-0">
					<Command shouldFilter={false}>
						<CommandInput
							placeholder="Search medications..."
							onValueChange={searchMedications}
						/>
						<CommandList className="max-h-[200px]">
							{isLoading ? (
								<CommandItem className="py-6 text-center">
									<Skeleton className="h-4 w-full" />
								</CommandItem>
							) : choices.length === 0 ? (
								<CommandEmpty>
									{medicationSearch.length < 3
										? "Type at least 3 characters to search"
										: "No medications found"}
								</CommandEmpty>
							) : (
								choices.map((option) => (
									<CommandItem
										key={option.code}
										onSelect={() => {
											field.field.onChange(option);
											setOpen(false);
										}}
									>
										<Check
											className={cn(
												"mr-2 h-4 w-4",
												field.field.value?.display === option.display
													? "opacity-100"
													: "opacity-0",
											)}
										/>
										{option.display}
									</CommandItem>
								))
							)}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	);
};
