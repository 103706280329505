import { cn } from '@/modules/ui/utils/cn';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, memo } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

export interface DateFieldProps {
  className?: string;
  emptyText?: string;
  source: string;
  label?: string;
  showTime?: boolean;
  showDate?: boolean;
  zone?: string;
  sortBy?: string;
}

export const LuxonDateField: FC<DateFieldProps> = memo((props) => {
  const {
    className,
    emptyText,
    showTime = false,
    showDate = true,
    source,
    zone = 'local',
  } = props;
  const translate = useTranslate();

  if (!showTime && !showDate) {
    throw new Error(
      '<DateField> cannot have showTime and showDate false at the same time',
    );
  }

  const record = useRecordContext(props);
  if (!record) {
    return null;
  }

  const value = get(record, source);
  if (value === null || value === '') {
    return emptyText ? (
      <span className={cn('text-sm', className)}>
        {emptyText && translate(emptyText, { _: emptyText })}
      </span>
    ) : null;
  }

  // Check if the value is a date-only string (no time component)
  const isDateOnly =
    typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value);

  const date =
    value instanceof Date
      ? value
      : isDateOnly
        ? DateTime.fromISO(value) // Don't specify zone for date-only strings
        : DateTime.fromISO(value, {
            zone: 'utc',
          }).setZone(zone);

  let dateString = '';
  if (showTime && showDate) {
    dateString = date.toLocaleString(DateTime.DATETIME_MED);
  } else if (showDate) {
    dateString = date.toLocaleString(DateTime.DATE_MED);
  } else if (showTime) {
    dateString = date.toLocaleString(DateTime.TIME_SIMPLE);
  }

  return <span className={cn('text-sm', className)}>{dateString}</span>;
});
