import { Form, FormProps } from 'ra-core';

import React from 'react';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const PREFIX_KEY = 'vironix';

interface StoredFormProps extends FormProps {
  formKey: string;
}

const StoredForm: React.FC<StoredFormProps> = ({
  formKey,
  children,
  ...props
}) => {
  const storedData = sessionStorage.getItem(`${PREFIX_KEY}.${formKey}`);
  const defaultValues = storedData ? JSON.parse(storedData) : null;

  const shouldLoadStoredData = () => {
    if (!storedData) return false;
    const parsedData = JSON.parse(storedData);
    const keys = Object.keys(parsedData);
    return keys.length > 1 || (keys.length === 1 && keys[0] !== 'reviewed_on');
  };

  const initialValues = shouldLoadStoredData() ? defaultValues : undefined;

  return (
    <Form defaultValues={{}} {...props}>
      <FormStorage formKey={formKey} initialValues={initialValues} />
      {children}
    </Form>
  );
};

const FormStorage = ({
  formKey,
  initialValues,
}: { formKey: string; initialValues: any }) => {
  const form = useFormContext();
  const key = `${PREFIX_KEY}.${formKey}`;

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      setTimeout(() => {
        form.reset(initialValues, {
          keepDefaultValues: true,
        });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const saveForm = (formData: any) => {
      const hasNonEmptyValues = Object.values(formData).some(
        (value) => value !== null && value !== undefined && value !== '',
      );

      if (Object.keys(formData).length === 0 || !hasNonEmptyValues) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(formData));
      }
    };

    const subscription = form.watch((formData) => {
      saveForm(formData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, key]);

  return null;
};

export default StoredForm;
