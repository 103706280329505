import ProviderField from '@/modules/ra-ui/components/ProviderField';
import AppTitle from '@/modules/layout/components/app-title';
import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import React, { type FC } from 'react';
import {
  DateField,
  ReferenceField,
  TextField,
  WrapperField,
  ListBase,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '@/modules/data-table/components/data-table-empty-state';
import { DataTable } from '@/modules/data-table/components/data-table';
import { FileText } from 'lucide-react';

const getColumns = () => {
  return [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Report Date',
      accessorKey: 'report_date',
      cell: () => <DateField source="report_date" />,
    },
    {
      header: 'Range',
      accessorKey: 'range_start',
      cell: () => (
        <WrapperField label="Range" sortBy="range_start">
          <DateField source="range_start" />
          {' - '}
          <DateField source="range_end" />
        </WrapperField>
      ),
    },
    {
      header: 'Type',
      accessorKey: 'type',
      cell: () => <BadgeField source="type" />,
    },
    {
      header: 'Provider',
      accessorKey: 'tenant_id',
      cell: () => <ProviderField label="Provider" source="tenant_id" />,
    },
    {
      header: 'Patient',
      accessorKey: 'patient_id',
      cell: () => (
        <ReferenceField
          label="Patient"
          source="patient_id"
          reference="users"
          link={(record) =>
            `/patients/${record.id}/show/clinical/care-services`
          }
          sortable={false}
          emptyText="N/A"
        >
          <TextField source="full_name" />
        </ReferenceField>
      ),
    },
  ];
};

export const ReportList: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <AppTitle title="Reports" />
      <ListBase
        sort={{
          field: null,
          order: null,
        }}
      >
        <div className="flex flex-col gap-2 mx-2">
          <DataTable
            toolbar={
              <DataTableToolbar showViewOptions={true}>
                <DataTableDateRangeFilter
                  column="range_start"
                  title="Date range"
                  dateOnly={true}
                />
                <DataTableProviderFilter
                  column="tenant_id[eq]"
                  title="Provider"
                />
                <DataTableFilterSingle
                  column="type[eq]"
                  title="Report Type"
                  options={[
                    {
                      label: 'Monitoring by Customer',
                      value: 'monitoring_report',
                    },
                    {
                      label: 'Monitoring by Clinician',
                      value: 'clinician_monitoring_report',
                    },
                    {
                      label: 'Care plans by Clinician',
                      value: 'care_plan_clinician_report',
                    },
                    {
                      label: 'Care plans by Customer',
                      value: 'care_plan_report',
                    },
                  ]}
                />
              </DataTableToolbar>
            }
            columns={getColumns()}
            emptyState={
              <EmptyState
                title="No reports found"
                description="Reports are automatically generated once services begin."
                icons={[FileText]}
              />
            }
            onRowClick={(row) => {
              navigate(`/reports/${row.id}/show`);
            }}
          />
        </div>
      </ListBase>
    </>
  );
};
