import { useContext } from 'react';
import { FeatureFlagContext } from '../components/FeatureFlagProvider';

interface UseFeatureFlagOptions {
  defaultValue?: unknown;
}

/**
 * Hook to access feature flags
 * 
 * @param flagId - The ID of the feature flag to check
 * @param options - Optional configuration
 * @returns An object containing the flag data and loading state
 */
export function useFeatureFlag(flagId: string, options: UseFeatureFlagOptions = {}) {
  const { flags, isLoading, error, refetchFlags } = useContext(FeatureFlagContext);

  // Get the flag or undefined if it doesn't exist
  const flag = flags[flagId] ?? options.defaultValue;
  
  // Check if the flag is enabled
  const isEnabled = Boolean(flag);
  
  // Get the flag value or the default value
  
  return {
    isEnabled,
    flag,
    isLoading,
    error,
    refetchFlags,
  };
}

/**
 * Hook to access all feature flags
 * 
 * @returns An object containing all flags and loading state
 */
export function useFeatureFlags() {
  const { flags, isLoading, error, refetchFlags } = useContext(FeatureFlagContext);
  
  return {
    flags,
    isLoading,
    error,
    refetchFlags,
  };
} 