import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/modules/ui/components/accordion';
import { Button } from '@/modules/ui/components/button';
import { Card, CardContent, CardFooter } from '@/modules/ui/components/card';
import { MultiSelectInput } from '@/modules/ui/inputs/multi-select-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import { TextInput } from '@/modules/ui/inputs/text-input';
import {
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ArrowLeft, GripVertical, X, Copy, Trash, ArrowUp } from 'lucide-react';
import {
  Form,
  required,
  useCreate,
  useGetList,
  useGetOne,
  useNotify,
  useUpdate,
  useRefresh,
  useDelete,
} from 'ra-core';
import { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import { ClinicalActivityContainerUI } from '@/modules/clinical-activities/components/ClinicalActivityContainer';
import { triggerTypes } from './triggers';
import { serviceInstanceTypesChoices } from '@/modules/patients/constants/serviceInstanceTypesChoices';
import type { Step } from '@/modules/clinical-activities/components/types';

interface SortableStepProps {
  step: any;
  onRemove: (id: string) => void;
}

const ActivityPreview = ({ id }: { id: string }) => {
  const mainFormContext = useFormContext();
  const values = mainFormContext.watch();
  const [activeTab, setActiveTab] = useState('preview');

  const [createPreview, { data: previewData }] = useCreate();
  const form = useForm();

  const submitPreview = (data: any) => {
    createPreview(
      `activity-templates/${id}/preview-notes`,
      { data },
      {
        onSuccess: () => {
          setActiveTab('output');
        },
      },
    );
  };

  return (
    <div className="h-full">
      <Tabs value={activeTab} onValueChange={setActiveTab} className="h-full">
        <TabsList className="mx-4 h-7">
          <TabsTrigger className="text-xs" value="preview">
            Preview
          </TabsTrigger>
          <TabsTrigger className="text-xs" value="output">
            Output
          </TabsTrigger>
        </TabsList>
        <TabsContent value="preview" className="h-[calc(100vh-12rem)]">
          <div className="bg-gray-50 rounded-lg h-full overflow-y-auto">
            <ClinicalActivityContainerUI
              activity={values}
              isPreview
              submitPreview={submitPreview}
              previewForm={form}
            />
          </div>
        </TabsContent>
        <TabsContent value="output" className="h-[calc(100vh-12rem)]">
          <div className="bg-gray-50 rounded-lg h-full overflow-y-auto p-4 pt-1">
            <Card>
              <CardContent className="p-4">
                {!previewData && (
                  <p className="text-sm text-gray-500">
                    Please make a form submission to view the output here.
                    Complete the entire form and submit at the last step.
                  </p>
                )}
                {previewData && (
                  <p className="text-sm">
                    The following notes were generated from the activity
                    template:
                  </p>
                )}
                <div className="flex flex-col gap-1 mt-2">
                  {previewData?.notes.map((note: any) => (
                    <div key={note.id} className="mb-4 border rounded-md p-2">
                      <div className="flex flex-row gap-2">
                        <span className="text-sm">
                          Duration: {formatDurationAbbreviated(note.duration)}
                        </span>
                        <span className="text-sm">
                          Type: {note.monitoring_activity_type}
                        </span>
                      </div>
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {note.notes}
                      </p>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const SortableStep = ({ step, onRemove }: SortableStepProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: step.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex items-center space-x-2 p-2 bg-gray-50 rounded-md"
    >
      <div {...attributes} {...listeners} className="cursor-move">
        <GripVertical className="h-4 w-4 text-gray-400" />
      </div>
      <div className="flex-1">
        <div className="font-medium">{step.step_template.step_title}</div>
        <div className="text-sm text-gray-500">
          {step.step_template.step_description}
        </div>
      </div>
      <Button variant="ghost" size="sm" onClick={() => onRemove(step.id)}>
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
};

const SchedulingConfig = () => {
  const formContext = useFormContext();
  const limitType = formContext.watch(
    'activity_config_json.scheduling.frequency_limit.limit_type',
  );

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="scheduling">
        <AccordionTrigger>Scheduling Configuration</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-4 pt-4">
            <SelectInput
              source="activity_config_json.scheduling.frequency_limit.limit_type"
              label="Frequency Limit Type"
              choices={[
                { id: 'none', name: 'No Limit' },
                { id: 'period', name: 'Time Period Limit' },
                { id: 'total', name: 'Total Lifetime Limit' },
              ]}
            />

            {limitType === 'period' && (
              <>
                <SelectInput
                  source="activity_config_json.scheduling.frequency_limit.period_type"
                  label="Period Type"
                  choices={[
                    { id: 'days', name: 'Days' },
                    { id: 'weeks', name: 'Weeks' },
                    { id: 'months', name: 'Months' },
                    { id: 'years', name: 'Years' },
                  ]}
                />
                <TextInput
                  source="activity_config_json.scheduling.frequency_limit.period_value"
                  label="Period Value"
                  type="number"
                  min={1}
                  helperText="Number of days/weeks/months/years"
                />
                <TextInput
                  source="activity_config_json.scheduling.frequency_limit.max_occurrences"
                  label="Max Occurrences in Period"
                  type="number"
                  min={1}
                />
                <SelectInput
                  source="activity_config_json.scheduling.frequency_limit.reset_trigger"
                  label="Reset Trigger"
                  choices={[{ id: 'rolling_period', name: 'Rolling Period' }]}
                  validate={[required()]}
                />
              </>
            )}

            {limitType === 'total' && (
              <TextInput
                source="activity_config_json.scheduling.frequency_limit.total_occurrences_limit"
                label="Total Lifetime Occurrences Limit"
                type="number"
                min={1}
              />
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const RecommendationConfig = () => {
  const formContext = useFormContext();
  const enabled = formContext.watch(
    'activity_config_json.recommendation.enabled',
  );

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="recommendation">
        <AccordionTrigger>Recommendation Configuration</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-4 pt-4">
            <SwitchInput
              source="activity_config_json.recommendation.enabled"
              label="Enable Recommendations"
              validate={[required()]}
            />

            {enabled && (
              <>
                {/* <TextAreaInput
                  source="activity_config_json.recommendation.patient_conditions"
                  label="Patient Conditions"
                  helperText="Enter conditions that make this activity particularly relevant (one per line)"
                /> */}
                <MultiSelectInput
                  source="activity_config_json.recommendation.care_program_types"
                  label="Care Program Types"
                  helperText="Only recommend this activity for patients with ANY of the selected care program types."
                  choices={serviceInstanceTypesChoices}
                />
                <MultiSelectInput
                  source="activity_config_json.recommendation.trigger_cues"
                  label="Trigger Cues"
                  helperText="If any of the selected events are met, the activity will be recommended."
                  choices={triggerTypes}
                />
                <TextInput
                  source="activity_config_json.recommendation.priority_score"
                  label="Priority Score"
                  type="number"
                  min={0}
                  max={100}
                  helperText="0-100, higher score indicates higher priority"
                />
                <MultiSelectInput
                  source="activity_config_json.recommendation.disabled_cues"
                  label="Disabled Cues"
                  helperText="If any of the selected events are met, the activity will be disabled."
                  choices={triggerTypes}
                />
                <MultiSelectInput
                  source="activity_config_json.recommendation.day_of_week"
                  label="Available Days"
                  choices={[
                    { id: 'monday', name: 'Monday' },
                    { id: 'tuesday', name: 'Tuesday' },
                    { id: 'wednesday', name: 'Wednesday' },
                    { id: 'thursday', name: 'Thursday' },
                    { id: 'friday', name: 'Friday' },
                    { id: 'saturday', name: 'Saturday' },
                    { id: 'sunday', name: 'Sunday' },
                  ]}
                  helperText="Days when activity is available for recommendation"
                />
                <SelectInput
                  source="activity_config_json.recommendation.week_of_month"
                  label="Available Weeks"
                  choices={[
                    { id: 'first', name: 'First Week' },
                    { id: 'last', name: 'Last Week' },
                  ]}
                  helperText="Weeks when activity is available for recommendation"
                />
              </>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export const ActivityTemplateForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const notify = useNotify();
  const [create] = useCreate();
  const [update] = useUpdate();
  const refresh = useRefresh();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { data: record, isLoading } = useGetOne(
    'activity-templates',
    { id },
    {
      refetchOnMount: 'always',
    },
  );
  const { data: stepTemplates } = useGetList('step-templates', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'created_at', order: 'DESC' },
    filter: { 'is_latest[eq]': 'true' },
  });

  const [deleteOne] = useDelete();

  const [selectedSteps, setSelectedSteps] = useState<Step[]>(
    record?.activity_template_steps?.map((step: any) => ({
      id: step.step_template_id,
      step_template: step.step_template,
    })) || [],
  );

  const handleClone = () => {
    create(
      `activity-templates/${id}/clone`,
      {
        data: { id },
      },
      {
        onSuccess: (data) => {
          notify('Activity template cloned successfully', { type: 'success' });
          navigate(`/clinical-activities/activity/${data.id}`);
          refresh();
        },
        onError: () => {
          notify('Error cloning activity template', { type: 'error' });
        },
      },
    );
  };

  const handleDelete = () => {
    deleteOne(
      'activity-templates',
      { id },
      {
        onSuccess: () => {
          navigate('/clinical-activities');
        },
      },
    );
  };

  const handlePromote = () => {
    create(
      `activity-templates/${id}/promote`,
      {
        data: { id },
      },
      {
        onSuccess: () => {
          notify('Activity template promoted successfully', {
            type: 'success',
          });
          navigate('/clinical-activities');
          refresh();
        },
        onError: () => {
          notify('Error promoting activity template', { type: 'error' });
        },
      },
    );
  };

  const handleSubmit = (values: any) => {
    const dataToSubmit = {
      ...values,
      activity_template_steps: selectedSteps.map((step, index) => ({
        step_number: index + 1,
        step_template_id: step.id,
      })),
    };

    update(
      'activity-templates',
      { id, data: dataToSubmit },
      {
        onSuccess: (data) => {
          notify('Activity template updated successfully', {
            type: 'success',
          });
          refresh();
          navigate(`/clinical-activities/activity/${data.id}`);
        },
        onError: () => {
          notify('Error saving activity template', { type: 'error' });
        },
      },
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setSelectedSteps((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleAddStep = (stepId: string) => {
    const stepTemplate = stepTemplates?.find(
      (template: any) => template.id === stepId,
    );
    if (stepTemplate && !selectedSteps.some((step) => step.id === stepId)) {
      setSelectedSteps([
        ...selectedSteps,
        {
          id: stepTemplate.id,
          step_title: stepTemplate.step_title,
          step_description: stepTemplate.step_description,
          activity_items_json: stepTemplate.activity_items_json,
          step_key: stepTemplate.step_key,
        },
      ]);
    }
  };

  const handleRemoveStep = (stepId: string) => {
    setSelectedSteps(selectedSteps.filter((step) => step.id !== stepId));
  };

  if (id && isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center space-x-4">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate('/clinical-activities')}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </Button>
          <h2 className="text-2xl font-bold tracking-tight">
            {id ? 'Edit Activity Template' : 'Create Activity Template'}
          </h2>
        </div>
        {id && (
          <div className="flex flex-row gap-2">
            {record?.is_draft && (
              <Button
                variant="outline"
                size="sm"
                onClick={handlePromote}
                type="button"
              >
                <ArrowUp className="h-4 w-4 mr-2" />
                Promote
              </Button>
            )}
            <Button
              variant="outline"
              size="sm"
              onClick={handleClone}
              type="button"
            >
              <Copy className="h-4 w-4 mr-2" />
              Clone
            </Button>
            <Button variant="destructive" size="sm" onClick={handleDelete}>
              <Trash className="h-4 w-4 mr-2" />
              Delete
            </Button>
          </div>
        )}
      </div>
      <Form onSubmit={handleSubmit} defaultValues={record}>
        <div className="grid grid-cols-2 gap-6 p-4 flex-1 overflow-hidden">
          <div className="overflow-auto">
            <Card>
              <CardContent className="space-y-4 mt-2 h-[calc(100vh-12rem)] overflow-y-auto">
                <TextInput
                  source="activity_title"
                  label="Title"
                  validate={[required()]}
                />
                <TextAreaInput
                  source="activity_description"
                  label="Description"
                />
                <TextInput source="activity_type" label="Type" />
                <div className="flex flex-row gap-2">
                  <SwitchInput source="is_enabled" label="Activity Enabled" />
                </div>
                <MultiSelectInput
                  source="activity_config_json.clinician_types"
                  label="Allowed Clinician Types"
                  choices={[
                    { id: 'medical_assistant', name: 'Medical Assistant' },
                    { id: 'registered_nurse', name: 'Registered Nurse (RN)' },
                    { id: 'physician', name: 'Physician' },
                    { id: 'physician_assistant', name: 'Physician Assistant' },
                    { id: 'other', name: 'Other' },
                  ]}
                  helperText="Leave empty to allow all clinician types"
                />
                <SwitchInput
                  source="activity_config_json.consolidate_monitoring_activity"
                  label="Consolidate Monitoring Activity"
                  helperText="When enabled, monitoring activities will be consolidated into a single activity. This will ignore settings in steps."
                />
                <SchedulingConfig />
                <RecommendationConfig />

                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="steps">
                    <AccordionTrigger>Steps</AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-4 pt-4">
                        <SelectInput
                          source="step_template_id"
                          label="Add Step Template"
                          choices={stepTemplates?.map((template: any) => ({
                            id: template.id,
                            name: template.step_title,
                          }))}
                          onChange={(stepId) => handleAddStep(stepId)}
                        />

                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                        >
                          <SortableContext
                            items={selectedSteps}
                            strategy={verticalListSortingStrategy}
                          >
                            <div className="space-y-2">
                              {selectedSteps.map((step) => (
                                <SortableStep
                                  key={step.id}
                                  step={step}
                                  onRemove={handleRemoveStep}
                                />
                              ))}
                            </div>
                          </SortableContext>
                        </DndContext>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </CardContent>
              <CardFooter className="py-2">
                <div className="flex justify-end w-full space-x-2 pt-2">
                  <Button type="submit" size="sm" className="h-8">
                    Save
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
          <ActivityPreview id={id} />
        </div>
      </Form>
    </div>
  );
};
