"use client";

import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import type { Table } from "@tanstack/react-table";
import { SlidersHorizontal, GripVertical, RotateCcw } from "lucide-react";

import { Button } from "@/modules/ui/components/button";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuItem,
} from "@/modules/ui/components/dropdown-menu";
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	type DragEndEvent,
} from "@dnd-kit/core";
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface DataTableViewOptionsProps<TData> {
	table: Table<TData>;
	resetColumns: () => void;
}

interface SortableColumnItemProps {
	id: string;
	label: string;
	isVisible: boolean;
	onVisibilityChange: (value: boolean) => void;
}

const SortableColumnItem = ({
	id,
	label,
	isVisible,
	onVisibilityChange,
}: SortableColumnItemProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			className="flex items-center space-x-2 py-1"
		>
			<div {...attributes} {...listeners} className="cursor-move">
				<GripVertical className="h-4 w-4 text-gray-400" />
			</div>
			<DropdownMenuCheckboxItem
				className="capitalize flex-1"
				checked={isVisible}
				onCheckedChange={onVisibilityChange}
			>
				{label}
			</DropdownMenuCheckboxItem>
		</div>
	);
};

export function DataTableViewOptions<TData>({
	table,
	resetColumns,
}: DataTableViewOptionsProps<TData>) {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const columns = table
		.getAllColumns()
		.filter(
			(column) =>
				typeof column.accessorFn !== "undefined" && column.getCanHide(),
		);

	// Get the current column order from the table state
	const currentColumnOrder = table.getState().columnOrder;

	// Sort columns based on the current column order
	const sortedColumns = [...columns].sort((a, b) => {
		// If there's no column order or it's empty, maintain original order
		if (!currentColumnOrder || currentColumnOrder.length === 0) {
			return 0;
		}

		const aIndex = currentColumnOrder.indexOf(a.id);
		const bIndex = currentColumnOrder.indexOf(b.id);

		// If both columns are not in the order array, maintain their original order
		if (aIndex === -1 && bIndex === -1) {
			return 0;
		}

		// If a column is not in the order array, place it at the end
		if (aIndex === -1) return 1;
		if (bIndex === -1) return -1;

		// Otherwise sort by index in the order array
		return aIndex - bIndex;
	});

	const columnIds = sortedColumns.map((column) => column.id);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (over && active.id !== over.id) {
			const oldIndex = columnIds.indexOf(active.id as string);
			const newIndex = columnIds.indexOf(over.id as string);

			if (oldIndex !== -1 && newIndex !== -1) {
				const newOrder = arrayMove(columnIds, oldIndex, newIndex);
				table.setColumnOrder(newOrder);
			}
		}
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="sm"
					className="ml-auto hidden h-8 lg:flex"
				>
					<SlidersHorizontal className=" h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[200px]">
				<DropdownMenuLabel>Toggle & reorder columns</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={columnIds}
						strategy={verticalListSortingStrategy}
					>
						<div className="max-h-[300px] overflow-y-auto">
							{sortedColumns.map((column) => {
								return (
									<SortableColumnItem
										key={column.id}
										id={column.id}
										label={
											typeof column.columnDef.header === "function"
												? column.id
												: (column.columnDef.header as string)
										}
										isVisible={column.getIsVisible()}
										onVisibilityChange={(value) =>
											column.toggleVisibility(!!value)
										}
									/>
								);
							})}
						</div>
					</SortableContext>
				</DndContext>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={resetColumns}>
					<RotateCcw className="h-4 w-4 mr-2" />
					Reset
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
