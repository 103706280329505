import EditInDialogButton from "@/modules/ra-ui/components/edit-dialog-button";
import { DateInput } from "@/modules/ui/inputs/date-input";
import { NumberInput } from "@/modules/ui/inputs/number-input";
import { required } from "react-admin";
import { Button } from "@/modules/ui/components/button";
import { Pencil } from "lucide-react";

export const ReimbursementRateEditButton = (props) => {
	const { label = "", editButtonVariant = "ghost" } = props;

	return (
		<EditInDialogButton
			title="Edit Reimbursement Rate"
			editButton={
				<Button
					size="sm"
					className="h-8 hover:bg-gray-300"
					variant={editButtonVariant}
					type="button"
				>
					<Pencil className="h-4 w-4" />
					{label}
				</Button>
			}
			dialogVariant="shadcn"
			closeOnClickOutside={false}
			resource="reimbursement-rates"
			refreshOnSuccess={true}
		>
			<div className="flex flex-col space-y-4">
				<DateInput
					source="as_of_date"
					label="Effective Date"
					validate={required()}
					helperText="When does this rate become effective?"
				/>

				<NumberInput
					source="hourly_reimbursement"
					label="Hourly Rate"
					helperText="Hourly reimbursement rate"
					prefix="$"
					validate={required()}
					decimalScale={2}
				/>

				<NumberInput
					source="enrollment_reimbursement"
					label="Enrollment Rate"
					helperText="Rate per enrollment"
					prefix="$"
					decimalScale={2}
				/>

				<NumberInput
					source="shift_reimbursement"
					label="Shift Rate"
					helperText="Rate per shift"
					prefix="$"
					decimalScale={2}
				/>

				<NumberInput
					source="training_reimbursement"
					label="Training Rate"
					helperText="Rate for training"
					prefix="$"
					decimalScale={2}
				/>

				<NumberInput
					source="incentive_reimbursement"
					label="Incentive Rate"
					helperText="Additional incentive rate"
					prefix="$"
					decimalScale={2}
				/>
			</div>
		</EditInDialogButton>
	);
};
