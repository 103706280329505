import RangeField from '@/modules/ra-ui/components/RangeField';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { cropText } from '@/modules/patients/utils/cropText';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { encounterStatus } from '../../constants/encounterStatus';
import { encounterType } from '../../constants/encounterType';
import { encounterClass } from '../../constants/encounterClass';
import { EncounterCreateButton } from '../../encounters/components/EncounterCreateButton';
import { EncounterEditButton } from '../../encounters/components/EncounterEditButton';
import { Badge } from '@/modules/ui/components/badge';
import { humanize } from 'inflection';

function ExpandedRow() {
  const record = useRecordContext();

  return (
    <div className="py-2">
      <div className="flex items-center mb-2">
        <p className="text-sm font-semibold text-gray-500 mr-2">Status:</p>
        <Badge variant="secondary" className="rounded-sm px-1 font-normal">
          {record.status ? humanize(record.status) : 'N/A'}
        </Badge>
        <p className="text-sm font-semibold text-gray-500 mx-2">Source:</p>
        <Badge variant="secondary" className="rounded-sm px-1 font-normal">
          {record.source ? humanize(record.source) : 'N/A'}
        </Badge>
        <p className="text-sm font-semibold text-gray-500 mx-2">Department:</p>
        <p className="px-1 font-normal">
          {record.department ? record.department : 'N/A'}
        </p>
      </div>
      <p className="text-sm font-semibold text-gray-500 mb-1">Notes:</p>
      <p className="whitespace-pre-wrap">{record.notes}</p>
    </div>
  );
}

export function EncountersDataGrid(props) {
  const { record } = props;

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList(
    `patients/${record.id}/encounters`,
    {
      pagination: { page, perPage },
      filter: filterValues,
      sort,
    },
  );
  if (!record) {
    return null;
  }

  return (
    <div>
      <ListContextProvider
        // @ts-ignore
        value={{
          filterValues,
          setFilters,
          displayedFilters: {},
          page,
          perPage,
          setPage,
          setPerPage,
          total,
        }}
      >
        <DataTableToolbar
          showViewOptions={false}
          search={false}
          className="mx-2"
        >
          <DataTableFilter
            column="status[in]"
            title="Status"
            options={encounterStatus.map((choice) => ({
              label: choice.name,
              value: choice.id,
            }))}
          />
          <DataTableFilter
            column="encounter_type[in]"
            title="Encounter Type"
            options={encounterType.map((choice) => ({
              label: choice.name,
              value: choice.id,
            }))}
          />
        </DataTableToolbar>
        <Datagrid
          data={data}
          total={total}
          isLoading={isLoading}
          sort={sort}
          bulkActionButtons={false}
          setSort={setSort}
          empty={
            <EmptyDatagrid
              resourceName="Encounters"
              textFormatString="No encounters found for patient"
            />
          }
          expand={<ExpandedRow />}
          rowClick="expand"
          expandSingle
        >
          <RangeField
            source_start="start_date"
            source_end="end_date"
            sortBy="start_date"
            label="Timestamp"
          />
          <BadgeField
            source="encounter_type"
            mapping={encounterType}
            humanize={false}
          />
          <BadgeField source="encounter_class" mapping={encounterClass} />
          <FunctionField
            render={(record) => cropText(record.notes, 120)}
            label="Notes"
          />
          <EncounterEditButton />
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    </div>
  );
}

export default function EncountersSection() {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 mt-4">
      <div className="flex flex-row space-x-1 justify-between items-center ">
        <div>
          <h3 className="text-xl font-semibold">Encounters</h3>
          <p className="text-sm text-gray-500">
            Interactions between a patient and healthcare provider(s) for the
            purpose of providing healthcare service(s) or assessing the health
            status of a patient.
          </p>
        </div>

        <EncounterCreateButton />
      </div>
      <EncountersDataGrid record={record} />
    </div>
  );
}
