import React from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/modules/ui/components/tabs';
import { cn } from '@/modules/ui/utils/cn';

interface TabOption {
  value: string;
  href?: string;
  title: string;
}

interface TabbedWrapperProps {
  options: TabOption[];
  selectedTab?: string | null;
  setSelectedTab: (value: string) => void;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

const TabbedWrapper = ({
  options,
  selectedTab,
  setSelectedTab,
  children,
  fullWidth = false,
}: TabbedWrapperProps) => {
  return (
    <Tabs value={selectedTab} onValueChange={setSelectedTab}>
      <TabsList className={cn('my-2   ', fullWidth ? 'w-full' : 'w-min')}>
        {options.map((option, i) => (
          <TabsTrigger
            key={i}
            value={option.value}
            className={cn('text-sm h-7 ', 'grow min-w-36')}
          >
            {option.title}
          </TabsTrigger>
        ))}
      </TabsList>
      {children}
    </Tabs>
  );
};

export default TabbedWrapper;
