import React from 'react';
import { AlertCircle } from 'lucide-react';
import { Card, CardContent } from '@/modules/ui/components/card';

function ErrorCard(props) {
  const { details } = props;
  return (
    <Card>
      <CardContent className="pt-6">
        <div className="flex justify-center mt-4">
          <div className="flex flex-col items-center">
            <div className="w-full text-center">
              <h6 className="text-lg font-semibold">An error has occurred!</h6>
              <p className="text-base font-normal">{details}</p>
            </div>
            <div className="w-full text-center mt-2">
              <AlertCircle className="h-6 w-6 text-destructive" />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default ErrorCard;
