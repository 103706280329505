import { find } from 'lodash';
import { useRecordContext } from 'react-admin';
import { VitalConfig } from '../components/VitalCardBase';
import { ScoreCard } from './ScoreCard';
import { ScoreChart } from './ScoreChart';

interface ScoreConfig extends VitalConfig {
  type?: string;
}

const scoreConfig: Array<ScoreConfig> = [
  {
    measureReportName: 'covid19_vitals',
    chartMeasureName: 'covid19_vitals',
    nameKey: 'Covid19 Vitals',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },
  {
    measureReportName: 'copd_prediction_score_vitals',
    chartMeasureName: 'copd_prediction_score_vitals',
    nameKey: 'COPD Vital Severity',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },

  {
    measureReportName: 'asthma_prediction_score_vitals',
    chartMeasureName: 'asthma_prediction_score_vitals',
    nameKey: 'Asthma Vital Severity',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },

  {
    measureReportName: 'chf_prediction_score_vitals',
    chartMeasureName: 'chf_prediction_score_vitals',
    nameKey: 'Heart Failure Vital Severity',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },
  {
    measureReportName: 'chf_prediction_score_exacerbation',
    chartMeasureName: 'chf_prediction_score_exacerbation',
    nameKey: 'Heart Failure Exacerbation Risk',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
    type: 'exacerbation',
  },
  {
    measureReportName: 'copd_prediction_score_exacerbation',
    chartMeasureName: 'copd_prediction_score_exacerbation',
    nameKey: 'COPD Exacerbation Risk',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'lungs',
    type: 'exacerbation',
  },
  {
    measureReportName: 'asthma_prediction_score_exacerbation',
    chartMeasureName: 'asthma_prediction_score_exacerbation',
    nameKey: 'Asthma Exacerbation Risk',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'lungs',
    type: 'exacerbation',
  },
  {
    measureReportName: 'chf_prediction_score_symptom',
    chartMeasureName: 'chf_prediction_score_symptom',
    nameKey: 'Heart Failure Symptom Severity',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },
  {
    measureReportName: 'copd_prediction_score_symptom',
    chartMeasureName: 'copd_prediction_score_symptom',
    nameKey: 'COPD Symptom Severity',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'lungs',
  },
  {
    measureReportName: 'asthma_prediction_score_symptom',
    chartMeasureName: 'asthma_prediction_score_symptom',
    nameKey: 'Asthma Symptom Severity',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'lungs',
  },
];

export function PatientScores() {
  const patientRecord = useRecordContext();

  if (!patientRecord) return null;

  const measureReports = patientRecord.measure_reports;

  const elements = scoreConfig
    .filter((element) => {
      const start = element.chartMeasureName.split('_');
      if (start) {
        const elementData = find(
          measureReports,
          (o) =>
            o.name === element.measureReportName &&
            o.category === 'prediction_scores',
        );

        if (elementData) {
          return true;
        }
      }
      return false;
    })
    .map((element) => {
      const elementData = find(
        measureReports,
        (o) =>
          o.name === element.measureReportName &&
          o.category === 'prediction_scores',
      );

      return (
        <div
          key={`score-${element.chartMeasureName}`}
          className="w-full sm:w-1/3"
        >
          <ScoreChart
            element={
              <ScoreCard
                value={elementData}
                text={element.nameKey}
                lastUpdated={elementData.updated_at}
                chartName={element.chartMeasureName}
                type={element.type || 'category'}
              />
            }
          />
        </div>
      );
    });

  return elements.length > 0 ? (
    <div className="flex flex-col space-y-2 mb-8">
      <div>
        <h3 className="text-xl font-semibold">Algorithm Scores</h3>
        <p className="text-sm text-gray-500">
          Interpretation of patient vital signs and symptoms by Vironix's AI
          algorithms.
        </p>
      </div>
      <div className="flex flex-wrap gap-4">{elements}</div>
    </div>
  ) : null;
}
