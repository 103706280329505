import React from 'react';
import { Button } from '@/modules/ui/components/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { YesNoRadioInput } from '@/modules/ui/inputs/yes-no-radio-input';
import { PlusCircle, Trash2, GripVertical } from 'lucide-react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ArrayInput, FormDataConsumer } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { MultiSelectInput } from '@/modules/ui/inputs/multi-select-input';
import { taskItemChecks } from './triggers';

const ITEM_TYPES = [
  {
    id: 'checklist',
    name: 'Checklist',
    description: 'Simple checkbox item for yes/no confirmation',
  },
  {
    id: 'yes_no',
    name: 'Yes/No',
    description: 'Radio buttons for explicit yes/no selection',
  },
  {
    id: 'dropdown',
    name: 'Dropdown',
    description: 'Select from predefined options',
    hasOptions: true,
  },
  // {
  //   id: 'scale',
  //   name: 'Scale',
  //   description: 'Numeric scale with configurable range',
  //   hasRange: true
  // },
  {
    id: 'text',
    name: 'Text',
    description: 'Single line text input',
  },
  {
    id: 'textarea',
    name: 'Text Area',
    description: 'Multi-line text input',
  },
  // {
  //   id: 'date',
  //   name: 'Date',
  //   description: 'Date picker input'
  // },
  // {
  //   id: 'time',
  //   name: 'Time',
  //   description: 'Time picker input'
  // },
  // {
  //   id: 'number',
  //   name: 'Number',
  //   description: 'Numeric input with optional validation',
  //   hasValidation: true
  // },
];

interface SortableItemProps {
  id: string;
  index: number;
  field: Record<string, any>;
  onRemove: () => void;
}

const SortableItem = ({ id, index, field, onRemove }: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const { control } = useFormContext();
  const itemType = useWatch({
    control,
    name: `activity_items_json.${index}.type`,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Card className={isDragging ? 'opacity-50' : ''}>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="cursor-grab hover:text-gray-700 dark:hover:text-gray-300"
              {...attributes}
              {...listeners}
            >
              <GripVertical className="h-5 w-5" />
            </button>
            <CardTitle className="text-base font-medium">
              Item {index + 1}
            </CardTitle>
          </div>
          <Button type="button" variant="ghost" size="sm" onClick={onRemove}>
            <Trash2 className="h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent className="space-y-4">
          <TextInput
            source={`activity_items_json.${index}.item_id`}
            label="Item Id"
            helperText="Required for storing, linking, and sorting"
            required
          />
          <TextInput
            source={`activity_items_json.${index}.text`}
            label="Text"
            helperText="The main text displayed to the user"
            required
          />
          <SelectInput
            source={`activity_items_json.${index}.type`}
            label="Type"
            choices={ITEM_TYPES.map((type) => ({
              id: type.id,
              name: `${type.name} - ${type.description}`,
            }))}
            required
          />
          <TextAreaInput
            source={`activity_items_json.${index}.prompt`}
            label="Prompt"
            helperText="Additional guidance or instructions for this item"
          />

          {/* Dropdown-specific fields */}
          {itemType === 'dropdown' && (
            <div className="space-y-4">
              <ArrayInput source={`activity_items_json.${index}.options`}>
                <SimpleFormIterator inline resource="options">
                  <TextInput source="id" label="Id" />
                  <TextInput source="name" label="Label" />
                </SimpleFormIterator>
              </ArrayInput>
              <TextInput
                source={`activity_items_json.${index}.default_option`}
                label="Default Option"
                helperText="Optional default selection"
              />
            </div>
          )}

          {/* Scale-specific fields */}
          {itemType === 'scale' && (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <TextInput
                  source={`activity_items_json.${index}.scale_min`}
                  label="Minimum Value"
                  type="number"
                  required
                />
                <TextInput
                  source={`activity_items_json.${index}.scale_max`}
                  label="Maximum Value"
                  type="number"
                  required
                />
              </div>
              <TextInput
                source={`activity_items_json.${index}.scale_step`}
                label="Step Size"
                type="number"
                defaultValue="1"
                required
              />
            </div>
          )}

          {/* Number-specific fields */}
          {itemType === 'number' && (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <TextInput
                  source={`activity_items_json.${index}.min_value`}
                  label="Minimum Value"
                  type="number"
                />
                <TextInput
                  source={`activity_items_json.${index}.max_value`}
                  label="Maximum Value"
                  type="number"
                />
              </div>
              <TextInput
                source={`activity_items_json.${index}.unit`}
                label="Unit"
                helperText="Optional unit for the number (e.g., kg, cm)"
              />
            </div>
          )}

          {/* Common validation fields for all types */}
          <div className="space-y-4">
            <FormDataConsumer>
              {({ formData }) => {
                const currentItemType =
                  formData?.activity_items_json?.[index]?.type;
                return currentItemType !== 'checklist' ? (
                  <YesNoRadioInput
                    source={`activity_items_json.${index}.required`}
                    label="Required"
                    helperText="If true, the user must complete this item before proceeding"
                  />
                ) : null;
              }}
            </FormDataConsumer>
          </div>

          <div className="space-y-4">
            <FormDataConsumer>
              {({ formData }) => {
                const currentItemType =
                  formData?.activity_items_json?.[index]?.type;
                return currentItemType === 'checklist' ||
                  currentItemType === 'yes_no' ? (
                  <MultiSelectInput
                    source={`activity_items_json.${index}.validation_checks`}
                    label="Validation Checks"
                    helperText="All must be true for the item to be considered completed (checked or yes). If any checks fail, then the user can't complete this step or activity."
                    choices={taskItemChecks}
                  />
                ) : null;
              }}
            </FormDataConsumer>
          </div>

          <ArrayInput source={`activity_items_json.${index}.resource_creation`}>
            <SimpleFormIterator resource="test">
              <SelectInput
                source="resource_type"
                label="Resource Type"
                choices={[
                  { id: 'encounters', name: 'Encounter' },
                  { id: 'tasks', name: 'Task' },
                  { id: 'assessments', name: 'Assessment' },
                  { id: 'voice_calls', name: 'Voice Call' },
                ]}
              />
              <SelectInput
                source="trigger_condition"
                label="Trigger Condition"
                choices={[
                  { id: 'on_yes', name: 'On Yes' },
                  { id: 'on_no', name: 'On No' },
                ]}
              />
              <TextInput source="display_text" label="Display Text" />
              <TextInput source="reference_field" label="Reference Field" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput
            source={`activity_items_json.${index}.enabled_when`}
            label="Enabled When Conditions (all must be true)"
          >
            <SimpleFormIterator resource="test">
              <TextInput source={`item_id`} label="Item Id" />
              <TextInput source={`value`} label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </CardContent>
      </Card>
    </div>
  );
};

export const ActivityItemForm = () => {
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'activity_items_json',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const addItem = () => {
    append({
      item_id: `item_${Date.now()}`,
      text: '',
      type: 'checklist',
      prompt: '',
      is_completed: false,
    });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = fields.findIndex((item) => item.id === active.id);
      const newIndex = fields.findIndex((item) => item.id === over.id);
      move(oldIndex, newIndex);
    }
  };

  return (
    <div className="flex flex-col relative">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Activity Items</h3>
        <Button type="button" size="sm" variant="outline" onClick={addItem}>
          <PlusCircle className="h-4 w-4 mr-2" />
          Add Item
        </Button>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={fields.map((field) => field.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-4">
            {fields.map((field, index) => (
              <SortableItem
                key={field.id}
                id={field.id}
                index={index}
                field={field}
                onRemove={() => remove(index)}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};
