import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "@/modules/ui/components/alert";
import { Badge } from "@/modules/ui/components/badge";
import { Button } from "@/modules/ui/components/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/modules/ui/components/card";
import { Progress } from "@/modules/ui/components/progress";
import { Separator } from "@/modules/ui/components/separator";
import { CheckBoxInput, mustBeTrue } from "@/modules/ui/inputs/checkbox-input";
import { cn } from "@/modules/ui/utils/cn";
import {
	AlertCircle,
	ArrowLeft,
	Check,
	CheckCircle2,
	Clock,
	X,
} from "lucide-react";
import { useFormContext } from "react-hook-form";

interface ReviewAndSubmitStepProps {
	steps: any[];
	isPreview?: boolean;
	onNavigateToStep?: (stepIndex: number) => void;
}

export function ReviewAndSubmitStep({
	steps,
	isPreview = false,
	onNavigateToStep,
}: ReviewAndSubmitStepProps) {
	const form = useFormContext();
	const formValues = form.getValues();

	// Helper function to format the value based on its type
	const formatValue = (value: any, itemType?: string) => {
		if (value === undefined || value === null) {
			return (
				<Badge variant="destructive" className="text-xs">
					Not completed
				</Badge>
			);
		}

		if (typeof value === "boolean") {
			if (itemType === "yes_no") {
				return value ? (
					<span className="inline-flex items-center text-green-600">
						<Check className="h-4 w-4 mr-1" />
						Yes
					</span>
				) : (
					<span className="inline-flex items-center text-red-600">
						<X className="h-4 w-4 mr-1" />
						No
					</span>
				);
			}
			return value ? (
				<span className="inline-flex items-center text-green-600">
					<Check className="h-4 w-4 mr-1" />
					Completed
				</span>
			) : (
				<span className="inline-flex items-center text-red-600">
					<X className="h-4 w-4 mr-1" />
					Not completed
				</span>
			);
		}

		if (value === "") {
			return (
				<Badge variant="destructive" className="text-xs">
					Not completed
				</Badge>
			);
		}

		// For text or textarea inputs, display in a grey area
		if (itemType === "text" || itemType === "textarea") {
			return (
				<div className="mt-1 w-full">
					<div className="bg-gray-100 p-3 rounded-md text-gray-800 whitespace-pre-wrap">
						{value.toString()}
					</div>
				</div>
			);
		}

		return value.toString();
	};

	// Helper function to format time in minutes and seconds
	const formatTime = (seconds: number) => {
		if (seconds === undefined || seconds === null) return "Not recorded";

		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;

		if (minutes === 0) {
			return `${remainingSeconds} seconds`;
		}

		return `${minutes} min ${remainingSeconds} sec`;
	};

	// Calculate completion statistics
	const totalItems = steps.reduce(
		(count, step) => count + step.activity_items_json.length,
		0,
	);

	let completedItems = 0;
	steps.forEach((step) => {
		step.activity_items_json.forEach((item) => {
			const value = formValues[step.step_key]?.[item.item_id];
			if (value !== undefined && value !== null && value !== "") {
				completedItems++;
			}
		});
	});

	const completionPercentage =
		totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;

	const isFullyCompleted = completedItems === totalItems;

	// Calculate total time taken across all steps
	let totalTimeTaken = 0;
	steps.forEach((step) => {
		const stepTime = formValues[step.step_key]?.time_taken;
		if (stepTime !== undefined && stepTime !== null) {
			totalTimeTaken += Number.parseInt(stepTime, 10);
		}
	});

	return (
		<Card>
			<CardHeader className="space-y-3">
				<CardTitle className="text-lg font-semibold">Review & Submit</CardTitle>
				<CardDescription>
					Please review all information before submitting. Ensure all data is
					accurate and complete.
				</CardDescription>

				{/* Completion summary */}
				<div className="mt-4 space-y-2">
					<div className="flex justify-between items-center">
						<span className="text-sm font-medium">Completion Status</span>
						<Badge
							variant={isFullyCompleted ? "secondary" : "outline"}
							className="text-xs"
						>
							{completedItems} of {totalItems} items completed
						</Badge>
					</div>
					<Progress value={completionPercentage} className="h-2" />

					{/* Time summary */}
					{totalTimeTaken > 0 && (
						<div className="flex items-center mt-2 text-sm text-muted-foreground">
							<Clock className="h-4 w-4 mr-1" />
							<span>Total time: {formatTime(totalTimeTaken)}</span>
						</div>
					)}

					{!isFullyCompleted && (
						<Alert variant="warning" className="mt-2">
							<AlertCircle className="h-4 w-4" />
							<AlertTitle>Incomplete Information</AlertTitle>
							<AlertDescription>
								Some required information is missing. Please complete all items
								before submitting.
							</AlertDescription>
						</Alert>
					)}
				</div>
			</CardHeader>

			<Separator />

			<CardContent className="space-y-6 pt-6">
				{/* Summary of completed steps */}
				<div className="space-y-4">
					{steps.map((step, index) => {
						// Calculate step completion
						const stepItems = step.activity_items_json.length;
						let stepCompleted = 0;
						step.activity_items_json.forEach((item) => {
							const value = formValues[step.step_key]?.[item.item_id];
							if (value !== undefined && value !== null && value !== "") {
								stepCompleted++;
							}
						});
						const stepCompletionPercentage =
							stepItems > 0 ? Math.round((stepCompleted / stepItems) * 100) : 0;

						const isStepComplete = stepCompleted === stepItems;
						const timeTaken = formValues[step.step_key]?.time_taken;

						return (
							<div key={index} className="space-y-2">
								<div className="flex justify-between items-center">
									<div className="flex items-center">
										<h3 className="font-medium text-sm flex items-center">
											{step.step_title}
											{isStepComplete ? (
												<CheckCircle2 className="h-4 w-4 ml-2 text-green-600" />
											) : (
												<AlertCircle className="h-4 w-4 ml-2 text-amber-500" />
											)}
										</h3>

										{/* Add button to navigate back to this step if incomplete */}
										{!isStepComplete && onNavigateToStep && (
											<Button
												variant="ghost"
												size="sm"
												className="ml-2 text-xs h-7 px-2 text-amber-600"
												onClick={() => onNavigateToStep(index)}
											>
												<ArrowLeft className="h-3 w-3 mr-1" />
												Go to step
											</Button>
										)}
									</div>
									<div className="flex items-center gap-2">
										{timeTaken && (
											<span className="text-xs text-muted-foreground flex items-center">
												<Clock className="h-3 w-3 mr-1" />
												{formatTime(Number.parseInt(timeTaken, 10))}
											</span>
										)}
										<Badge
											variant={isStepComplete ? "secondary" : "outline"}
											className="text-xs"
										>
											{stepCompletionPercentage}% complete
										</Badge>
									</div>
								</div>

								<div className="pl-4 text-sm text-muted-foreground space-y-2 border-l-2 border-l-gray-200 py-2">
									{step.activity_items_json.map((item) => {
										const value = formValues[step.step_key]?.[item.item_id];
										const isCompleted =
											value !== undefined && value !== null && value !== "";
										const isTextInput =
											item.type === "text" || item.type === "textarea";

										return (
											<div
												key={item.item_id}
												className={cn(
													"py-1",
													!isCompleted && "bg-red-50 p-2 rounded",
													isTextInput
														? "flex flex-col"
														: "flex justify-between",
												)}
											>
												<span className="font-medium">{item.text}: </span>
												<span
													className={cn(!isCompleted ? "text-red-500" : "")}
												>
													{formatValue(value, item.type)}
												</span>
											</div>
										);
									})}

									{/* Show additional notes if any */}
									{formValues[step.step_key]?.additional_notes && (
										<div className="py-1 flex flex-col">
											<span className="font-medium">Additional Notes: </span>
											<div className="mt-1 bg-gray-100 p-3 rounded-md text-gray-800 whitespace-pre-wrap">
												{formValues[step.step_key].additional_notes}
											</div>
										</div>
									)}
								</div>
								{index < steps.length - 1 && <Separator className="my-4" />}
							</div>
						);
					})}
				</div>
			</CardContent>

			<CardFooter className="bg-muted/50 p-6 flex flex-col items-start">
				{/* Verification checkbox */}
				<div className="flex items-start space-x-3">
					<CheckBoxInput
						source="review_and_submit.verify_and_submit"
						label="I verify that all information provided is accurate and complete."
						validate={mustBeTrue("You must verify before proceeding.")}
						helperText="By checking this box, you confirm that you have reviewed all information and it is ready for submission."
					/>
				</div>
			</CardFooter>
		</Card>
	);
}
