import { DataTable } from '@/modules/data-table/components/data-table';
import { EmptyState } from '@/modules/data-table/components/data-table-empty-state';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Clock } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { AttendanceCreateButton } from './AttendanceCreateButton';
import { humanize } from 'inflection';
import { Badge } from '@/modules/ui/components/badge';
import { AttendanceEditButton } from './AttendanceEditButton';
import { DateTimeShow } from '@/modules/ra-ui/components/datetime-show';
import { AttendanceInfoButton } from './AttendanceInfoButton';
type AttendanceColumns = {
  id: number;
  check_in_time: string;
  check_out_time: string;
  user_fullname: string;
  attendance_type: string;
  location_id: number;
};

const columnHelper = createColumnHelper<AttendanceColumns>();

const getColumns = (showEditButton: boolean) => {
  const columns: ColumnDef<AttendanceColumns>[] = [
    columnHelper.accessor('user_fullname', {
      header: 'User',
      cell: (info) => info.getValue(),
      enableSorting: true,
      id: 'user.full_name',
    }),
    columnHelper.accessor('check_in_time', {
      header: 'Check In',
      cell: () => {
        return <DateTimeShow source="check_in_time" showTZ />;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('check_out_time', {
      header: 'Check Out',
      cell: () => {
        return <DateTimeShow source="check_out_time" showTZ />;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('attendance_type', {
      header: 'Type',
      cell: (info) => {
        const value = info.getValue();
        if (!value) return '-';
        return <Badge variant="outline">{humanize(value)}</Badge>;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('location_id', {
      header: 'Location',
      cell: (info) => {
        const value = info.getValue();
        if (!value) return '-';

        return (
          <ReferenceField
            reference="locations"
            source="location_id"
            link={(record) => `/locations/${record.id}/show`}
          >
            <TextField source="name" />
          </ReferenceField>
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor(
      (row) => {
        if (!row.check_out_time) return null;
        const checkIn = DateTime.fromISO(row.check_in_time, {
          zone: 'utc',
        }).setZone('local');
        const checkOut = DateTime.fromISO(row.check_out_time, {
          zone: 'utc',
        }).setZone('local');
        const duration = checkOut.diff(checkIn, ['hours', 'minutes']);
        return `${Math.floor(duration.hours)}h ${Math.floor(duration.minutes)}m`;
      },
      {
        id: 'duration',
        header: () => 'Duration',
        cell: (info) => info.getValue() || '-',
        enableSorting: false,
      },
    ),
  ];

  if (showEditButton) {
    columns.push(
      columnHelper.display({
        id: 'actions',
        header: () => 'Actions',
        cell: () => (
          <div className="flex gap-2">
            <AttendanceInfoButton />
            <AttendanceEditButton />
          </div>
        ),
      }),
    );
  }
  return columns;
};

export const AttendanceListTable = ({
  myAttendance = false,
}: {
  myAttendance?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2 mx-2">
      <DataTable
        columns={getColumns(!myAttendance)}
        toolbar={
          <DataTableToolbar
            actionButtons={myAttendance ? [] : [<AttendanceCreateButton />]}
            search={
              !myAttendance ? (
                <MultiSearch
                  searchFields={[
                    { value: 'user.full_name', label: 'Name' },
                    { value: 'user_id', label: 'ID' },
                  ]}
                />
              ) : null
            }
            showViewOptions={true}
          >
            <DataTableDateRangeFilter
              column="check_in_time"
              title="Date range"
              dateOnly
              presets={[
                {
                  name: 'today',
                  label: 'Today',
                  getRange: () => {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const endOfDay = new Date(today);
                    endOfDay.setHours(23, 59, 59, 999);
                    return { from: today, to: endOfDay };
                  },
                },
                {
                  name: 'yesterday',
                  label: 'Yesterday',
                  getRange: () => {
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    yesterday.setHours(0, 0, 0, 0);
                    const endOfYesterday = new Date(yesterday);
                    endOfYesterday.setHours(23, 59, 59, 999);
                    return { from: yesterday, to: endOfYesterday };
                  },
                },
                {
                  name: 'thisWeek',
                  label: 'This Week',
                  getRange: () => {
                    const today = new Date();
                    const endOfWeek = new Date(today);
                    endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
                    return { from: today, to: endOfWeek };
                  },
                },
              ]}
            />
            {!myAttendance && (
              <DataTableReferenceFilter
                resource="users"
                column="user_id[eq]"
                title="User"
                optionText="full_name"
                customFilter={(name) => ({
                  ...(name &&
                    name.length > 1 && {
                      q: { field: 'full_name', value: name },
                    }),
                  'role[ne]': `patient`,
                  'attendance_tracking_enabled[eq]': true,
                })}
              />
            )}
          </DataTableToolbar>
        }
        emptyState={
          <EmptyState
            title="No attendance records found"
            description="No attendance records found"
            icons={[Clock]}
          />
        }
      />
    </div>
  );
};
