import { DataTableFilter } from "@/modules/data-table/components/data-table-filter";
import DataTableProviderFilter from "@/modules/data-table/components/data-table-provider-filter";
import DataTableReferenceFilter from "@/modules/data-table/components/data-table-reference-filter";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import { EnrollmentEditButton } from "@/modules/enrollment/components/EnrollmentEditButton";
import { EnrollmentExportButton } from "@/modules/enrollment/components/EnrollmentExportButton";
import AppTitle from "@/modules/layout/components/app-title";
import { patientStatus } from "@/modules/patients/constants/patientStatus";
import { primaryConditions } from "@/modules/patients/constants/primaryConditions";
import { DateTimeProviderTZField } from "@/modules/ra-ui/components/DateTimeProviderTZField";
import { LuxonDateField } from "@/modules/ra-ui/components/LuxonDateField";
import BadgeField from "@/modules/ra-ui/components/badge-field";
import { get } from "lodash";
import { type FC, useMemo } from "react";

import { DataTable } from "@/modules/data-table/components/data-table";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import { DataTableFilterSingle } from "@/modules/data-table/components/data-table-filter-single";
import { MultiSearch } from "@/modules/data-table/components/data-table-multi-search";
import { ListUIRoot } from "@/modules/data-table/components/list-ui-root";
import { EnrollmentListConfig } from "@/modules/enrollment/components/EnrollmentListConfig";
import {
	EnrollmentQueueProvider,
	useEnrollmentQueue,
} from "@/modules/enrollment/components/EnrollmentQueueProvider";
import { EditableCell } from "@/modules/ra-ui/components/editable-input-cell";
import { UserPlus } from "lucide-react";
import {
	ListBase,
	ReferenceField,
	TextField,
	useListContext,
	usePermissions,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { ProviderLocationFilter } from "../../modules/data-table/components/ProviderLocationFilter";
import { Button } from "@/modules/ui/components/button";
import { NameField } from "@/modules/patients/components/NameField";
export const STORE_KEY = "enrollmentQueue";

const Toolbar = () => {
	return (
		<div className="w-full">
			<EnrollmentListConfig />
		</div>
	);
};

const ToolbarDataTable = ({ showViewOptions }) => {
	return (
		<DataTableToolbar
			showViewOptions={showViewOptions}
			preferenceKey={STORE_KEY}
			actionButtons={[<EnrollmentExportButton />]}
			search={
				<MultiSearch
					searchFields={[
						{ value: "user.full_name", label: "Name" },
						{ value: "id", label: "ID" },
						{ value: "birthdate", label: "DOB" },
						{ value: "user.phone_number", label: "Phone Number" },
					]}
				/>
			}
		>
			<DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
			<ProviderLocationFilter />
			<DataTableReferenceFilter
				resource="users"
				column="expected_onboarding_user_id[eq]"
				title="Enrollment User"
				optionText="full_name"
				customFilter={(name) => ({
					...(name &&
						name.length > 1 && {
							q: { field: "full_name", value: name },
						}),
				})}
			/>

			<DataTableFilter
				column="primary_condition[in]"
				title="Primary Condition"
				options={[
					{ label: "Asthma", value: "Asthma" },
					{ label: "COPD", value: "COPD" },
					{ label: "Diabetes", value: "Diabetes" },
					{ label: "General", value: "general" },
					{ label: "Heart Failure", value: "HeartFailure" },
					{
						label: "Chronic Kidney Disease",
						value: "chronic_kidney_disease",
					},
				]}
			/>
			<DataTableFilterSingle
				column="virtual_enrollment[eq]"
				title="Virtual Enrollment"
				options={[
					{ label: "Yes", value: true },
					{ label: "No", value: false },
				]}
			/>
		</DataTableToolbar>
	);
};

const getColumns = (permissions) => {
	return [
		{
			header: "Name",
			id: "user.last_name",
			accessorKey: "user.last_name",
			cell: () => <NameField />,
		},
		{
			header: "Onboarding Appointment",
			accessorKey: "onboarding_appointment_datetime",
			cell: () => (
				<DateTimeProviderTZField
					source="onboarding_appointment_datetime"
					showTime
					showTZ
				/>
			),
		},
		{
			header: "Appointment Location",
			accessorKey: "onboarding_appointment_location_id",
			cell: () => (
				<ReferenceField
					source="onboarding_appointment_location_id"
					reference="locations"
					link={false}
					emptyText="None"
				>
					<TextField source="name" />
				</ReferenceField>
			),
		},
		{
			header: "Eligible Devices",
			accessorKey: "eligible_devices",
			cell: () => <TextField source="eligible_devices" />,
		},
		{
			header: "Insurance Coverage Type",
			accessorKey: "insurance_coverage_type",
			cell: () => <BadgeField source="insurance_coverage_type" />,
		},
		{
			header: "Billing Provider",
			accessorKey: "billing_provider_id",
			cell: () => (
				<ReferenceField
					source="billing_provider_id"
					reference="users"
					link={false}
					emptyText="Not set"
				>
					<TextField source="full_name" />
				</ReferenceField>
			),
		},
		{
			header: "Planned Device IMEI",
			accessorKey: "onboarding_imei",
			cell: () => (
				<EditableCell fieldName="onboarding_imei" resource="patients" />
			),
		},
		{
			header: "Additional Notes",
			accessorKey: "onboarding_notes",
			cell: () => (
				<EditableCell fieldName="onboarding_notes" resource="patients" />
			),
		},
		{
			header: "Email",
			id: "user.email",
			accessorKey: "user.email",
			cell: () => <TextField source="user.email" />,
			show: false,
		},
		{
			header: "Primary Condition",
			accessorKey: "primary_condition",
			cell: () => (
				<BadgeField
					source="primary_condition"
					mapping={primaryConditions}
					applyMappedClassName={false}
					humanize={false}
				/>
			),
			show: false,
		},
		{
			header: "Registered On",
			id: "user.registered_on",
			accessorKey: "user.registered_on",
			cell: () => <LuxonDateField source="user.registered_on" />,
			show: false,
		},
		...(get(permissions, "is_internal") === true
			? [
					{
						header: "Provider",
						id: "user.tenant_id",
						accessorKey: "user.tenant_id",
						cell: () => (
							<ReferenceField
								source="user.tenant_id"
								reference="providers"
								link={false}
								emptyText="None"
								queryOptions={{
									refetchOnWindowFocus: false,
								}}
							>
								<TextField source="name" />
							</ReferenceField>
						),
					},
				]
			: []),

		{
			header: "Insurances",
			accessorKey: "insurances",
			cell: () => <TextField source="insurances" />,
			show: false,
		},

		{
			header: "Expected Care Program",
			accessorKey: "expected_care_program",
			cell: () => <TextField source="expected_care_program" />,
			show: false,
		},
		{
			header: "Enrollment User",
			accessorKey: "expected_onboarding_user_id",
			cell: () => (
				<ReferenceField
					source="expected_onboarding_user_id"
					reference="users"
					link={false}
					emptyText="None"
				>
					<TextField source="full_name" />
				</ReferenceField>
			),
			show: false,
		},
		{
			header: "Is Virtual Enrollment",
			accessorKey: "virtual_enrollment",
			cell: ({ row }) => (row.original.virtual_enrollment ? "Yes" : "No"),
			show: false,
		},

		{
			header: "Status",
			accessorKey: "patient_status",
			cell: () => (
				<BadgeField source="patient_status" mapping={patientStatus} />
			),
			show: false,
		},
		{
			header: "Actions",
			accessorKey: "actions",
			cell: () => <EnrollmentEditButton />,
			enableHiding: false,
		},
	];
};

const EnrollmentListBody = () => {
	const { permissions } = usePermissions();
	const { config } = useEnrollmentQueue();
	const navigate = useNavigate();
	const noDateSet = config.appointmentDate === null;

	const finalColumns = useMemo(() => {
		return getColumns(permissions);
	}, [permissions]);

	return (
		<ListUIRoot>
			<AppTitle title="Enrollment Queue" />
			<ListBase
				resource="patients"
				storeKey={STORE_KEY}
				exporter={false}
				filter={{
					"patient_status[in]": config.showEnrolledPatients
						? ["pending_enrollment", "active", "declined"]
						: ["pending_enrollment"],
					...(config.appointmentDate && {
						"onboarding_appointment_datetime[gte]": config.appointmentDate.from,
						"onboarding_appointment_datetime[lte]": config.appointmentDate.to,
					}),
				}}
				queryOptions={{
					enabled: !noDateSet,
				}}
				sort={{ field: "onboarding_appointment_datetime", order: "ASC" }}
			>
				<div className="flex flex-col gap-2 w-full">
					<Toolbar />
					{noDateSet ? (
						<div className="flex flex-col items-center justify-center h-full gap-5 p-8 text-center">
							<p className="font-bold text-xl">No date range selected</p>
							<p className="text-gray-500 text-md">
								Please select a date range to view enrollment data.
							</p>
						</div>
					) : (
						<div className="w-max-content overflow-x-auto">
							<DataTable
								toolbar={<ToolbarDataTable showViewOptions={true} />}
								columns={finalColumns}
								emptyState={
									<EmptyState
										title="No enrollment data found"
										description="There are no patients in the enrollment queue for the
                    selected date range."
										icons={[UserPlus]}
										action={<ResetButton />}
									/>
								}
								onRowClick={(row) => {
									navigate(`/patients/${row.id}/show`);
								}}
								storeKey={STORE_KEY}
							/>
						</div>
					)}
				</div>
			</ListBase>
		</ListUIRoot>
	);
};

export const EnrollmentList: FC = () => {
	return (
		<div className="pb-2">
			<EnrollmentQueueProvider>
				<EnrollmentListBody />
			</EnrollmentQueueProvider>
		</div>
	);
};

const ResetButton = () => {
	const { setConfig } = useEnrollmentQueue();
	const { setFilters } = useListContext();
	return (
		<Button
			variant="outline"
			onClick={() => {
				console.log("clicked");
				setConfig((prevConfig) => ({
					...prevConfig,
					appointmentDate: {
						from: new Date(new Date().setHours(0, 0, 0, 0)),
						to: new Date(new Date().setHours(23, 59, 59, 999)),
					},
				}));
				setFilters({});
			}}
			className="shadow-sm hover:bg-white"
		>
			Reset Filters
		</Button>
	);
};
