"use client";

import * as React from "react";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";

import { cn } from "../utils/cn";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export function DatePicker({
	value,
	onChange,
}: {
	value: string;
	onChange: (date: string) => void;
}) {
	const handleDateSelect = (date: Date | undefined) => {
		if (date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const day = String(date.getDate()).padStart(2, "0");
			onChange(`${year}-${month}-${day}`);
		}
	};

	// Parse YYYY-MM-DD string to local date . Try to handle ones with T, removing the time portion
	const dateValue = value
		? (() => {
				try {
					// Handle both YYYY-MM-DD and ISO date strings
					if (value.includes("T")) {
						return new Date(value);
					}
					const [year, month, day] = value.split("-").map(Number);
					return new Date(year, month - 1, day);
				} catch (e) {
					console.error("Invalid date format:", value);
					return undefined;
				}
			})()
		: undefined;

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"w-[280px] justify-start text-left font-normal",
						!value && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{dateValue ? format(dateValue, "PPP") : <span>Pick a date</span>}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={dateValue}
					onSelect={handleDateSelect}
				/>
			</PopoverContent>
		</Popover>
	);
}
