export const allergyCategory = [
  { id: 'food', name: 'Food' },
  { id: 'medication', name: 'Medication' },
  { id: 'environment', name: 'Environment' },
  { id: 'biologic', name: 'Biologic' },
];

export const allergySeverity = [
  { id: 'mild', name: 'Mild' },
  { id: 'moderate', name: 'Moderate' },
  { id: 'severe', name: 'Severe' },
  { id: 'unknown', name: 'Unknown' },
];
