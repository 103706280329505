import RangeField from '@/modules/ra-ui/components/RangeField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { humanize } from 'inflection';
import { DateTime } from 'luxon';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  useGetList,
  useRecordContext,
} from 'react-admin';

function ExpandedRow() {
  const record = useRecordContext();

  return (
    <div className="py-2">
      <span className="flex flex-row gap-4">
        <p className="text-sm font-semibold text-gray-500 ">Created at</p>
        {record.created_at
          ? DateTime.fromISO(record.created_at, { zone: 'utc' }).toLocaleString(
              DateTime.DATETIME_MED,
            )
          : null}
      </span>
      <span className="flex flex-row gap-4">
        <p className="text-sm font-semibold text-gray-500 ">Updated at</p>
        {record.updated_at
          ? DateTime.fromISO(record.updated_at, { zone: 'utc' }).toLocaleString(
              DateTime.DATETIME_MED,
            )
          : null}
      </span>
      <span className="flex flex-row gap-4 items-center">
        <p className="text-sm font-semibold text-gray-500 ">Value</p>
        <p className="text-base font-semibold">
          {JSON.stringify(record.value)}
        </p>
      </span>
      <span className="flex flex-row gap-4 items-center">
        <p className="text-sm font-semibold text-gray-500 ">Unit</p>
        <p className="text-base font-semibold">{JSON.stringify(record.unit)}</p>
      </span>
      <span className="flex flex-row gap-4 items-center">
        <p className="text-sm font-semibold text-gray-500 ">Additional Info</p>
        <p className="text-base font-semibold">
          {JSON.stringify(record.additional_info)}
        </p>
      </span>
      <span className="flex flex-row gap-4 items-center">
        <p className="text-sm font-semibold text-gray-500 ">Codes</p>
        <p className="text-base font-semibold">
          {JSON.stringify(record.codes)}
        </p>
      </span>
    </div>
  );
}

export function ObservationsDataGrid(props) {
  const { record } = props;

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList(
    `patients/${record.id}/observations-v2`,
    {
      pagination: { page, perPage },
      filter: {},
      sort,
    },
  );
  if (!record) {
    return null;
  }

  return (
    <div>
      <Datagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        setSort={setSort}
        empty={
          <EmptyDatagrid
            resourceName="Observations"
            textFormatString="No observations found for patient"
          />
        }
        expand={<ExpandedRow />}
        expandSingle
        rowClick="expand"
      >
        <RangeField
          source_start="timestamp"
          source_end="timestamp_end"
          showTime
          sortBy="timestamp"
          label="Timestamp"
        />

        <FunctionField
          render={(record) => record.name && humanize(record.name)}
          label="Name"
        />
        <BadgeField source="category" />
        <BadgeField source="source" />
      </Datagrid>
      {data && data.length ? (
        <ListContextProvider
          value={{ page, perPage, setPage, setPerPage, total } as any}
        >
          <Pagination />
        </ListContextProvider>
      ) : null}
    </div>
  );
}

export default function ObservationsSection(props) {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 mt-4">
      <div className="flex flex-row space-x-1 justify-between items-center ">
        <div>
          <h3 className="text-xl font-semibold">Observations</h3>
          <p className="text-sm text-gray-500">
            Observations are measurements of a patient's health status. They
            include vital signs, lab results, and other clinical measurements.
            They are added to a patient's record using medical devices, manual
            entry by patients, assessments, and via health information
            exchanges.
          </p>
        </div>
      </div>
      <ObservationsDataGrid record={record} />
    </div>
  );
}
