import React from 'react';
import { usePermissions, useRecordContext } from 'react-admin';

import { MonitoringActivitiesListDataGrid } from '../../../monitoring-activity/components/MonitoringActivitiesListDataGrid';
import { PatientClinicalActivitiesList } from '@/modules/clinical-activities/components/PatientClinicalActivitiesList';
import { useFeatureFlag } from '@/modules/feature-flags/hooks/useFeatureFlag';
import { get } from 'lodash';

const ClinicalActivitiesSection = () => {
  const { isEnabled } = useFeatureFlag('clinical_activities');
  const { permissions } = usePermissions();

  if (!isEnabled || !get(permissions, 'clinical_activities.list')) {
    return null;
  }

  return (
    <div>
      <div>
        <h3 className="text-xl font-semibold">Clinical Activities</h3>
        <p className="text-sm text-gray-500">
          All clinical activities for this patient related to providing virtual
          care services.
        </p>
      </div>
      <PatientClinicalActivitiesList />
    </div>
  );
};

export default function MonitoringActivitiesSection(props) {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Monitoring</h3>
        <p className="text-sm text-gray-500">
          All monitoring activities for this patient related to providing
          virtual care services.
        </p>
      </div>
      <MonitoringActivitiesListDataGrid />
      <ClinicalActivitiesSection />
    </div>
  );
}
