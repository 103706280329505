import * as React from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import { Alert, AlertTitle } from '../../ui/components/alert';
import { humanize } from 'inflection';
import { useAttendance } from '@/modules/attendance/hooks/UserCheckedIn';

export const CanBeConsentedReasons: React.FC = () => {
  // Hooks
  const record = useRecordContext();
  const { data } = useGetOne(
    'patients',
    {
      id: `${record?.id}/can-be-consented`,
    },
    { enabled: !!record?.id },
  );
  const { isCheckedIn, attendanceEnforced } = useAttendance();

  // Derived state
  const hasMissingItems = data?.missing_items?.length > 0;

  // Render missing items alert
  const renderMissingItemsAlert = () => {
    if (!hasMissingItems) return null;

    return (
      <Alert variant="warning" className="my-2">
        <AlertTitle>Cannot mark as consented</AlertTitle>
        <div className="text-left mt-2">
          <p className="mb-2">
            The following items need to be completed first:
          </p>
          <ul className="list-disc pl-6">
            {data.missing_items.map((item, index) => (
              <li key={index} className="text-sm text-gray-600">
                {humanize(item)}
              </li>
            ))}
          </ul>
        </div>
      </Alert>
    );
  };

  // Render check-in alert
  const renderCheckInAlert = () => {
    if (!attendanceEnforced || isCheckedIn) return null;

    if (record?.virtual_enrollment) {
      return (
        <Alert variant="info" className="my-1">
          <AlertTitle>
            <AlertTitle>Check-in </AlertTitle>
          </AlertTitle>
          <div className="text-left mt-2">
            <p>
              This patient is to be enrolled virtually, so check-in is not
              required.
            </p>
          </div>
        </Alert>
      );
    }

    return (
      <Alert variant="warning" className="my-1">
        <AlertTitle>Check-in Required</AlertTitle>
        <div className="text-left mt-2">
          <p>You must check in before consenting patients.</p>
        </div>
      </Alert>
    );
  };

  return (
    <>
      {renderMissingItemsAlert()}
      {renderCheckInAlert()}
    </>
  );
};
