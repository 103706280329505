import AppTitle from "@/modules/layout/components/app-title";
import { DataTable } from "@/modules/data-table/components/data-table";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import { CreateButton } from "@/modules/ra-ui/components/create-button";
import { Building } from "lucide-react";
import React, { type FC } from "react";
import { ListBase } from "react-admin";
import { useNavigate } from "react-router-dom";
import { LuxonDateField } from "@/modules/ra-ui/components/LuxonDateField";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";

const getColumns = () => {
	return [
		{
			header: "Name",
			accessorKey: "name",
			cell: ({ row }) => (
				<span className="font-medium mx-2">{row.original.name}</span>
			),
		},
		{
			header: "Active",
			accessorKey: "is_active",
			cell: ({ row }) => (row.original.is_active ? "Yes" : "No"),
		},
		{
			header: "Registered On",
			accessorKey: "registered_on",
			cell: () => <LuxonDateField source="registered_on" showTime />,
		},
		{
			header: "Primary Email",
			accessorKey: "email",
		},
	];
};

export const TenantList: FC = () => {
	const navigate = useNavigate();

	return (
		<>
			<AppTitle title="Providers" />
			<ListBase
				sort={{
					field: "registered_on",
					order: "DESC",
				}}
			>
				<div className="flex flex-col gap-2 mx-2">
					<DataTable
						columns={getColumns()}
						toolbar={
							<DataTableToolbar
								showViewOptions={true}
								actionButtons={[
									<CreateButton variant="default" label="Provider" />,
								]}
								searchProps={{
									source: "name",
									placeholder: "Search...",
								}}
							/>
						}
						emptyState={
							<EmptyState
								title="No providers found"
								description="Providers are organizations that provide services to patients."
								icons={[Building]}
							/>
						}
						onRowClick={(row) => {
							navigate(`/providers/${row.id}/edit`);
						}}
					/>
				</div>
			</ListBase>
		</>
	);
};
