import React from "react";

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/modules/ui/components/tabs";

import { Badge } from "@/modules/ui/components/badge";
import { cn } from "@/modules/ui/utils/cn";
import INoteAction from "healthicons-react/dist/filled/INoteAction";
import { get } from "lodash";
import { ClipboardList, ListTodo, Phone } from "lucide-react";
import {
	useGetIdentity,
	useGetList,
	usePermissions,
	useRecordContext,
	useStore,
} from "ra-core";
import { MonitoringActivityAside } from "../../monitoring-activity/components/MonitoringActivityAside";
import { CallLogList } from "../../voice-calls/components/CallLogList";
import ClinicalActivityBase from "@/modules/clinical-activities/components/ClinicalActivityBase";
import { useFeatureFlags } from "@/modules/feature-flags/hooks/useFeatureFlag";
import { TaskAsideBase } from "@/modules/tasks/components/TaskAsideBase";

const TasksBadge = ({ onClickBadge, isCollapsed }) => {
	const record = useRecordContext();
	const { identity } = useGetIdentity();
	const { total, isLoading } = useGetList("tasks", {
		pagination: { page: 1, perPage: 1 },
		filter: {
			"patient_id[eq]": record?.id,
			"complete[eq]": false,
			"assigned_to_id[eq]": identity?.id,
		},
		sort: { field: "created_at", order: "DESC" },
	});

	const displayTotal = total > 99 ? "99+" : total;
	const text = !isCollapsed ? displayTotal : `${displayTotal} Tasks`;
	if (isLoading) {
		return null;
	}
	if (total === 0 && isCollapsed) {
		return (
			<Badge
				variant="outline"
				className="ml-1 text-sm rounded-full cursor-pointer bg-white border-gray-300"
				onClick={onClickBadge}
			>
				<ListTodo className=" mr-1" />
				Tasks
			</Badge>
		);
	}

	if (total === 0 && !isCollapsed) {
		return null;
	}

	return (
		<Badge
			variant="outline"
			className={cn(
				"ml-1 border-yellow-500 bg-yellow-50 text-sm rounded-full",
				{ "cursor-pointer": isCollapsed },
			)}
			onClick={isCollapsed ? onClickBadge : null}
		>
			{text}
		</Badge>
	);
};

const TABS_CONFIG = [
	{
		id: "activity",
		label: "Activity",
		icon: ClipboardList,
		permission: "clinical_activities.list",
		featureFlag: "clinical_activities",
		content: ClinicalActivityBase,
		className: "px-2",
	},
	{
		id: "monitoring",
		label: "Monitoring",
		icon: INoteAction,
		permission: "monitoring_activities.list",
		content: MonitoringActivityAside,
		className: "px-2",
	},
	{
		id: "tasks",
		label: "Tasks",
		icon: ListTodo,
		content: () => <TaskAsideBase />,
		className: "px-1",
		showBadge: true,
	},
	{
		id: "calls",
		label: "Calls",
		icon: Phone,
		permission: "voice_calls.list",
		featureFlag: "voice_calls",
		content: CallLogList,
		className: "px-2",
	},
];

export function PatientAside(props) {
	const { isCollapsed, panelRef } = props;
	const { permissions } = usePermissions();
	const { flags, isLoading: isFlagsLoading } = useFeatureFlags();
	const [selectedTab, setSelectedTab] = useStore("selectedTab", "monitoring");

	if (isFlagsLoading) {
		return null;
	}

	const openPanel = () => {
		if (panelRef.current) {
			panelRef.current.resize(25);
		}
	};

	if (isCollapsed) {
		return (
			<div className="absolute right-10 top-2">
				<TasksBadge onClickBadge={openPanel} isCollapsed={isCollapsed} />
			</div>
		);
	}

	const availableTabs = TABS_CONFIG.filter(
		(tab) =>
			(!tab.permission || get(permissions, tab.permission, false)) &&
			(tab.featureFlag ? flags[tab.featureFlag] : true),
	);

	return (
		<Tabs value={selectedTab} onValueChange={setSelectedTab}>
			<div className="relative rounded-sm h-aside-tabs bg-muted overflow-x-auto">
				<TabsList className="flex flex-row justify-stretch w-full h-12 min-w-fit">
					{availableTabs.map(({ id, label, icon: Icon, showBadge }) => (
						<TabsTrigger key={id} asChild value={id} className="grow text-sm">
							<div>
								<Icon className="opacity-70 mr-1" />
								{label}
								{showBadge && (
									<TasksBadge
										onClickBadge={openPanel}
										isCollapsed={isCollapsed}
									/>
								)}
							</div>
						</TabsTrigger>
					))}
				</TabsList>
			</div>
			{availableTabs.map(({ id, content: Content, className }) => (
				<TabsContent key={id} className={className} value={id}>
					<Content />
				</TabsContent>
			))}
		</Tabs>
	);
}
