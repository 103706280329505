import {
	minLength,
	required,
	useGetIdentity,
	useGetList,
	useRecordContext,
} from "react-admin";
import CreateInDialogButton from "@/modules/ra-ui/components/create-dialog-button";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { DateInput } from "@/modules/ui/inputs/date-input";
import { TextInput } from "@/modules/ui/inputs/text-input";
import { TextAreaInput } from "@/modules/ui/inputs/text-area-input";

const filterUserName = (searchText) => ({
	q: { field: "full_name", value: searchText },
});

export const TaskCreateInputs = ({ modal = false }) => {
	const record = useRecordContext();

	const { data: careTeam, total } = useGetList(
		`patients/${record?.id}/care-teams`,
		{
			pagination: { page: 1, perPage: 5 },
			sort: { field: "id", order: "ASC" },
		},
	);

	if (!record) return null;

	const filters: any = {
		"role[ne]": "patient",
		"tenant_id[in]": [record?.user.tenant_id, null],
	};

	const presetOptions = {
		label: "Care Team",
		choices: careTeam?.map((item) => ({
			id: item.practitioner_id,
			full_name: item.practitioner?.full_name,
			credentials: item.practitioner?.credentials,
			is_internal: item.practitioner?.is_internal,
		})),
	};

	return (
		<div className="flex flex-col gap-2">
			<TextInput
				source="title"
				validate={[
					required(),
					minLength(10, "Come on, you can do better! Be descriptive!"),
				]}
				helperText={false}
			/>
			<TextAreaInput
				source="description"
				helperText={false}
				multiline
				minRows={2}
			/>
			<DateInput source="due_on" helperText={false} validate={[required()]} />
			<SelectInput
				source="priority"
				choices={[
					{ id: "1", name: "High" },
					{ id: "2", name: "Medium" },
					{ id: "3", name: "Low" },
				]}
				helperText={false}
			/>

			<AutocompleteInput
				source="assigned_to_id"
				reference="users"
				filter={filters}
				label="Assigned to"
				optionText={(choice) => {
					if (choice.is_internal) {
						return `${choice.full_name} (Vironix) ${
							choice.credentials ? `(${choice.credentials})` : ""
						}`;
					}
					return `${choice.full_name} ${
						choice.credentials ? `(${choice.credentials})` : ""
					}`;
				}}
				parse={(v) => (v === "" ? null : v)}
				helperText={false}
				filterToQuery={filterUserName}
				presetOptions={total > 0 ? presetOptions : null}
				modal={modal}
			/>
		</div>
	);
};

export function TaskCreateButton() {
	const record = useRecordContext();
	const { identity } = useGetIdentity();

	const transform = (data) => {
		return {
			...data,
			patient_id: record.id,
			added_by_id: identity?.id,
			task_type: "one_off",
			tenant_id: record?.user.tenant_id,
		};
	};

	return (
		<CreateInDialogButton
			title="Add new task"
			resource="tasks"
			transform={transform}
			label="Task"
			notifyMessage="Task created"
			refreshOnSuccess
			dialogVariant="shadcn"
		>
			<TaskCreateInputs modal={true} />
		</CreateInDialogButton>
	);
}
