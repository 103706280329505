import React, { ReactElement, ReactEventHandler } from 'react';
import { Button } from '@/modules/ui/components/button';
import { Trash2 } from 'lucide-react';
import { cn } from '@/modules/ui/utils/cn';
import {
  RaRecord,
  useDeleteWithUndoController,
  DeleteParams,
  useRecordContext,
  useResourceContext,
  RedirectionSideEffect,
} from 'ra-core';
import { UseMutationOptions } from '@tanstack/react-query';

export interface DeleteWithUndoButtonProps<
  RecordType extends RaRecord = any,
  MutationOptionsError = unknown,
> {
  icon?: ReactElement;
  onClick?: ReactEventHandler<any>;
  mutationOptions?: Omit<
    UseMutationOptions<
      RecordType,
      MutationOptionsError,
      DeleteParams<RecordType>
    >,
    'mutationFn'
  >;
  record?: RecordType;
  redirect?: RedirectionSideEffect;
  resource?: string;
  className?: string;
  label?: string;
  variant?:
    | 'link'
    | 'default'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'ghost';
}

const defaultIcon = <Trash2 className="h-4 w-4" />;

export const DeleteWithUndoButton = <RecordType extends RaRecord = any>(
  props: DeleteWithUndoButtonProps<RecordType>,
) => {
  const {
    label = 'Delete',
    className,
    icon = defaultIcon,
    onClick,
    redirect = 'list',
    mutationOptions,
    variant = 'destructive',
    ...rest
  } = props;

  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  const { isLoading, handleDelete } = useDeleteWithUndoController({
    record,
    resource,
    redirect,
    onClick,
    mutationOptions,
  });

  if (!record || record.id == null) {
    return null;
  }

  return (
    <Button
      onClick={handleDelete}
      disabled={isLoading}
      className={cn('', className)}
      variant={variant}
      {...rest}
    >
      {icon}
      {label && <span className="ml-2">{label}</span>}
    </Button>
  );
};
