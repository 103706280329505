import React from "react";
import { useRecordContext } from "react-admin";
import { get } from "lodash";
import { calcAge } from "@/utils/calcAge";

const genderMap = {
	Male: "M",
	Female: "F",
	Other: "O",
};

export const NameField = (props) => {
	const record = useRecordContext(props);

	if (!record) {
		return null;
	}

	const birthdate = get(record, "birthdate");
	const gender = get(record, "gender");
	const age = calcAge(birthdate);

	return (
		<span className="text-sm whitespace-nowrap flex items-center gap-1">
			<span className="font-medium">{get(record, "user.full_name")}</span>
			{age && gender ? (
				<span className="text-xs ">{`(${age}yr ${genderMap[gender]})`} </span>
			) : null}
		</span>
	);
};
