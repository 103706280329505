import { ClinicalActivitySelector } from "./ClinicalActivitySelector";
import { ClinicalActivityContainer } from "./ClinicalActivityContainer";
import { usePatientClinicalActivity } from "../context/PatientClinicalActivityContext";

const ClinicalActivitySwitcher = () => {
	const { inProgressActivityId } = usePatientClinicalActivity();

	return (
		<div className="overflow-y-auto h-screen-minus-tabs">
			{inProgressActivityId ? (
				<ClinicalActivityContainer />
			) : (
				<ClinicalActivitySelector />
			)}
		</div>
	);
};

export default ClinicalActivitySwitcher;
