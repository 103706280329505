import { DateTime } from 'luxon';

export const dateFormatter = (userDate: string, format = DateTime.DATE_MED) => {
  if (!userDate) {
    return '';
  }
  
  // Check if time is present in the string by looking for : character
  const hasTime = userDate.includes(':');
  
  if (hasTime) {
    return DateTime.fromISO(userDate, { zone: "utc" })
      .toLocal()
      .toLocaleString(format);
  }
  
  return DateTime.fromISO(userDate)
    .toLocaleString(format);
};
