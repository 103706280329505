import { get } from 'lodash';
import React from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  ReferenceField,
  useGetList,
  useRecordContext,
} from 'react-admin';

import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { CPTCodeField } from '@/modules/ra-ui/components/CPTCodeField';
import { ColoredChipField } from '@/modules/ra-ui/components/ColoredChipField';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { serviceInstanceTypesAbbreviated } from '../constants/serviceInstanceTypesAbbreviated';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { ServiceProgressField } from './ServiceProgressField';
import { usePatientTableState } from '@/modules/common/hooks/usePatientTableState';

export function ServiceDataGrid() {
  const record = useRecordContext();
  const {
    page,
    perPage,
    sort,
    filterValues,
    setPage,
    setPerPage,
    setSort,
    setFilters,
  } = usePatientTableState('services', record?.id);

  const { data, total, isLoading } = useGetList('services', {
    pagination: { page, perPage },
    filter: {
      'patient_id[eq]': record?.id,
      ...filterValues,
    },
    sort,
  });

  if (!record) {
    return null;
  }
  return (
    <ListContextProvider
      // @ts-ignore
      value={{
        filterValues,
        setFilters,
        displayedFilters: {},
        page,
        perPage,
        setPage,
        setPerPage,
        total,
      }}
    >
      <DataTableToolbar showViewOptions={false} search={false} className="">
        <DataTableDateRangeFilter column="start_date" title="Start Date" />
        <DataTableFilter
          column="service_instance_id[in]"
          title="Care Program"
          options={record.service_instances.map((serviceInstance) => ({
            label:
              serviceInstance.type &&
              serviceInstanceTypesAbbreviated[serviceInstance.type],
            value: serviceInstance.id.toString(),
          }))}
        />
      </DataTableToolbar>
      <Datagrid
        bulkActionButtons={false}
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        setSort={setSort}
        rowClick={false}
      >
        <LuxonDateField source="start_date" zone="utc" />
        <LuxonDateField source="end_date" zone="utc" />
        <LuxonDateField source="date" label="Date completed" zone="utc" />
        <ColoredChipField source="status" />
        <CPTCodeField source="cpt_code" label="CPT code" />
        <ServiceProgressField source="progress" />
        <BooleanField source="claimable" />
        <BooleanField
          source="patient_communication_performed"
          emptyText="N/A"
        />
        <ReferenceField
          source="service_instance_id"
          reference="service-instances"
          label="Care Program Type"
        >
          <FunctionField
            render={(record) =>
              `${get(
                serviceInstanceTypesAbbreviated,
                record.type,
                record.type,
              )}`
            }
          />
        </ReferenceField>
      </Datagrid>
      <Pagination />
    </ListContextProvider>
  );
}
