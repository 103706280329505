import { Badge } from "@/modules/ui/components/badge";
import { Button } from "@/modules/ui/components/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/modules/ui/components/command";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/modules/ui/components/dialog";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/modules/ui/components/popover";
import { Separator } from "@/modules/ui/components/separator";
import { cn } from "@/modules/ui/utils/cn";
import { CheckIcon, PlusCircle, UserMinus, UserPlus, X } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetList, useListContext } from "react-admin";
import { debounce } from "lodash";
// Define types for practitioners and members
interface Practitioner {
	id: string;
	full_name: string;
	credentials?: string;
}

const MemberCommandList = ({
	practitioners,
	currentMembers,
	onSelect,
	handleSearchChange,
}: {
	practitioners: Practitioner[];
	currentMembers: Practitioner[];
	onSelect: (member: Practitioner) => void;
	handleSearchChange: (value: string) => void;
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Command shouldFilter={false}>
			<CommandInput
				placeholder="Search care team members..."
				ref={inputRef}
				onValueChange={handleSearchChange}
			/>
			<CommandList>
				<CommandEmpty>No members found</CommandEmpty>
				<CommandGroup>
					{practitioners.map((member) => (
						<CommandItem
							key={member.id}
							onSelect={() => onSelect(member)}
							className="flex items-center"
						>
							<CheckIcon
								className={cn(
									"mr-2 h-4 w-4",
									currentMembers.some((m) => m.id === member.id)
										? "opacity-100"
										: "opacity-0",
								)}
							/>
							<span>{member.full_name}</span>
							{member.credentials && (
								<span className="ml-2 text-xs text-muted-foreground">
									({member.credentials})
								</span>
							)}
						</CommandItem>
					))}
				</CommandGroup>
			</CommandList>
		</Command>
	);
};

export const CareTeamMemberFilter = () => {
	const [open, setOpen] = useState(false);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [selectionType, setSelectionType] = useState<"include" | "exclude">(
		"include",
	);
	const [includeMembers, setIncludeMembers] = useState<Practitioner[]>([]);
	const [excludeMembers, setExcludeMembers] = useState<Practitioner[]>([]);
	const [searchText, setSearchText] = useState("");

	const { setFilters, filterValues } = useListContext();

	// Get practitioners with search filtering
	const { data: practitioners = [] } = useGetList(
		"users",
		{
			pagination: { page: 1, perPage: 100 },
			sort: { field: "full_name", order: "ASC" },
			filter: {
				"role[notin]": "patient",
				"is_provider[eq]": true,
				...(searchText &&
					searchText.length > 1 && {
						q: { field: "full_name", value: searchText },
					}),
			},
		},
		{
			enabled: open || popoverOpen,
		},
	);

	// Get include members by IDs
	const includeIds = useMemo(() => {
		return filterValues["care_team_members.practitioner_id[inall]"]
			? filterValues["care_team_members.practitioner_id[inall]"]
					.split(",")
					.map((id) => Number.parseInt(id, 10))
			: [];
	}, [filterValues]);

	const { data: includeData = [] } = useGetList(
		"users",
		{
			pagination: { page: 1, perPage: 100 },
			filter: {
				"id[inall]": includeIds.length > 0 ? includeIds.join(",") : undefined,
			},
		},
		{
			enabled: open && includeIds.length > 0,
		},
	);

	// Get exclude members by IDs
	const excludeIds = useMemo(() => {
		return filterValues["care_team_members.practitioner_id[notin]"]
			? filterValues["care_team_members.practitioner_id[notin]"]
					.split(",")
					.map((id) => Number.parseInt(id, 10))
			: [];
	}, [filterValues]);

	const { data: excludeData = [] } = useGetList(
		"users",
		{
			pagination: { page: 1, perPage: 100 },
			filter: {
				"id[inall]": excludeIds.length > 0 ? excludeIds.join(",") : undefined,
			},
		},
		{
			enabled: open && excludeIds.length > 0,
		},
	);

	// Update state when data is loaded
	useEffect(() => {
		if (open && includeData.length > 0) {
			setIncludeMembers(includeData);
		}
	}, [open, includeData]);

	useEffect(() => {
		if (open && excludeData.length > 0) {
			setExcludeMembers(excludeData);
		}
	}, [open, excludeData]);

	// Handle dialog open
	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	// Handle dialog close
	const handleClose = () => {
		setOpen(false);
	};
	// Handle search input change with debounce
	const handleSearchChange = useCallback((value: string) => {
		const debouncedSearch = debounce((searchValue: string) => {
			setSearchText(searchValue);
		}, 500);
		debouncedSearch(value);
	}, []);

	// Handle adding a member to include or exclude list
	const handleAddMember = (member: Practitioner) => {
		if (selectionType === "include") {
			if (!includeMembers.find((m) => m.id === member.id)) {
				setIncludeMembers([...includeMembers, member]);
			}
		} else {
			if (!excludeMembers.find((m) => m.id === member.id)) {
				setExcludeMembers([...excludeMembers, member]);
			}
		}
		setPopoverOpen(false);
	};

	// Handle removing a member from include list
	const handleRemoveIncludeMember = (id: string) => {
		setIncludeMembers(includeMembers.filter((member) => member.id !== id));
	};

	// Handle removing a member from exclude list
	const handleRemoveExcludeMember = (id: string) => {
		setExcludeMembers(excludeMembers.filter((member) => member.id !== id));
	};

	// Apply filters
	const handleApply = () => {
		const newFilters = { ...filterValues };

		// Set include filter
		if (includeMembers.length > 0) {
			newFilters["care_team_members.practitioner_id[inall]"] = includeMembers
				.map((m) => m.id)
				.join(",");
		} else {
			newFilters["care_team_members.practitioner_id[inall]"] = undefined;
		}

		// Set exclude filter
		if (excludeMembers.length > 0) {
			newFilters["care_team_members.practitioner_id[notin]"] = excludeMembers
				.map((m) => m.id)
				.join(",");
		} else {
			newFilters["care_team_members.practitioner_id[notin]"] = undefined;
		}

		// Filter out undefined values
		const cleanedFilters = Object.fromEntries(
			Object.entries(newFilters).filter(([_, v]) => v !== undefined),
		);

		setFilters(cleanedFilters);
		handleClose();
	};

	// Clear filters
	const handleClear = () => {
		setIncludeMembers([]);
		setExcludeMembers([]);

		const newFilters = { ...filterValues };
		newFilters["care_team_members.practitioner_id[inall]"] = undefined;
		newFilters["care_team_members.practitioner_id[notin]"] = undefined;

		// Filter out undefined values
		const cleanedFilters = Object.fromEntries(
			Object.entries(newFilters).filter(([_, v]) => v !== undefined),
		);

		setFilters(cleanedFilters);
		handleClose();
	};

	// Calculate total number of filters
	const selectedItems = [
		...(filterValues["care_team_members.practitioner_id[inall]"]
			? filterValues["care_team_members.practitioner_id[inall]"].split(",")
			: []),
		...(filterValues["care_team_members.practitioner_id[notin]"]
			? filterValues["care_team_members.practitioner_id[notin]"].split(",")
			: []),
	].filter(Boolean);

	return (
		<>
			<Button
				variant="outline"
				onClick={handleOpen}
				size="sm"
				className="h-8 border-dashed"
			>
				<PlusCircle className="mr-2 h-4 w-4" />
				Care Team Member
				{selectedItems.length > 0 ? (
					<>
						<Separator orientation="vertical" className="mx-2 h-4" />
						<Badge variant="secondary" className="rounded-sm px-1 font-normal">
							{selectedItems.length} selected
						</Badge>
					</>
				) : null}
			</Button>

			<Dialog open={open} onOpenChange={setOpen}>
				<DialogContent className="sm:max-w-[600px]">
					<DialogHeader>
						<DialogTitle>Care Team Filter</DialogTitle>
						<DialogDescription>
							Filter patients by care team membership. Include patients who have
							ALL selected members, and exclude patients who have ANY of the
							excluded members.
						</DialogDescription>
					</DialogHeader>

					<div className="space-y-4">
						{/* Include Members Section */}
						<div className="space-y-2">
							<div className="flex items-center justify-between">
								<h3 className="text-sm font-medium">Include Members</h3>
								<Popover
									open={popoverOpen && selectionType === "include"}
									onOpenChange={(open) => {
										setPopoverOpen(open);
										if (open) {
											setSelectionType("include");
										}
										setSearchText("");
									}}
									modal
								>
									<PopoverTrigger asChild>
										<Button variant="outline" size="sm" className="h-8 gap-1">
											<UserPlus className="h-4 w-4" />
											<span>Add</span>
										</Button>
									</PopoverTrigger>
									<PopoverContent className="p-0 w-80" align="end">
										<MemberCommandList
											practitioners={practitioners}
											currentMembers={includeMembers}
											onSelect={handleAddMember}
											handleSearchChange={handleSearchChange}
										/>
									</PopoverContent>
								</Popover>
							</div>

							<div className="text-sm text-muted-foreground">
								Patients must have ALL these members on their care team
							</div>

							{includeMembers.length > 0 ? (
								<div className="flex flex-wrap gap-2 mt-2">
									{includeMembers.map((member) => (
										<Badge
											key={member.id}
											variant="secondary"
											className="flex items-center gap-1 px-3 py-1"
										>
											{member.full_name}
											<X
												className="h-3 w-3 cursor-pointer"
												onClick={() => handleRemoveIncludeMember(member.id)}
											/>
										</Badge>
									))}
								</div>
							) : (
								<div className="text-sm italic text-muted-foreground">
									No members selected
								</div>
							)}
						</div>

						<div className="border-t my-4" />

						{/* Exclude Members Section */}
						<div className="space-y-2">
							<div className="flex items-center justify-between">
								<h3 className="text-sm font-medium">Exclude Members</h3>
								<Popover
									open={popoverOpen && selectionType === "exclude"}
									onOpenChange={(open) => {
										setPopoverOpen(open);
										if (open) {
											setSelectionType("exclude");
										}
										setSearchText("");
									}}
									modal
								>
									<PopoverTrigger asChild>
										<Button variant="outline" size="sm" className="h-8 gap-1">
											<UserMinus className="h-4 w-4" />
											<span>Add</span>
										</Button>
									</PopoverTrigger>
									<PopoverContent className="p-0 w-80" align="end">
										<MemberCommandList
											practitioners={practitioners}
											currentMembers={excludeMembers}
											onSelect={handleAddMember}
											handleSearchChange={handleSearchChange}
										/>
									</PopoverContent>
								</Popover>
							</div>

							<div className="text-sm text-muted-foreground">
								Patients must NOT have ANY of these members on their care team
							</div>

							{excludeMembers.length > 0 ? (
								<div className="flex flex-wrap gap-2 mt-2">
									{excludeMembers.map((member) => (
										<Badge
											key={member.id}
											variant="destructive"
											className="flex items-center gap-1 px-3 py-1"
										>
											{member.full_name}
											<X
												className="h-3 w-3 cursor-pointer"
												onClick={() => handleRemoveExcludeMember(member.id)}
											/>
										</Badge>
									))}
								</div>
							) : (
								<div className="text-sm italic text-muted-foreground">
									No members selected
								</div>
							)}
						</div>
					</div>

					<DialogFooter className="flex justify-between">
						<Button variant="outline" onClick={handleClear}>
							Clear
						</Button>
						<Button onClick={handleApply}>Apply</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};
