import {
	useRecordContext,
	required,
	FormDataConsumer,
	maxLength,
} from "react-admin";
import EditInDialogButton from "@/modules/ra-ui/components/edit-dialog-button";
import {
	allergyCategory,
	allergySeverity,
} from "../constants/allergyConstants";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { TextInput } from "@/modules/ui/inputs/text-input";
import { DateInput } from "@/modules/ui/inputs/date-input";
import { MedicationSearchInput } from "@/modules/medications/components/MedicationSearchInputV2";
import { SnomedInput } from "@/modules/ui/inputs/snomed-input";

export function AllergyEditButton({ variant = "ghost", label = null }) {
	const record = useRecordContext();

	return (
		<EditInDialogButton
			title="Edit allergy"
			resource={`patients/${record?.patient_id}/allergy-intolerances`}
			variant={variant}
			label={label}
			notifyMessage="Allergy updated"
			refreshOnSuccess={true}
			closeOnClickOutside={false}
			dialogVariant="shadcn"
		>
			<div className="flex flex-col gap-1">
				<SelectInput
					source="category"
					choices={allergyCategory}
					optionText="name"
					optionValue="id"
					validate={required()}
				/>
				<FormDataConsumer>
					{({ formData }) => {
						if (formData.category === "medication") {
							return <MedicationSearchInput source="substance" />;
						}
						if (!formData.category) {
							return null;
						}
						return (
							<SnomedInput
								source="substance"
								reference="snowstorm"
								meta={{
									category: formData.category,
								}}
								label="Substance"
								validate={required()}
							/>
						);
					}}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData }) => {
						if (formData.substance) {
							return (
								<>
									<SnomedInput
										source="reaction"
										reference="snowstorm"
										meta={{
											category: "finding",
										}}
										label="Reactions"
										multiple
										validate={[
											maxLength(3, "You can only add up to 3 reactions"),
										]}
									/>
									<SelectInput
										source="reaction_severity"
										choices={allergySeverity}
										optionText="name"
										optionValue="id"
										validate={required()}
									/>
								</>
							);
						}
						return null;
					}}
				</FormDataConsumer>

				<SelectInput
					source="status"
					choices={[
						{ id: "active", name: "Active" },
						{ id: "inactive", name: "Inactive" },
						{ id: "resolved", name: "Resolved" },
					]}
					optionText="name"
					optionValue="id"
					validate={required()}
					defaultValue="active"
				/>
				<DateInput source="onset_date" />
				<TextInput source="notes" label="Allergy notes" multiline minRows={3} />
			</div>
		</EditInDialogButton>
	);
}
