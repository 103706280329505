import React from 'react';
import {
  required,
  useCreate,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';
import { Button } from '@/modules/ui/components/button';
import { humanize } from 'inflection';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { unseenReasons } from '@/modules/patients/constants/unseenReasons';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';

export function MarkUnseenButton() {
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  const [create] = useCreate();
  const transform = (data) => {
    return {
      patient_status: 'unseen',
      unseen_reason: data.unseen_reason,
    };
  };

  if (!record) return null;

  if (record.patient_status === 'disabled') return null;

  const logMonitoringNote = (data) => {
    const patient_id = record?.id;

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity?.id,
        type: 'activity',
        description:
          'Patient status updated to ' + humanize(data.patient_status, true),
      },
    });
  };

  return (
    <CreateInDialogButton
      title="Mark patient as unseen"
      resource={`patients/${record.id}/status`}
      transform={transform}
      notifyMessage="Patient set as unseen"
      refreshOnSuccess={true}
      dialogVariant="shadcn"
      createButton={
        <Button className=" w-fit mx-auto" variant="outline">
          Mark Unseen
        </Button>
      }
      saveButtonAlwaysEnable
      onSuccess={(data) => {
        logMonitoringNote(data);
      }}
    >
      <div className="flex flex-col gap1">
        <SelectInput
          source="unseen_reason"
          label="Reason"
          choices={unseenReasons}
          validate={required()}
        />
      </div>
    </CreateInDialogButton>
  );
}
