
export const triggerTypes = [
    {
        id: 'all_metrics_in_range',
        name: 'All metrics in range',
    },
    {
        id: 'any_metric_out_of_range',
        name: 'Any metric out of range',
    },
    {
        id: 'metric_non_compliance',
        name: 'Metric non compliance',
    },
    {
        id: "has_99453_completed",
        name: "Has 99453 completed"
    },
    
];

export const taskItemChecks = [
    {
        id: 'any_device_measurement',
        name: 'Any device measurement',
    },
    {
        id: 'consent_form_uploaded',
        name: 'Consent form uploaded',
    },
    {
        id: 'care_plan_revised',
        name: 'Care plan revised',
    },
    {
        id: 'mobile_app_login',
        name: 'Mobile app login',
    },
];

// resource links
// - tasks
// - assessments
// - encounters