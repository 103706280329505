import type { VitalConfig } from '../components/VitalCardBase';

export const vitalsConfig: Array<VitalConfig> = [
  {
    measureReportName: 'oxygen_saturation_7_day_baseline',
    chartMeasureName: 'oxygen_saturation',
    nameKey: 'Oxygen Saturation',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'lungs',
  },
  {
    measureReportName: 'heart_rate_7_day_baseline',
    chartMeasureName: 'heart_rate',
    nameKey: 'Heart Rate',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'heartbeat',
  },
  {
    measureReportName: 'body_temperature_7_day_baseline',
    chartMeasureName: 'body_temperature',
    nameKey: 'Temperature',
    roundDigits: 1,
    alwaysShow: false,
    faIcon: 'thermometer-half',
  },
  {
    measureReportName: 'peak_expiratory_flow_rate_7_day_baseline',
    chartMeasureName: 'peak_expiratory_flow_rate',
    nameKey: 'Peak Flow',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: 'forced_expiratory_volume_1_7_day_baseline',
    chartMeasureName: 'forced_expiratory_volume_1',
    nameKey: 'FEV1',
    roundDigits: 1,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: 'blood_pressure_7_day_baseline',
    chartMeasureName: 'blood_pressure',
    nameKey: 'Blood Pressure',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: 'body_mass_7_day_baseline',
    chartMeasureName: 'body_mass',
    nameKey: 'Weight',
    roundDigits: 1,
    alwaysShow: false,
    faIcon: 'wind',
  },
  {
    measureReportName: `blood_glucose_7_day_baseline`,
    chartMeasureName: 'blood_glucose',
    nameKey: 'Blood Glucose',
    roundDigits: 0,
    alwaysShow: false,
    faIcon: 'droplet',
  },
];
