import { Badge } from "@/modules/ui/components/badge";
import {
	Card,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/modules/ui/components/card";
import { cn } from "@/modules/ui/utils/cn";
import { dateFormatter } from "@/utils/formatedDate";
import { FileEdit } from "lucide-react";
import { DateTime } from "luxon";

interface ClinicalActivityItemProps {
	title: string;
	description: string;
	onClick?: () => void;
	lastCompleted?: string;
	disabled?: boolean;
	inProgress?: boolean;
	unavailableReason?: string;
}

export const ClinicalActivitySelectorItem = ({
	title,
	description,
	onClick,
	lastCompleted,
	disabled = false,
	inProgress = false,
	unavailableReason = null,
}: ClinicalActivityItemProps) => {
	return (
		<Card
			className={cn(
				"cursor-pointer transition-all hover:bg-accent",
				"border shadow-sm",
				disabled && "opacity-50 cursor-not-allowed hover:bg-background",
			)}
			onClick={onClick}
		>
			<CardHeader className="flex flex-row items-center gap-4 space-y-0 p-4">
				<div className="space-y-1">
					<CardTitle className="text-base">{title}</CardTitle>
					<CardDescription className="text-sm">{description}</CardDescription>
					{unavailableReason && (
						<div className="mt-2 flex items-center gap-1.5 rounded-md bg-red-100 px-2 py-1 text-xs w-fit">
							<span className="font-medium">Unavailable Reason:</span>
							<span className=" font-medium">{unavailableReason}</span>
						</div>
					)}
					{lastCompleted && (
						<div className="mt-2 flex items-center gap-1.5 rounded-md bg-muted/50 px-2 py-1 text-xs w-fit">
							<span className="font-medium text-muted-foreground">
								Last completed:
							</span>
							<span className=" font-medium">
								{dateFormatter(lastCompleted, DateTime.DATETIME_MED)}
							</span>
						</div>
					)}
				</div>
				{inProgress && (
					<Badge
						variant="outline"
						className="p-1.5 flex items-center gap-1"
						title="In Progress"
					>
						<FileEdit className="h-4 w-4 text-amber-500" />
						<span className="flex space-x-0.5 ml-0.5">
							<span className="h-1 w-1 rounded-full bg-amber-500 animate-bounce [animation-delay:-0.3s]" />
							<span className="h-1 w-1 rounded-full bg-amber-500 animate-bounce [animation-delay:-0.15s]" />
							<span className="h-1 w-1 rounded-full bg-amber-500 animate-bounce" />
						</span>
					</Badge>
				)}
			</CardHeader>
		</Card>
	);
};
