import AppTitle from "@/modules/layout/components/app-title";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import TabbedWrapper from "@/modules/ra-ui/components/tabbed-wrapper";
import { MultiSearch } from "@/modules/data-table/components/data-table-multi-search";
import { DataTable } from "@/modules/data-table/components/data-table";
import React, { type FC } from "react";
import { ReferenceField, TextField, useStore, ListBase } from "react-admin";
import BadgeField from "@/modules/ra-ui/components/badge-field";
import { DateField } from "react-admin";
import { ListUIRoot } from "@/modules/data-table/components/list-ui-root";
import { useNavigate } from "react-router-dom";

const Toolbar = ({ selectedTab, setSelectedTab, showViewOptions }) => (
	<div className="w-full">
		<TabbedWrapper
			options={[
				{ title: "Open", value: "open" },
				{ title: "Closed", value: "closed" },
				{ title: "All", value: "" },
			]}
			selectedTab={selectedTab}
			setSelectedTab={setSelectedTab}
		>
			<DataTableToolbar
				showViewOptions={showViewOptions}
				search={
					<MultiSearch
						searchFields={[
							{ value: "user.full_name", label: "Name" },
							{ value: "patient_id", label: "ID" },
						]}
					/>
				}
			/>
		</TabbedWrapper>
	</div>
);

const columns = [
	{
		header: "Category",
		accessorKey: "category",
		cell: () => <BadgeField source="category" />,
	},
	{
		header: "Patient Name",
		accessorKey: "user_id",
		cell: () => (
			<ReferenceField source="user_id" reference="patients" link="show">
				<TextField source="user.full_name" />
			</ReferenceField>
		),
	},
	{
		header: "Created On",
		accessorKey: "created_on",
		cell: () => <DateField source="created_on" />,
	},
	{
		header: "Status",
		accessorKey: "status",
		cell: () => <BadgeField source="status" />,
	},
];

export const SupportTicketList: FC = () => {
	const [selectedTab, setSelectedTab] = useStore(
		"support-tickets.listParams.status",
		"open",
	);
	const navigate = useNavigate();

	return (
		<>
			<AppTitle title="Support Tickets" />
			<ListUIRoot>
				<ListBase
					resource="support-tickets"
					exporter={false}
					filter={{
						...(selectedTab === "open" && { "status[eq]": "open" }),
						...(selectedTab === "closed" && { "status[eq]": "closed" }),
					}}
				>
					<div className="flex flex-col gap-2 mx-2 w-full">
						<DataTable
							columns={columns}
							toolbar={
								<Toolbar
									selectedTab={selectedTab}
									setSelectedTab={setSelectedTab}
									showViewOptions={true}
								/>
							}
							storeKey="support-tickets"
							onRowClick={(row) => {
								navigate(`/support-tickets/${row.id}/show`);
							}}
						/>
					</div>
				</ListBase>
			</ListUIRoot>
		</>
	);
};
