import { SendEmailVerificationButton } from '@/modules/users/components/SendEmailVerificationButton';
import { get } from 'lodash';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { dateFormatter } from '../../../../utils/formatedDate';
import { InfoBlock } from '../../components/InfoBlock';

export const EmailVerifiedSection = () => {
  const record = useRecordContext();

  const isVerified = get(record, 'user.is_email_verified');
  const lastVerificationSent = get(record, 'user.confirm_email_last_sent');

  return (
    <InfoBlock
      title="Email Verified"
      component={
        <div className="flex items-center justify-between gap-2  w-fit mr-4">
          <div className="flex flex-col gap-1">
            <div className="flex items-center">
              <span className="font-semibold">{isVerified ? 'Yes' : 'No'}</span>
            </div>
            {lastVerificationSent && (
              <span className="text-sm text-gray-500 whitespace-nowrap">
                Last sent: {dateFormatter(lastVerificationSent)}
              </span>
            )}
          </div>
          <SendEmailVerificationButton
            resource="patients"
            source="user.is_email_verified"
          />
        </div>
      }
    />
  );
};
