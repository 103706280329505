import { Link, useMatch } from 'react-router-dom';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import React from 'react';
import { cn } from '@/modules/ui/utils/cn';
import { useBasename, useStore } from 'ra-core';
import { SidebarMenuButton } from '@/modules/ui/components/sidebar';
import { useSidebar } from '@/modules/ui/components/sidebar';
export function SidebarItem(props) {
  const { item } = props;
  const { open, setOpen } = useSidebar();
  const [collpaseOnClick] = useStore(
    'preferences.collapseSidebarOnClick',
    false,
  );
  const basename = useBasename();

  const to = item?.link || '';
  const match = useMatch({ path: to, end: to === `${basename}/` });
  const externalLink = item?.link?.startsWith('http');

  const patientQueueMatch = useMatch({
    path: 'patients/:patientId/show/clinical/*',
    end: true,
  });

  const finalMatch =
    to === 'patient-queue' && patientQueueMatch
      ? true
      : to === 'patients' && patientQueueMatch
        ? false
        : !!match;

  const handleClick = () => {
    if (collpaseOnClick) {
      setOpen(false);
    }
  };

  const linkContent = (
    <>
      <item.icon className="h-4 w-4" />
      <span>{item.name}</span>
      {item.label && (
        <span
          className={cn(
            'ml-auto',
            finalMatch && 'text-background dark:text-white',
          )}
        >
          {item.label}
        </span>
      )}
    </>
  );

  if (!open) {
    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <SidebarMenuButton asChild isActive={!!finalMatch}>
              <Link
                to={to}
                target={externalLink ? '_blank' : ''}
                onClick={handleClick}
              >
                {linkContent}
              </Link>
            </SidebarMenuButton>
          </TooltipTrigger>
          <TooltipContent side="right" className="flex items-center gap-4">
            {item.name}
            {item.label && (
              <span className="ml-auto text-muted-foreground">
                {item.label}
              </span>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <SidebarMenuButton asChild isActive={!!finalMatch}>
      <Link to={to} target={externalLink ? '_blank' : ''} onClick={handleClick}>
        {linkContent}
      </Link>
    </SidebarMenuButton>
  );
}
