import { useState } from 'react';
import { TaskList } from './TaskList';
import { TaskCreateAside } from './TaskCreateAside';
import { Button } from '@/modules/ui/components/button';
import { Plus } from 'lucide-react';

export const TaskAsideBase = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  const handleToggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
  };

  return (
    <div className="flex flex-col gap-1 h-[90vh]">
      <div className="flex justify-end px-2 items-center  border-gray-200 pb-2">
        {!showCreateForm && (
          <Button
            variant="default"
            className="w-fit"
            onClick={handleToggleCreateForm}
          >
            <Plus className="h-4 w-4 mr-2" />
            Task
          </Button>
        )}
      </div>
      {showCreateForm ? (
        <TaskCreateAside handleCancelCreate={handleToggleCreateForm} />
      ) : (
        <TaskList />
      )}
    </div>
  );
};
