import * as React from "react";
import { FieldTitle, useChoices, useInput } from "ra-core";
import { FormLabel } from "../components/form";
import { inputBaseClassname } from "./input-base-classname";
import { InputMessage } from "./input-message";
import MultipleSelector, { type Option } from "../components/multi-select";
import { cn } from "@/modules/ui/utils/cn";

export interface MultiSelectInputProps {
	choices?: any[];
	className?: string;
	label?: string | React.ReactElement | false;
	helperText?: string | React.ReactElement | false;
	source: string;
	optionText?: string | ((choice: any) => React.ReactNode);
	optionValue?: string;
	translateChoice?: boolean;
	validate?: any;
	defaultValue?: any[];
	maxSelected?: number;
	onMaxSelected?: (maxLimit: number) => void;
	[key: string]: any;
}

export const MultiSelectInput = (props: MultiSelectInputProps) => {
	const {
		choices = [],
		className,
		label,
		helperText,
		source,
		optionText = "name",
		optionValue = "id",
		translateChoice = true,
		validate,
		defaultValue,
		maxSelected,
		onMaxSelected,
		...rest
	} = props;

	const { getChoiceText, getChoiceValue } = useChoices({
		optionText,
		optionValue,
		translateChoice,
	});

	const {
		field,
		fieldState: { error, invalid, isTouched },
		formState: { isSubmitted },
		isRequired,
	} = useInput({
		source,
		validate,
		defaultValue: defaultValue || [],
		...rest,
	});

	// Convert choices to options format required by MultipleSelector
	const options = React.useMemo(() => {
		return choices.map((choice) => ({
			value: getChoiceValue(choice).toString(),
			label: getChoiceText(choice).toString(),
		}));
	}, [choices, getChoiceValue, getChoiceText]);

	// Convert field value to options format
	const selectedOptions = React.useMemo(() => {
		if (!field.value) return [];
		const values = Array.isArray(field.value) ? field.value : [field.value];
		return values.map((value) => {
			const choice = choices.find(
				(c) => getChoiceValue(c).toString() === value.toString(),
			);
			return {
				value: value.toString(),
				label: choice ? getChoiceText(choice).toString() : value.toString(),
			};
		});
	}, [field.value, choices, getChoiceValue, getChoiceText]);

	const handleChange = React.useCallback(
		(options: Option[]) => {
			const values = options.map((option) => option.value);
			field.onChange(values);
		},
		[field],
	);

	const renderHelperText =
		helperText !== false || ((isTouched || isSubmitted) && invalid);

	return (
		<div className={cn(inputBaseClassname, className)}>
			<FormLabel>
				<FieldTitle label={label} source={source} isRequired={isRequired} />
			</FormLabel>
			<MultipleSelector
				value={selectedOptions}
				onChange={handleChange}
				defaultOptions={options}
				disabled={rest.disabled}
				maxSelected={maxSelected}
				onMaxSelected={onMaxSelected}
				placeholder="Select options"
			/>
			{renderHelperText && (
				<InputMessage
					touched={isTouched || isSubmitted}
					error={error?.message}
					helperText={helperText}
				/>
			)}
		</div>
	);
};
