import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/modules/ui/components/select";
import { Input } from "@/modules/ui/components/input";
import { get } from "lodash";
import React from "react";
import { useController } from "react-hook-form";

type Unit = {
	name: string;
	display: string;
	text: string;
	system: string;
	code: {
		code: string;
	};
	min: number;
	max: number;
};

// Conversion functions
const convertHeight = {
	inToCm: (inches: number): number => Number((inches * 2.54).toFixed(1)),
	cmToIn: (cm: number): number => Math.round(cm / 2.54),
};

const convertWeight = {
	lbToKg: (lb: number): number => Number((lb / 2.20462).toFixed(1)),
	kgToLb: (kg: number): number => Math.round(kg * 2.20462),
};

export const weightUnits: Unit[] = [
	{
		name: "pound",
		display: "Pounds",
		text: "lb",
		system: "imperial",
		code: {
			code: "imperial__pound",
		},
		min: 88,
		max: 440,
	},
	{
		name: "kilograms",
		display: "Kilograms",
		text: "kg",
		system: "metric",
		code: {
			code: "metric__kilogram",
		},
		min: 40,
		max: 200,
	},
];

export const heightUnits: Unit[] = [
	{
		name: "inch",
		display: "Feet & Inches",
		text: "in",
		system: "imperial",
		code: {
			code: "imperial__inch",
		},
		min: 48,
		max: 84,
	},
	{
		name: "centimeter",
		display: "Centimeters",
		text: "cm",
		system: "metric",
		code: {
			code: "metric__centimeter",
		},
		min: 122,
		max: 213,
	},
];

const HeightTextInput = (props) => {
	const { unitValue, required, field } = props;

	const getValueFeet = () => {
		const value = get(field, "value");
		return value ? Math.floor(Math.round(value) / 12) : 0;
	};

	const getValueInches = () => {
		const value = get(field, "value");
		return value ? Math.round(Math.round(value) % 12) : 0;
	};

	const handleChange = (event) => {
		if (event.target.name === "feet") {
			field.onChange((getValueInches() || 0) + Number(event.target.value) * 12);
		}
		if (event.target.name === "inches") {
			field.onChange((getValueFeet() || 0) * 12 + Number(event.target.value));
		}
	};

	// Ensure we have a valid unitValue with a text property
	const unitText = unitValue?.text || heightUnits[0].text;

	if (unitText === "in")
		return (
			<div className="flex flex-row gap-2 items-baseline p-1">
				<div className="relative">
					<Input
						name="feet"
						onChange={handleChange}
						value={getValueFeet()}
						id="feet"
						placeholder="0"
						type="number"
						required={required}
						min={4}
						max={7}
						step={1}
						className="w-24 pr-7"
					/>
					<span className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500">
						ft
					</span>
				</div>
				<div className="relative">
					<Input
						name="inches"
						onChange={handleChange}
						value={getValueInches()}
						id="inches"
						placeholder="0"
						required={required}
						type="number"
						min={0}
						max={11}
						step={1}
						className="w-24 pr-7"
					/>
					<span className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500">
						in
					</span>
				</div>
			</div>
		);
	else if (unitText === "cm")
		return (
			<div className="flex flex-row gap-2 items-baseline p-1">
				<div className="relative">
					<Input
						{...field}
						value={field.value || ""}
						id="centimeter"
						placeholder="0"
						required={required}
						type="number"
						min={122}
						max={213}
						step={0.1}
						className="w-32 pr-9"
					/>
					<span className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500">
						cm
					</span>
				</div>
			</div>
		);

	return <Input disabled placeholder="Height" className="w-32" />;
};

export const HeightInput = (props) => {
	const { source, required = true } = props;

	const { field: fieldValue } = useController({
		name: source + ".value",
		defaultValue: 0,
	});
	const { field: fieldUnit } = useController({
		name: source + ".unit",
		defaultValue: heightUnits[0],
	});

	React.useEffect(() => {
		// Initialize the unit if it's not set
		if (!fieldUnit.value) {
			fieldUnit.onChange(heightUnits[0]);
		}
	}, [fieldUnit]);

	const onChangeUnit = (value) => {
		const newUnit = heightUnits.find((u) => u.text === value);
		const currentUnit = get(fieldUnit, "value");
		const currentValue = get(fieldValue, "value");

		if (currentValue && currentUnit && newUnit) {
			if (currentUnit.text === "in" && newUnit.text === "cm") {
				fieldValue.onChange(convertHeight.inToCm(currentValue));
			} else if (currentUnit.text === "cm" && newUnit.text === "in") {
				fieldValue.onChange(convertHeight.cmToIn(currentValue));
			}
		} else {
			fieldValue.onChange(0);
		}

		fieldUnit.onChange(newUnit);
	};

	// Ensure unitValue is always defined
	const unitValue = fieldUnit.value || heightUnits[0];

	return (
		<div className="flex items-center gap-2">
			<HeightTextInput
				unitValue={unitValue}
				required={required}
				field={fieldValue}
			/>
			<UnitSelector
				required={required}
				field={fieldUnit}
				choices={heightUnits}
				onChangeUnit={onChangeUnit}
			/>
		</div>
	);
};

const UnitSelector = (props) => {
	const { field, required, choices, onChangeUnit } = props;

	// Improved handling of potentially undefined field value
	const fieldValue = field?.value || {};
	const unitText = fieldValue.text || choices[0].text;

	return (
		<Select value={unitText} onValueChange={onChangeUnit} required={required}>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Unit" />
			</SelectTrigger>
			<SelectContent>
				{choices.map((u) => (
					<SelectItem key={u.text} value={u.text}>
						{u.display}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

export const WeightInput = (props) => {
	const { source, required = true } = props;
	const { field: fieldValue } = useController({
		name: source + ".value",
		defaultValue: 0,
	});
	const { field: fieldUnit } = useController({
		name: source + ".unit",
		defaultValue: weightUnits[0],
	});

	React.useEffect(() => {
		// Initialize the unit if it's not set
		if (!fieldUnit.value) {
			fieldUnit.onChange(weightUnits[0]);
		}
	}, [fieldUnit]);

	const onChangeUnit = (value) => {
		const newUnit = weightUnits.find((u) => u.text === value);
		const currentUnit = get(fieldUnit, "value");
		const currentValue = get(fieldValue, "value");

		if (currentValue && currentUnit && newUnit) {
			if (currentUnit.text === "lb" && newUnit.text === "kg") {
				fieldValue.onChange(convertWeight.lbToKg(currentValue));
			} else if (currentUnit.text === "kg" && newUnit.text === "lb") {
				fieldValue.onChange(convertWeight.kgToLb(currentValue));
			}
		} else {
			fieldValue.onChange(0);
		}

		fieldUnit.onChange(newUnit);
	};

	// Ensure currentUnit is always defined by using a fallback to the default unit
	const currentUnit = fieldUnit.value || weightUnits[0];
	const min = currentUnit.min;
	const max = currentUnit.max;
	const step = currentUnit.text === "kg" ? 0.1 : 1;

	return (
		<div className="flex items-center gap-2 p-1">
			<div className="relative">
				<Input
					{...fieldValue}
					value={fieldValue.value || ""}
					id="weight"
					placeholder="0"
					required={required}
					type="number"
					min={min}
					max={max}
					step={step}
					className="w-32 pr-9"
				/>
				<span className="absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500">
					{currentUnit.text}
				</span>
			</div>
			<UnitSelector
				required={required}
				field={fieldUnit}
				choices={weightUnits}
				onChangeUnit={onChangeUnit}
			/>
		</div>
	);
};
