import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Button } from '@/modules/ui/components/button';
import { Card } from '@/modules/ui/components/card';
import { Skeleton } from '@/modules/ui/components/skeleton';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useGetIdentity, useGetList } from 'ra-core';
import type React from 'react';
import { useNavigate } from 'react-router-dom';

type ConsentedPatientsColumns = {
  id: number;
  user: {
    full_name: string;
  };
  consented_on: string;
  enrolled_by_id: number;
  patient_status: string;
};

const columnHelper = createColumnHelper<ConsentedPatientsColumns>();
const columns = [
  columnHelper.accessor('user.full_name', {
    header: () => 'Patient',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('consented_on', {
    header: () => 'Consented Date',
    cell: (info) =>
      DateTime.fromISO(info.getValue(), { zone: 'UTC' }).toLocaleString(
        DateTime.DATE_SHORT,
      ),
  }),
];

const ConsentedPatientsList: React.FC = () => {
  const navigate = useNavigate();
  const { identity } = useGetIdentity();

  const { data, isLoading, total } = useGetList('patients', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'consented_on', order: 'DESC' },
    filter: {
      'patient_status[eq]': 'consented',
      'consented_by_id[eq]': identity?.id,
    },
  });

  if (isLoading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }

  if (total === 0) {
    return null;
  }

  const onRowClick = (row: any) => {
    navigate(`/patients/${row.original.id}/show`);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <h1 className="text-lg font-bold">My Consented Patients</h1>
      <p className="text-sm text-amber-600">
        These patients have consented but are not fully enrolled. Please
        complete their enrollment as soon as possible.
      </p>
      <div className="my-2 w-fit">
        <Card>
          <SimpleTable data={data} columns={columns} rowClick={onRowClick} />
        </Card>
        <div className="flex justify-center px-3">
          <Button
            variant="link"
            onClick={() =>
              navigate(
                `/patients?status=consented&filter={"consented_by_id[eq]": ${identity?.id}}`,
              )
            }
          >
            View More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConsentedPatientsList;
