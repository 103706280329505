import { cn } from "@/modules/ui/utils/cn";
import { Check, AlertCircle } from "lucide-react";
import { Button } from "@/modules/ui/components/button";
import type { Step } from "./types";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/modules/ui/components/tooltip";

interface StepInprogress extends Step {
	is_completed?: boolean;
}

interface StepNavigatorProps {
	steps: StepInprogress[];
	activeStep: number;
	onStepChange: (step: number) => void;
	className?: string;
}

export function StepNavigator({
	steps,
	activeStep,
	onStepChange,
	className,
}: StepNavigatorProps) {
	// For simplicity, we'll assume steps before the active step are completed
	const isStepCompleted = (step: StepInprogress, index: number) => {
		// If the step has an explicit completion status, use that
		if (typeof step.is_completed !== "undefined") {
			return step.is_completed;
		}

		// Otherwise, consider steps before the active step as completed
		return index < activeStep;
	};

	// Check if the active step is the review step (which is after all regular steps)
	const isReviewStepActive = activeStep === steps.length;

	return (
		<nav
			className={cn(
				"flex flex-col space-y-1 rounded-lg border bg-card p-2",
				className,
			)}
		>
			{/* Regular steps */}
			{steps.map((step, index) => {
				const isCompleted = isStepCompleted(step, index);
				const isActive = activeStep === index;
				const isPending = !isCompleted && !isActive;

				return (
					<TooltipProvider key={index}>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									type="button"
									onClick={() => onStepChange(index)}
									variant="ghost"
									className={cn(
										"relative flex w-full items-center justify-start gap-2 px-3 py-1.5 text-left text-sm font-medium",
										isActive && "bg-accent text-accent-foreground",
										!isActive && isCompleted && "text-muted-foreground",
										isPending && "border-l-2 border-l-amber-500",
									)}
								>
									<div
										className={cn(
											"flex h-6 w-6 shrink-0 items-center justify-center rounded-full border text-xs",
											isCompleted
												? "border-primary bg-primary text-primary-foreground"
												: isActive
													? "border-accent-foreground"
													: "border-muted",
										)}
									>
										{isCompleted ? (
											<Check className="h-3.5 w-3.5" />
										) : (
											<span>{index + 1}</span>
										)}
									</div>
									<span className="truncate">{step.step_title}</span>

									{/* Show warning icon for incomplete steps that are not active */}
									{isPending && (
										<AlertCircle className="h-4 w-4 text-amber-500 ml-auto" />
									)}
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								{isCompleted
									? "Completed"
									: isActive
										? "Current step"
										: "Not completed yet"}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				);
			})}

			{/* Review and Submit step (always at the end) */}
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							type="button"
							onClick={() => onStepChange(steps.length)}
							variant="ghost"
							className={cn(
								"relative flex w-full items-center justify-start gap-2 px-3 py-1.5 text-left text-sm font-medium",
								isReviewStepActive && "bg-accent text-accent-foreground",
								!isReviewStepActive && "text-muted-foreground",
								!isReviewStepActive &&
									activeStep < steps.length &&
									"border-l-2 border-l-amber-500",
							)}
						>
							<div
								className={cn(
									"flex h-6 w-6 shrink-0 items-center justify-center rounded-full border text-xs",
									isReviewStepActive
										? "border-accent-foreground"
										: "border-muted",
								)}
							>
								<span>{steps.length + 1}</span>
							</div>
							<span className="truncate">Review & Submit</span>

							{/* Show warning icon for review step if not active and not all steps are completed */}
							{!isReviewStepActive && activeStep < steps.length && (
								<AlertCircle className="h-4 w-4 text-amber-500 ml-auto" />
							)}
						</Button>
					</TooltipTrigger>
					<TooltipContent>
						{isReviewStepActive
							? "Final review before submission"
							: "Complete all steps before review"}
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</nav>
	);
}
