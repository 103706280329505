import type { UseFormReturn } from 'react-hook-form';
import { cn } from '@/modules/ui/utils/cn';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/modules/ui/components/card';
import { StepChecklistItem } from './StepChecklistItem';
import { TimeRangeIndicator } from './TimeRangeIndicator';
import { required } from 'react-admin';
import { ElapsedTime } from '@/modules/monitoring-activity/components/ElapsedTime';
import { Separator } from '@/modules/ui/components/separator';
import { TimeRangeOutOfRangeReason } from './TimeRangeOutOfRangeReason';
import type { Step } from './types';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';

interface StepContentProps {
  step: Step;
  form: UseFormReturn<any>;
  className?: string;
  isPreview?: boolean;
}

export function StepContent({
  step,
  form,
  className,
  isPreview = false,
}: StepContentProps) {
  // Only show time range components if we have valid min/max values
  const hasValidTimeRange =
    step.expected_time_range?.min != null &&
    step.expected_time_range?.max != null &&
    !isNaN(step.expected_time_range.min) &&
    !isNaN(step.expected_time_range.max);

  return (
    <Card className={cn('', className)}>
      <CardHeader className="space-y-3">
        <CardTitle className="text-lg font-semibold">
          {step.step_title}
        </CardTitle>
        {step.step_description && (
          <CardDescription>{step.step_description}</CardDescription>
        )}
        {hasValidTimeRange && (
          <div className="space-y-4 w-full">
            <TimeRangeIndicator
              minTime={step.expected_time_range!.min}
              maxTime={step.expected_time_range!.max}
              className="w-96 sm:w-56"
              source={`${step.step_key}.time_taken`}
              key={`${step.step_key}.time_range_indicator`}
            />
            <div className="flex flex-row gap-2 items-start w-full">
              <ElapsedTime
                source={`${step.step_key}.time_taken`}
                label="Time Taken"
                validate={[required()]}
                className="flex-none"
                key={`${step.step_key}.time_taken`}
              />
              <TimeRangeOutOfRangeReason
                source={`${step.step_key}.time_taken`}
                minTime={step.expected_time_range!.min}
                maxTime={step.expected_time_range!.max}
                className="flex-1"
                key={`${step.step_key}.time_taken_out_of_range_reason`}
              />
            </div>
            <Separator className="w-full" />
          </div>
        )}
      </CardHeader>
      <CardContent className="space-y-6">
        {step.activity_items_json.map((item) => (
          <StepChecklistItem
            key={item.item_id}
            item={item}
            form={form}
            parentId={step.step_key}
            isPreview={isPreview}
          />
        ))}

        {step.step_key !== 'review_and_submit' && (
          <>
            <Separator className="w-full mt-6" />
            <div className="space-y-2">
              <TextAreaInput
                source={`${step.step_key}.additional_notes`}
                label="Additional Notes"
                helperText="Add any additional notes or observations about this step."
                key={`${step.step_key}.additional_notes`}
              />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}
