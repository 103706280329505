import { DateTimePicker } from '@/modules/ui/components/date-time-picker';
import { FieldTitle, useInput } from 'ra-core';
import {
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
} from '@/modules/ui/components/form';
import { InputMessage } from './input-message';
import { humanize } from 'inflection';
import React, { forwardRef } from 'react';

export const DateTimeInput = forwardRef((props: any, ref) => {
  const {
    className,
    defaultValue,
    label,
    format,
    onBlur,
    onChange,
    helperText,
    parse,
    resource,
    source,
    validate,
    timezone,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, isTouched },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  let showLabel: string | boolean = label;
  if (label === undefined || label === null) {
    showLabel = humanize(source);
  }

  return (
    <FormItem id={id} className="flex flex-col my-1">
      {showLabel && (
        <FormLabel>
          <FieldTitle label={label} source={source} isRequired={isRequired} />
        </FormLabel>
      )}
      <FormControl>
        <DateTimePicker
          {...field}
          className={className}
          ref={ref}
          timezone={timezone}
          {...rest}
        />
      </FormControl>
      {helperText && <FormDescription>{helperText}</FormDescription>}
      <InputMessage touched={isTouched} error={error?.message} />
    </FormItem>
  );
});

DateTimeInput.displayName = 'DateTimeInput';
