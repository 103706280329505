import React from 'react';
import { useRecordContext, useDataProvider } from 'react-admin';
import { useInfiniteQuery } from '@tanstack/react-query';
import { SimpleTable } from '@/modules/ra-ui/components/simple-table';
import { Badge } from '@/modules/ui/components/badge';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { titleize } from 'inflection';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { patientStatus } from '../constants/patientStatus';
import { declinedReasons } from '@/modules/enrollment/constants/declinedReasons';
import { unenrollReasons } from '../constants/unenrollReasons';
import { unseenReasons } from '../constants/unseenReasons';

type PatientStatusHistoryColumns = {
  id: number;
  new_status: string;
  changed_at: string;
  changed_by_name?: string;
  reason?: string;
};

const columnHelper = createColumnHelper<PatientStatusHistoryColumns>();
const columns = [
  columnHelper.accessor('changed_at', {
    header: () => 'Timestamp',
    cell: (info) => {
      const date = DateTime.fromISO(info.getValue(), { zone: 'utc' }).setZone(
        'local',
      );
      const dateString = date.toLocaleString(DateTime.DATETIME_MED);
      const tzAbbreviation = date.toFormat('ZZZZ');
      return (
        <span>
          {dateString}
          <Badge variant="outline" className="ml-1 px-1.5">
            {tzAbbreviation}
          </Badge>
        </span>
      );
    },
    enableSorting: true,
  }),
  columnHelper.accessor('new_status', {
    header: () => 'Status',
    cell: (info) => {
      const status = patientStatus.find((s) => s.id === info.getValue());
      return (
        <Badge variant="outline">
          {status?.name || titleize(info.getValue())}
        </Badge>
      );
    },
    enableSorting: true,
  }),
  columnHelper.accessor('changed_by_name', {
    header: () => 'Changed By',
    cell: (info) => info.getValue(),
    enableSorting: true,
  }),
  columnHelper.accessor('reason', {
    header: () => 'Reason',
    cell: (info) => {
      const row = info.row.original;
      if (row.new_status === 'declined') {
        const reason = declinedReasons.find((r) => r.id === info.getValue());
        return reason?.name || info.getValue();
      }
      if (row.new_status === 'disabled') {
        const reason = unenrollReasons.find((r) => r.id === info.getValue());
        return reason?.value || info.getValue();
      }
      if (row.new_status === 'unseen') {
        const reason = unseenReasons.find((r) => r.id === info.getValue());
        return reason?.name || info.getValue();
      }
      return info.getValue();
    },
    enableSorting: true,
  }),
];

export const PatientStatusHistoryDataGrid = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const pagination = {
    page: 1,
    perPage: 20,
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['patient-status-history', { patient_id: record?.id }],
      queryFn: ({ pageParam = pagination.page }) =>
        dataProvider
          .getList('patient-status-history', {
            pagination: {
              page: pageParam,
              perPage: pagination.perPage,
            },
            sort: { field: 'changed_at', order: 'DESC' },
            filter: {
              'patient_id[eq]': record?.id,
            },
          })
          .then(({ data, total, pageInfo }) => ({
            data,
            total,
            pageInfo,
            pageParam,
          })),
      initialPageParam: pagination.page,
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo?.hasNextPage ? lastPage.pageParam + 1 : undefined,
    });

  if (isLoading) {
    return (
      <div className="w-full h-32 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const flatData = data?.pages.flatMap((page) => page.data) || [];

  if (flatData.length === 0) {
    return (
      <EmptyDatagrid
        resourceName="Status History"
        textFormatString="None found for patient"
        icon={false}
      />
    );
  }

  return (
    <div>
      <SimpleTable
        data={flatData}
        columns={columns}
        sorting={[{ id: 'changed_at', desc: true }]}
      />
      {hasNextPage && (
        <div className="mt-4 text-center">
          <button
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {isFetchingNextPage ? 'Loading more...' : 'Load More'}
          </button>
        </div>
      )}
    </div>
  );
};
