import { useQuery } from "@tanstack/react-query";
import React, { createContext, type ReactNode } from "react";
import { useDataProvider } from "react-admin";

// Define the shape of the context
interface FeatureFlagContextType {
	flags: Record<string, any>;
	isLoading: boolean;
	error: Error | null;
	refetchFlags: () => Promise<void>;
}

// Create the context with default values
export const FeatureFlagContext = createContext<FeatureFlagContextType>({
	flags: {},
	isLoading: true,
	error: null,
	refetchFlags: async () => {},
});

interface FeatureFlagProviderProps {
	children: ReactNode;
}

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
	const dataProvider = useDataProvider();

	// Function to fetch feature flags from the API
	const fetchFeatureFlags = () => {
		return dataProvider.getCustom("feature-flags", {}).then((res) => res.data);
	};

	const {
		data: flags,
		isLoading,
		error,
	} = useQuery({
		queryKey: ["feature-flags"],
		queryFn: fetchFeatureFlags,
		staleTime: 60 * 60 * 1000,
	});

	// Context value
	const contextValue: FeatureFlagContextType = {
		flags,
		isLoading,
		error,
		refetchFlags: fetchFeatureFlags,
	};

	return (
		<FeatureFlagContext.Provider value={contextValue}>
			{children}
		</FeatureFlagContext.Provider>
	);
}
