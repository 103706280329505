import { Button } from "@/modules/ui/components/button";
import { get } from "lodash";
import { User2 } from "lucide-react";
import { useState } from "react";
import {
	Form,
	required,
	useGetIdentity,
	useListContext,
	useNotify,
	useRefresh,
	useUpdateMany,
} from "react-admin";

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/modules/ui/components/dialog";
import { LoadingSpinner } from "@/modules/ui/components/loading-spinner";
import { filterUserName } from "@/utils/filterUserName";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";

export const AssignTasksButton = (props) => {
	const { identity } = useGetIdentity();

	const { selectedIds, onSelect } = useListContext();

	const refresh = useRefresh();
	const notify = useNotify();

	const [updateMany, { isLoading }] = useUpdateMany();

	const [open, setOpen] = useState(false);

	const onSubmit = (value) => {
		updateMany(
			"tasks",
			{
				ids: selectedIds,
				data: { assigned_to_id: value.assigned_to_id },
			},
			{
				onSuccess: () => {
					refresh();
					setOpen(false);
					notify("Successfully updated tasks", {
						type: "info",
					});
					onSelect([]);
				},
				onError: (error) => {
					notify(
						get(error, "message") ||
							"An error occurred while updating the tasks",
						{
							type: "error",
						},
					);
				},
			},
		);
	};

	return (
		<div>
			<Dialog open={open} onOpenChange={setOpen}>
				<DialogTrigger asChild>
					<Button
						variant="outline"
						size="sm"
						className="text-black"
						type="button"
					>
						<User2 className="w-4 h-4 mr-2" />
						Assign tasks to
					</Button>
				</DialogTrigger>
				<DialogContent className="min-w-72">
					<DialogHeader>
						<DialogTitle>Assign tasks</DialogTitle>
						<DialogDescription>
							Assign tasks to the selected user
						</DialogDescription>
					</DialogHeader>
					<Form onSubmit={onSubmit}>
						<AutocompleteInput
							label="Assigned to"
							filterToQuery={filterUserName}
							modal={true}
							source="assigned_to_id"
							reference="users"
							validate={[required()]}
							filter={{
								"tenant_id[in]": [identity?.tenant_id],
								"role[ne]": "patient",
							}}
							optionText={(choice) => {
								if (choice.is_internal) {
									return `${choice.first_name} ${choice.last_name} (Vironix) ${
										choice.credentials ? `(${choice.credentials})` : ``
									}`;
								}
								return `${choice.full_name} ${
									choice.credentials ? `(${choice.credentials})` : ``
								}`;
							}}
						/>
						<DialogFooter className="mt-2">
							<Button type="submit" disabled={isLoading}>
								{isLoading ? <LoadingSpinner className="w-4 h-4 " /> : "Assign"}
							</Button>
						</DialogFooter>
					</Form>
				</DialogContent>
			</Dialog>
		</div>
	);
};
