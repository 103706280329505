import React from 'react';
import { useRecordContext } from 'react-admin';
import { Badge } from '@/modules/ui/components/badge';
import { humanize } from 'inflection';
import { dateFormatter } from '@/utils/formatedDate';

export function ExpandedAllergyRow() {
  const record = useRecordContext();

  return (
    <div className="p-2">
      <div className="flex flex-row gap-4 mb-3 justify-start">
        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500 mb-1">Category</p>
          <Badge variant="outline" className="w-fit">
            {record.category ? humanize(record.category) : 'N/A'}
          </Badge>
        </div>

        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500 mb-1">Status</p>
          <Badge variant="outline" className="w-fit">
            {record.status ? humanize(record.status) : 'N/A'}
          </Badge>
        </div>

        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500 mb-1">
            Created Date
          </p>
          <span className="text-sm">
            {record.created_at ? dateFormatter(record.created_at) : 'N/A'}
          </span>
        </div>

        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500 mb-1">Onset Date</p>
          <span className="text-sm">
            {record.onset_date ? dateFormatter(record.onset_date) : 'N/A'}
          </span>
        </div>
      </div>

      <div className="flex flex-row gap-4 mb-3 justify-start">
        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500 mb-1">Substance</p>
          <span className="text-sm">
            {record.substance?.display || 'Unknown substance'}
          </span>
        </div>
        <div className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500 mb-1">Severity</p>
          <Badge variant="outline" className="w-fit">
            {record.reaction_severity
              ? humanize(record.reaction_severity)
              : 'N/A'}
          </Badge>
        </div>
        <div className="mb-4">
          <p className="text-sm font-semibold text-gray-500 mb-2">Reactions</p>
          <div className="flex flex-wrap gap-2">
            {record.reaction?.length > 0 ? (
              record.reaction.map((reaction, index) => (
                <Badge key={index} variant="secondary" className="rounded-md">
                  {reaction.display}
                </Badge>
              ))
            ) : (
              <span className="text-sm text-gray-500">
                No reactions recorded
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-semibold text-gray-500 mb-2">Notes</p>
        <div className="bg-gray-50 p-3 rounded-md">
          <p className="text-sm text-gray-700">
            {record.notes || 'No notes recorded'}
          </p>
        </div>
      </div>
    </div>
  );
}
