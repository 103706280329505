import EditInDialogButton from "@/modules/ra-ui/components/edit-dialog-button";
import { MiniDeleteButton } from "@/modules/ra-ui/components/mini-delete-button";
import React from "react";
import {
	WithRecord,
	required,
	useGetIdentity,
	useGetOne,
	useRecordContext,
} from "react-admin";
import { TaskActivities } from "./TaskActivities";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import { TextAreaInput } from "@/modules/ui/inputs/text-area-input";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { DateInput } from "@/modules/ui/inputs/date-input";
import { TextInput } from "@/modules/ui/inputs/text-input";
import { SwitchInput } from "@/modules/ui/inputs/switch-input";
const filterUserName = (searchText) => ({
	q: { field: "full_name", value: searchText },
});

const AssignedToField = ({ patient_tenant_id = null }) => {
	const record = useRecordContext();
	const { data: patient } = useGetOne(
		"patients",
		{ id: record?.patient_id },
		{ enabled: !!record && !patient_tenant_id },
	);

	const filters: any = {
		"role[ne]": "patient",
		"tenant_id[in]": [patient_tenant_id || patient?.user.tenant_id, null],
	};

	return (
		<AutocompleteInput
			source="assigned_to_id"
			reference="users"
			filter={filters}
			label="Assigned to"
			optionText={(choice) => {
				if (choice.is_internal) {
					return `${choice.full_name} (Vironix) ${
						choice.credentials ? `(${choice.credentials})` : ""
					}`;
				}
				return `${choice.full_name} ${
					choice.credentials ? `(${choice.credentials})` : ""
				}`;
			}}
			parse={(v) => (v === "" ? null : v)}
			helperText={false}
			filterToQuery={filterUserName}
			modal
		/>
	);
};

export function TaskEditButton({ patient_tenant_id = null }) {
	const { identity } = useGetIdentity();

	const transform = (data, { previousData }) => {
		if (data.complete && !previousData.complete) {
			return {
				...data,
				complete: true,
				completed_at: new Date().toISOString(),
				completed_by_id: identity?.id,
			};
		}

		return data;
	};

	return (
		<EditInDialogButton
			title="Edit task"
			resource="tasks"
			transform={transform}
			fullWidth
			notifyMessage="Task updated"
			refreshOnSuccess
			closeOnClickOutside
			dialogVariant="shadcn"
		>
			<div className="flex flex-col gap-2">
				<TextInput source="title" validate={required()} helperText={false} />
				<TextAreaInput
					source="description"
					helperText={false}
					multiline
					minRows={2}
				/>
				<DateInput source="due_on" helperText={false} />
				<SelectInput
					source="priority"
					choices={[
						{ id: "1", name: "High" },
						{ id: "2", name: "Medium" },
						{ id: "3", name: "Low" },
					]}
					helperText={false}
				/>
				<AssignedToField patient_tenant_id={patient_tenant_id} />
				<WithRecord
					render={(record) =>
						record.added_by_name && (
							<TextInput
								source="added_by_name"
								label="Added by"
								helperText={false}
								disabled
							/>
						)
					}
				/>
				<WithRecord
					render={(record) =>
						record.completed_by_name && (
							<TextInput
								source="completed_by_name"
								label="Completed by"
								helperText={false}
								disabled
							/>
						)
					}
				/>
				<div className="flex flex-row gap-1 items-center justify-between">
					<SwitchInput source="complete" helperText={false} />
					<MiniDeleteButton resource="tasks" />
				</div>
				<TaskActivities showSeperator items={5} />
			</div>
		</EditInDialogButton>
	);
}
