import AppTitle from '@/modules/layout/components/app-title';
import React, { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import { ColoredChipField } from '../../modules/ra-ui/components/ColoredChipField';

// const AuditLogValueShow = () => <SimpleShowLayout></SimpleShowLayout>;

export const AuditLogsList: FC = (props) => (
  <>
    <AppTitle title="AuditLogs" />
    <List {...props} sort={{ field: 'timestamp', order: 'DESC' }} perPage={25}>
      <Datagrid
        rowClick={false}
        // expand={<AuditLogValueShow />}
        expandSingle
        size="small"
        bulkActionButtons={false}
      >
        <TextField source="id" />
        <DateField source="timestamp" showTime />
        <ColoredChipField source="action" />
        <ColoredChipField source="resource" />
        <ReferenceField
          label="User"
          source="user_id"
          reference="users"
          link="show"
        >
          <TextField source="full_name" />
        </ReferenceField>
      </Datagrid>
    </List>
  </>
);
