import React, { useState } from 'react';
import { ListContextProvider, SortPayload, useGetList } from 'react-admin';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { DataTable } from '@/modules/data-table/components/data-table';
import { EmptyState } from '@/modules/data-table/components/data-table-empty-state';
import { Lock } from 'lucide-react';

const getColumns = () => {
  return [
    {
      header: 'Timestamp',
      accessorKey: 'timestamp',
      cell: () => <LuxonDateField source="timestamp" showTime />,
    },
    {
      header: 'Event Type',
      accessorKey: 'event_type',
      cell: () => <BadgeField source="event_type" variant="secondary" />,
      enableSorting: false,
    },
    {
      header: 'IP Address',
      accessorKey: 'ip_address',
      cell: ({ row }) => (
        <a
          href={`https://whatismyipaddress.com/ip/${row.original.ip_address}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          {row.original.ip_address}
        </a>
      ),
      enableSorting: false,
    },
  ];
};

export const AuthLogPanel = ({ userId }) => {
  const [sort, setSort] = useState({
    field: 'timestamp',
    order: 'DESC',
  } as SortPayload);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const { data, total, isLoading } = useGetList('auth-logs', {
    pagination: { page: page, perPage: perPage },
    sort: sort,
    filter: {
      'user_id[eq]': userId,
      'event_type[ne]': 'refresh_token_expired',
    },
  });

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Security Log</CardTitle>
        <CardDescription>
          A log of recent security events. Please contact support if you see
          anything suspicious.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ListContextProvider
          value={
            {
              data,
              isLoading,
              sort,
              setSort,
              page,
              setPage,
              perPage,
              setPerPage,
              total,
            } as any
          }
        >
          <DataTable
            data={data}
            loading={isLoading}
            columns={getColumns()}
            emptyState={
              <EmptyState title="No security events found" icons={[Lock]} />
            }
          />
        </ListContextProvider>
      </CardContent>
    </Card>
  );
};
