import { useRecordContext } from 'react-admin';

import { ExportPatientDataButton } from '@/modules/patients/components/ExportPatientDataButton';
import { ServiceInstanceDataGrid } from '../../components/ServiceInstanceDataGrid';
import { ServiceDataGrid } from '../../components/ServicesDatagrid';
import { ClaimItemsListDataGrid } from '../../components/ClaimItemsListDataGrid';

export default function ServicesSection() {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 my-4">
      <div className="flex flex-col space-y-2">
        <div>
          <h3 className="text-xl font-semibold">Services</h3>
          <p className="text-sm text-gray-500">Services rendered to patient.</p>
        </div>
        <ServiceDataGrid />
      </div>
      <div className="flex flex-col space-y-2">
        <div>
          <h3 className="text-xl font-semibold">Claims</h3>
          <p className="text-sm text-gray-500">
            Qualified claims for services rendered to patient.
          </p>
        </div>
        <ClaimItemsListDataGrid />
      </div>

      <div className="flex flex-col space-y-2">
        <div>
          <h3 className="text-xl font-semibold">Care Programs</h3>
          <p className="text-sm text-gray-500">
            Care programs are the virtual care services that are provided to the
            patient.
          </p>
        </div>
        <ServiceInstanceDataGrid />
      </div>
      <div className="flex flex-col space-y-2 pb-8">
        <div>
          <h3 className="text-xl font-semibold">Export patient data</h3>
          <p className="text-sm text-gray-500">
            Need to download a detailed report of patient services?
          </p>
        </div>
        <ExportPatientDataButton />
      </div>
    </div>
  );
}
