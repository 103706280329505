import { DataTableFilterSingle } from "@/modules/data-table/components/data-table-filter-single";
import DataTableProviderFilter from "@/modules/data-table/components/data-table-provider-filter";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import AppTitle from "@/modules/layout/components/app-title";
import BadgeField from "@/modules/ra-ui/components/badge-field";
import { TaskEditButton } from "@/modules/tasks/components/TaskEditButton";
import React, { type FC, useEffect, useState, useMemo } from "react";
import {
	BooleanField,
	DateField,
	ReferenceField,
	TextField,
	useGetIdentity,
	ListBase,
} from "react-admin";
import { get } from "lodash";
import TabbedWrapper from "@/modules/ra-ui/components/tabbed-wrapper";
import { AssignTasksButton } from "@/modules/tasks/components/AssignTasksButton";
import { Badge } from "@/modules/ui/components/badge";
import { cropText } from "@/modules/patients/utils/cropText";
import DataTableReferenceFilter from "@/modules/data-table/components/data-table-reference-filter";
import { TaskFilters } from "@/modules/tasks/components/TaskFilters";
import { DataTableFilterButton } from "@/modules/data-table/components/data-table-filter-button";
import { DataTable } from "@/modules/data-table/components/data-table";
import { Checkbox } from "@/modules/ui/components/checkbox";
import { ListUIRoot } from "@/modules/data-table/components/list-ui-root";

const Toolbar = ({ setSelectedTab, selectedTab, showViewOptions = false }) => {
	const { identity } = useGetIdentity();

	if (!identity) return null;

	return (
		<div className="w-full">
			<TabbedWrapper
				options={[
					{ value: "false", title: "Not Completed" },
					{ value: "true", title: "Completed" },
					{ value: "", title: "All" },
				]}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
			>
				<DataTableToolbar
					showViewOptions={showViewOptions}
					search={false}
					className="px-2"
				>
					<DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
					<DataTableFilterButton>
						<DataTableFilterSingle
							column="assigned_to_id[eq]"
							title="Assigned To Me"
							options={[
								{ label: "Yes", value: get(identity, "id", "").toString() },
							]}
						/>
						<DataTableFilterSingle
							column="added_by_id[eq]"
							title="Added By Me"
							options={[
								{ label: "Yes", value: get(identity, "id", "").toString() },
							]}
						/>
						<DataTableReferenceFilter
							resource="users"
							column="assigned_to_id[eq]"
							title="Assigned To"
							optionText="full_name"
							customFilter={(name) => ({
								...(name &&
									name.length > 1 && {
										q: { field: "full_name", value: name },
									}),
								"role[ne]": `patient`,
							})}
						/>
						<DataTableReferenceFilter
							resource="users"
							column="added_by_id[eq]"
							title="Added By"
							optionText="full_name"
							customFilter={(name) => ({
								...(name &&
									name.length > 1 && {
										q: { field: "full_name", value: name },
									}),
								"role[ne]": "patient",
							})}
						/>
						{...TaskFilters}
					</DataTableFilterButton>
				</DataTableToolbar>
			</TabbedWrapper>
		</div>
	);
};

const getColumns = ({ selectedTab, cropSize }) => {
	return [
		{
			id: "select",
			header: ({ table }) => (
				<div className="px-1 py-auto">
					<Checkbox
						checked={table.getIsAllRowsSelected()}
						onCheckedChange={(checked) =>
							table?.getToggleAllRowsSelectedHandler()?.({
								target: { checked },
							})
						}
					/>
				</div>
			),
			cell: ({ row }) => (
				<div className="px-1 h-full">
					<Checkbox
						checked={row.getIsSelected()}
						disabled={!row.getCanSelect()}
						onCheckedChange={row.getToggleSelectedHandler()}
					/>
				</div>
			),
		},
		{
			header: "Title",
			accessorKey: "title",
			cell: () => <TextField source="title" />,
		},
		{
			header: "Description",
			accessorKey: "description",
			cell: ({ row }) => cropText(row.original.description, cropSize),
		},
		{
			header: "Type",
			accessorKey: "task_type",
			cell: () => <BadgeField source="task_type" />,
		},
		{
			header: "Priority",
			accessorKey: "priority",
			cell: ({ row }) => {
				const priority = row.original.priority;
				const priorityMap = {
					1: { label: "High", color: "red" },
					2: { label: "Medium", color: "yellow" },
					3: { label: "Low", color: "green" },
				};
				const { label, color } = priorityMap[priority] || {
					label: priority,
					color: "gray",
				};
				if (!label) return null;
				return (
					<Badge
						variant="outline"
						className={`bg-${color}-100 text-${color}-800 border-${color}-300`}
					>
						{label}
					</Badge>
				);
			},
		},
		{
			header: "Patient",
			accessorKey: "patient_id",
			cell: () => (
				<ReferenceField
					source="patient_id"
					reference="patients"
					link="show"
					sortBy="user.last_name"
				>
					<TextField source="user.full_name" />
				</ReferenceField>
			),
		},
		{
			header: "Assigned To",
			accessorKey: "assigned_to_name",
			cell: () => <TextField source="assigned_to_name" />,
		},
		{
			header: "Complete",
			accessorKey: "complete",
			cell: () => <BooleanField source="complete" />,
		},
		{
			header: "Created On",
			accessorKey: "created_on",
			cell: () => <DateField source="created_on" />,
		},
		{
			header: "Added By",
			accessorKey: "added_by_id",
			cell: () => (
				<ReferenceField
					source="added_by_id"
					reference="users"
					link="show"
					sortBy="last_name"
				>
					<TextField source="full_name" />
				</ReferenceField>
			),
		},
		{
			header: selectedTab === "true" ? "Completed At" : "Due On",
			accessorKey: selectedTab === "true" ? "completed_at" : "due_on",
			cell: () => (
				<DateField
					source={selectedTab === "true" ? "completed_at" : "due_on"}
				/>
			),
		},
		{
			header: "Actions",
			accessorKey: "actions",
			cell: () => <TaskEditButton />,
			enableHiding: false,
		},
	];
};

export const TaskList: FC = (props) => {
	const [selectedTab, setSelectedTab] = useState("false");
	const [cropSize, setCropSize] = useState(50);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width < 640) {
				// sm
				setCropSize(30);
			} else if (width < 768) {
				// md
				setCropSize(40);
			} else if (width < 1024) {
				// lg
				setCropSize(50);
			} else if (width < 1280) {
				// xl
				setCropSize(60);
			} else {
				// 2xl and above
				setCropSize(100);
			}
		};

		handleResize(); // Set initial size
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const columns = useMemo(
		() => getColumns({ selectedTab, cropSize }),
		[selectedTab, cropSize],
	);

	return (
		<ListUIRoot>
			<AppTitle title="Tasks" />
			<ListBase
				{...props}
				exporter={false}
				filter={{
					"complete[eq]": selectedTab !== "" ? selectedTab : undefined,
				}}
			>
				<div className="flex flex-col gap-2 w-full">
					<div className="w-max-content overflow-x-auto">
						<DataTable
							columns={columns}
							toolbar={
								<Toolbar
									selectedTab={selectedTab}
									setSelectedTab={setSelectedTab}
								/>
							}
							bulkActionButtons={<AssignTasksButton />}
							enableRowSelection={true}
						/>
					</div>
				</div>
			</ListBase>
		</ListUIRoot>
	);
};
