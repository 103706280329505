import PasswordResetButton from "@/modules/ra-ui/components/PasswordResetButton";
import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "@/modules/ui/components/alert";
import { get } from "lodash";
import { Lightbulb } from "lucide-react";
import React from "react";
import { useRecordContext } from "react-admin";
import { dateFormatter } from "../../../../utils/formatedDate";
import { InfoBlock } from "../../components/InfoBlock";
import { InviteCodeInput } from "./InviteCodeInput";
import { PatientProfileSection } from "../../components/PatientProfileSection";
import PatientCreateInviteCodeButton from "./PatientCreateInviteCodeButton";
import { MobileAppRejectedSection } from "./MobileAppRejected";
import { EmailVerifiedSection } from "./EmailVerifiedSection";

export const PatientMobileAppSection = () => {
	const record = useRecordContext();
	const email = get(record, "user.email", "");

	return (
		<PatientProfileSection
			title="Mobile App Invitation"
			action={
				email ? (
					<div className="flex flex-row flex-wrap gap-2 justify-end">
						<PatientCreateInviteCodeButton />
						<PasswordResetButton className="h-8" resource="patients" />
					</div>
				) : null
			}
			description={
				email
					? "Send an invite code to the patient to download & setup an account for the mobile app access"
					: null
			}
		>
			<div className="p-4">
				{email ? (
					<div className="flex flex-row flex-wrap gap-2">
						<InfoBlock
							title="Last welcome email sent"
							text={
								get(record, "user.welcome_email_last_sent")
									? dateFormatter(get(record, "user.welcome_email_last_sent"))
									: "Never"
							}
						/>
						<InfoBlock
							title="Last mobile app login"
							text={
								get(record, "user.last_login")
									? dateFormatter(get(record, "user.last_login"))
									: "Never"
							}
						/>
						<MobileAppRejectedSection />
						<EmailVerifiedSection />
						<InviteCodeInput />
					</div>
				) : (
					<Alert className="border-none w-fit mr-2">
						<Lightbulb className="h-5 w-5 mr-2" />
						<AlertTitle>No email set</AlertTitle>
						<AlertDescription>
							Patient email is required in order to use mobile application.
						</AlertDescription>
					</Alert>
				)}
			</div>
		</PatientProfileSection>
	);
};
