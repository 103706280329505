import { cn } from '@/modules/ui/utils/cn';
import React from 'react';

type Props = {
  title: string | any;
  text?: string;
  width?: string;
  component?: any;
  icon?: any;
};

export const InfoBlock: React.FC<Props> = ({
  title,
  text,
  component,
  icon,
}) => {
  return (
    <div className={cn('text-wrap break-words', 'min-w-[150px]   w-fit')}>
      <div>
        <div className="mb-0.5">
          {icon ? (
            <div className="flex items-center">
              {icon ? icon : null}
              <p className="text-sm">{title}</p>
            </div>
          ) : (
            <p className="text-sm">{title}</p>
          )}
        </div>
        {text ? (
          <p className="text-base font-medium overflow-auto whitespace-normal text-wrap break-words	">
            {text}
          </p>
        ) : null}
        {component ? component : null}
      </div>
    </div>
  );
};
