import MappedChipField from '@/modules/ra-ui/components/MappedChipField';
import { get } from 'lodash';
import React from 'react';
import {
  Datagrid,
  DeleteButton,
  ListContextProvider,
  Pagination,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { careTeamRoles } from '../../constants/careTeamRoles';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { Users } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/modules/ui/components/avatar';
import { getInitials } from '@/utils/getInitials';

export default function CareTeamList(props) {
  const record = useRecordContext();

  const sort = { field: null, order: null };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);

  const { data, total, isLoading } = useGetList(
    `patients/${record?.id}/care-teams`,
    {
      pagination: { page, perPage },
      sort,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <>
      <Datagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        empty={
          <EmptyDatagrid
            resourceName="Care Team"
            icon={<Users />}
            textFormatString="No members found"
          />
        }
        rowClick={false}
        {...props}
        // setSort={setSort}
      >
        <NameAvatarField label="Name" />
        <TextField source="practitioner.credentials" label="Credentials" />
        <MappedChipField source="role" label="Role" mapping={careTeamRoles} />
        {/* <EditClaimItemButton /> */}
        <DeleteButton
          resource={`patients/${record.id}/care-teams`}
          redirect={false}
          label=""
          sx={{
            minWidth: 'unset',
            '& .MuiButton-startIcon': {
              mr: 0,
            },
          }}
        />
      </Datagrid>
      <ListContextProvider
        value={{ page, perPage, setPage, setPerPage, total } as any}
      >
        {total > 10 ? <Pagination /> : null}
      </ListContextProvider>
    </>
  );
}

const NameAvatarField = (props) => {
  const { className } = props;
  const record = useRecordContext();
  const name = get(record, 'practitioner.full_name');
  // const email = get(record, 'email');

  return (
    <div className="flex flex-row items-center gap-2">
      <Avatar>
        <AvatarFallback> {getInitials(name)} </AvatarFallback>
      </Avatar>
      <span className={`text-base font-normal semi-bold ${className}`}>
        {name}
      </span>
    </div>
  );
};
