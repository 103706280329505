import React from "react";
import { FieldTitle, useInput } from "ra-core";
import { Switch } from "@/modules/ui/components/switch";
import {
	FormItem,
	FormLabel,
	FormControl,
	FormDescription,
} from "@/modules/ui/components/form";
import { humanize } from "inflection";
import { cn } from "../utils/cn";
import { InputMessage } from "./input-message";
export const SwitchInput = (props: any) => {
	const {
		className,
		defaultValue = false,
		label,
		format,
		onBlur,
		onChange,
		helperText,
		parse,
		resource,
		source,
		validate,
		size = "sm",
		...rest
	} = props;

	const {
		field,
		id,
		isRequired,
		fieldState: { error, isTouched },
	} = useInput({
		defaultValue,
		format,
		parse,
		resource,
		source,
		type: "checkbox",
		validate,
		onBlur,
		onChange,
		...rest,
	});

	let showLabel: string | boolean = label;
	if (label === undefined || label === null) {
		showLabel = humanize(source);
	}

	if (size === "lg") {
		return (
			<FormItem
				className={cn(
					className,
					"flex flex-row items-center justify-between rounded-lg border p-4",
				)}
				id={id}
			>
				<div className="space-y-0.5">
					{showLabel && (
						<FormLabel className="text-base">
							{showLabel}
							{isRequired && <span className="">*</span>}
						</FormLabel>
					)}
					{helperText && <FormDescription>{helperText}</FormDescription>}
				</div>
				<FormControl>
					<Switch
						checked={Boolean(field.value)}
						onCheckedChange={field.onChange}
					/>
				</FormControl>
			</FormItem>
		);
	}

	return (
		<FormItem
			className={cn(
				className,
				"flex flex-col items-start justify-between pt-2",
			)}
			id={id}
		>
			<div className="flex items-center space-x-2">
				<Switch
					id={source}
					checked={Boolean(field.value)}
					onCheckedChange={field.onChange}
				/>
				{showLabel && (
					<FormLabel htmlFor={source}>
						<FieldTitle label={label} source={source} isRequired={isRequired} />
					</FormLabel>
				)}
			</div>
			{helperText && <FormDescription>{helperText}</FormDescription>}
			<InputMessage touched={isTouched} error={error?.message} />
		</FormItem>
	);
};
