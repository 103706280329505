import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';

export const TaskFilters = [
  <DataTableFilter
    column="task_type[in]"
    title="Task Type"
    options={[
      { label: 'One off', value: 'one_off' },
      { label: 'Onboarding', value: 'onboarding' },
      { label: 'Voice Call Message', value: 'voice_call' },
    ]}
  />,
  <DataTableDateRangeFilter
    column="due_on"
    title="Due On"
    dateOnly={true}
    presets={[
      {
        name: 'today',
        label: 'Due Today',
        getRange: () => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const endOfDay = new Date(today);
          endOfDay.setHours(23, 59, 59, 999);
          return { from: today, to: endOfDay };
        },
      },
      {
        name: 'thisWeek',
        label: 'Due This Week',
        getRange: () => {
          const today = new Date();
          const endOfWeek = new Date(today);
          endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
          return { from: today, to: endOfWeek };
        },
      },
      {
        name: 'nextWeek',
        label: 'Due Next Week',
        getRange: () => {
          const monday = new Date();
          monday.setDate(monday.getDate() + (8 - monday.getDay()));
          const sunday = new Date(monday);
          sunday.setDate(monday.getDate() + 6);
          return { from: monday, to: sunday };
        },
      },
      {
        name: 'thisMonth',
        label: 'Due This Month',
        getRange: () => {
          const today = new Date();
          const endOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
          );
          return { from: today, to: endOfMonth };
        },
      },
      {
        name: 'overdue',
        label: 'Overdue',
        getRange: () => {
          const pastDate = new Date(2000, 0, 1);
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          yesterday.setHours(23, 59, 59, 999);
          return { from: pastDate, to: yesterday };
        },
      },
    ]}
  />,
  <DataTableDateRangeFilter
    column="created_on"
    title="Created On"
    presets={[
      {
        name: 'today',
        label: 'Today',
        getRange: () => {
          const today = new Date();
          const endOfDay = new Date(today);
          endOfDay.setHours(23, 59, 59, 999);
          return { from: today, to: endOfDay };
        },
      },
      {
        name: 'thisWeek',
        label: 'This Week',
        getRange: () => {
          const today = new Date();
          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() - today.getDay());
          const endOfWeek = new Date(today);
          endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
          endOfWeek.setHours(23, 59, 59, 999);
          return { from: startOfWeek, to: endOfWeek };
        },
      },
      {
        name: 'thisMonth',
        label: 'This Month',
        getRange: () => {
          const today = new Date();
          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
          );
          const endOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
          );
          endOfMonth.setHours(23, 59, 59, 999);
          return { from: startOfMonth, to: endOfMonth };
        },
      },
    ]}
  />,
];
