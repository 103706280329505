// This component should let users set tenant and location of devices.
import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { Input } from '@/modules/ui/components/input';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { cn } from '@/modules/ui/utils/cn';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';

import React, { useState } from 'react';
import {
  Form,
  required,
  useCreate,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';

import { Smartphone } from 'lucide-react';
import { SecurityKey } from './SecurityKey';

export const TwoFactorSetup = () => {
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const { identity, refetch } = useGetIdentity();

  const [generate, { data: dataGen, reset }] = useCreate();
  const [verify] = useCreate();
  const [disable] = useCreate();
  const [updatePreferredMethod] = useUpdate();
  const notify = useNotify();

  const { data: keys } = useGetOne(
    'auth/webauthn',
    { id: 'keys' },
    { enabled: !!identity?.id },
  );

  const generateSecret = () => {
    generate('auth/generate-2fa', {
      data: {},
    });
  };

  const clickVerify2FA = (formData: any) => {
    verify(
      'auth/enable-2fa',
      {
        data: {
          code: formData.code,
        },
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          notify('2FA is now enabled', { type: 'success' });
          reset();
          localStorage.removeItem('RaStore.user');
          refetch();
        },
      },
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clickDisable2FA = (formData: any) => {
    disable(
      'auth/disable-2fa',
      {
        data: {
          password: formData.password,
        },
      },
      {
        onSuccess: () => {
          refresh();
          setOpen(false);
          notify('2FA is now disabled', { type: 'success' });
          reset();
          localStorage.removeItem('RaStore.user');
          refetch();
        },
      },
    );
  };

  const handlePreferredMethodChange = (value: string) => {
    if (!identity?.id) return;
    updatePreferredMethod(
      'users',
      {
        id: `settings`,
        data: { preferred_mfa_method: value },
      },
      {
        onSuccess: () => {
          notify('Preferred 2FA method updated', { type: 'success' });
          refetch();
        },
        onError: (error) => {
          notify('Failed to update preferred 2FA method', { type: 'error' });
        },
      },
    );
  };

  return (
    <>
      <Card className=" w-full">
        <CardHeader>
          <CardTitle className="text-2xl">Two-Factor Authentication</CardTitle>
          <CardDescription>
            Two-factor authentication adds an extra layer of security to your
            account besides just your password. Vironix highly recommends 2FA
            for all users.
            {!identity?.mfa_enabled && (
              <div className="mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                <p className="font-bold">
                  Warning: Two-Factor Authentication is not enabled
                </p>
                <p>
                  Your account is currently not protected by Two-Factor
                  Authentication. We strongly recommend enabling 2FA to enhance
                  your account security.
                </p>
              </div>
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col text-sm  gap-2">
            <div className="flex items-center gap-2 justify-between">
              <div>
                <strong>Preferred 2FA Method</strong>{' '}
              </div>
              <Select
                value={identity?.preferred_mfa_method || ''}
                onValueChange={handlePreferredMethodChange}
                disabled={
                  !(identity?.totp_enabled || keys?.credentials?.length)
                }
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select method" />
                </SelectTrigger>
                <SelectContent>
                  {identity?.totp_enabled ? (
                    <SelectItem value="authenticator">
                      Authenticator App
                    </SelectItem>
                  ) : null}
                  {keys?.credentials?.length ? (
                    <SelectItem value="webauthn">Security Key</SelectItem>
                  ) : null}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center gap-4 justify-between p-4 border rounded-lg shadow-sm">
              <div className="flex items-center gap-2">
                <Smartphone className="w-6 h-6 text-gray-500" />
                <div>
                  <strong>Authenticator App:</strong>{' '}
                  <span>{identity?.totp_enabled ? 'Enabled' : 'Disabled'}</span>
                </div>
              </div>
              <Button
                onClick={handleClickOpen}
                disabled={open}
                variant="outline"
                size="sm"
                className="text-black hover:bg-gray-100"
                type="button"
              >
                {identity?.totp_enabled ? 'Configure' : 'Setup'}
              </Button>
            </div>
            <SecurityKey />
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        // onClose={handleClose}
      >
        <DialogContent
          onClose={handleClose}
          className={cn('min-w-[400px] overflow-y-auto max-h-[90vh]')}
        >
          <DialogHeader>
            <DialogTitle>Setup Two Factor Authentication</DialogTitle>
            <DialogDescription className="max-w-[400px]">
              Vironix supports TOTP as a 2FA method. You will need to make use
              of apps like Google Authenticator, Authy, or other 2FA Apps.
            </DialogDescription>
          </DialogHeader>
          {identity?.totp_enabled && (
            <div className="flex flex-col gap-2">
              <p>You have already 2FA enabled</p>
              <p className="text-sm text-red-500">
                We highly recommend enabling 2FA for security reasons.
              </p>
              <Form onSubmit={clickDisable2FA}>
                <div className="flex flex-col gap-2">
                  <TextInput
                    source="password"
                    validate={[required()]}
                    label="Current password"
                    type="password"
                    autoComplete="current-password"
                  />

                  <Button type="submit" variant="destructive">
                    Disable 2FA
                  </Button>
                </div>
              </Form>
            </div>
          )}

          {!identity?.totp_enabled && !!!dataGen && (
            <Button variant="outline" onClick={generateSecret}>
              Setup 2FA
            </Button>
          )}
          {dataGen && (
            <div className="flex flex-col text-sm  gap-2">
              <div className="flex flex-col gap-1">
                <strong>Secret Key:</strong>
                <Input value={dataGen.secret_key} contentEditable={false} />
              </div>
              <p>Or </p>
              <div>
                <strong>QR Code:</strong>
                <p>
                  Scan the QR Code with your 2FA App and enter the code below to
                  verify.
                </p>
                <img
                  src={`data:image/png;base64,${dataGen.qr_code}`}
                  alt="QR Code"
                  className="w-48 h-48 mx-auto"
                />
              </div>
              <Form onSubmit={clickVerify2FA}>
                <div className="flex flex-row gap-2 items-end justify-center">
                  <TextInput source="code" validate={[required()]} />
                  <Button>Verify</Button>
                </div>
              </Form>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
