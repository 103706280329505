import { CircleDot } from 'lucide-react';
import { find } from 'lodash';
import { useDataProvider, useRecordContext } from 'react-admin';
import { useQuery } from '@tanstack/react-query';

import { Button } from '@/modules/ui/components/button';
import { Card, CardContent } from '@/modules/ui/components/card';
import { assessmentsNames } from '../../users/constants/assessmentsNames';
import { formatPastDay } from '@/utils/formatPastDay';
import { useQuestionnaireContext } from '../hooks/useQuestionnaireContext';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { humanize } from 'inflection';

const formatNote = (note: string) => {
  try {
    return humanize(note.replace('.', ' - '));
  } catch (e) {
    return note;
  }
};

export function ModalVitalsToUpdateContent() {
  const patientRecord = useRecordContext();
  const dataProvider = useDataProvider();

  const {
    workFlow,
    setWorkFlow,
    refetchAssessments,
    isLoadingAssessments,
    setInProgress,
  } = useQuestionnaireContext();

  const formatObservations = () => {
    const observations = {};
    for (let i = 0; i < data.data.length; i++) {
      observations[data.data[i].name] = data.data[i].timestamp;
    }
    return observations;
  };

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchObservations,
  } = useQuery({
    queryKey: ['userObservations', patientRecord?.id],
    queryFn: () =>
      dataProvider.getCustom(
        `patients/${patientRecord?.id}/observations-v2`,
        {},
      ),
  });

  const onClickRecheck = () => {
    refetchObservations({ cancelRefetch: true });
    refetchAssessments({ cancelRefetch: true });
  };

  const onSkipClick = () => {
    setWorkFlow({
      ...workFlow,
      available: true,
    });
    setInProgress(true);
  };

  const vitalNotes = find(workFlow.available_notes, {
    type: 'observations',
  });
  const profileNotes = find(workFlow.available_notes, {
    type: 'profile',
  });

  if (!patientRecord?.id) return null;

  if (isLoading || isFetching || isLoadingAssessments) {
    return (
      <Card className="min-h-[200px]">
        <CardContent className="flex items-center justify-center">
          <LoadingSpinner />
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {vitalNotes && (
        <div className="space-y-4">
          <p className="text-base font-medium">
            To start this assessment please ask a Patient to update the
            following vital signs in the mobile application:
          </p>
          <div className="space-y-2">
            {vitalNotes.items.map((note) => (
              <div key={note} className="flex items-center gap-2">
                <CircleDot className="h-2 w-2 text-muted-foreground" />
                <p className="text-base font-medium">
                  {`${assessmentsNames[note]} (last updated ${formatPastDay(
                    formatObservations()[note],
                  )})`}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {profileNotes && (
        <div className="space-y-4">
          <p className="text-base font-medium">
            To start this assessment please complete the patient's profile.
            <span className="text-sm ml-1">(Required)</span>
          </p>
          <div className="space-y-2">
            {profileNotes.items.map((note) => (
              <div key={note} className="flex items-center gap-2">
                <CircleDot className="h-2 w-2 text-muted-foreground" />
                <p className="text-base font-medium">{formatNote(note)}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-end gap-3">
        <Button
          variant="outline"
          onClick={onSkipClick}
          disabled={!!profileNotes}
        >
          Skip
        </Button>
        {vitalNotes && (
          <Button onClick={onClickRecheck}>Recheck Vitals Status</Button>
        )}
      </div>
    </div>
  );
}
