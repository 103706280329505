import { Button } from '@/modules/ui/components/button';
import React, { useContext } from 'react';
import {
  CreateBase,
  Form,
  FormDataConsumer,
  required,
  useGetIdentity,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { MetricsDialogContext } from './MetricsDialog';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { RadioGroupInput } from '@/modules/ui/inputs/radio-group-input';
import { NumberInput } from '@/modules/ui/inputs/number-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';

const flagReason = [
  { id: 'outlier', name: 'Outlier' },
  { id: 'device_issue', name: 'Device issue' },
  { id: 'other', name: 'Other' },
];

const annotateReason = [
  { id: 'clinical_note', name: 'Clinical note' },
  { id: 'context_info', name: 'Contextual info' },
  { id: 'other', name: 'Other' },
];

export function AnnotateMetricCard({ clickCancel }) {
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  const refresh = useRefresh();
  const { selectedRow, measureName } = useContext(MetricsDialogContext);

  return (
    <div>
      <div className="mb-4">
        <p className="text-lg">Add annotation</p>
        <p className="text-sm">
          Use note to provide additional context regarding the data point.
        </p>
        <p className="text-sm">Use flag for anomalies in the data. </p>
        <p className="text-sm ml-2 italic">
          Flagged points will not be included in the graph and in baseline
          calulations, unless a new value is provided.
        </p>
      </div>
      <CreateBase
        resource={`patients/${record?.id}/observation-annotations`}
        transform={(data) => ({
          ...data,
          observation_id: selectedRow,
          author_id: identity?.id,
        })}
        redirect={false}
        mutationOptions={{
          onSuccess: () => {
            clickCancel();
            refresh();
          },
        }}
      >
        <Form>
          <div className="flex flex-col gap-2">
            <SelectInput
              source="annotation_type"
              label="Type"
              choices={[
                { id: 'flag', name: 'Flag' },
                { id: 'note', name: 'Note' },
              ]}
              defaultValue="note"
              validate={required()}
              helperText={false}
            />
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <RadioGroupInput
                    source="annotation_reason"
                    label="Reason"
                    choices={
                      formData.annotation_type === 'flag'
                        ? flagReason
                        : annotateReason
                    }
                    helperText={false}
                    validate={required()}
                  />
                  {formData.annotation_type === 'flag' &&
                    (measureName === 'blood_pressure' ? (
                      <div className="flex flex-row gap-2">
                        <NumberInput
                          source="new_value.systolic"
                          label="Systolic"
                          helperText={false}
                        />
                        <NumberInput
                          source="new_value.diastolic"
                          label="Diastolic"
                          helperText={false}
                        />
                      </div>
                    ) : (
                      <NumberInput source="new_value" helperText={false} />
                    ))}
                </>
              )}
            </FormDataConsumer>
            <TextAreaInput
              source="note"
              multiline
              helperText={false}
              validate={required()}
            />
            <div className="flex flex-row gap-2 mt-2">
              <Button variant="default">Save</Button>
              <Button variant="outline" type="button" onClick={clickCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </CreateBase>
    </div>
  );
}
