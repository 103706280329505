import HistoryIcon from "@mui/icons-material/History";
import { Box, Card, CardContent, Chip } from "@mui/material";
import { DateTime } from "luxon";

const scoreCategoryMapping = {
	//map 1-5
	1: { name: "Low", color: "#a7f171" },
	2: { name: "Low - Medium", color: "#fffc00" },
	3: { name: "Medium", color: "#ffc300" },
	4: { name: "Medium - High", color: "#ff6d00" },
	5: { name: "High", color: "#ff1800" },
};
const scoreExacerbationMapping = {
	//map 1-5
	1: { name: "Low", color: "#a7f171" },
	2: { name: "Low - Medium", color: "#fffc00" },
	3: { name: "Medium", color: "#ffc300" },
	4: { name: "Medium - High", color: "#ff6d00" },
	5: { name: "High", color: "#ff1800" },
};

export function ScoreCard(props) {
	const { value, text, lastUpdated, type, handleIndicatorClick } = props;

	const formatUpdateChip = () => {
		const dateToday = DateTime.local();
		const assessmentTime = DateTime.fromISO(lastUpdated, {
			zone: "utc",
		}).setZone("local");

		const diff = dateToday.diff(assessmentTime, ["minutes", "hours", "days"]);

		let text;

		if (diff.days >= 2) {
			text = `${diff.days.toFixed(0)} days ago`;
		} else if (diff.days >= 1 && diff.days < 2) {
			text = `${diff.days.toFixed(0)} day ago`;
		} else if (diff.hours < 24 && diff.hours >= 1) {
			text = `${diff.hours.toFixed(0)} hours ago`;
		} else if (diff.minutes < 60 && diff.minutes > 5) {
			text = `${diff.minutes.toFixed(0)} minutes ago`;
		} else if (diff.minutes <= 5) {
			text = "Just now";
		}

		return <Chip label={text} sx={{ color: "black" }} />;
	};

	const formatValue = () => {
		if (type === "category" && value.value) {
			const category = scoreCategoryMapping[value.value];

			return (
				<Box
					fontWeight={600}
					sx={{
						border: "2px solid",
						borderColor: category.color,
						borderRadius: "5px",
						width: "max-content",
					}}
					px={1}
					my={1}
				>
					<h6 className="text-center">{category.name}</h6>
				</Box>
			);
		} else if (type === "exacerbation") {
			// value - float from 0.0 to 1.0
			const score = value.value * 100;
			const bin = Math.ceil(score / 20) || 1;
			const category = scoreExacerbationMapping[bin];

			return (
				<Box
					fontWeight={600}
					sx={{
						border: "2px solid",
						borderColor: category?.color,
						borderRadius: "5px",
						width: "max-content",
					}}
					px={1}
					my={1}
				>
					<h6 className="text-center"> {Math.round(score)}</h6>
				</Box>
			);
		}
	};

	const formatDate = () => {
		if (lastUpdated) {
			const dateToday = DateTime.local().startOf("day");
			const assessmentTime = DateTime.fromISO(lastUpdated, {
				zone: "utc",
			})
				.setZone("local")
				.startOf("day");

			const diff = dateToday.diff(assessmentTime, "days").days;

			if (diff >= 1) {
				return DateTime.fromISO(lastUpdated, {
					zone: "utc",
				})
					.setZone("local")
					.toLocaleString(DateTime.DATETIME_SHORT);
			} else {
				return (
					"Today, " +
					DateTime.fromISO(lastUpdated, {
						zone: "utc",
					})
						.setZone("local")
						.toLocaleString(DateTime.TIME_SIMPLE)
				);
			}
		}
	};

	return (
		<Card
			variant="outlined"
			sx={{
				maxWidth: "max-content",
			}}
		>
			<span onClick={handleIndicatorClick} className="cursor-pointer">
				<CardContent>
					<div className="flex flex-row justify-between gap-4">
						<div>
							<div className="flex flex-col justify-center items-start">
								<div className="mb-2">
									<div className="flex items-center gap-4">
										<div className="text-sm font-medium">
											<div className="font-semibold">{text}</div>
										</div>
									</div>
								</div>
								<div>
									<div className="flex flex-col">
										<div>
											<div className="flex items-center gap-4">
												<HistoryIcon fontSize="small" />
												<p className="text-sm">{formatDate()}</p>
											</div>
										</div>
										<div>{formatValue()}</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="flex flex-col justify-between min-h-full items-center">
								<div>{formatUpdateChip()}</div>
							</div>
						</div>
					</div>
				</CardContent>
			</span>
		</Card>
	);
}
