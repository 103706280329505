import AppTitle from '@/modules/layout/components/app-title';
import React from 'react';
import {
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Title,
  useCreateController,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { CardSectionLayout } from '../../modules/testing/components/CardSectionLayout';
import { Button } from '@/modules/ui/components/button';

const testAccounts = [
  { id: 'copd@vironix.ai', name: 'copd@vironix.ai' },
  { id: 'copd_base@vironix.ai', name: 'copd_base@vironix.ai' },
  { id: 'asthma@vironix.ai', name: 'asthma@vironix.ai' },
  { id: 'asthma_base@vironix.ai', name: 'asthma_base@vironix.ai' },
  { id: 'chf_base@vironix.ai', name: 'chf_base@vironix.ai' },
  { id: 'chf@vironix.ai', name: 'chf@vironix.ai' },
];

const SubmitToolbar = () => (
  <div className="flex justify-end">
    <SaveButton label="Add" />
  </div>
);

export const Testing = () => {
  return (
    <div className="m-4 mb-2">
      <AppTitle title="Testing" />
      <Title title="Testing" />
      <div className="flex flex-col space-y-1">
        <AddDeviceObservationToPatient />
        <AddDeviceToPatient />
        <AddDeviceObservationToPatientUpdated />
        <Button
          type="button"
          onClick={() => {
            throw new Error('Sentry Test error');
          }}
          className="bg-red-500 text-white w-fit"
        >
          Break the world
        </Button>
      </div>
    </div>
  );
};

const DeviceObservationInput = () => {
  const deviceType = useWatch({ name: 'device_type' });

  return (
    <div className="flex flex-col gap-2">
      <SelectInput
        label="Device type"
        source="device_type"
        required
        choices={[
          { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
          { id: 'blood_pressure', name: 'Blood Pressure' },
          { id: 'weight_scale', name: 'Weight scale' },
          { id: 'peak_flow_meter', name: 'Peak Flow Meter' },
          { id: 'glucometer', name: 'Glucometer' },
        ]}
      />
      {deviceType === 'pulse_oximeter' && (
        <NumberInput required source="spo2" label="SpO2 (%)" />
      )}
      {(deviceType === 'pulse_oximeter' || deviceType === 'blood_pressure') && (
        <NumberInput required source="pulse" label="Heart Rate (bpm)" />
      )}
      {deviceType === 'blood_pressure' && (
        <NumberInput required source="systolic" label="Systolic (mmHg)" />
      )}
      {deviceType === 'blood_pressure' && (
        <NumberInput required source="diastolic" label="Diastolic (mmHg)" />
      )}
      {deviceType === 'weight_scale' && (
        <NumberInput required source="weight" label="Weight" />
      )}
      {deviceType === 'weight_scale' && (
        <SelectInput
          required
          source="unit"
          label="Unit"
          choices={[
            { id: 'lbs', name: 'lbs' },
            { id: 'kg', name: 'kg' },
          ]}
        />
      )}
      {deviceType === 'glucometer' && (
        <NumberInput required source="blood_glucose" label="Blood Glucose" />
      )}
      {deviceType === 'peak_flow_meter' && (
        <NumberInput
          required
          source="peak_expiratory_flow_rate"
          label="Peak Flow"
        />
      )}
    </div>
  );
};

const AddDeviceObservationToPatient = () => {
  const { save } = useCreateController({
    resource: 'testing/add-device-observation',
    redirect: false,
  });

  return (
    <CardSectionLayout
      title="Add Device Measurement"
      helpText="Adds a device measurement to the test patient account. Use this to mock device measurements."
    >
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <div className="space-y-4">
          <SelectInput
            fullWidth
            required
            label="Patient account email"
            source="email"
            choices={testAccounts}
          />
          <DeviceObservationInput />
        </div>
      </SimpleForm>
    </CardSectionLayout>
  );
};

const AddDeviceObservationToPatientUpdated = () => {
  const { save } = useCreateController({
    resource: 'testing/add-device-observation',
    redirect: false,
  });

  return (
    <CardSectionLayout
      title="Add Device Measurement"
      helpText="Adds a device measurement to the test patient account. Use this to mock device measurements."
    >
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <NumberInput source="patient_id" />
        <DeviceObservationInput />
      </SimpleForm>
    </CardSectionLayout>
  );
};

const AddDeviceToPatient = () => {
  const { save } = useCreateController({
    resource: 'testing/add-device',
    redirect: false,
  });

  return (
    <CardSectionLayout
      title="Add Device"
      helpText="Adds a test device to a test patient account."
    >
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <SelectInput
          fullWidth
          required
          label="Patient account email"
          source="email"
          choices={testAccounts}
        />
        <SelectInput
          required
          label="Device type"
          source="device_type"
          choices={[
            { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
            { id: 'blood_pressure', name: 'Blood Pressure' },
          ]}
        />
        <TextInput source="external_id" label="IMEI" required={false} />
      </SimpleForm>
    </CardSectionLayout>
  );
};
