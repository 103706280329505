import { usePatientTableState } from '@/modules/common/hooks/usePatientTableState';
import { DataTable } from '@/modules/data-table/components/data-table';
import { EmptyState } from '@/modules/data-table/components/data-table-empty-state';
import ConfirmDeleteButton from '@/modules/ui/components/confirm-delete-button';
import { ClipboardList } from 'lucide-react';
import { DateTime } from 'luxon';
import {
  ListContextProvider,
  ReferenceField,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';

const columns = [
  {
    id: 'activity_template.activity_title',
    accessorKey: 'activity_template.activity_title',
    header: 'Activity',
    cell: () => <TextField source="activity_template.activity_title" />,
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: 'Created At',
    cell: ({ row }) => {
      const date = DateTime.fromISO(row.original.created_at).toLocaleString(
        DateTime.DATETIME_MED,
      );
      return date;
    },
  },
  {
    header: 'Completed By',
    accessorKey: 'practitioner_id',
    cell: () => (
      <ReferenceField
        source="practitioner_id"
        reference="users"
        link="show"
        sortBy="last_name"
      >
        <TextField source="full_name" />
      </ReferenceField>
    ),
  },
  {
    id: 'actions',
    cell: () => <ConfirmDeleteButton resource="clinical-activity-instances" />,
  },
];

export const PatientClinicalActivitiesList = () => {
  const record = useRecordContext();

  const {
    page,
    perPage,
    sort,
    filterValues,
    setPage,
    setPerPage,
    setSort,
    setFilters,
  } = usePatientTableState('clinical-activity-instances', record?.id, {
    defaultSort: { field: 'completion_date', order: 'DESC' },
  });

  const { data, total, isLoading } = useGetList(
    'clinical-activity-instances',
    {
      pagination: { page, perPage },
      sort,
      filter: {
        'patient_id[eq]': record?.id,
        'is_completed[eq]': true,
        ...filterValues,
      },
    },
    {
      enabled: !!record,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <div className="w-full overflow-x-auto">
      <ListContextProvider
        value={
          {
            filterValues,
            setFilters,
            displayedFilters: {},
            page,
            perPage,
            setPage,
            setPerPage,
            total,
            sort,
            setSort,
            isLoading,
          } as any
        }
      >
        <DataTable
          columns={columns}
          data={data}
          loading={isLoading}
          emptyState={
            <EmptyState
              title="No clinical activities"
              description="No clinical activities found"
              icons={[ClipboardList]}
              className="max-w-full"
            />
          }
          storeKey="clinical-activities-list"
          containerClassName="pb-2"
        />
      </ListContextProvider>
    </div>
  );
};
