import React, { type FC } from "react";

import { EnrollmentInfo } from "@/modules/enrollment/components/EnrollmentInfo";
import { MedicationsCreateButton } from "@/modules/medications/components/MedicationsCreateButton";
import { MedicationsList } from "@/modules/medications/components/MedicationsList";
import { CareTeamCreateButton } from "@/modules/patients/care-team/components/CareTeamCreateButton";
import CareTeamList from "@/modules/patients/care-team/components/CareTeamList";
import {
	ConditionCreate,
	ConditionsDataGrid,
} from "@/modules/patients/clinical-sections/components/Conditions";
import { ConsentForm } from "@/modules/patients/components/ConsentForm";
import { DevicePatientActivities } from "@/modules/patients/components/DevicePatientActivities";
import { DevicesDataGrid } from "@/modules/patients/components/DevicesDataGrid";
import { PatientProfileSection } from "@/modules/patients/components/PatientProfileSection";
import { ServiceInstanceCreateDialog } from "@/modules/patients/components/ServiceInstanceCreate";
import { ServiceInstanceDataGrid } from "@/modules/patients/components/ServiceInstanceDataGrid";
import { usePatientStatus } from "@/modules/patients/hooks/patient-status";
import { PatientMobileAppSection } from "@/modules/patients/mobile-app/components/PatientMobileAppSection";
import { PatientContactCreateButton } from "@/modules/patients/patient-contacts/components/ContactsCreateEditButton";
import { PatientContactsGrid } from "@/modules/patients/patient-contacts/components/PatientContactsGrid";
import { usePermissions, useRecordContext } from "react-admin";
import { PatientPhoneNumberList } from "../../modules/patients/components/PatientPhoneNumberList";
import { EnrollmentInfoEdit } from "@/modules/enrollment/components/EnrollmentInfoEdit";
import { PatientStatusHistoryDataGrid } from "@/modules/patients/components/PatientStatusHistoryDataGrid";
import { PatientDataExportTable } from "@/modules/patients/data-export/components/PatientDataExportTable";
import { PatientDataExportButton } from "@/modules/patients/data-export/components/PatientDataExportButton";
import { get } from "lodash";
const PatientShowSimpleView = () => {
	const record = useRecordContext();
	const patientStatus = usePatientStatus();
	const { permissions } = usePermissions();
	const isNotEnrolled = [
		"pending_enrollment",
		"consented",
		"declined",
		"disabled",
		"unseen",
	].includes(patientStatus);
	const isNeverEnrolled = ["pending_enrollment", "unseen", "declined"].includes(
		patientStatus,
	);
	if (!record) return null;

	return (
		<div className="flex flex-col gap-4">
			{isNotEnrolled ? (
				<PatientProfileSection
					title="Enrollment Information"
					description="Enrollment information about the patient"
				>
					<EnrollmentInfo />
				</PatientProfileSection>
			) : null}

			<PatientProfileSection
				title="Care Programs"
				description="Care programs are the virtual care services that are provided to the patient."
				action={<ServiceInstanceCreateDialog />}
			>
				<ServiceInstanceDataGrid />
			</PatientProfileSection>

			<PatientProfileSection
				title="Devices"
				description="Devices that have been assigned to the patient."
			>
				<DevicesDataGrid />
			</PatientProfileSection>

			<PatientProfileSection
				title="Device History"
				description="Device history & events for the patient."
			>
				<DevicePatientActivities />
			</PatientProfileSection>

			<PatientProfileSection
				title="Care Team"
				description="Care team members are clinicians responsible for monitoring and managing the patient’s care."
				action={<CareTeamCreateButton />}
			>
				<CareTeamList />
			</PatientProfileSection>

			<PatientProfileSection
				title="Medications"
				description="Medications that have been prescribed to the patient."
				action={<MedicationsCreateButton />}
				component="div"
			>
				<MedicationsList />
			</PatientProfileSection>

			{!isNeverEnrolled && (
				<PatientProfileSection
					title="Consent Form"
					description="Manage consent form for VCM services."
				>
					<ConsentForm />
				</PatientProfileSection>
			)}

			<PatientProfileSection
				title="Family & Contacts"
				description="Store patient’s family and contacts information here for easy access."
				action={<PatientContactCreateButton />}
				component="div"
			>
				<PatientContactsGrid />
			</PatientProfileSection>

			<PatientProfileSection
				title="Conditions & Diagnosis"
				description="Conditions that the patient has been diagnosed with."
				action={<ConditionCreate />}
				component="div"
			>
				<ConditionsDataGrid />
			</PatientProfileSection>

			{!isNotEnrolled && (
				<PatientProfileSection
					title="Enrollment Information"
					description="Enrollment information about the patient"
					action={<EnrollmentInfoEdit />}
				>
					<EnrollmentInfo />
				</PatientProfileSection>
			)}

			<PatientProfileSection
				title="Patient Status History"
				description="Status history for the patient."
			>
				<PatientStatusHistoryDataGrid />
			</PatientProfileSection>

			<PatientProfileSection
				title="Phone Numbers"
				description="Phone numbers that are associated with the patient."
			>
				<PatientPhoneNumberList />
			</PatientProfileSection>
			<PatientMobileAppSection />
			{get(permissions, "data_export.list") && (
				<PatientProfileSection
					title="Patient Data Export"
					description="Review and begin a patient data export request. The data export will be sent to the patient's email address."
					action={<PatientDataExportButton />}
					component="div"
				>
					<PatientDataExportTable />
				</PatientProfileSection>
			)}
		</div>
	);
};

export const PatientShowSimple: FC = () => {
	return <PatientShowSimpleView />;
};
