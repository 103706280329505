"use client";

import * as React from "react";
import { cn } from "@/modules/ui/utils/cn";
import {
	Select,
	SelectContent,
	SelectEmpty,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/modules/ui/components/select";
import { useGetList, useGetOne, useInput, FieldTitle } from "ra-core";
import { useCallback, useMemo } from "react";
import { get } from "lodash";
import { FormLabel } from "@/modules/ui/components/form";
import { InputMessage } from "./input-message";
import { Button } from "@/modules/ui/components/button";

interface ReferenceSelectInputProps {
	defaultValue?: any;
	isRequired?: boolean;
	disabled?: boolean;
	source: string;
	resource?: string;
	label?: string | React.ReactElement | false;
	optionText?: string | ((record: any) => string);
	optionValue?: string;
	filter?: Record<string, any> | null;
	reference: string;
	helperText?: string | React.ReactElement | false;
	className?: string;
	validate?: any;
	emptyText?: string;
	emptyValue?: string;
	count?: number;
	meta?: any;
	sort?: any;
}

export function ReferenceSelectInput({
	defaultValue,
	isRequired: isRequiredOverride,
	disabled,
	source,
	resource,
	label,
	optionText = "name",
	optionValue = "id",
	filter = null,
	reference,
	helperText = false,
	className,
	validate,
	emptyText = "Select...",
	emptyValue = "",
	count = 5,
	meta,
	sort,
}: ReferenceSelectInputProps) {
	const {
		field,
		isRequired,
		fieldState: { error, invalid, isTouched },
		formState: { isSubmitted },
	} = useInput({
		defaultValue,
		isRequired: isRequiredOverride,
		resource,
		source,
		disabled,
		validate,
	});

	const [, setKey] = React.useState(+new Date());

	const getOptionLabel = (option: any) => {
		return typeof optionText === "function"
			? optionText(option)
			: get(option, optionText);
	};

	const getChoiceValue = useCallback(
		(option: any) => {
			return get(option, optionValue);
		},
		[optionValue],
	);

	const { data, error: fetchError } = useGetList(reference, {
		sort: sort || { field: "id", order: "ASC" },
		pagination: { page: 1, perPage: count },
		filter: filter || {},
		meta,
	});

	const { data: dataSingle } = useGetOne(
		reference,
		{
			id: field.value,
		},
		{
			enabled: !!field.value,
		},
	);

	const finalChoices = useMemo(() => {
		const choices = data || [];
		if (!dataSingle) {
			return choices;
		}

		const uniqueChoices = new Set(
			[...choices, dataSingle].map((choice) =>
				JSON.stringify({ value: getChoiceValue(choice), data: choice }),
			),
		);

		return Array.from(uniqueChoices).map((choice) => JSON.parse(choice).data);
	}, [data, dataSingle, getChoiceValue]);

	console.log(finalChoices, finalChoices.length);

	const renderHelperText =
		!!fetchError ||
		helperText !== false ||
		((isTouched || isSubmitted) && invalid);

	return (
		<div className={cn("space-y-2", className)}>
			<FormLabel>
				<FieldTitle
					label={label}
					source={source}
					resource={resource}
					isRequired={isRequired}
				/>
			</FormLabel>
			<Select
				value={field.value?.toString() || emptyValue}
				onValueChange={field.onChange}
				disabled={disabled}
			>
				<SelectTrigger className="w-full">
					<SelectValue placeholder={emptyText} />
				</SelectTrigger>
				<SelectContent>
					{finalChoices.length === 0 && (
						<SelectEmpty>No options found</SelectEmpty>
					)}
					{finalChoices.map((option) => {
						const value = getChoiceValue(option);
						const label = getOptionLabel(option);
						return (
							<SelectItem key={value} value={value.toString()}>
								{label || value}
							</SelectItem>
						);
					})}
					{!isRequired && (
						<Button
							className="w-full px-2 text-left"
							variant="ghost"
							size="sm"
							onClick={(e) => {
								e.stopPropagation();
								field.onChange("");
								setKey(+new Date());
							}}
						>
							Clear
						</Button>
					)}
				</SelectContent>
			</Select>
			{renderHelperText && (
				<InputMessage
					touched={isTouched || isSubmitted || !!fetchError}
					error={error?.message || fetchError?.message}
					helperText={helperText}
				/>
			)}
		</div>
	);
}
