import { DataTable } from "@/modules/data-table/components/data-table";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import { MultiSearch } from "@/modules/data-table/components/data-table-multi-search";
import { Button } from "@/modules/ui/components/button";
import { PlusCircle } from "lucide-react";
import { DateTime } from "luxon";
import React, { useState } from "react";
import {
	ListContextProvider,
	useGetList,
	useStore,
	type SortPayload,
} from "ra-core";
import { Badge } from "@/modules/ui/components/badge";
import { humanize } from "inflection";
import { get } from "lodash";
import type { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import TabbedWrapper from "@/modules/ra-ui/components/tabbed-wrapper";

// Activity Templates Table Columns
const activityColumns: ColumnDef<any>[] = [
	{
		accessorKey: "activity_title",
		header: "Title",
	},
	{
		accessorKey: "activity_description",
		header: "Description",
	},
	{
		accessorKey: "activity_type",
		header: "Type",
		cell: ({ row }) => {
			const type = get(row.original, "activity_type");
			return type ? (
				<Badge variant="outline" className="w-max">
					{humanize(type)}
				</Badge>
			) : null;
		},
	},
	{
		accessorKey: "estimated_duration",
		header: "Duration",
	},
	{
		accessorKey: "activity_template_family_id",
		header: "Family",
	},
	{
		accessorKey: "version_number",
		header: "Version",
	},
	{
		accessorKey: "is_draft",
		header: "Draft",
		cell: ({ row }) => {
			return (
				<Badge variant="outline" className="w-max">
					{row.original.is_draft ? "Yes" : "No"}
				</Badge>
			);
		},
	},
	{
		accessorKey: "created_at",
		header: "Created At",
		cell: ({ row }) => {
			const date = DateTime.fromISO(row.original.created_at).toLocaleString(
				DateTime.DATETIME_MED,
			);
			return date;
		},
	},
];

// Step Templates Table Columns
const stepColumns: ColumnDef<any>[] = [
	{
		accessorKey: "step_title",
		header: "Title",
	},
	{
		accessorKey: "step_description",
		header: "Description",
	},
	{
		accessorKey: "expected_time_range",
		header: "Expected Time",
		cell: ({ row }) => {
			const timeRange = get(row.original, "expected_time_range");
			return timeRange ? (
				<Badge variant="outline" className="w-max">
					{(timeRange.min / 60).toFixed(0)}-{(timeRange.max / 60).toFixed(0)}{" "}
					min
				</Badge>
			) : null;
		},
	},
	{
		accessorKey: "version_number",
		header: "Version",
	},
	{
		accessorKey: "is_draft",
		header: "Draft",
		cell: ({ row }) => {
			return (
				<Badge variant="outline" className="w-max">
					{row.original.is_draft ? "Yes" : "No"}
				</Badge>
			);
		},
	},
	{
		accessorKey: "created_at",
		header: "Created At",
		cell: ({ row }) => {
			const date = DateTime.fromISO(row.original.created_at).toLocaleString(
				DateTime.DATETIME_MED,
			);
			return date;
		},
	},
];

const ActivityTemplatesTable = () => {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sort, setSort] = useState<SortPayload>({
		field: "created_at",
		order: "DESC",
	});
	const [filterValues, setFilters] = useState({});
	const [displayedFilters] = useState({});
	const [selectedTab, setSelectedTab] = useStore(
		"activity_template_builder.published",
		"published",
	);

	const { data, total, isLoading } = useGetList("activity-templates", {
		pagination: { page, perPage },
		sort,
		filter: {
			...(selectedTab === "published" ? { "is_latest[eq]": true } : {}),
			"is_draft[eq]": selectedTab === "published" ? false : true,
			...filterValues,
		},
	});

	return (
		<ListContextProvider
			value={{
				data,
				isLoading,
				page,
				perPage,
				setPage,
				setPerPage,
				total,
				sort,
				setSort,
				filterValues,
				setFilters,
				displayedFilters,
				selectedIds: [],
				onSelect: () => {},
				onToggleItem: () => {},
				onSelectAll: () => {},
				onUnselectItems: () => {},
				resource: "activity-templates",
				refetch: () => {},
				error: null,
				hasNextPage: false,
				hasPreviousPage: false,
				hideFilter: () => {},
				isFetching: false,
				isPending: false,
				showFilter: () => {},
			}}
		>
			<div className="w-full">
				<TabbedWrapper
					options={[
						{ value: "published", title: "Published" },
						{ value: "draft", title: "Draft" },
					]}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				>
					<DataTable
						columns={activityColumns}
						storeKey="activity-templates"
						onRowClick={(record) =>
							navigate(`/clinical-activities/activity/${record.id}`)
						}
						toolbar={
							<DataTableToolbar
								search={
									<MultiSearch
										searchFields={[
											{ value: "activity_title", label: "Title" },
											{ value: "activity_type", label: "Type" },
										]}
									/>
								}
								createButton={
									<Button
										size="sm"
										className="h-8"
										onClick={() =>
											navigate("/clinical-activities/activity/create")
										}
									>
										<PlusCircle className="mr-2 h-4 w-4" />
										New Activity Template
									</Button>
								}
							></DataTableToolbar>
						}
					/>
				</TabbedWrapper>
			</div>
		</ListContextProvider>
	);
};

const StepTemplatesTable = () => {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sort, setSort] = useState<SortPayload>({
		field: "created_at",
		order: "DESC",
	});
	const [filterValues, setFilters] = useState({});
	const [displayedFilters] = useState({});
	const [selectedTab, setSelectedTab] = useStore(
		"step_template_builder.selectedTab",
		"published",
	);

	const { data, total, isLoading } = useGetList("step-templates", {
		pagination: { page, perPage },
		sort,
		filter: {
			...(selectedTab === "published" ? { "is_latest[eq]": true } : {}),
			"is_draft[eq]": selectedTab === "published" ? false : true,
			...filterValues,
		},
	});

	return (
		<ListContextProvider
			value={{
				data,
				isLoading,
				page,
				perPage,
				setPage,
				setPerPage,
				total,
				sort,
				setSort,
				filterValues,
				setFilters,
				displayedFilters,
				selectedIds: [],
				onSelect: () => {},
				onToggleItem: () => {},
				onSelectAll: () => {},
				onUnselectItems: () => {},
				resource: "step-templates",
				refetch: () => {},
				error: null,
				hasNextPage: false,
				hasPreviousPage: false,
				hideFilter: () => {},
				isFetching: false,
				isPending: false,
				showFilter: () => {},
			}}
		>
			<div className="w-full">
				<TabbedWrapper
					options={[
						{ value: "published", title: "Published" },
						{ value: "draft", title: "Draft" },
					]}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				>
					<DataTable
						columns={stepColumns}
						storeKey="step-templates"
						onRowClick={(record) =>
							navigate(`/clinical-activities/step/${record.id}`)
						}
						toolbar={
							<DataTableToolbar
								search={
									<MultiSearch
										searchFields={[{ value: "step_title", label: "Title" }]}
									/>
								}
								createButton={
									<Button
										size="sm"
										className="h-8"
										onClick={() => navigate("/clinical-activities/step/create")}
									>
										<PlusCircle className="mr-2 h-4 w-4" />
										New Step Template
									</Button>
								}
							></DataTableToolbar>
						}
					/>
				</TabbedWrapper>
			</div>
		</ListContextProvider>
	);
};

export const ClinicalActivityBuilder = () => {
	return (
		<div className="space-y-8 p-4">
			<div>
				<h2 className="text-2xl font-bold tracking-tight mb-4">
					Activity Templates
				</h2>
				<p className="text-sm text-gray-500 mb-4">
					The following are activity templates. These define what clinicians can
					do. They consist of one or more steps. Multiple clinical activities
					can share the same steps. If a step is edited, all activities that use
					that step will be updated to the new version.
				</p>
				<ActivityTemplatesTable />
			</div>
			<div>
				<h2 className="text-2xl font-bold tracking-tight mb-4">
					Step Templates
				</h2>
				<p className="text-sm text-gray-500 mb-4">
					Step templates are building blocks of activity templates.
				</p>
				<StepTemplatesTable />
			</div>
		</div>
	);
};
