import React from 'react';
import { useRecordContext } from 'react-admin';
import { find, get } from 'lodash';
import { vitalsConfig } from '../../../modules/metrics/constants/vitalsConfig';

const LatestMeasurement = ({ name, data }) => {
  const observation = find(data, (o) => o.name === name);

  if (!observation) return null;

  let value = get(observation, 'value');

  // Handle blood pressure special case
  if (name === 'blood_pressure') {
    value = `${get(observation, 'value.systolic')}/${get(
      observation,
      'value.diastolic',
    )}`;
  } else {
    // Find matching vital config to apply rounding
    const vitalConfig = find(
      vitalsConfig,
      (config) => config.chartMeasureName === name,
    );
    if (vitalConfig && typeof value === 'number') {
      value = value.toFixed(vitalConfig.roundDigits);
    }
  }

  return (
    <div className="bg-blue-100  text-blue-700 p-2 flex items-center justify-center">
      <p className="text-sm font-semibold">{value}</p>
    </div>
  );
};

export const LatestMeasurementField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);

  if (!record) {
    return null;
  }

  return (
    <LatestMeasurement data={get(record, 'observations', [])} name={source} />
  );
};
