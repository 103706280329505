import { useRecordContext } from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { DateInput } from '@/modules/ui/inputs/date-input';
import { NumberInput } from '@/modules/ui/inputs/number-input';
import { required } from 'react-admin';

export const ReimbursementRateCreateButton = (props) => {
  const { variant = 'text' } = props;
  const record = useRecordContext();

  const transform = (data) => {
    return {
      ...data,
      user_id: record.id,
    };
  };

  if (!record) {
    return null;
  }

  return (
    <CreateInDialogButton
      title="Add Reimbursement Rate"
      transform={transform}
      variant={variant}
      label="Add Rate"
      dialogVariant="shadcn"
      closeOnClickOutside={false}
      resource="reimbursement-rates"
      refreshOnSuccess={true}
    >
      <div className="flex flex-col space-y-4">
        <DateInput
          source="as_of_date"
          label="Effective Date"
          validate={required()}
          helperText="When does this rate become effective?"
        />

        <NumberInput
          source="hourly_reimbursement"
          label="Hourly Rate"
          helperText="Hourly reimbursement rate"
          prefix="$"
          validate={required()}
          decimalScale={2}
        />

        <NumberInput
          source="enrollment_reimbursement"
          label="Enrollment Rate"
          helperText="Rate per enrollment"
          prefix="$"
          decimalScale={2}
        />

        <NumberInput
          source="shift_reimbursement"
          label="Shift Rate"
          helperText="Rate per shift"
          prefix="$"
          decimalScale={2}
        />

        <NumberInput
          source="training_reimbursement"
          label="Training Rate"
          helperText="Rate for training"
          prefix="$"
          decimalScale={2}
        />

        <NumberInput
          source="incentive_reimbursement"
          label="Incentive Rate"
          helperText="Additional incentive rate"
          prefix="$"
          decimalScale={2}
        />
      </div>
    </CreateInDialogButton>
  );
};
