import React, { useEffect, useState } from 'react';
import { Button } from '@/modules/ui/components/button';
import { Phone, PhoneOff, Loader2, Volume2, VolumeX } from 'lucide-react';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import { cn } from '@/modules/ui/utils/cn';
import { Audio, useNotification } from '@telnyx/react-client';

export function InProgressCall() {
  const notification = useNotification();
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [dismissedCallIds, setDismissedCallIds] = useState<Set<string>>(
    new Set(),
  );
  const call = notification?.call;
  const callState = call?.state;

  // Reset dismissed state and mute when a new call comes in
  useEffect(() => {
    if (call?.id && !dismissedCallIds.has(call.id)) {
      // Reset mute state for new calls
      setIsMuted(false);
      return;
    }
  }, [call?.id, dismissedCallIds]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    let startTime: number;

    if (callState === 'active') {
      startTime = Date.now();
      interval = setInterval(() => {
        const elapsed = Math.floor((Date.now() - startTime) / 1000);
        setDuration(elapsed);
      }, 1000);
    } else if (callState === 'trying') {
      setDuration(0);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [callState]);

  const handleMuteToggle = () => {
    if (isMuted) {
      call?.unmuteAudio();
      setIsMuted(false);
    } else {
      call?.muteAudio();
      setIsMuted(true);
    }
  };

  const handleEndCall = () => {
    if (call) {
      call.hangup();
    }
  };

  const handleClose = () => {
    if (call?.id) {
      setDismissedCallIds((prev) => new Set([...prev, call.id]));
    }
  };

  const getCallStateDisplay = (state: string | undefined): string => {
    switch (state) {
      case 'new':
        return 'Initializing call...';
      case 'trying':
      case 'requesting':
        return 'Calling...';
      case 'recovering':
        return 'Reconnecting...';
      case 'ringing':
        return 'Incoming call';
      case 'answering':
        return 'Connecting...';
      case 'early':
      case 'active':
        return 'Connected';
      case 'held':
        return 'On hold';
      case 'hangup':
        return 'Call ended';
      case 'destroy':
      case 'purge':
        return 'Call disconnected';
      default:
        return 'Unknown status';
    }
  };

  // Don't show if there's no call or if this specific call was dismissed
  if (!call || (call.id && dismissedCallIds.has(call.id))) {
    return null;
  }

  const isCallEnded = callState === 'hangup' || callState === 'destroy';

  return (
    <>
      {!['hangup', 'destroy', 'purge'].includes(callState) && (
        <Audio stream={call.remoteStream} />
      )}
      <div
        className={cn(
          'fixed bottom-0 left-1/2 -translate-x-1/2 z-[9999] bg-white',
          'border-red-300 border-4 rounded-t-lg border-b-0 shadow-lg p-4',
          'transition-all duration-200 ease-in-out',
        )}
      >
        <div className="flex items-center gap-6">
          {/* Duration */}
          <div className="flex items-center gap-2">
            <Phone className="h-5 w-5 text-primary" />
            <span className="text-sm font-medium">
              Duration: {formatDurationAbbreviated(duration)}
            </span>
          </div>

          {/* Call Status */}
          <div className="flex items-center gap-2">
            {callState === 'connecting' ||
            callState === 'trying' ||
            callState === 'requesting' ? (
              <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
            ) : callState === 'active' || callState === 'early' ? (
              <div className="h-2 w-2 rounded-full bg-green-500" />
            ) : (
              <div className="h-2 w-2 rounded-full bg-red-500" />
            )}
            <span className="text-sm text-gray-600">
              Status: {getCallStateDisplay(callState)}
            </span>
          </div>

          {/* Controls */}
          <div className="flex items-center gap-2">
            {!isCallEnded && (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleMuteToggle}
                  className={cn(
                    'flex items-center gap-2',
                    isMuted && 'text-red-500',
                  )}
                >
                  {isMuted ? (
                    <VolumeX className="h-4 w-4" />
                  ) : (
                    <Volume2 className="h-4 w-4" />
                  )}
                </Button>

                <Button
                  variant="destructive"
                  size="sm"
                  onClick={handleEndCall}
                  className="flex items-center gap-2"
                >
                  <PhoneOff className="h-4 w-4" />
                  End Call
                </Button>
              </>
            )}

            {isCallEnded && (
              <Button
                variant="secondary"
                size="sm"
                onClick={handleClose}
                className="flex items-center gap-2"
              >
                Close
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
