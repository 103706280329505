import { Button } from "@/modules/ui/components/button";
import {
	Command,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/modules/ui/components/command";
import { FormLabel } from "@/modules/ui/components/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/modules/ui/components/popover";
import { Skeleton } from "@/modules/ui/components/skeleton";
import { cn } from "@/modules/ui/utils/cn";
import { useQuery } from "@tanstack/react-query";
import { Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import { FieldTitle, useDataProvider, useInput } from "react-admin";
import { inputBaseClassname } from "./input-base-classname";
import React from "react";
import { InputMessage } from "./input-message";

export const SnomedInput = ({
	modal = true,
	source,
	label,
	reference,
	className,
	meta,
	multiple = false,
	validate,
	helperText,
}: {
	modal?: boolean;
	source: string;
	label: string;
	reference: string;
	className?: string;
	meta?: any;
	multiple?: boolean;
	validate?: any;
	helperText?: string | React.ReactElement | false;
}) => {
	const dataProvider = useDataProvider();
	const [search, setSearch] = useState("");
	const [open, setOpen] = useState(false);
	const [cachedOptions, setCachedOptions] = useState<any[]>([]);

	const {
		field,
		isRequired,
		fieldState: { error, invalid, isTouched },
		formState: { isSubmitted },
	} = useInput({
		source,
		validate,
	});

	// Cache selected options and fetch details for codes
	useEffect(() => {
		const fetchItemDetails = async (code: string) => {
			try {
				const response = await dataProvider.getCustom(`${reference}/${code}`, {
					meta: { ...meta },
				});
				return response.data;
			} catch (error) {
				console.error(`Error fetching details for code ${code}:`, error);
				return null;
			}
		};

		const updateCache = async () => {
			if (multiple && Array.isArray(field.value)) {
				const newItems = [];

				for (const item of field.value) {
					// If we already have complete data, use it
					if (item.display) {
						if (!cachedOptions.some((cached) => cached.code === item.code)) {
							newItems.push(item);
						}
					}
					// If we only have the code, fetch the details
					else if (
						item.code &&
						!cachedOptions.some((cached) => cached.code === item.code)
					) {
						const details = await fetchItemDetails(item.code);
						if (details) {
							newItems.push(details);
						}
					}
				}

				if (newItems.length > 0) {
					setCachedOptions((prev) => [...prev, ...newItems]);
				}
			} else if (!multiple && field.value) {
				// For single selection
				if (
					field.value.code &&
					!field.value.display &&
					!cachedOptions.some((cached) => cached.code === field.value.code)
				) {
					const details = await fetchItemDetails(field.value.code);
					if (details) {
						setCachedOptions([details]);
						// Update the field value with complete data
						field.onChange(details);
					}
				} else if (
					field.value.display &&
					!cachedOptions.some((cached) => cached.code === field.value.code)
				) {
					setCachedOptions([field.value]);
				}
			}
		};

		updateCache();
	}, [
		field.value,
		multiple,
		reference,
		dataProvider,
		meta,
		cachedOptions,
		field,
	]);

	// Add a useEffect to fetch initial data when the component loads with prefilled values
	useEffect(() => {
		const fetchInitialData = async () => {
			// Only run this effect once when the component mounts with prefilled data
			if (field.value && cachedOptions.length === 0) {
				if (multiple && Array.isArray(field.value) && field.value.length > 0) {
					// For multiple selection, fetch all items that aren't in cache
					const itemsToFetch = field.value.filter(
						(item) =>
							item.code &&
							!cachedOptions.some((cached) => cached.code === item.code),
					);

					if (itemsToFetch.length > 0) {
						const fetchedItems = await Promise.all(
							itemsToFetch.map(async (item) => {
								try {
									const response = await dataProvider.getCustom(
										`${reference}/${item.code}`,
										{
											meta: { ...meta },
										},
									);
									return response.data;
								} catch (error) {
									console.error(
										`Error fetching details for code ${item.code}:`,
										error,
									);
									return item; // Return original item if fetch fails
								}
							}),
						);

						const validItems = fetchedItems.filter(Boolean);
						setCachedOptions((prev) => [...prev, ...validItems]);
					}
				} else if (!multiple && field.value?.code) {
					// For single selection
					try {
						const response = await dataProvider.getCustom(
							`${reference}/${field.value.code}`,
							{
								meta: { ...meta },
							},
						);
						setCachedOptions((prev) => [...prev, response.data]);
						// Optionally update the field value with complete data
						field.onChange(response.data);
					} catch (error) {
						console.error(
							`Error fetching details for code ${field.value.code}:`,
							error,
						);
						setCachedOptions((prev) => [...prev, field.value]); // Use what we have if fetch fails
					}
				}
			}
		};

		fetchInitialData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // <-- Run only once on mount, not when field.value changes

	const {
		data: searchResults,
		isLoading,
		error: fetchError,
	} = useQuery({
		queryKey: ["snomed", reference, search],
		queryFn: () =>
			dataProvider
				.getCustom(`${reference}/search`, {
					meta: { query: search, ...meta, limit: 25 },
				})
				.then((res) => res.data),
		enabled: !!search && typeof search === "string" && search.length >= 3,
	});

	// Combine search results with cached options
	const data = React.useMemo(() => {
		if (!searchResults) return cachedOptions;

		const combinedResults = [...searchResults];

		// Add cached options that aren't in the search results
		for (const cached of cachedOptions) {
			if (!combinedResults.some((result) => result.code === cached.code)) {
				combinedResults.push(cached);
			}
		}

		return combinedResults;
	}, [searchResults, cachedOptions]);

	const searchMedications = (searchText: string) => {
		if (searchText && searchText.length >= 3) {
			setSearch(searchText);
		} else if (searchText.length === 0) {
			setSearch(""); // Clear search when input is empty
		}
	};

	const handleSelect = (option: any) => {
		if (multiple) {
			const currentValue = field.value || [];
			const isSelected = currentValue.some(
				(item: any) => item.code === option.code,
			);

			if (isSelected) {
				// Remove the option if already selected
				field.onChange(
					currentValue.filter((item: any) => item.code !== option.code),
				);
			} else {
				// Add the option if not already selected
				field.onChange([...currentValue, option]);
			}
		} else {
			// Single selection behavior
			field.onChange(option);
			setOpen(false);
		}
	};

	const isOptionSelected = (option: any) => {
		if (!field.value) return false;

		if (multiple) {
			return (
				Array.isArray(field.value) &&
				field.value.some((item: any) => item.code === option.code)
			);
		}

		return field.value.display === option.display;
	};

	const getDisplayValue = () => {
		if (!field.value) return `Select ${label}...`;

		if (multiple && Array.isArray(field.value)) {
			if (field.value.length === 0) return `Select ${label}...`;

			// Find display values from cached options when possible
			const displayValues = field.value.map((item) => {
				if (item.display) return item.display;
				const cached = cachedOptions.find(
					(cached) => cached.code === item.code,
				);
				return cached ? cached.display : item.code;
			});

			if (displayValues.length === 1) return displayValues[0];
			if (displayValues.length <= 3) return displayValues.join(", ");
			return `${displayValues.length} items selected`;
		}

		// For single selection
		if (field.value.display) return field.value.display;
		const cached = cachedOptions.find(
			(cached) => cached.code === field.value.code,
		);
		return cached ? cached.display : field.value.code;
	};

	const renderHelperText =
		!!fetchError ||
		helperText !== false ||
		((isTouched || isSubmitted) && invalid);

	return (
		<div className={cn(inputBaseClassname, className)}>
			<FormLabel>
				<FieldTitle label={label} source={source} isRequired={isRequired} />
			</FormLabel>
			<Popover open={open} onOpenChange={setOpen} modal={modal}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className="w-full justify-between h-10"
					>
						{getDisplayValue()}
						<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[300px] p-0">
					<Command shouldFilter={false}>
						<CommandInput
							placeholder="Search ..."
							onValueChange={searchMedications}
						/>
						<CommandList className="max-h-[200px]">
							{isLoading ? (
								<CommandItem className="py-6 text-center">
									<Skeleton className="h-4 w-full" />
								</CommandItem>
							) : data?.length === 0 ? (
								<CommandEmpty>
									{search.length < 3
										? "Type at least 3 characters to search"
										: "No items found"}
								</CommandEmpty>
							) : (
								data?.map((option) => (
									<CommandItem
										key={option.code}
										onSelect={() => handleSelect(option)}
									>
										<Check
											className={cn(
												"mr-2 h-4 w-4",
												isOptionSelected(option) ? "opacity-100" : "opacity-0",
											)}
										/>
										{option.code} - {option.display}
									</CommandItem>
								))
							)}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			{renderHelperText && (
				<InputMessage
					touched={isTouched || isSubmitted || !!fetchError}
					error={error?.message || fetchError?.message}
					helperText={helperText}
				/>
			)}
		</div>
	);
};
