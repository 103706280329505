import "./sentry";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { pdfjs } from "react-pdf";

declare global {
	interface Window {
		config: any;
		Featurebase: any;
	}
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const config = window.config; // ok now

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

reportWebVitals();

//Print app version
console.log(
	"Vironix Front",
	import.meta.env.PACKAGE_VERSION,
	import.meta.env.VITE_GIT_SHA,
);
