import { humanize } from 'inflection';
import * as React from 'react';
import {
  useGetOne,
  usePermissions,
  useCreate,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle } from '../../ui/components/alert';
import { get } from 'lodash';
import { Button } from '@/modules/ui/components/button';

export const CanBeActiveReasons: React.FC = () => {
  const { permissions } = usePermissions();
  const { id } = useParams();
  const { data, isLoading } = useGetOne('patients', {
    id: `${id}/consented-to-active-progress`,
  });

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleRecheck = () => {
    create(
      `patients/${id}/check-consented-to-active-status`,
      {
        data: {},
      },
      {
        onSuccess: () => {
          notify('Rechecking completed');
          refresh();
        },
      },
    );
  };

  if (isLoading) {
    return null;
  }

  if (!data?.missing_items?.length) {
    if (get(permissions, 'patients.check_consented_to_active_status')) {
      return (
        <div className="flex flex-col gap-2 mb-4">
          <Alert
            variant="default"
            className="mb-0 bg-green-100 border-green-500"
          >
            <AlertTitle>No missing items but patient is not active</AlertTitle>
            <div className="flex flex-row gap-2 justify-center">
              <Button variant="default" onClick={handleRecheck}>
                Recheck
              </Button>
            </div>
          </Alert>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="flex flex-col gap-2 mb-4">
      {data.is_recently_consented_and_claimable && (
        <Alert variant="default" className="mb-0 bg-green-100 border-green-500">
          <AlertTitle>Enrollment Claimable</AlertTitle>
          <p className="text-sm">
            This patient can be claimed by any clinician who completes these
            items.
          </p>
        </Alert>
      )}
      <Alert variant="warning">
        <AlertTitle>Progress to active status</AlertTitle>
        <div className="text-left mt-2">
          <p className="mb-2 text-sm">
            Once the following items are completed, the patient will be set to
            active automatically.
          </p>
          <ul className="list-disc pl-6">
            {data.missing_items.map((item, index) => (
              <li key={index} className="text-sm text-gray-600">
                {humanize(item)}
              </li>
            ))}
          </ul>
        </div>
      </Alert>
    </div>
  );
};
