import { DataTable } from "@/modules/data-table/components/data-table";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import DataTableProviderFilter from "@/modules/data-table/components/data-table-provider-filter";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import AppTitle from "@/modules/layout/components/app-title";
import ProviderField from "@/modules/ra-ui/components/ProviderField";
import { AddressField } from "@/modules/ra-ui/components/address-field";
import { Building } from "lucide-react";
import React, { type FC } from "react";
import { ListBase } from "react-admin";
import { useNavigate } from "react-router-dom";
import { LocationCreateButton } from "../../modules/locations/components/LocationCreateButton";
const getColumns = () => {
	return [
		{
			header: "Name",
			accessorKey: "name",
		},
		{
			header: "Address",
			accessorKey: "address",
			enableSorting: false,
			cell: () => <AddressField source="address" />,
		},
		{
			header: "Provider",
			accessorKey: "tenant_id",
			cell: () => <ProviderField source="tenant_id" label="Provider" />,
		},
	];
};

export const LocationList: FC = () => {
	const navigate = useNavigate();
	return (
		<>
			<AppTitle title="Locations" />
			<ListBase
				sort={{
					field: null,
					order: null,
				}}
			>
				<div className="flex flex-col gap-2 mx-2">
					<DataTable
						toolbar={
							<DataTableToolbar
								showViewOptions={false}
								createButton={<LocationCreateButton />}
								search={false}
							>
								<DataTableProviderFilter
									column="tenant_id[eq]"
									title="Provider"
								/>
							</DataTableToolbar>
						}
						columns={getColumns()}
						emptyState={
							<EmptyState
								title="No locations found"
								description="Locations are the physical locations where services are provided and devices stored."
								icons={[Building]}
							/>
						}
						onRowClick={(row) => {
							navigate(`/locations/${row.id}/show`);
						}}
					/>
				</div>
			</ListBase>
		</>
	);
};
