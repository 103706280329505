import React from 'react';
import { required } from 'ra-core';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import { useWatch } from 'react-hook-form';
import { cn } from '@/modules/ui/utils/cn';
interface TimeRangeOutOfReasonProps {
  source: string;
  minTime: number;
  maxTime: number;
  className?: string;
}

export function TimeRangeOutOfRangeReason({
  source,
  minTime,
  maxTime,
  className,
}: TimeRangeOutOfReasonProps) {
  const watchTimeTaken = useWatch({
    name: source,
  });

  // Calculate warning zones (30 seconds = 0.5 minutes)
  const warningBefore = Math.max(0, minTime - 30);
  const warningAfter = maxTime + 30;

  const isOutOfRange =
    watchTimeTaken > 0 &&
    (watchTimeTaken < warningBefore || watchTimeTaken > warningAfter);

  if (!isOutOfRange) {
    return null;
  }

  return (
    <div className={cn(className)}>
      <TextAreaInput
        label={`Out of range reason`}
        name={`${source}_out_of_range_reason`}
        placeholder="Please explain why the time is out of range..."
        rows={2}
        className={'text-sm'}
        validate={[required()]}
      />
    </div>
  );
}
