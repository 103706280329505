import { BulkMarkDeviceReceivedButton } from '@/modules/devices/components/BulkMarkDeviceReceivedButton';
import DeviceExternalIdField from '@/modules/devices/components/DeviceExternalIdField';
import AppTitle from '@/modules/layout/components/app-title';
import { AssignToProviderButton } from '@/modules/ra-ui/components/AssignToProviderButton';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import ProviderField from '@/modules/ra-ui/components/ProviderField';
import { AddressField } from '@/modules/ra-ui/components/address-field';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { ShowSimpleGrid } from '@/modules/ra-ui/components/show-simple-grid';
import { StatCardField } from '@/modules/ra-ui/components/stat-card-field';
import { Card, CardContent } from '@/modules/ui/components/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import { BarChartHorizontal, Box, Signal } from 'lucide-react';
import React, { FC } from 'react';
import {
  Datagrid,
  DeleteButton,
  ListContextProvider,
  Pagination,
  RecordContextProvider,
  ShowBase,
  TextField,
  useGetList,
  useGetOne,
  useList,
  useRecordContext,
} from 'react-admin';
import { EditLocationButton } from '../../modules/locations/components/EditLocationButton';
import { LocationDetailDeviceCountTable } from '../../modules/locations/components/LocationDetailDeviceCountTable';

const LocationDetails = () => {
  const record = useRecordContext();

  const { data } = useGetOne('locations', {
    id: `${record?.id}/details`,
  });

  return (
    <div className="">
      <RecordContextProvider value={data}>
        <div className="flex flex-shrink flex-row gap-1 mb-2">
          <StatCardField source="total_devices" label="Total Devices" />
          <StatCardField
            source="total_inactive_devices"
            label="Total Inactive Devices"
          />
          <StatCardField
            source="pending_device_count"
            label="Pending Devices"
          />
        </div>

        <div className="w-1/2">
          <LocationDetailDeviceCountTable />
        </div>
      </RecordContextProvider>
    </div>
  );
};

const LocationShowToolbar = () => {
  return (
    <div className="flex flex-row flex-wrap justify-end gap-2 items-center my-2">
      <EditLocationButton />
      <AssignToProviderButton resource="locations" />
      <DeleteButton redirect={false} />
    </div>
  );
};

const LocationDevicesListDataGrid: React.FC<{
  bulkActionButtons?: any;
  status?: string | null;
  title?: string;
  description?: string | null;
}> = ({
  bulkActionButtons = false,
  status = null,
  title = 'Devices',
  description = null,
}) => {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList(
    'devices',
    {
      pagination: { page, perPage },
      filter: {
        'location_id[eq]': record?.id,
        ...(status ? { 'status[eq]': status } : {}),
      },
      sort,
    },
    { enabled: !!record },
  );
  const listContext = useList({ data, isLoading, resource: 'devices' });

  if (!record) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col items-start mb-2">
        <h3 className="text-lg font-semibold mb-1">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <ListContextProvider
        value={
          { ...listContext, page, perPage, setPage, setPerPage, total } as any
        }
      >
        <Datagrid
          data={data}
          total={total}
          isLoading={isLoading}
          sort={sort}
          bulkActionButtons={bulkActionButtons}
          setSort={setSort}
          empty={
            <EmptyDatagrid
              resourceName="Devices"
              textFormatString="No devices found for this location."
            />
          }
          rowClick={(_id, _basePath, record) => `/devices/${record.id}/show`}
        >
          <BadgeField source="device_type" />
          <TextField source="device_manufacturer" />
          <TextField source="device_model" />
          <DeviceExternalIdField source="external_id" label="IMEI" />

          <BadgeField source="status" />
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    </div>
  );
};

const InternalFacilityBanner = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  if (record.tenant_id) {
    return null;
  }
  return (
    <div
      className="
      bg-yellow-100
      rounded-md
      m-2
      p-1
      px-4
      text-center
      w-fit
      mx-auto
      text-md
      "
    >
      This is an internal Vironix location.
    </div>
  );
};

export const LocationShow: FC = (props) => {
  return (
    <>
      <AppTitle title="Location" onlyHeader />
      <ShowBase {...props}>
        <div className="flex flex-col gap-2 mb-4">
          <LocationShowToolbar />
          <Card className="mb-4 max-h-[40vh]">
            <CardContent>
              <div className="flex flex-row gap-2 py-2">
                <ShowSimpleGrid>
                  <TextField source="name" />
                  <TextField source="description" />
                  <AddressField source="address" />
                  <ProviderField
                    label="Provider"
                    emptyText={<InternalFacilityBanner />}
                  />
                  <LuxonDateField showTime source="created_at" />
                </ShowSimpleGrid>
              </div>
            </CardContent>
          </Card>

          <Tabs defaultValue="summary">
            <TabsList className="flex h-12">
              <TabsTrigger asChild value="summary" className="grow text-sm">
                <div>
                  <BarChartHorizontal className="opacity-70 mr-1" />
                  Summary
                </div>
              </TabsTrigger>
              <TabsTrigger asChild value="devices" className="grow text-sm">
                <div>
                  <Signal className="opacity-70 mr-1" />
                  All Devices
                </div>
              </TabsTrigger>
              <TabsTrigger asChild value="orders" className="grow text-sm">
                <div>
                  <Box className="opacity-70 mr-1" />
                  Pending Devices
                </div>
              </TabsTrigger>
            </TabsList>
            <TabsContent className="px-1" value="summary">
              <LocationDetails />
            </TabsContent>
            <TabsContent className="px-1" value="devices">
              <LocationDevicesListDataGrid />
            </TabsContent>
            <TabsContent className="px-1" value="orders">
              <LocationDevicesListDataGrid
                status="pending"
                title="Pending devices"
                description="The following devices are ordered for this location but have not yet been marked as received."
                bulkActionButtons={
                  <>
                    <BulkMarkDeviceReceivedButton resource="devices" />
                  </>
                }
              />
            </TabsContent>
          </Tabs>
        </div>
      </ShowBase>
    </>
  );
};
