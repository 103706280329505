import type { FC } from "react";
import { ListBase, useStore } from "react-admin";
import { useNavigate } from "react-router-dom";

import { DataTableFilter } from "@/modules/data-table/components/data-table-filter";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import { primaryConditions } from "@/modules/patients/constants/primaryConditions";
import { LuxonDateField } from "@/modules/ra-ui/components/LuxonDateField";

import { DataTable } from "@/modules/data-table/components/data-table";
import { DataTableDaysFilter } from "@/modules/data-table/components/data-table-days-filter";
import { DataTableFilterButton } from "@/modules/data-table/components/data-table-filter-button";
import { DataTableFilterSingle } from "@/modules/data-table/components/data-table-filter-single";
import { MultiSearch } from "@/modules/data-table/components/data-table-multi-search";
import DataTableProviderFilter from "@/modules/data-table/components/data-table-provider-filter";
import DataTableReferenceFilter from "@/modules/data-table/components/data-table-reference-filter";
import { ListUIRoot } from "@/modules/data-table/components/list-ui-root";
import AppTitle from "@/modules/layout/components/app-title";
import { PatientQueueEmpty } from "@/modules/patient-queue/components/PatientQueueEmpty";
import { patientPriorityLevel } from "@/modules/patients/constants/patientPriorityLevel";
import { patientReviewStatus } from "@/modules/patients/constants/patientReviewStatus";
import ProviderField from "@/modules/ra-ui/components/ProviderField";
import BadgeField from "@/modules/ra-ui/components/badge-field";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/modules/ui/components/select";
import { Separator } from "@/modules/ui/components/separator";
import { Toggle } from "@/modules/ui/components/toggle";
import clsx from "clsx";
import { Clipboard, ClipboardCheck } from "lucide-react";
import { LatestMeasurementField } from "../../modules/patient-queue/components/LatestMeasurementField";
import { NameField } from "../../modules/patients/components/NameField";

const Toolbar = ({ showViewOptions = true, view, storeKey }) => (
	<DataTableToolbar
		showViewOptions={showViewOptions}
		search={
			<MultiSearch
				searchFields={[
					{ value: "user.full_name", label: "Name" },
					{ value: "id", label: "ID" },
				]}
			/>
		}
		preferenceKey={storeKey}
	>
		<DataTableFilterButton>
			<DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
			{view === "general" ? null : (
				<DataTableFilter
					column={`${view}_review_status[in]`}
					title="Review Status"
					options={[
						{ label: "Pending", value: "pending", icon: Clipboard },
						{
							label: "Reviewed",
							value: "reviewed",
							icon: ClipboardCheck,
						},
					]}
				/>
			)}
			<DataTableFilter
				column="care_setting[in]"
				title="Care Setting"
				options={[
					{ label: "Home", value: "home" },
					{
						label: "Residential Care Facility",
						value: "residential_care_facility",
					},
				]}
			/>
			<DataTableFilter
				column="primary_condition[in]"
				title="Primary Condition"
				options={[
					{ label: "Asthma", value: "Asthma" },
					{ label: "COPD", value: "COPD" },
					{ label: "Diabetes", value: "Diabetes" },
					{ label: "General", value: "general" },
					{ label: "Heart Failure", value: "HeartFailure" },
					{ label: "Chronic Kidney Disease", value: "chronic_kidney_disease" },
				]}
			/>
			<DataTableDaysFilter
				column="last_device_activity[lte]"
				title="Days since Measurement"
			/>
			<DataTableFilterSingle
				column="has_device[eq]"
				title="Has Devices"
				options={[
					{ label: "Yes", value: true },
					{ label: "No", value: false },
				]}
			/>
			<DataTableReferenceFilter
				resource="users"
				column="care_team_members.practitioner_id[eq]"
				title="Care Team Member"
				optionText="full_name"
				customFilter={(name) => ({
					...(name &&
						name.length > 1 && {
							q: { field: "full_name", value: name },
						}),
					"role[ne]": `patient`,
					"is_provider[eq]": true,
				})}
			/>
		</DataTableFilterButton>
	</DataTableToolbar>
);

const PatientQueueTable = () => {
	const [underCare, setUnderCare] = useStore(
		"patient-queue.list.under_care",
		true,
	);
	const [view, setView] = useStore("patient-queue.list.viewType", "general");

	const columns = [
		{
			header: "Priority",
			accessorKey: "priority",
			cell: () => (
				<BadgeField
					source="priority"
					mapping={patientPriorityLevel}
					humanize={false}
				/>
			),
		},
		{
			header: "Patient",
			id: "user.last_name",
			accessorKey: "user.last_name",
			cell: () => <NameField />,
		},
		{
			header: "Latest measurement",
			accessorKey: "last_device_activity",
			cell: () => (
				<LuxonDateField source="last_device_activity" showTime={true} />
			),
		},
		...(view === "general"
			? []
			: [
					{
						header: "Last Reviewed",
						accessorKey: `${view}_last_reviewed`,
						cell: () => (
							<LuxonDateField
								source={`${view}_last_reviewed`}
								showTime={true}
								emptyText="Never"
							/>
						),
					},
				]),
		{
			header: "Heart rate (bpm)",
			accessorKey: "heart_rate",
			enableSorting: false,
			cell: () => <LatestMeasurementField source="heart_rate" />,
		},
		{
			header: "Blood pressure (mmHg)",
			accessorKey: "blood_pressure",
			enableSorting: false,
			cell: () => <LatestMeasurementField source="blood_pressure" />,
		},
		{
			header: "Weight (lb)",
			accessorKey: "body_mass",
			enableSorting: false,
			cell: () => <LatestMeasurementField source="body_mass" />,
		},
		{
			header: "Oxygen saturation (%)",
			accessorKey: "oxygen_saturation",
			enableSorting: false,
			cell: () => <LatestMeasurementField source="oxygen_saturation" />,
		},
		{
			header: "Blood glucose (mg/dL)",
			accessorKey: "blood_glucose",
			enableSorting: false,
			cell: () => <LatestMeasurementField source="blood_glucose" />,
		},
		{
			header: "Peak expiratory flow rate (L/min)",
			accessorKey: "peak_expiratory_flow_rate",
			enableSorting: false,
			cell: () => <LatestMeasurementField source="peak_expiratory_flow_rate" />,
		},
		{
			header: "FEV1 (L)",
			accessorKey: "forced_expiratory_volume_1",
			enableSorting: false,
			cell: () => (
				<LatestMeasurementField source="forced_expiratory_volume_1" />
			),
		},
		{
			header: "Primary Condition",
			accessorKey: "primary_condition",
			cell: () => (
				<BadgeField
					source="primary_condition"
					mapping={primaryConditions}
					applyMappedClassName={false}
					humanize={false}
				/>
			),
		},
		{
			header: "Provider",
			id: "user.tenant_id",
			accessorKey: "user.tenant_id",
			enableSorting: false,
			cell: () => <ProviderField source="user.tenant_id" sortBy={null} />,
		},
		...(view === "general"
			? []
			: [
					{
						header: "Review Status",
						accessorKey: `${view}_review_status`,
						cell: () => (
							<BadgeField
								source={`${view}_review_status`}
								mapping={patientReviewStatus}
							/>
						),
					},
				]),
	];

	const navigate = useNavigate();

	const storeKey = "patient-queue." + view;

	return (
		<ListUIRoot>
			<AppTitle title="Patient Queue" />
			<div className="flex items-center gap-2">
				<Toggle
					variant="outline"
					aria-label="Toggle under care"
					onClick={() => setUnderCare(!underCare)}
					size="lg"
					className={clsx(
						"h-8 bg-white",
						underCare ? "border-primary" : "bg-white",
					)}
					defaultChecked={underCare}
				>
					{underCare ? "Showing Patients in my Care" : "Showing all Patients"}
				</Toggle>
				<Separator orientation="vertical" className="h-8" />
				<Select value={view} onValueChange={setView}>
					<SelectTrigger className="w-fit h-8">
						<SelectValue placeholder="Select view" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="general">General</SelectItem>
						<SelectItem value="rpm">RPM</SelectItem>
						<SelectItem value="ccm">CCM</SelectItem>
						<SelectItem value="pcm">PCM</SelectItem>
					</SelectContent>
				</Select>
			</div>
			<ListBase
				resource="patient-queue"
				sort={{ field: "priority", order: "DESC" }}
				filter={{
					"patient_status[eq]": "active",
					...(view !== "general" && {
						[`has_${view}[eq]`]: true,
					}),
				}}
				queryOptions={{
					meta: {
						expand: ["profile", "observations"],
						under_care: underCare,
					},
				}}
			>
				<div className="w-max-content overflow-x-auto">
					<DataTable
						toolbar={<Toolbar view={view} storeKey={storeKey} />}
						columns={columns}
						onRowClick={(row) => {
							navigate(`/patients/${row.id}/show/clinical`);
						}}
						storeKey={storeKey}
						emptyState={<PatientQueueEmpty underCare={underCare} />}
					/>
				</div>
			</ListBase>
		</ListUIRoot>
	);
};

export const PatientQueue: FC = () => {
	return (
		<div className="pb-2">
			<PatientQueueTable />
		</div>
	);
};
