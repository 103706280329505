import { ICD10Chip } from '@/modules/icd10/components/ICD10Chip';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { get } from 'lodash';
import { useRecordContext, useGetList, ListContextProvider } from 'ra-core';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceField,
  TextField,
  BooleanField,
  Pagination,
} from 'react-admin';
import { serviceInstanceTypes } from '../constants/serviceInstanceTypes';
import { ServiceInstanceEditDialog } from './ServiceInstanceCreate';

export function ServiceInstanceDataGrid() {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList('service-instances', {
    pagination: { page, perPage },
    filter: { 'patient_id[eq]': record?.id },
    sort,
  });
  if (!record) {
    return null;
  }

  return (
    <>
      <Datagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        setSort={setSort}
        empty={
          <EmptyDatagrid
            resourceName="Care Programs"
            textFormatString="No Care Programs found for patient"
            description="Care programs are used to track progress and bill for services. If you intend to bill for services, please add the appropriate virtual care program."
          />
        }
        rowClick={false}
      >
        <FunctionField
          label="Care program type"
          render={(record) =>
            `${get(serviceInstanceTypes, record.type, record.type)}`
          }
        />{' '}
        <DateField source="start" />
        <ReferenceArrayField
          label="Diagnosis Codes"
          reference="icd10"
          source="icd10_ids"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ICD10Chip />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField source="practitioner_id" reference="users" link={false}>
          <TextField source="full_name" />
        </ReferenceField>
        <BooleanField source="is_active" label="Active" />
        <BooleanField
          source="is_labor_provided"
          label="Vironix Labor Provided"
        />
        <ServiceInstanceEditDialog />
      </Datagrid>
      {total > 10 && (
        <ListContextProvider
          value={{ page, perPage, setPage, setPerPage, total } as any}
        >
          <Pagination />
        </ListContextProvider>
      )}
    </>
  );
}
