import { Trash2 } from 'lucide-react';
import ConfirmActionButton from './confirm-action-button';
import { type ComponentProps } from 'react';
import { useDelete, useRecordContext } from 'ra-core';

type ConfirmDeleteButtonProps = Omit<
  ComponentProps<typeof ConfirmActionButton>,
  | 'dialogTitle'
  | 'dialogDescription'
  | 'confirmText'
  | 'cancelText'
  | 'action'
  | 'isLoading'
> & {
  dialogTitle?: string;
  dialogDescription?: string;
  confirmText?: string;
  cancelText?: string;
  resource: string;
};

const ConfirmDeleteButton = ({
  dialogTitle = 'Confirm Deletion',
  dialogDescription = 'Are you sure you want to delete this item? This action cannot be undone.',
  confirmText = 'Delete',
  cancelText = 'Cancel',
  Icon = Trash2,
  buttonVariant = 'destructive',
  resource,
  ...props
}: ConfirmDeleteButtonProps) => {
  const [deleteOne, { isLoading }] = useDelete();
  const record = useRecordContext();

  const handleDelete = () => {
    deleteOne(resource, {
      id: record?.id,
    });
  };

  if (!record) {
    return null;
  }

  return (
    <ConfirmActionButton
      dialogTitle={dialogTitle}
      dialogDescription={dialogDescription}
      confirmText={confirmText}
      cancelText={cancelText}
      Icon={Icon}
      buttonVariant={buttonVariant}
      action={handleDelete}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default ConfirmDeleteButton;
