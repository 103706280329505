import { Input } from "@/modules/ui/components/input";
import { useInput } from "ra-core";
import {
	FormItem,
	FormLabel,
	FormControl,
	FormDescription,
} from "@/modules/ui/components/form";
import { InputMessage } from "./input-message";
import { humanize } from "inflection";
import type React from "react";
import { forwardRef } from "react";
import { NumericFormat, type NumericFormatProps } from "react-number-format";

interface NumberInputProps
	extends Omit<NumericFormatProps, "value" | "onValueChange"> {
	className?: string;
	defaultValue?: number;
	label?: string | boolean;
	helperText?: string | React.ReactNode;
	resource?: string;
	source: string;
	validate?: any;
	decimalScale?: number;
	fixedDecimalScale?: boolean;
	thousandSeparator?: string;
	allowNegative?: boolean;
	prefix?: string;
	suffix?: string;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
	(props, ref) => {
		const {
			className,
			defaultValue,
			label,
			helperText,
			resource,
			source,
			validate,
			decimalScale = 0,
			fixedDecimalScale = false,
			thousandSeparator,
			allowNegative = true,
			prefix,
			suffix,
			...rest
		} = props;

		const {
			field: { onChange, value, ...fieldProps },
			fieldState: { error, isTouched },
			id,
			isRequired,
		} = useInput({
			defaultValue,
			parse: (value) => {
				if (value === null || value === undefined || value === "") return null;
				const parsed = Number(value);
				return Number.isNaN(parsed) ? null : parsed;
			},
			resource,
			source,
			validate,
			...rest,
		});

		const handleValueChange = (values: { floatValue: number | undefined }) => {
			onChange(values.floatValue ?? null);
		};

		let showLabel: string | boolean = label;
		if (label === undefined || label === null) {
			showLabel = humanize(source);
		}

		return (
			<FormItem id={id}>
				{showLabel && (
					<FormLabel>
						{showLabel}
						{isRequired && <span className="text-destructive">*</span>}
					</FormLabel>
				)}
				<FormControl>
					<NumericFormat
						{...fieldProps}
						value={value ?? ""}
						onValueChange={handleValueChange}
						className={className}
						getInputRef={ref}
						customInput={Input}
						decimalScale={decimalScale}
						fixedDecimalScale={fixedDecimalScale}
						thousandSeparator={thousandSeparator}
						allowNegative={allowNegative}
						prefix={prefix}
						suffix={suffix}
						valueIsNumericString
						{...rest}
					/>
				</FormControl>
				{helperText && <FormDescription>{helperText}</FormDescription>}
				<InputMessage touched={isTouched} error={error?.message} />
			</FormItem>
		);
	},
);

NumberInput.displayName = "NumberInput";
