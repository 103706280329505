import React, { createContext, useContext, ReactNode } from 'react';
import { useGetIdentity, useDataProvider } from 'react-admin';
import { useQuery } from '@tanstack/react-query';

interface AttendanceContextType {
  isCheckedIn: boolean;
  isLoading: boolean;
  attendanceData: any | null;
  refetch: () => void;
  attendanceEnforced: boolean;
}

const AttendanceContext = createContext<AttendanceContextType | undefined>(
  undefined,
);

export function AttendanceProvider({ children }: { children: ReactNode }) {
  const { identity } = useGetIdentity();
  const dataProvider = useDataProvider();

  const {
    data: attendanceData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['attendance-ongoing'],
    queryFn: () =>
      dataProvider
        .getCustom('attendances/ongoing', {})
        .then(({ data }) => data),
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!identity?.attendance_tracking_enabled && identity.is_internal,
  });

  const attendanceEnforced = identity?.attendance_tracking_enabled;

  const isCheckedIn = !!attendanceData?.id;

  const value = {
    isCheckedIn,
    attendanceEnforced,
    isLoading,
    attendanceData,
    refetch,
  };

  return (
    <AttendanceContext.Provider value={value}>
      {children}
    </AttendanceContext.Provider>
  );
}

export function useAttendance() {
  const context = useContext(AttendanceContext);
  if (context === undefined) {
    throw new Error('useAttendance must be used within an AttendanceProvider');
  }
  return context;
}
