import React, { useRef, useState } from "react";
import { PatientOverview } from "./PatientOverview";
import { Separator } from "@/modules/ui/components/separator";
import { PatientAside } from "./PatientAside";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/modules/ui/components/ui/resizable";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { useSidebar } from "@/modules/ui/components/sidebar";
import { cn } from "@/modules/ui/utils/cn";
import { PatientClinicalActivityProvider } from "@/modules/clinical-activities/context/PatientClinicalActivityContext";
export function PatientLayout(props) {
	const { children } = props;
	const [isCollapsed, setIsCollapsed] = useState(false);
	const panelRef = useRef<ImperativePanelHandle>(null);
	const { open } = useSidebar();

	return (
		<div className="flex flex-col items-center justify-center  h-screen ">
			<PatientClinicalActivityProvider>
				<ResizablePanelGroup
					direction="horizontal"
					className={cn(
						"flex flex-1",
						open ? "max-w-open-drawer" : "max-w-closed-drawer",
					)}
					autoSaveId="patient-layout"
				>
					<ResizablePanel defaultSize={75} minSize={60}>
						<div className="w-full p-3 xl:p-4 2xl:p-8 overflow-y-auto  h-full ">
							<div className="w-full xl:max-w-screen-xl mx-auto overflow-x-auto ">
								<PatientOverview />
								<Separator className="mb-2" />
								{children}
							</div>
						</div>
					</ResizablePanel>
					<ResizableHandle withHandle />
					<ResizablePanel
						id="patient-aside"
						ref={panelRef}
						collapsible
						onCollapse={() => setIsCollapsed(true)}
						onExpand={() => setIsCollapsed(false)}
						defaultSize={25}
						minSize={20}
						className="flex flex-col overflow-x-hidden overflow-y-hidden  w-1/6 lg:w-3/12 xl:w-[20%] bg-white border-l border-l-gray-200 min-h-screen p-1 "
					>
						<PatientAside isCollapsed={isCollapsed} panelRef={panelRef} />
					</ResizablePanel>
				</ResizablePanelGroup>
			</PatientClinicalActivityProvider>
		</div>
	);
}
