import { EditClaimItemButton } from '@/modules/claims/components/EditClaimItem';
import { CPTCodeField } from '@/modules/ra-ui/components/CPTCodeField';
import { useGetList, ListContextProvider, useRecordContext } from 'ra-core';
import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  BooleanField,
  Pagination,
} from 'react-admin';

export function ClaimItemsListDataGrid() {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList('claim-items', {
    pagination: { page, perPage },
    filter: { 'patient_id[eq]': record?.id },
    sort,
  });

  if (!record) {
    return null;
  }

  return (
    <>
      <ListContextProvider
        value={
          {
            page,
            perPage,
            setPage,
            setPerPage,
            total,
            data,
            isLoading,
            sort,
            setSort,
          } as any
        }
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <DateField source="date_of_service" />
          <CPTCodeField label="CPT Code" source="cpt_code" />
          <NumberField source="units" label="Units" />
          <BooleanField source="submitted" label="Submitted" />
          <EditClaimItemButton />
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    </>
  );
}
