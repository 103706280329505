import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Download, ArrowLeft } from 'lucide-react';
import { Button } from '@/modules/ui/components/button';

interface PDFState {
  url: string;
  title?: string;
}

export default function PDFViewer() {
  const location = useLocation();
  const navigate = useNavigate();
  const pdfState = location.state as PDFState;

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setError(null);
  };

  const onDocumentLoadError = (error: Error) => {
    setError('Failed to load PDF. The file might be missing or inaccessible.');
    console.error('PDF load error:', error);
  };

  const goToPreviousPage = () => {
    setPageNumber((prev) => Math.max(1, prev - 1));
  };

  const goToNextPage = () => {
    setPageNumber((prev) => Math.min(numPages, prev + 1));
  };

  if (!pdfState?.url) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-destructive/15 text-destructive px-4 py-2 rounded-md">
          No PDF URL provided
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center min-h-screen p-4 bg-gray-100">
      <div className="w-full max-w-5xl mb-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => navigate(-1)}
          className="flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Back
        </Button>
      </div>

      {pdfState.title && (
        <h1 className="text-2xl font-semibold mb-4">{pdfState.title}</h1>
      )}

      {error ? (
        <div className="mt-8 bg-destructive/15 text-destructive px-4 py-2 rounded-md">
          {error}
        </div>
      ) : (
        <>
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <Document
              file={pdfState.url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              loading={
                <div className="flex items-center justify-center p-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
                </div>
              }
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className="max-w-full"
              />
            </Document>
          </div>

          {numPages > 0 && (
            <div className="flex items-center gap-4 mt-4">
              <Button
                variant="outline"
                size="icon"
                onClick={goToPreviousPage}
                disabled={pageNumber <= 1}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>

              <span className="text-sm text-muted-foreground">
                Page {pageNumber} of {numPages}
              </span>

              <Button
                variant="outline"
                size="icon"
                onClick={goToNextPage}
                disabled={pageNumber >= numPages}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>

              <Button
                variant="outline"
                size="icon"
                onClick={() => window.open(pdfState.url, '_blank')}
                title="Download PDF"
              >
                <Download className="h-4 w-4" />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
