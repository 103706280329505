import { useDataProvider, useRecordContext } from 'react-admin';
import { useQuery } from '@tanstack/react-query';

export function useAISummaries() {
  const patientRecord = useRecordContext();
  const dataProvider = useDataProvider();

  const query = useQuery({
    queryKey: ['llm-summaries', patientRecord?.id],
    queryFn: () =>
      dataProvider
        .getCustom('foundation-model-responses/summary-enabled', {
          meta: {
            patient_id: patientRecord?.id,
          },
        })
        .then((data) => data.data),
    enabled: !!patientRecord,
  });

  return query;
}
