import { TextInput } from "@/modules/ui/inputs/text-input";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import DateTimeProviderTZInput from "@/modules/ui/inputs/date-time-provider-tz-input";
import EditInDialogButton from "@/modules/ra-ui/components/edit-dialog-button";
import { get } from "lodash";
import { useMemo } from "react";
import { useRecordContext } from "react-admin";
import { SwitchInput } from "@/modules/ui/inputs/switch-input";
import { filterLocationName } from "@/utils/filterLocationName";

const filterUserName = (searchText) => ({
	q: { field: "full_name", value: searchText },
});

export const EnrollmentEditButton = () => {
	const record = useRecordContext();

	const filters = useMemo(
		() => ({
			"role[ne]": `patient`,
			"tenant_id[in]": [get(record, "user.tenant_id"), null],
		}),
		[record],
	);

	if (!record) {
		return null;
	}
	return (
		<EditInDialogButton
			title="Edit Enrollment Information"
			resource="patients"
			variant="outline"
			refreshOnSuccess={true}
			stopPropagation={true}
			dialogVariant="shadcn"
			closeOnClickOutside={false}
			dialogContentClassName="lg:min-w-[600px]"
		>
			<div className="flex flex-col gap-2">
				<div className="mb-2 text-lg">{record.user?.full_name}</div>
				<TextInput
					source="eligible_devices"
					label="Eligible Devices"
					helperText={false}
				/>
				<TextInput source="insurances" label="Insurances" helperText={false} />
				<SelectInput
					source="insurance_coverage_type"
					label="Insurance Coverage Type"
					choices={[
						{ id: "partial", name: "Partial" },
						{ id: "full", name: "Full" },
						{ id: "other", name: "Other" },
					]}
					helperText={false}
				/>
				<DateTimeProviderTZInput source="onboarding_appointment_datetime" />
				<AutocompleteInput
					label="Appointment Location"
					source="onboarding_appointment_location_id"
					reference="locations"
					optionText="name"
					filter={{
						"tenant_id[eq]": record?.user?.tenant_id,
					}}
					helperText={false}
					filterToQuery={filterLocationName}
				/>
				<TextInput
					source="expected_care_program"
					label="Expected Care Program"
					helperText={false}
				/>
				<AutocompleteInput
					label="Enrollment Clinician"
					source="expected_onboarding_user_id"
					reference="users"
					filter={filters}
					optionText={(choice) => {
						if (choice.is_internal) {
							return `${choice.first_name} ${choice.last_name} (Vironix) ${
								choice.credentials ? `(${choice.credentials})` : ``
							}`;
						}
						return `${choice.full_name} ${
							choice.credentials ? `(${choice.credentials})` : ``
						}`;
					}}
					helperText={false}
					filterToQuery={filterUserName}
				/>
				<SwitchInput
					source="virtual_enrollment"
					label="Virtual Enrollment"
					helperText={false}
				/>
			</div>
		</EditInDialogButton>
	);
};
