import { Button } from '@/modules/ui/components/button';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import {
  FormDataConsumer,
  required,
  useCreate,
  useGetList,
  useNotify,
  useRecordContext,
} from 'react-admin';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog';

import { deviceTypes } from '@/modules/devices/constants/deviceTypes';
import { serviceInstanceTypes } from '@/modules/patients/constants/serviceInstanceTypes';
import { Badge } from '@/modules/ui/components/badge';
import { Separator } from '@/modules/ui/components/separator';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { titleize } from 'inflection';
import { get } from 'lodash';
import { useFeatureFlag } from '@/modules/feature-flags/hooks/useFeatureFlag';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { FormProvider, useForm } from 'react-hook-form';

export function ESignButton({
  label = 'eSign',
  variant = 'default',
}: { label?: string; variant?: 'default' | 'outline' }) {
  /** Handle new esign requests here */
  const record = useRecordContext();
  const notify = useNotify();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { isEnabled: isESignEnabled } = useFeatureFlag('esign');
  const [create, { isLoading }] = useCreate();

  const { data: devices, isLoading: isLoadingDevices } = useGetList(
    'devices',
    {
      pagination: { page: 1, perPage: 10 },
      filter: {
        'patient_id[eq]': record.id,
        'status[eq]': 'active',
      },
      sort: { field: 'requested_on', order: 'DESC' },
    },
    {
      enabled: !!record?.id && confirmDialogOpen && isESignEnabled,
    },
  );

  const {
    data: emergencyContacts,
    isLoading: isLoadingEmergencyContacts,
    total: totalEmergencyContacts,
  } = useGetList(
    'contacts',
    {
      pagination: { page: 1, perPage: 10 },
      filter: {
        'patient_id[eq]': record.id,
        'is_emergency_contact[eq]': true,
      },
    },
    { enabled: !!record?.id && confirmDialogOpen && isESignEnabled },
  );

  const validEmergencyContacts = emergencyContacts?.filter((contact) => {
    return contact.cell_phone_number || contact.home_phone_number;
  });

  // Update the condition to check for valid contacts
  const hasValidEmergencyContact =
    validEmergencyContacts && validEmergencyContacts.length > 0;

  const form = useForm();

  if (!isESignEnabled) {
    return null;
  }

  const fullName = record?.user
    ? `${record.user.first_name || ''} ${record.user.last_name || ''}`.trim()
    : 'Unknown';

  const serviceInstances = get(record, 'service_instances', [])
    .filter((instance) => instance.is_active)
    .map((instance) => serviceInstanceTypes[instance.type] || instance.type);

  /**
   * Opens the confirmation dialog with a message describing the request.
   */
  const openConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleSubmit = (data: any) => {
    if (!data.email_override && !hasEmail) {
      notify(
        'Please make sure the patient has an email address in their profile.',
        { type: 'warning' },
      );
      return;
    }

    create(
      'esign-request',
      {
        data: {
          patient_id: record.id,
          document_type: 'consent_form',
          ...data,
        },
      },
      {
        onSuccess(response) {
          if (response?.slug) {
            if (data.request_type === 'new_window') {
              window.open(`https://docuseal.com/s/${response.slug}`, '_blank');
              notify('E-sign session opened in a new window.', {
                type: 'success',
              });
            } else {
              notify('DocuSeal email has been sent successfully.', {
                type: 'success',
              });
            }
          } else {
            notify('No slug returned for eSign session.', { type: 'warning' });
          }
        },
        onError(error: any) {
          notify(`Error sending eSign request. ${error?.message || ''}`, {
            type: 'error',
          });
        },
      },
    );
  };

  const handleConfirmCancel = () => {
    setConfirmDialogOpen(false);
  };

  const hasEmail = get(record, 'user.email');

  const isSubmitDisabled =
    isLoading ||
    isLoadingDevices ||
    isLoadingEmergencyContacts ||
    !hasValidEmergencyContact;

  return (
    <>
      <Dialog open={confirmDialogOpen} onOpenChange={setConfirmDialogOpen}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Confirm eSign Request</DialogTitle>
              </DialogHeader>
              <p>Generating eSignature Consent Form for {fullName} </p>
              <p>The following care programs are active:</p>
              <div className="flex flex-row gap-2">
                {serviceInstances.map((instance) => (
                  <Badge variant="outline" key={instance}>
                    {instance}
                  </Badge>
                ))}
              </div>
              <p>The following medical devices are active:</p>
              <div className="flex flex-row gap-2">
                {isLoadingDevices && (
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                )}
                {!isLoadingDevices &&
                  devices?.map((device) => (
                    <Badge variant="outline" key={device.id}>
                      {`${titleize(device.device_manufacturer)} - ${
                        deviceTypes.find(
                          (type) => type.id === device.device_type,
                        )?.name || titleize(device.device_type)
                      }`}
                    </Badge>
                  ))}
              </div>
              {isLoadingEmergencyContacts && (
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              )}
              {!isLoadingEmergencyContacts && totalEmergencyContacts > 0 && (
                <>
                  <p>The patient has the following emergency contacts:</p>
                  <ul className="list-disc pl-6 space-y-1">
                    {emergencyContacts?.map((contact) => (
                      <li key={contact.id} className="text-sm">
                        {contact.full_name}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {!isLoadingEmergencyContacts && !hasValidEmergencyContact && (
                <Alert variant="warning">
                  <AlertTitle>No valid emergency contacts found</AlertTitle>
                  <AlertDescription>
                    Please add an emergency contact with a phone number to the
                    patient to continue.
                  </AlertDescription>
                </Alert>
              )}
              {!hasEmail && (
                <div>
                  <Alert variant="warning">
                    <AlertTitle>No email found</AlertTitle>
                    <AlertDescription>
                      Please add an email to the patient to continue.
                    </AlertDescription>
                  </Alert>
                  <SwitchInput
                    source="email_override"
                    label="I verify that the patient does not have an email."
                    helperText="Please check this only if the patient does not have an email. This action will logged."
                  />
                </div>
              )}
              <Separator className="my-2 w-full" />

              <div className="flex flex-col gap-2">
                <div className="space-y-2">
                  <h4 className="font-semibold text-lg">
                    How would you like the patient to sign?
                  </h4>
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">In Person</span>
                      <span className="text-sm text-muted-foreground">
                        - For patients present at the facility
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Email</span>
                      <span className="text-sm text-muted-foreground">
                        - For remote patient consent
                      </span>
                    </div>
                  </div>
                </div>
                <SelectInput
                  source="request_type"
                  choices={[
                    { id: 'new_window', name: 'In Person' },
                    { id: 'email', name: 'Email' },
                  ]}
                  defaultValue="new_window"
                />
                <SwitchInput source="use_guardian" label="Guardian as signer" />
                <FormDataConsumer>
                  {({ formData }) => {
                    if (formData.use_guardian) {
                      return (
                        <TextInput
                          source="guardian_email"
                          validate={[required()]}
                          label="Guardian Email"
                        />
                      );
                    }
                  }}
                </FormDataConsumer>
              </div>

              <DialogFooter className="mt-3">
                <Button
                  // type="submit"
                  disabled={isSubmitDisabled}
                  onClick={form.handleSubmit(handleSubmit)}
                >
                  Proceed
                </Button>
                <Button
                  onClick={handleConfirmCancel}
                  variant="destructive"
                  disabled={isLoading}
                  type="button"
                >
                  Cancel
                </Button>
              </DialogFooter>
            </DialogContent>
          </form>
        </FormProvider>
      </Dialog>
      <Button
        className="h-8"
        onClick={openConfirmDialog}
        disabled={isLoading}
        type="button"
        variant={variant}
      >
        {isLoading ? (
          <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
        ) : (
          label
        )}
      </Button>
    </>
  );
}
