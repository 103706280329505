import { get } from "lodash";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { QueryClient } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuditLogsList } from "./pages/AuditLogs";
import { AuthProvider } from "./utils/AuthProvider";
import DataProvider from "./utils/DataProvider";
import { DeviceList, DeviceShow, DeviceIssues } from "./pages/Devices";
import { Invitation } from "./pages/Invitation";
import { Layout } from "./modules/layout/components/Layout";
import { Login } from "./pages/Login";
import { PatientQueue } from "./pages/PatientQueue";

import { ReportList, ReportShow } from "@/pages/Reports";
import { SupportTicketList } from "@/pages/SupportTicket";
import { SupportTicketShow } from "@/pages/SupportTicket/SupportTicketShow";
import {
	OrganizationCreate,
	OrganizationShowEdit,
	TenantList,
} from "@/pages/Tenants";
import { ConfirmOTP, Testing } from "./pages/Testing";
import { theme } from "./Theme";
import { UserList } from "@/pages/Users";
import { InternalUserList } from "@/pages/Users/InternalUserList";
import { UserShowEdit } from "@/pages/Users/UserShowEdit";
import { i18nProvider } from "./utils/i18nProvider";
import { localStorageStore } from "./utils/localStorageStore";
import { LocationList, LocationShow } from "@/pages/Locations";
import { PatientList } from "./pages/Patients/PatientList";
import { PatientEdit } from "./pages/Patients/PatientEdit";
import { PatientCreate } from "./pages/Patients/PatientCreate";
import { PatientShow } from "./pages/Patients/PatientShow";
import { ClaimItemList } from "./pages/ClaimItem/ClaimItemList";
import { httpClient } from "./utils/httpClient";
import { RootLoadingPage } from "./modules/layout/components/RootLoading";
import { TaskList } from "./pages/Tasks/TaskList";
import { Home } from "./pages/Home/Home";
import { Account } from "@/pages/Account/Account";
import { DemoProvider } from "./modules/demo/components/DemoProvider";
import { EnrollmentList } from "./pages/Enrollment/EnrollmentList";
import { MonitoringProgress } from "./pages/MonitoringProgress/MonitoringProgress";
import PassKeyLogin from "./pages/Testing/PassKeyLogin";
import { VoiceCallsPage } from "./pages/Account/VoiceCalls";
import { EmailVerification } from "./pages/EmailVerification/EmailVerification";
import { BillingPage } from "./pages/Billing/BillingPage";
import { DownloadPage } from "./pages/Download/Download";
import { EnrolledPatientsPage } from "./pages/Account/EnrolledPatients";
import { AttendanceList } from "./pages/Attendance";
import { MyAttendance } from "./pages/Attendance/MyAttendance";
import { VoiceCallList } from "./pages/VoiceCalls";
import { ClinicalActivityBuilder } from "@/modules/clinical-activity-editor/components/ClinicalActivityBuilder";
import { ActivityTemplateForm } from "@/modules/clinical-activity-editor/components/ActivityTemplateForm";
import { StepTemplateForm } from "@/modules/clinical-activity-editor/components/StepTemplateForm";
import PDFViewer from "./pages/PDF/PDFViewer";
const apiUrl = window.config.apiUrl;

export const dataProvider = DataProvider(apiUrl, httpClient);

const authProvider = AuthProvider();
const queryClient = new QueryClient({
	defaultOptions: { queries: { staleTime: 10 * 1000 } },
});

function AdminBase() {
	return (
		<Admin
			dataProvider={dataProvider}
			authProvider={authProvider}
			i18nProvider={i18nProvider}
			theme={theme}
			layout={Layout}
			queryClient={queryClient}
			loginPage={Login}
			store={localStorageStore()}
			requireAuth
			loading={RootLoadingPage}
			dashboard={Home}
		>
			{(permissions) => [
				get(permissions, "patients") ? (
					<Resource
						name="patients"
						list={PatientList}
						show={PatientShow}
						edit={PatientEdit}
						create={PatientCreate}
					/>
				) : null,
				get(permissions, "patients.queue") ? (
					<Resource
						name="patient-queue"
						list={PatientQueue}
						options={{ label: "Patient Queue" }}
					/>
				) : null,
				get(permissions, "devices") ? (
					<Resource name="devices" list={DeviceList} show={DeviceShow} />
				) : null,
				get(permissions, "locations") ? (
					<Resource name="locations" list={LocationList} show={LocationShow} />
				) : null,
				get(permissions, "claim_items") ? (
					<Resource
						name="claim-items"
						list={ClaimItemList}
						options={{ label: "Claims" }}
					/>
				) : null,
				get(permissions, "monitoring_activity") ? (
					<Resource name="monitoring-activities" />
				) : null,
				get(permissions, "service_instances") ? (
					<Resource name="service-instances" />
				) : null,
				get(permissions, "tasks") ? (
					<Resource name="tasks" list={TaskList} />
				) : null,
				get(permissions, "task-activities") ? (
					<Resource name="task-activities" />
				) : null,
				get(permissions, "users.list") ? (
					<Resource
						name="users"
						list={get(permissions, "users.list") && UserList}
						edit={UserShowEdit}
					/>
				) : null,
				get(permissions, "support_tickets") ? (
					<Resource
						name="support-tickets"
						list={SupportTicketList}
						show={SupportTicketShow}
						options={{ label: "Support Tickets" }}
					/>
				) : null,
				get(permissions, "tenants.list") === "all" ? (
					<Resource
						name="providers"
						list={TenantList}
						edit={OrganizationShowEdit}
						create={OrganizationCreate}
					/>
				) : null,
				get(permissions, "reports.list") ? (
					<Resource name="reports" list={ReportList} show={ReportShow} />
				) : null,
				get(permissions, "audit_logs.list") ? (
					<Resource
						name="audit-logs"
						list={AuditLogsList}
						options={{ label: "Audit Logs" }}
					/>
				) : null,
				get(permissions, "voice_calls.list") ? (
					<Resource name="voice-calls" list={VoiceCallList} />
				) : null,
				get(permissions, "attendances.list") ? (
					<Resource name="attendances" list={AttendanceList} />
				) : null,
				<CustomRoutes>
					{get(permissions, "role") === "superadmin" &&
					window.config.env !== "production" ? (
						<Route path="/testing" element={<Testing />} />
					) : null}
					{get(permissions, "internal_users.update") ? (
						<Route path="/internal-users/:id/*" element={<UserShowEdit />} />
					) : null}
					{get(permissions, "internal_users.list") ? (
						<Route path="/internal-users" element={<InternalUserList />} />
					) : null}
					<Route path="/account" element={<Account />} />
					{get(permissions, "is_internal") ? (
						<Route
							path="/account/enrolled-patients"
							element={<EnrolledPatientsPage />}
						/>
					) : null}
					{get(permissions, "voice_calls.list") ? (
						<Route path="/account/voice-calls" element={<VoiceCallsPage />} />
					) : null}
					{get(permissions, "tenants.invoices") &&
					get(permissions, "is_internal") === false ? (
						<Route path="/billing" element={<BillingPage />} />
					) : null}
					<Route path="/enrollment" element={<EnrollmentList />} />
					<Route path="/monitoring" element={<MonitoringProgress />} />
					{get(permissions, "internal_users") &&
					get(permissions, "device_activities") ? (
						<Route path="/device-issues" element={<DeviceIssues />} />
					) : null}
					<Route path="/attendance/my" element={<MyAttendance />} />
					{get(permissions, "clinical_activity_templates.list") ? (
						<>
							<Route
								path="/clinical-activities"
								element={<ClinicalActivityBuilder />}
							/>
							<Route
								path="/clinical-activities/activity/create"
								element={<ActivityTemplateForm />}
							/>
							<Route
								path="/clinical-activities/activity/:id"
								element={<ActivityTemplateForm />}
							/>
							<Route
								path="/clinical-activities/step/create"
								element={<StepTemplateForm />}
							/>
							<Route
								path="/clinical-activities/step/:id"
								element={<StepTemplateForm />}
							/>
						</>
					) : null}
					<Route path="/pdf" element={<PDFViewer />} />
				</CustomRoutes>,
			]}
		</Admin>
	);
}

function App() {
	return (
		<DemoProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/*" element={<AdminBase />} />
					<Route path="/otp" element={<ConfirmOTP />} />
					<Route path="/mobile/invite" element={<Invitation />} />
					{window.config.env !== "production" ? (
						<Route path="/passkey" element={<PassKeyLogin />} />
					) : null}
					<Route path="/email-verification" element={<EmailVerification />} />
					<Route path="/download" element={<DownloadPage />} />
				</Routes>
			</BrowserRouter>
		</DemoProvider>
	);
}

export default App;
