import React from 'react';

import { useParams } from 'react-router-dom';
import ObservationsSection from './Observations';
import { MedicationsSection } from './MedicationsSection';
import EncountersSection from './Encounters';
import ConditionsSection from './Conditions';
import { SidebarNav } from '../../../ui/components/sidebar-nav';
import { ScrollArea, ScrollBar } from '@/modules/ui/components/scroll-area';
import { Card } from '@/modules/ui/components/card';
import AllergiesSection from '../../allergies/components/Allergies';
const sidebarNavItems = [
  {
    title: 'Observations',
    href: 'medical-history/observations',
  },
  {
    title: 'Medications',
    href: 'medical-history/medications',
  },
  {
    title: 'Encounters',
    href: 'medical-history/encounters',
  },
  {
    title: 'Conditions',
    href: 'medical-history/conditions',
  },
  {
    title: 'Allergies',
    href: 'medical-history/allergies',
  },
];

interface ClinicalLayoutProps {
  children: React.ReactNode;
}

export function ClinicalLayout({ children }: ClinicalLayoutProps) {
  return (
    <Card className="mb-4 sm:mb-6 md:mb-8">
      <div className="space-y-2 p-2 sm:p-3 md:p-4">
        <ScrollArea className="w-full whitespace-nowrap">
          <div className="flex">
            <SidebarNav items={sidebarNavItems} horizontal />
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <div>{children}</div>
      </div>
    </Card>
  );
}

export default function ClinicalSection() {
  const params = useParams<{ '*': string }>();

  const section = params['*'] || 'medical-history/observations';

  return (
    <ClinicalLayout>
      {section === 'medical-history/observations' && <ObservationsSection />}
      {section === 'medical-history/medications' && <MedicationsSection />}
      {section === 'medical-history/encounters' && <EncountersSection />}
      {section === 'medical-history/conditions' && <ConditionsSection />}
      {section === 'medical-history/allergies' && <AllergiesSection />}
    </ClinicalLayout>
  );
}
