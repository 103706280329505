import { ethnicity } from "@/modules/patients/constants/ethnicity";
import { patientConditions } from "@/modules/patients/constants/patientConditions";
import { patientRiskFactors } from "@/modules/patients/constants/patientRiskFactors";
import { race } from "@/modules/patients/constants/race";
import { smokingChoices } from "@/modules/patients/constants/smokingChoices";
import DateTimeProviderTZInput from "@/modules/ui/inputs/date-time-provider-tz-input";
import { AddressInput } from "@/modules/ra-ui/components/address-input";
import { get } from "lodash";
import { DateTime } from "luxon";
import {
	AutocompleteInput,
	BooleanInput,
	CheckboxGroupInput,
	DateInput,
	FormDataConsumer,
	NumberInput,
	RadioButtonGroupInput,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	TextInput,
	email,
	maxValue,
	minValue,
	required,
} from "react-admin";
import { FormCard } from "../../modules/ra-ui/components/FormCard";
import { PhoneNumberInput } from "../../modules/ra-ui/components/PhoneNumberInput";
import {
	HeightInput,
	WeightInput,
} from "../../modules/ra-ui/components/UnitInput";
import { phoneNumberTypes } from "@/modules/patients/constants/phoneNumberTypeMapping";

const filterUserName = (searchText) => ({
	q: { field: "full_name", value: searchText },
});

const RPMConditions = [
	{ id: "Asthma", name: "Asthma" },
	{ id: "COPD", name: "COPD" },
	{ id: "Diabetes", name: "Diabetes" },
	{ id: "general", name: "General Monitoring" },
	{ id: "HeartFailure", name: "Heart Failure" },
	{ id: "chronic_kidney_disease", name: "Chronic Kidney Disease" },
];

export const PatientRPMInfo = ({
	children = null,
	sourceRoot = "patient.",
}) => (
	<FormCard title="Monitoring configuration">
		<div className="grid grid-cols-2 gap-8 justify-between">
			<div className="flex flex-col gap-1">
				<SelectInput
					required
					label="RPM Condition"
					source={sourceRoot + "primary_condition"}
					choices={RPMConditions}
					helperText={false}
				/>
				<SelectInput
					source="care_setting"
					defaultValue="home"
					validate={required()}
					choices={[
						{ id: "home", name: "Home" },
						{
							name: "Residential Care Facility",
							id: "residential_care_facility",
						},
					]}
				/>
			</div>
			<div className="flex flex-col gap-1 items-start">{children}</div>
		</div>
	</FormCard>
);

const validateEmail = email();
const maxDate = DateTime.local().minus({ years: 13 }).toString();
const minDate = DateTime.local().minus({ years: 120 }).toString();

export const PatientGeneralDetails = ({
	sourceRoot = "patient.",
	userRoot = "",
}) => (
	<FormCard title="Patient Identity">
		<div className="grid grid-cols-1 xl:grid-cols-2 max-w-7xl gap-4">
			<div className="flex flex-col gap-1 xl:col-span-1">
				<div className="flex flex-row gap-2 ">
					<TextInput
						className="col-span-1"
						source={userRoot + "first_name"}
						label="First Name"
						required
						helperText={false}
					/>
					<TextInput
						className="col-span-1"
						source={userRoot + "middle_name"}
						label="Middle Name"
						helperText={false}
					/>
					<TextInput
						className="col-span-1"
						source={userRoot + "last_name"}
						label="Last Name"
						required
						helperText={false}
					/>
				</div>
				<div className="flex flex-row gap-2">
					<DateInput
						source={sourceRoot + ".birthdate"}
						validate={[
							maxValue(maxDate, "Must be older then 13 y.o."),
							minValue(minDate, "Must be younger then 120 y.o."),
							required(),
						]}
						helperText={false}
					/>
					<SelectInput
						label="Sex at Birth"
						validate={required()}
						source={sourceRoot + "gender"}
						choices={[
							{ id: "Male", name: "Male" },
							{ id: "Female", name: "Female" },
						]}
					/>
				</div>
				<TextInput
					source={userRoot + "email"}
					validate={(value) => (value ? validateEmail(value) : null)}
					helperText="Email is required for mobile app access"
					label="Email"
				/>
				<div className="flex flex-row gap-2 flex-wrap items-center">
					<PhoneNumberInput
						label="Mobile Number"
						source={userRoot + "mobile_phone_number"}
						helperText={false}
					/>

					<PhoneNumberInput
						label="Home Number"
						source={userRoot + "home_phone_number"}
						helperText={false}
					/>

					<PhoneNumberInput
						label="Facility Number"
						source={userRoot + "facility_phone_number"}
						helperText={false}
					/>
				</div>
				<SelectInput
					label="Primary Phone Number Type"
					source={userRoot + "primary_phone_number_type"}
					choices={phoneNumberTypes}
					defaultValue="mobile"
					helperText={false}
				/>
				<div className="flex flex-row gap-2 ">
					<SelectInput
						label="Race"
						source={sourceRoot + "patient.profile.demographics.race"}
						choices={race}
					/>
					<SelectInput
						label="Ethnicity"
						source={sourceRoot + "patient.profile.demographics.ethnicity"}
						choices={ethnicity}
					/>
				</div>
			</div>
			<div className="xl:col-span-1">
				<AddressInput
					source={userRoot + "address"}
					label="Patient Address"
					isRequired
				/>
			</div>
		</div>
	</FormCard>
);
export const PatientDemographics = ({ sourceRoot = "patient." }) => (
	<FormCard title="Patient details" optional collapsible>
		<div className="w-fit flex flex-col gap-4">
			<HeightInput
				source={sourceRoot + "patient.profile.demographics.height"}
				label="Height"
				required={false}
			/>
			<WeightInput
				source={sourceRoot + "patient.profile.demographics.weight"}
				label="Weight"
				required={false}
			/>
		</div>
	</FormCard>
);

export const PatientMedicalHistory = ({ sourceRoot = "patient." }) => (
	<FormCard title="Medical history" optional collapsible>
		<div className="w-fit flex flex-col gap-4">
			<SelectArrayInput
				label="Conditions"
				source={sourceRoot + "patient.profile.comorbidities"}
				choices={patientConditions}
				helperText={false}
			/>
			<SelectArrayInput
				label="Risk factors"
				source={sourceRoot + "patient.profile.risk_factors"}
				choices={patientRiskFactors}
				helperText={false}
			/>
			<SelectInput
				label="Smoking habits"
				source={sourceRoot + "patient.profile.smoking"}
				choices={smokingChoices}
				helperText={false}
			/>
		</div>
	</FormCard>
);

export const PatientCHFSection = ({ sourceRoot = "patient." }) => (
	<FormDataConsumer subscription={{ values: true }}>
		{({ formData, ...rest }) => {
			const comorbidities =
				get(formData, sourceRoot + "patient.profile.comorbidities", []) || [];

			if (
				get(formData, sourceRoot + "primary_condition") === "HeartFailure" ||
				comorbidities.includes("Congestive Heart Failure")
			) {
				return (
					<FormCard title="Congestive heart failure" optional collapsible>
						<div className="w-fit flex flex-col gap-4">
							<NumberInput
								label="Ejection fraction (%)"
								source={sourceRoot + "patient.profile.chf.ejection_fraction"}
							/>
							<RadioButtonGroupInput
								source={sourceRoot + "patient.profile.chf.mlhfq"}
								label="Minnesota living with heart failure questionnaire (MLHFQ) level"
								defaultValue="Unknown"
								{...rest}
								choices={[
									{ name: "Good (Mlhfq<25)", id: "Good" },
									{ name: "Moderate (25<=Mlhfq<50)", id: "Moderate" },
									{ name: "Poor (Mlhfq>50)", id: "Poor" },
									{ name: "Unknown", id: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={sourceRoot + "patient.profile.chf.bp_medications"}
								label="Currently on blood pressure medications?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
									{ name: "Unknown", id: "" },
								]}
							/>
							<RadioButtonGroupInput
								source={sourceRoot + "patient.profile.chf.diuretics"}
								label="Currently on on daily diuretics?"
								{...rest}
								choices={[
									{ id: "No Daily Diuretic", name: "No Daily Diuretic" },
									{ id: "Low-Dose Diuretic", name: "Low-Dose Diuretic" },
									{ id: "Twice Daily Diuretic", name: "Twice Daily Diuretic" },
									{
										id: "Twice Daily Diuretic & Additional Diuretics That Work In The Kidney",
										name: "Twice Daily Diuretic & Additional Diuretics That Work In The Kidney",
									},
									{ id: "", name: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot +
									"patient.profile.chf.administered_iv_diuretics_in_last_six_months"
								}
								label="Administered IV diuretics in last 6 months?"
								{...rest}
								choices={[
									{ id: true, name: "Yes" },
									{ id: false, name: "No" },
									{ id: "", name: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot + "patient.profile.chf.hospitalized_in_last_year"
								}
								label="Admitted to the hospital due to congestive herat failure in the last year?"
								{...rest}
								choices={[
									{ id: true, name: "Yes" },
									{ id: false, name: "No" },
									{ id: "", name: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot + "patient.profile.chf.visited_ICU_in_last_year"
								}
								label="Admitted to the ICU due to congestive herat failure in the last year?"
								{...rest}
								choices={[
									{ id: true, name: "Yes" },
									{ id: false, name: "No" },
									{ id: "", name: "Unknown" },
								]}
							/>
							{/* <RadioButtonGroupInput
                source="dyspnea"
                label="Dyspnea Grade"
                {...rest}
                choices={[
                  { id: 'Grade 0', name: '0' },
                  { id: 'Grade 1', name: '1' },
                  { id: 'Grade 2', name: '2' },
                  { id: 'Grade 3', name: '3' },
                  { id: 'Grade 4', name: '4' },
                ]}
              /> */}
						</div>
					</FormCard>
				);
			}
		}}
	</FormDataConsumer>
);

export const PatientCOPDSection = ({ sourceRoot = "patient." }) => (
	<FormDataConsumer subscription={{ values: true }}>
		{({ formData, ...rest }) => {
			const comorbidities =
				get(formData, sourceRoot + "patient.profile.comorbidities", []) || [];
			if (
				get(formData, sourceRoot + "primary_condition") === "COPD" ||
				comorbidities.includes("COPD")
			) {
				return (
					<FormCard title="COPD" optional collapsible>
						<div className="w-fit flex flex-col gap-4">
							<NumberInput
								label="How many COPD exacerbations have you had in the last year"
								source={
									sourceRoot + "patient.profile.copd.exacerbations_in_last_year"
								}
								validate={minValue(0, "Must be greater than 0")}
							/>
							<RadioButtonGroupInput
								source={sourceRoot + "patient.profile.copd.dyspnea"}
								label="Which statement best describes your usual breathlessness during activity?"
								{...rest}
								choices={[
									{
										name: "Breathless only with demanding exercise",
										id: "Grade 0",
									},
									{
										name: "Breathless when hurrying on flat ground",
										id: "Grade 1",
									},
									{
										name: "Walk slower than people of my same age",
										id: "Grade 2",
									},
									{
										name: "Need to stop to breathe after walking about 100 yards",
										id: "Grade 3",
									},
									{
										name: "Too breathless to leave the house and dress myself",
										id: "Grade 4",
									},
								]}
							/>
							<RadioButtonGroupInput
								source={sourceRoot + "patient.profile.copd.gold_stage"}
								label="What is your GOLD Stage?"
								{...rest}
								choices={[
									{ name: "Gold stage I (Early)", id: "1" },
									{ name: "Gold stage II (Moderate)", id: "2" },
									{ name: "Gold stage III (Severe)", id: "3" },
									{ name: "Gold stage IV (Very Severe)", id: "4" },
									{ name: "Unknown", id: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot + "patient.profile.copd.visited_ICU_in_last_year"
								}
								label="Have you visited the ICU for COPD in the last year?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
								]}
							/>
						</div>
					</FormCard>
				);
			}
		}}
	</FormDataConsumer>
);

export const PatientAsthmaSection = ({ sourceRoot = "patient." }) => (
	<FormDataConsumer subscription={{ values: true }}>
		{({ formData, ...rest }) => {
			const comorbidities =
				get(formData, sourceRoot + "patient.profile.comorbidities", []) || [];
			if (
				get(formData, sourceRoot + "primary_condition") === "Asthma" ||
				comorbidities.includes("Asthma")
			) {
				return (
					<FormCard title="Asthma" optional collapsible>
						<div className="w-fit flex flex-col gap-4">
							<NumberInput
								label="How many times have you visited the doctor or hospital for Asthma Flare-ups in the last year?"
								source={
									sourceRoot +
									"patient.profile.asthma.exacerbations_in_last_year"
								}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot + "patient.profile.asthma.visited_ICU_in_last_year"
								}
								label="Have you visited the ICU for Asthma in the last year?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot +
									"patient.profile.asthma.hospitalized_in_last_year"
								}
								label="Have you been admitted to the hospital for your Asthma in the past year?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
								]}
							/>
							<CheckboxGroupInput
								source={
									sourceRoot + "patient.profile.asthma.current_medications"
								}
								label="Select any of your current asthma medications:"
								{...rest}
								choices={[
									{ name: "Albuterol Inhaler", id: "Albuterol Inhaler" },
									{
										name: "High Dose Inhaled Steroid",
										id: "High Dose Inhaled Steroid",
									},
									{
										name: "Injectable Medication",
										id: "Injectable Medication",
									},
									{
										name: "Low Dose Inhaled Steroid",
										id: "Low Dose Inhaled Steroid",
									},
									{
										name: "Medium Dose Inhaled Steroid",
										id: "Medium Dose Inhaled Steroid",
									},
									{
										name: "Long Acting Beta Agonist",
										id: "Long Acting Beta Agonist",
									},
								]}
							/>
						</div>
					</FormCard>
				);
			}
		}}
	</FormDataConsumer>
);

// date_of_initial_diagnosis: Optional[datetime] = None
// insulin_usage: Optional[bool] = None
// recent_heart_attack: Optional[datetime] = None
// recent_stroke: Optional[datetime] = None
// high_sugar_diet: Optional[bool] = Field(
//     False,
//     description="Does the patient have a high sugar diet?",
// )

export const PatientDiabetesSection = ({ sourceRoot = "patient." }) => (
	<FormDataConsumer subscription={{ values: true }}>
		{({ formData, ...rest }) => {
			const comorbidities =
				get(formData, sourceRoot + "patient.profile.comorbidities", []) || [];
			if (
				get(formData, sourceRoot + "primary_condition") === "Diabetes" ||
				comorbidities.includes("Diabetes")
			) {
				return (
					<FormCard title="Diabetes" optional collapsible>
						<div className="w-fit flex flex-col gap-4">
							<DateInput
								label="Date of initial diagnosis"
								source="patient.profile.diabetes.date_of_initial_diagnosis"
							/>
							<RadioButtonGroupInput
								source="patient.profile.diabetes.insulin_usage"
								label="Does the patient use insulin?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
								]}
							/>
							<DateInput
								label="Recent heart attack"
								source="patient.profile.diabetes.recent_heart_attack"
							/>
							<DateInput
								label="Recent stroke"
								source="patient.profile.diabetes.recent_stroke"
							/>
							<RadioButtonGroupInput
								source="patient.profile.diabetes.high_sugar_diet"
								label="Does the patient have a high sugar diet?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
								]}
							/>
						</div>
					</FormCard>
				);
			}
		}}
	</FormDataConsumer>
);

export const PatientChronicKidneyDiseaseSection = ({
	sourceRoot = "patient.",
}) => (
	<FormDataConsumer subscription={{ values: true }}>
		{({ formData, ...rest }) => {
			const comorbidities =
				get(formData, sourceRoot + "patient.profile.comorbidities", []) || [];
			if (
				get(formData, sourceRoot + "primary_condition") ===
					"chronic_kidney_disease" ||
				comorbidities.includes("Chronic Kidney Disease")
			) {
				return (
					<FormCard title="Chronic Kidney Disease" optional collapsible>
						<div className="w-fit flex flex-col gap-4">
							<NumberInput
								label="Estimated glomerular filtration rate (eGFR)"
								source={sourceRoot + "patient.profile.chf.egfr"}
							/>
							<NumberInput
								label="Albumin to creatinine ratio (ACR)"
								source={sourceRoot + "patient.profile.chf.acr"}
							/>
							<RadioButtonGroupInput
								source="patient.profile.chronic_kidney_disease.in_dialysis"
								label="Is the patient in dialysis?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot +
									"patient.profile.chronic_kidney_disease.ckd_stage"
								}
								label="Chronic kidney disease stage"
								defaultValue="Unknown"
								{...rest}
								choices={[
									{ name: "Stage 1", id: "Stage 1" },
									{ name: "Stage 2", id: "Stage 2" },
									{ name: "Stage 3A", id: "Stage 3A" },
									{ name: "Stage 3B", id: "Stage 3B" },
									{ name: "Stage 4", id: "Stage 4" },
									{ name: "Stage 5", id: "Stage 5" },
									{ name: "Unknown", id: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot +
									"patient.profile.chronic_kidney_disease.ckd_cause"
								}
								label="What caused chronic kidney disease?"
								defaultValue="Unknown"
								{...rest}
								choices={[
									{ name: "Hypertension", id: "Hypertension" },
									{ name: "Diabetes", id: "Diabetes" },
									{
										name: "Polysistic Kidney Disease",
										id: "Polysistic Kidney Disease",
									},
									{
										name: "Chronic glomerulonephritis",
										id: "Chronic glomerulonephritis",
									},
									{
										name: "Chronic pyelonephritis",
										id: "Chronic pyelonephritis",
									},
									{ name: "Other", id: "Other" },
									{ name: "Unknown", id: "Unknown" },
								]}
							/>
							<RadioButtonGroupInput
								source={
									sourceRoot +
									"patient.profile.chronic_kidney_disease.bp_medications"
								}
								label="Currently on blood pressure medications?"
								{...rest}
								choices={[
									{ name: "Yes", id: true },
									{ name: "No", id: false },
									{ name: "Unknown", id: "" },
								]}
							/>
						</div>
					</FormCard>
				);
			}
		}}
	</FormDataConsumer>
);

export const PatientScreenedSection = ({
	sourceRoot = "",
	tenant_id = null,
}) => (
	<FormCard title="Enrollment Information">
		<div className="grid grid-cols-2 gap-4">
			<TextInput
				source={sourceRoot + "eligible_devices"}
				label="Eligible Devices"
			/>
			<TextInput source={sourceRoot + "insurances"} label="Insurances" />
			<SelectInput
				source={sourceRoot + "insurance_coverage_type"}
				label="Insurance Coverage Type"
				choices={[
					{ id: "partial", name: "Partial" },
					{ id: "full", name: "Full" },
					{ id: "other", name: "Other" },
				]}
			/>
			<FormDataConsumer>
				{({ formData }) =>
					tenant_id || formData.tenant_id ? (
						<DateTimeProviderTZInput
							source={sourceRoot + "onboarding_appointment_datetime"}
							label="Onboarding Appointment"
							tenant_id={tenant_id || formData.tenant_id}
						/>
					) : null
				}
			</FormDataConsumer>
			<FormDataConsumer>
				{({ formData }) =>
					tenant_id || formData.tenant_id ? (
						<ReferenceInput
							source={sourceRoot + "onboarding_appointment_location_id"}
							reference="locations"
							label="Appointment Location"
							filter={{
								"tenant_id[eq]": tenant_id || formData.tenant_id,
							}}
						>
							<AutocompleteInput
								label="Appointment Location"
								optionText="name"
								parse={(v) => (v === "" ? null : v)}
								helperText={false}
							/>
						</ReferenceInput>
					) : null
				}
			</FormDataConsumer>
			<TextInput
				source={sourceRoot + "expected_care_program"}
				label="Expected Care Program"
			/>

			<FormDataConsumer>
				{({ formData }) => (
					<ReferenceInput
						source={sourceRoot + "expected_onboarding_user_id"}
						reference="users"
						filter={{
							"role[ne]": `patient`,
							"tenant_id[in]": [formData.tenant_id, null],
						}}
					>
						<AutocompleteInput
							filterToQuery={filterUserName}
							label="Expected Onboarding Clinician"
							optionText={(choice) => {
								if (choice.is_internal) {
									return `${choice.first_name} ${choice.last_name} (Vironix) ${
										choice.credentials ? `(${choice.credentials})` : ``
									}`;
								}
								return `${choice.full_name} ${
									choice.credentials ? `(${choice.credentials})` : ``
								}`;
							}}
							sx={{
								width: 400,
							}}
							parse={(v) => (v === "" ? null : v)}
							helperText={false}
						/>
					</ReferenceInput>
				)}
			</FormDataConsumer>
			<BooleanInput
				source="virtual_enrollment"
				label="Virtual Enrollment"
				helperText={false}
			/>
		</div>
	</FormCard>
);
