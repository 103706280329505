import React, { useState, ReactNode } from 'react';
import { Button } from '@/modules/ui/components/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Filter } from 'lucide-react';
import { useListContext } from 'react-admin';
import { cn } from '@/modules/ui/utils/cn';
import { Badge } from '@/modules/ui/components/badge';

interface DataTableFilterButtonProps {
  children: ReactNode;
}

export const DataTableFilterButton: React.FC<DataTableFilterButtonProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const { filterValues } = useListContext();

  const activeFiltersCount = Object.keys(filterValues)
    .map((key) => key.split('[')[0]) // Remove everything after [
    .filter((value, index, self) => self.indexOf(value) === index).length; // Get unique values

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn('h-8 border-dashed', {})}
        >
          <Filter className="mr-2 h-4 w-4" />
          {activeFiltersCount > 0 ? (
            <Badge variant="secondary" className="rounded-sm px-1 ">
              {`${activeFiltersCount}  active`}
            </Badge>
          ) : (
            'Filter'
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit p-0" align="start">
        <div className="flex flex-col items-start space-y-2 p-4">
          {children}
        </div>
      </PopoverContent>
    </Popover>
  );
};
