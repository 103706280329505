import React from 'react';
import {
  useRecordContext,
  useDataProvider,
  RecordContextProvider,
  usePermissions,
} from 'ra-core';
import { Badge } from '@/modules/ui/components/badge';
import { Phone, PhoneIncoming } from 'lucide-react';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Separator } from '@/modules/ui/components/separator';
import { CallInfoDialog } from './CallInfoDialog';
import { get } from 'lodash';
import { humanize } from 'inflection';
import { FunctionField, ReferenceField } from 'react-admin';
import { CallPatientButton } from './CallPatientButton';
const InboundCallFrom = () => {
  const record = useRecordContext();

  if (!record) return null;

  if (record.patient_contact_id) {
    return (
      <span className="flex items-center gap-1 bg-orange-100 border border-orange-200 my-2 p-1 rounded-md">
        <PhoneIncoming className="h-4 w-4" />
        Contact:{' '}
        <ReferenceField source="patient_contact_id" reference="contacts">
          <FunctionField
            render={(record) => (
              <span>
                {record.full_name} (
                {record.relationship_to_patient
                  ? humanize(record.relationship_to_patient)
                  : '-'}
                )
              </span>
            )}
          />
        </ReferenceField>
      </span>
    );
  }

  return (
    <span className="flex items-center gap-1">
      <PhoneIncoming className="h-4 w-4" /> Patient
    </span>
  );
};

const CallLogEntry = ({ call }) => {
  return (
    <RecordContextProvider value={call}>
      <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-200 ">
        <div className="flex justify-between items-start">
          <div className="flex justify-start gap-2">
            <span className="text-sm font-medium text-gray-600">
              Patient Phone Call
            </span>
            <Badge variant="outline">
              {call.direction ? humanize(call.direction) : '-'}
            </Badge>
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center text-md text-gray-500 mb-1">
          <span>
            {call.direction === 'outbound' ? (
              call.clinician_name
            ) : (
              <InboundCallFrom />
            )}
          </span>
          <span>Duration: {formatDurationAbbreviated(call.duration)}</span>
        </div>
        <div className="text-sm mb-1">
          <DateTimeProviderTZField
            source="start_time"
            showTime
            showTZ
            side="top"
            tenant_id={call.tenant_id}
          />
        </div>

        <Separator className="my-2 w-full" />

        <p className="text-sm mt-1 whitespace-pre-wrap break-words">
          {call.notes}
        </p>

        <CallInfoDialog callId={call.id} />
      </div>
    </RecordContextProvider>
  );
};

export function CallLogList() {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  const pagination = {
    page: 1,
    perPage: 10,
  };
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['voice-calls', { id: record?.id }],
      queryFn: ({ pageParam = pagination.page }) =>
        dataProvider
          .getList('voice-calls', {
            pagination: {
              page: pageParam,
              perPage: pagination.perPage,
            },
            sort: { field: 'start_time', order: 'DESC' },
            filter: {
              'patient_id[eq]': record?.id,
            },
          })
          .then(({ data, total, pageInfo }) => ({
            data,
            total,
            pageInfo,
            pageParam,
          })),
      initialPageParam: pagination.page,
      enabled: !!record?.id,
      getNextPageParam: (lastLoadedPage) => {
        if (lastLoadedPage.pageInfo) {
          return lastLoadedPage.pageInfo.hasNextPage
            ? lastLoadedPage.pageParam + 1
            : undefined;
        }
        const totalPages = Math.ceil(
          (lastLoadedPage.total || 0) / pagination.perPage,
        );

        return lastLoadedPage.pageParam < totalPages
          ? Number(lastLoadedPage.pageParam) + 1
          : undefined;
      },
    });

  if (!record || isLoading) {
    return null;
  }

  if (!get(permissions, 'voice_calls.list')) return null;

  const calls = data?.pages.flatMap((page) => page.data) || [];

  const renderContent = () => {
    if (calls.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <Phone className="h-12 w-12 opacity-70 mb-2" />
          <p className="text-sm">No call logs found for this patient</p>
        </div>
      );
    }

    return (
      <div className="space-y-4 max-h-screen overflow-y-auto pb-20">
        {calls.map((call) => (
          <CallLogEntry key={call.id} call={call} />
        ))}

        {hasNextPage && (
          <button
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
            className="w-full text-sm text-blue-600 hover:text-blue-800 mt-2"
          >
            {isFetchingNextPage ? 'Loading more...' : 'Load more'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-end items-center">
        <CallPatientButton />
      </div>
      {renderContent()}
    </div>
  );
}
