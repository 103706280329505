import ProviderField from '@/modules/ra-ui/components/ProviderField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { formatDuration } from '@/utils/formatDuration';
import { get } from 'lodash';

import { serviceInstanceTypes } from '@/modules/patients/constants/serviceInstanceTypes';
import React from 'react';
import {
  ArrayField,
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  Datagrid,
  DateField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  WrapperField,
  useRecordContext,
} from 'react-admin';
import ReportHeaderTemplate from './ReportHeaderTemplate';
import { useSidebar } from '@/modules/ui/components/sidebar';
const BUFFER = 28;

const guides = {
  rpm: 'Note: The CPT Manual states that CPT codes 99457 and 99091 cannot be billed concurrently with each other.',
  ccm: 'Note: 99490/99439 cannot be billed in the same calendar month as 99491/99437',
};

const ReportContent = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const { open } = useSidebar();
  const sidebarWidth = open
    ? DRAWER_WIDTH + BUFFER
    : CLOSED_DRAWER_WIDTH + BUFFER;

  if (!record) {
    return null;
  }

  if (!record.content || !record.content[source].length) {
    return (
      <Card
        className={`w-full overflow-auto`}
        style={{
          maxWidth: `calc(100vw - ${sidebarWidth}px)`,
        }}
      >
        <CardContent className="p-2">
          {`No ${serviceInstanceTypes[source]} patients found.`}
        </CardContent>
      </Card>
    );
  }

  const headers = Object.keys(record.content[source][0]);

  return (
    <Card
      className={`w-full overflow-auto`}
      style={{
        maxWidth: `calc(100vw - ${sidebarWidth}px)`,
      }}
    >
      <CardContent className="p-2">
        <CardHeader>
          <CardTitle>{source.toUpperCase()}</CardTitle>
          <CardDescription>
            <p>{guides[source]}</p>
          </CardDescription>
        </CardHeader>
        <ArrayField source={`content.${source}`}>
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <ReferenceField
              label="Patient"
              source="patient_id"
              reference="users"
              link={(item) =>
                `/patients/${item.id}/show/clinical/care-services`
              }
              sortable={false}
              emptyText="N/A"
            >
              <TextField source="full_name" />
            </ReferenceField>
            {headers
              .filter((h) => h !== 'patient_id')
              .map((header) => (
                <TableField key={header} source={header} />
              ))}
          </Datagrid>
        </ArrayField>
      </CardContent>
    </Card>
  );
};

const TableField = (props) => {
  const { source, emptyText = null } = props;
  const record = useRecordContext();
  let classes = '';

  let value = get(record, source);

  if (value === null || value === '') {
    return emptyText ? <span>{emptyText}</span> : null;
  }

  if (source.endsWith('remaining_time') || source.endsWith('remaining_count')) {
    // if the value is more than 0, underline in yellow; keep text black
    if (value > 0) {
      classes = 'border-b-4 border-yellow-500 pr-2';
    }
  }

  if (source.endsWith('_time')) {
    value = formatDuration(value);
  }

  return <span className={classes}>{value}</span>;
};

const MonitoringReportHeader = () => {
  return (
    <ReportHeaderTemplate>
      <>
        <SimpleShowLayout>
          <DateField source="report_date" />
          <WrapperField label="Range">
            <DateField source="range_start" />
            {' - '}
            <DateField source="range_end" />
          </WrapperField>
          <TextField source="name" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <BadgeField source="type" />

          <ProviderField label="Provider" />
          <ReferenceField
            label="Patient"
            source="patient_id"
            reference="users"
            link={(record) =>
              `/patients/${record.id}/show/clinical/care-services`
            }
            sortable={false}
            emptyText="N/A"
          >
            <TextField source="full_name" />
          </ReferenceField>
        </SimpleShowLayout>
      </>
    </ReportHeaderTemplate>
  );
};

const MonitoringReportContent = () => {
  return (
    <>
      <ReportContent source="rpm" />
      <ReportContent source="ccm" />
    </>
  );
};

export { MonitoringReportContent, MonitoringReportHeader };
