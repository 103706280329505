import StoredForm from '@/modules/monitoring-activity/components/StoredForm';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Button } from '@/modules/ui/components/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from '@/modules/ui/components/card';
import {
  useCreate,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { TaskCreateInputs } from './TaskCreateButton';

export const TaskCreateAside = ({ handleCancelCreate }) => {
  const patientRecord = useRecordContext();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isPending }] = useCreate();

  if (!patientRecord) return null;

  const transform = (data) => {
    const transformedData = {
      ...data,
      patient_id: patientRecord.id,
      added_by_id: identity?.id,
      task_type: 'one_off',
      tenant_id: patientRecord?.user.tenant_id,
    };
    return transformedData;
  };

  const sessionKey = `task-create_${patientRecord.id}`;

  const onSubmit = (data) => {
    create(
      'tasks',
      { data: transform(data) },
      {
        onSuccess: () => {
          notify('Task created');
          refresh();
          handleCancelCreate();
          sessionStorage.removeItem(`vironix.${sessionKey}`);
        },
        onError: (error: Error) => {
          notify(
            typeof error === 'string'
              ? error
              : error?.message || 'ra.notification.http_error',
            { type: 'warning' },
          );
        },
      },
    );
  };

  return (
    <TaskForm
      onSubmit={onSubmit}
      handleCancelCreate={handleCancelCreate}
      storeKey={sessionKey}
      isPending={isPending}
    />
  );
};

const TaskForm = ({ onSubmit, handleCancelCreate, storeKey, isPending }) => (
  <StoredForm formKey={storeKey} onSubmit={onSubmit} resource="tasks">
    <Card>
      <CardHeader className="pt-3 pb-2">
        <p className="text-lg font-semibold">Add Task</p>
        <CardDescription>Create a new task for this patient.</CardDescription>
      </CardHeader>
      <CardContent>
        <TaskCreateInputs />
      </CardContent>
    </Card>
    <FormActions
      handleCancelCreate={handleCancelCreate}
      storeKey={storeKey}
      isPending={isPending}
    />
  </StoredForm>
);

const FormActions = ({ handleCancelCreate, storeKey, isPending }) => (
  <div className="flex flex-col justify-start gap-2 items-end mt-4">
    <div className="flex flex-row gap-2">
      <SaveButton
        variant="default"
        type="submit"
        size="sm"
        label="Save"
        className="w-32"
        disabled={isPending}
      />
      <ClearButton storeKey={storeKey} />
    </div>
    <Button variant="link" onClick={handleCancelCreate} type="button">
      Cancel
    </Button>
  </div>
);

const ClearButton = ({ storeKey }) => {
  const { reset } = useFormContext();
  const patientRecord = useRecordContext();

  if (!patientRecord) return null;
  const handleClearForm = () => {
    reset({
      keepDefaultValues: false,
      keepValues: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
    sessionStorage.removeItem(storeKey);
  };

  return (
    <Button variant="outline" onClick={handleClearForm} type="button">
      Clear
    </Button>
  );
};

export default TaskCreateAside;
