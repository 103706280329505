import { DataTable } from "@/modules/data-table/components/data-table";
import { DataTableFilter } from "@/modules/data-table/components/data-table-filter";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import BadgeField from "@/modules/ra-ui/components/badge-field";
import React from "react";
import {
	FunctionField,
	ListContextProvider,
	useGetList,
	useRecordContext,
} from "react-admin";
// import { Badge } from "@/modules/ui/components/badge";
// import { humanize } from "inflection";
import {
	allergyCategory,
	allergySeverity,
} from "../constants/allergyConstants";
import { LuxonDateField } from "@/modules/ra-ui/components/LuxonDateField";
import { AllergyCreateButton } from "./AllergyCreateButton";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import { AlertTriangle } from "lucide-react";
import { usePatientTableState } from "@/modules/common/hooks/usePatientTableState";
import { AllergyEditButton } from "./AllergyEditButton";
import { ExpandedAllergyRow } from "./ExpandedAllergyRow";

const columns = [
	{
		accessorKey: "category",
		header: "Category",
		cell: () => <BadgeField source="category" mapping={allergyCategory} />,
	},
	{
		accessorKey: "substance",
		header: "Substance",
		cell: () => (
			<FunctionField
				source="substance"
				render={(record) => record.substance?.display || "Unknown substance"}
			/>
		),
	},
	{
		accessorKey: "status",
		header: "Status",
		cell: () => <BadgeField source="status" />,
	},
	{
		accessorKey: "reaction_severity",
		header: "Severity",
		cell: () => (
			<BadgeField source="reaction_severity" mapping={allergySeverity} />
		),
	},
	{
		accessorKey: "onset_date",
		header: "Onset Date",
		cell: () => <LuxonDateField source="onset_date" />,
	},
	{
		accessorKey: "actions",
		header: "Actions",
		cell: () => {
			return <AllergyEditButton />;
		},
	},
];

export function AllergiesDataGrid(props) {
	const { record } = props;

	const {
		page,
		perPage,
		sort,
		filterValues,
		setPage,
		setPerPage,
		setSort,
		setFilters,
	} = usePatientTableState("allergy-intolerances", record?.id, {
		defaultSort: { field: "onset_date", order: "DESC" },
		defaultFilterValues: {},
	});

	const { data, total, isLoading } = useGetList(
		`patients/${record.id}/allergy-intolerances`,
		{
			pagination: { page, perPage },
			filter: filterValues,
			sort,
		},
	);

	if (!record) {
		return null;
	}

	return (
		<div>
			<ListContextProvider
				// @ts-ignore
				value={{
					data,
					filterValues,
					setFilters,
					page,
					perPage,
					setPage,
					setPerPage,
					total,
					sort,
					setSort,
					isLoading,
				}}
			>
				<DataTable
					columns={columns}
					data={data}
					loading={isLoading}
					toolbar={
						<DataTableToolbar
							showViewOptions={false}
							search={false}
							className="mx-2"
						>
							<DataTableFilter
								column="category[in]"
								title="Category"
								options={allergyCategory.map((choice) => ({
									label: choice.name,
									value: choice.id,
								}))}
							/>
							<DataTableFilter
								column="reaction_severity[in]"
								title="Severity"
								options={allergySeverity.map((choice) => ({
									label: choice.name,
									value: choice.id,
								}))}
							/>
						</DataTableToolbar>
					}
					emptyState={
						<EmptyState
							title="No Allergies"
							description="No allergies recorded for patient"
							icons={[AlertTriangle]}
							className="max-w-full"
						/>
					}
					storeKey="allergies-list"
					containerClassName="pb-2"
					expandedContent={<ExpandedAllergyRow />}
					// getRowCanExpand={() => true}
					// renderSubComponent={ExpandedRow}
				/>
			</ListContextProvider>
		</div>
	);
}

export default function AllergiesSection() {
	const record = useRecordContext();
	if (!record) {
		return null;
	}

	return (
		<div className="flex flex-col space-y-2 mt-4">
			<div className="flex flex-row space-x-1 justify-between items-center">
				<div>
					<h3 className="text-xl font-semibold">Allergies</h3>
					<p className="text-sm text-gray-500">
						Record of patient allergies, intolerances, and adverse reactions to
						substances.
					</p>
				</div>
				<AllergyCreateButton />
			</div>
			<AllergiesDataGrid record={record} />
		</div>
	);
}
