import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { Button } from '@/modules/ui/components/button';
import { AlertCircle } from 'lucide-react';
import { useResetErrorBoundaryOnLocationChange } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const ErrorCapture = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  const navigate = useNavigate();
  useResetErrorBoundaryOnLocationChange(resetErrorBoundary);

  const handleGoBack = () => {
    resetErrorBoundary();
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen px-4 ">
      <Alert variant="destructive" className="bg-white w-8/12 ">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          <p>{error.toString()}</p>
        </AlertDescription>
      </Alert>
      <Button onClick={handleGoBack} className="mt-4 text-center mx-auto">
        Go Back
      </Button>
    </div>
  );
};
