import CreateInDialogButton from "@/modules/ra-ui/components/create-dialog-button";
import React from "react";
import { maxValue, minValue, required, useRecordContext } from "react-admin";

import { DateInput } from "@/modules/ui/inputs/date-input";
import { NumberInput } from "@/modules/ui/inputs/number-input";
import { SwitchInput } from "@/modules/ui/inputs/switch-input";
import { TextInput } from "@/modules/ui/inputs/text-input";

export const CreateFeeForServiceButton = ({ isHybrid }) => {
	const record = useRecordContext();

	return (
		<CreateInDialogButton
			resource={`providers/${record?.id}/fee-for-service-pricing`}
			title="Create Fee for Service Pricing"
			variant="default"
			label="Create Fee for Service Pricing"
			transform={(data) => {
				if (isHybrid) {
					data.hybrid_pricing = true;
				}
				return data;
			}}
			dialogVariant="shadcn"
		>
			<div className="flex flex-col gap-1">
				<NumberInput
					source="proportion"
					helperText={false}
					validate={[required(), minValue(0), maxValue(1.0)]}
					decimalScale={2}
				/>
				<TextInput
					source="specific_locality_id_override"
					defaultValue={null}
					helperText={false}
				/>
				<NumberInput
					source="volume_threshold"
					defaultValue={0}
					helperText={
						<span>
							Volume threshold for negotiated price to apply.
							<br />
							Use 0 for first tier. If set to 100, then this price will be used
							for units 101 - next tier or infinity if no next tier.
						</span>
					}
					validate={[required(), minValue(0)]}
				/>
				<DateInput source="effective_date" helperText={false} />
				<SwitchInput source="clear_existing" defaultChecked={false} />
			</div>
		</CreateInDialogButton>
	);
};
