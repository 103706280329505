import { EncounterCreateButton } from "@/modules/patients/encounters/components/EncounterCreateButton";
import { TaskCreateButton } from "@/modules/tasks/components/TaskCreateButton";
import { Button } from "@/modules/ui/components/button";
import { CheckBoxInput, mustBeTrue } from "@/modules/ui/inputs/checkbox-input";
import { DateTimeInput } from "@/modules/ui/inputs/date-time-input";
import { NumberInput } from "@/modules/ui/inputs/number-input";
import { ReferenceSelectInput } from "@/modules/ui/inputs/reference-select-input";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { TextAreaInput } from "@/modules/ui/inputs/text-area-input";
import { TextInput } from "@/modules/ui/inputs/text-input";
import { YesNoRadioInput } from "@/modules/ui/inputs/yes-no-radio-input";
import { cn } from "@/modules/ui/utils/cn";
import { humanize } from "inflection";
import { Plus } from "lucide-react";
import { required, useRecordContext } from "ra-core";
import { createElement } from "react";
import { type UseFormReturn, useFormContext } from "react-hook-form";
import type { ActivityItem } from "./types";
import { formatPastDateTime } from "@/utils/formatPastDateTime";
import { formatAssessmentName } from "@/modules/patients/assessment-summary/utils/formatAssessmentName";
import { CarePlanCreateButton } from "@/modules/patients/clinical-sections/components/CarePlans";

const availableResourceCreation = {
	encounters: {
		button: EncounterCreateButton,
		reference: (patient_id) => `patients/${patient_id}/encounters`,
		optionText: (record) =>
			`${humanize(record.encounter_type)} - ${formatPastDateTime(record.created_at)}`,
		sort: {
			field: "created_at",
			order: "DESC",
		},
	},
	tasks: {
		button: TaskCreateButton,
		reference: (_) => "tasks",
		filter: (patient_id) => ({
			"patient_id[eq]": patient_id,
		}),
		optionText: (record) =>
			`${humanize(record.task_type)} - ${record.created_at}`,
		sort: {
			field: "created_at",
			order: "DESC",
		},
	},
	assessments: {
		reference: (patient_id) => `patients/${patient_id}/runs`,
		meta: {
			category: null,
		},
		optionText: (record) =>
			`${formatAssessmentName(record.name)} - ${formatPastDateTime(record.created_at)}`,
		sort: {
			field: "created_at",
			order: "DESC",
		},
	},
	care_plans: {
		button: CarePlanCreateButton,
		reference: (_) => "care-plans",
		filter: (patient_id) => ({
			"patient_id[eq]": patient_id,
			"is_active[eq]": true,
		}),
		optionText: (record) =>
			`${humanize(record.plan_name)} - ${formatPastDateTime(record.created_at)}`,
		sort: {
			field: "created_at",
			order: "DESC",
		},
	},
};

interface StepChecklistItemProps {
	item: ActivityItem;
	form: UseFormReturn<any>;
	parentId?: string;
	isPreview?: boolean;
}

const ResourceCreateButton = ({ item, fieldName, isPreview = false }) => {
	const { watch } = useFormContext();
	const value = watch(fieldName);
	const isCompleted = value === true || value === "true";
	const record = useRecordContext();

	if (!record) return null;

	if (!item.resource_creation) return null;

	return item.resource_creation.map((resource) => {
		const { resource_type, trigger_condition, display_text, reference_field } =
			resource;

		if (!availableResourceCreation[resource_type]) return null;

		const {
			button: ButtonComponent,
			reference,
			optionText,
			filter,
			meta,
			sort,
		} = availableResourceCreation[resource_type];

		if (!reference) return null;

		if (
			trigger_condition === "manual_button" ||
			(trigger_condition === "on_yes" && isCompleted)
		) {
			// ensure all props are not null that are required
			if (!resource_type || !trigger_condition || !reference_field) return null;

			return (
				<div className="flex gap-4 w-full max-w-md items-center">
					<div className="flex-1">
						<ReferenceSelectInput
							source={reference_field}
							label={display_text}
							reference={reference(record?.id)}
							optionText={optionText}
							sort={sort}
							disabled={isPreview}
							filter={filter?.(record?.id)}
							meta={meta}
							validate={[item.required && required()]}
						/>
					</div>
					{isPreview && (
						<Button
							type="button"
							variant="default"
							size="sm"
							className="shrink-0 whitespace-nowrap my-2 h-10 mt-8"
						>
							<Plus className="mr-2 h-4 w-4" />
							{`Create ${resource_type}`}
						</Button>
					)}
					{!isPreview && ButtonComponent && createElement(ButtonComponent)}
				</div>
			);
		}

		return null;
	});
};

export function StepChecklistItem({
	item,
	parentId,
	isPreview = false,
}: StepChecklistItemProps) {
	const fieldName = `${parentId}.${item.item_id}`;

	const { watch } = useFormContext();

	const renderInput = () => {
		switch (item.type) {
			case "yes_no":
				return (
					<YesNoRadioInput
						source={fieldName}
						label={item.text}
						helperText={item.prompt || undefined}
						validate={required()}
					/>
				);

			case "dropdown":
				return (
					<SelectInput
						source={fieldName}
						label={item.text}
						helperText={item.prompt || undefined}
						validate={item.required && [required()]}
						choices={item.options}
					/>
				);

			case "scale":
			case "number":
				return (
					<div className="space-y-2">
						<NumberInput
							source={fieldName}
							label={item.text}
							helperText={item.prompt || undefined}
							validate={item.required && [required()]}
						/>
					</div>
				);

			case "text":
				return (
					<div className="space-y-2">
						<TextInput
							source={fieldName}
							label={item.text}
							helperText={item.prompt || undefined}
							validate={item.required && [required()]}
						/>
					</div>
				);

			case "textarea":
				return (
					<div className="space-y-2">
						<TextAreaInput
							id={item.item_id}
							source={fieldName}
							className="max-w-lg min-h-[100px]"
							validate={item.required && [required()]}
							helperText={item.prompt || undefined}
							label={item.text}
						/>
					</div>
				);

			case "date":
				return (
					<DateTimeInput
						source={fieldName}
						label={item.text}
						helperText={item.prompt || undefined}
					/>
				);

			default:
				return (
					<div className="flex items-start space-x-3">
						<CheckBoxInput
							source={fieldName}
							label={item.text}
							helperText={item.prompt || undefined}
							validate={mustBeTrue()}
						/>
					</div>
				);
		}
	};

	const enabled_when_watch = item.enabled_when?.map((cond) => ({
		current_value: watch(`${parentId}.${cond.item_id}`),
		...cond,
	}));

	if (enabled_when_watch && enabled_when_watch.length > 0) {
		const allConditionsMatch = enabled_when_watch.every(
			(cond) => String(cond.current_value) === String(cond.value),
		);
		if (!allConditionsMatch) {
			return null;
		}
	}

	return (
		<div className="space-y-4">
			<div
				className={cn(
					"space-y-2",
					"flex flex-col ",
					// item.type === "yes_no" ? "" : "flex items-start justify-between",
				)}
			>
				<div className="flex-1">{renderInput()}</div>
				<ResourceCreateButton
					item={item}
					fieldName={fieldName}
					isPreview={isPreview}
				/>
			</div>
		</div>
	);
}
