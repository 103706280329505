import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { get } from 'lodash';
import { AlertTriangle } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteButton,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  ICD10ArrayPatientInput,
  maxCount,
} from '../../icd10/components/ICD10Input';
import { serviceInstanceTypes } from '../constants/serviceInstanceTypes';
import { serviceInstanceTypesChoices } from '../constants/serviceInstanceTypesChoices';

const filterProviderName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export const serviceInstanceCreateInputs = [
  // <BooleanInput
  //   helperText="This service will be tracked and added to the patients bill."
  //   source="is_tracked_for_insurance"
  //   label="Track for Insurance"
  // />,
];

export const ServiceInstanceCreateDialog = () => {
  const record = useRecordContext();

  const transform = (data) => {
    return { ...data, patient_id: record.id };
  };

  return (
    <CreateInDialogButton
      title="Create care program"
      resource="service-instances"
      transform={transform}
      variant="default"
      label="Care Program"
      notifyMessage="Care program created"
      enableLogAction
      refreshOnSuccess
      logActionLabel={(data) => {
        const careProgramType = serviceInstanceTypes[data.type];
        return `Care program "${careProgramType}" created for patient`;
      }}
      initialData={{
        practitioner_id: record?.billing_provider_id,
        start: DateTime.fromISO(record?.user?.registered_on, {
          zone: 'utc',
        }).toISODate(),
      }}
    >
      <div className="flex flex-col gap-0">
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => {
            if (
              (formData.type === 'ccm' ||
                formData.type === 'pcm' ||
                formData.type === 'cccm') &&
              get(
                record,
                'patient.profile.chronic_kidney_disease.in_dialysis',
              ) === true
            ) {
              return (
                <Alert variant="warning" className="mb-2">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>
                    Dialysis patient may conflict with CCM / PCM
                  </AlertTitle>
                  <AlertDescription>
                    Neither CCM nor PCM can be billed at the same time as
                    End-Stage Renal Disease services codes (CPT codes
                    90951-90970). <br /> Neither RPM nor RTM should be billed
                    when there is a more specific monitoring service being
                    provided. <br /> We highly recommend consulting with your
                    biller.
                  </AlertDescription>
                </Alert>
              );
            }
          }}
        </FormDataConsumer>
        <DateInput source="start" validate={required()} helperText={false} />
        <SelectInput
          label="Service Type"
          source="type"
          validate={required()}
          choices={serviceInstanceTypesChoices.filter(
            (c) => c.enabled !== false,
          )}
          helperText={false}
        />
        <ICD10ArrayPatientInput
          patientId={record.id}
          validate={[required(), maxCount()]}
        />
        <ReferenceInput
          source="practitioner_id"
          reference="users"
          filter={{
            'role[ne]': 'patient',
            'npi_number[ne]': null,
            'is_provider[eq]': true,
            'tenant_id[eq]': record?.user?.tenant_id,
          }}
        >
          <AutocompleteInput
            label="Billing Provider"
            optionText="full_name"
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
            filterToQuery={filterProviderName}
            validate={required()}
          />
        </ReferenceInput>
        <BooleanInput
          source="is_labor_provided"
          label="Vironix Labor Provided"
          defaultValue={true}
          helperText="Keep this on if Vironix will provide the labor for this service."
        />
      </div>
    </CreateInDialogButton>
  );
};

export const ServiceInstanceEditDialog = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  const transform = (data) => {
    return data;
  };

  if (!record) {
    return null;
  }

  return (
    <EditInDialogButton
      title="Edit care program"
      resource="service-instances"
      transform={transform}
      refreshOnSuccess={true}
      deleteButton={
        <DeleteButton
          resource="service-instances"
          redirect={false}
          mutationOptions={{
            onSuccess: () => {
              notify('Care program deleted.');
              refresh();
            },
          }}
          confirmTitle="Delete Care Program?"
          confirmContent="Please be sure you want to delete this care program. You should generally deactivate care programs instead."
        />
      }
    >
      <div className="flex flex-col gap-0">
        <DateInput source="start" validate={required()} />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.is_active === false) {
              return <DateInput source="end" validate={required()} />;
            }
          }}
        </FormDataConsumer>
        <SelectInput
          label="Service Type"
          source="type"
          required
          choices={serviceInstanceTypesChoices.filter(
            (c) => c.enabled !== false,
          )}
          disabled={true}
        />
        <ICD10ArrayPatientInput
          patientId={record.patient_id}
          validate={[required(), maxCount()]}
        />
        <ReferenceInput
          source="practitioner_id"
          reference="users"
          filter={{
            'role[ne]': 'patient',
            'is_provider[eq]': true,
            'npi_number[ne]': null,
          }}
        >
          <AutocompleteInput
            label="Billing Provider"
            optionText="full_name"
            parse={(v) => (v === '' ? null : v)}
            filterToQuery={filterProviderName}
          />
        </ReferenceInput>
        <BooleanInput
          source="is_labor_provided"
          label="Vironix Labor Provided"
          disabled={get(permissions, 'is_internal') !== true}
        />
        <BooleanInput source="is_active" label="Active" />
      </div>
    </EditInDialogButton>
  );
};
