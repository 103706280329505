import { Card, CardContent, CardHeader } from '@/modules/ui/components/card';
import { CardTitle, CardDescription } from '@/modules/ui/components/card';
import { DataTable } from '@/modules/data-table/components/data-table';
import { EmptyState } from '@/modules/data-table/components/data-table-empty-state';
import { DollarSign } from 'lucide-react';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import {
  useRecordContext,
  useGetList,
  ListContextProvider,
  NumberField,
  type SortPayload,
} from 'react-admin';
import { useState } from 'react';
import { ReimbursementRateCreateButton } from './ReimbursementRateCreateButton';
import { ReimbursementRateEditButton } from './ReimbursementRateEditButton';

const columns = [
  {
    accessorKey: 'as_of_date',
    header: 'Effective Date',
    cell: () => <LuxonDateField source="as_of_date" />,
  },

  {
    accessorKey: 'hourly_reimbursement',
    header: 'Hourly Rate',
    cell: () => (
      <NumberField
        source="hourly_reimbursement"
        options={{ style: 'currency', currency: 'USD' }}
      />
    ),
  },
  {
    accessorKey: 'enrollment_reimbursement',
    header: 'Enrollment Rate',
    cell: () => (
      <NumberField
        source="enrollment_reimbursement"
        options={{ style: 'currency', currency: 'USD' }}
      />
    ),
  },
  {
    accessorKey: 'shift_reimbursement',
    header: 'Shift Rate',
    cell: () => (
      <NumberField
        source="shift_reimbursement"
        options={{ style: 'currency', currency: 'USD' }}
      />
    ),
  },
  {
    accessorKey: 'training_reimbursement',
    header: 'Training Rate',
    cell: () => (
      <NumberField
        source="training_reimbursement"
        options={{ style: 'currency', currency: 'USD' }}
      />
    ),
  },
  {
    accessorKey: 'incentive_reimbursement',
    header: 'Incentive Rate',
    cell: () => (
      <NumberField
        source="incentive_reimbursement"
        options={{ style: 'currency', currency: 'USD' }}
      />
    ),
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="flex gap-2">
          <ReimbursementRateEditButton />
        </div>
      );
    },
  },
];

export const UserReimbursementRates = () => {
  const record = useRecordContext();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState<SortPayload>({
    field: 'as_of_date',
    order: 'DESC',
  });
  const [filterValues, setFilters] = useState({});

  const { data, total, isLoading } = useGetList(
    'reimbursement-rates',
    {
      pagination: { page, perPage },
      sort,
      filter: {
        'user_id[eq]': record?.id,
        ...filterValues,
      },
    },
    {
      enabled: !!record,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>Reimbursement Rates</CardTitle>
            <CardDescription>
              Manage user reimbursement rates here.
            </CardDescription>
          </div>
          <ReimbursementRateCreateButton variant="default" />
        </div>
      </CardHeader>
      <CardContent>
        <ListContextProvider
          // @ts-expect-error
          value={{
            data,
            isLoading,
            page,
            perPage,
            setPage,
            setPerPage,
            total,
            sort,
            setSort,
            filterValues,
            setFilters,
          }}
        >
          <DataTable
            columns={columns}
            data={data || []}
            loading={isLoading}
            emptyState={
              <EmptyState
                title="No reimbursement rates"
                description="No reimbursement rates have been set for this user"
                icons={[DollarSign]}
                className="max-w-full"
              />
            }
            storeKey="reimbursement-rates-list"
            containerClassName="pb-2"
          />
        </ListContextProvider>
      </CardContent>
    </Card>
  );
};
