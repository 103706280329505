import IncompatibleBillingWarning from '@/modules/monitoring-activity/components/IncompatibleBillingWarning';
import { monitoringActivityTypes } from '@/modules/monitoring-activity/constants/monitoringActivityTypes';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Button } from '@/modules/ui/components/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from '@/modules/ui/components/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import { filter, find, get, isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';
import {
  FormDataConsumer,
  required,
  useCreate,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { serviceInstanceTypes } from '../../patients/constants/serviceInstanceTypes';
import presetNotes from '../constants/presetNotes';
import { checkDeviceMeasurements } from '../util/checkDeviceMeasurements';
import { ElapsedTime } from './ElapsedTime';
import StoredForm from './StoredForm';
import { AutocompleteInput } from '@/modules/ui/inputs/autocomplete-input';
import { filterUserName } from '@/utils/filterUserName';
import { DateTimeInput } from '@/modules/ui/inputs/date-time-input';
import { YesNoRadioInput } from '@/modules/ui/inputs/yes-no-radio-input';
const monitoringActivityTypeChoices = map(monitoringActivityTypes, (v, k) => ({
  name: v,
  id: k,
}));

const mustBeTrue =
  (message = 'You must confirm') =>
  (value) =>
    value === true ? undefined : message;

export const MonitoringActivityCreateAside = ({
  handleCancelCreate,
  noteType = 'careprogram',
}) => {
  const patientRecord = useRecordContext();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isPending }] = useCreate();

  if (!patientRecord || !identity) return null;

  const transform = (data) => {
    if (noteType === 'emr') {
      const transformedData = {
        ...data,
        patient_id: patientRecord.id,
        type: 'emr_review_note',
      };
      return transformedData;
    } else {
      const transformedData = {
        ...data,
        patient_id: patientRecord.id,
        practitioner_id: identity.id,
        type: data.type || 'note',
      };
      return transformedData;
    }
  };

  const sessionKey =
    noteType === 'emr'
      ? `emr-review-note_${patientRecord.id}`
      : `monitoring-activity_${patientRecord.id}`;

  const onSubmit = (data) => {
    create(
      'monitoring-activities',
      { data: transform(data) },
      {
        onSuccess: () => {
          notify('Monitoring activity created');
          refresh();
          handleCancelCreate();
          sessionStorage.removeItem('vironix.' + sessionKey);
        },
        onError: (error) => {
          notify(
            typeof error === 'string'
              ? error
              : get(error, 'message') || 'ra.notification.http_error',
            { type: 'warning' },
          );
        },
      },
    );
  };

  return noteType === 'emr' ? (
    <EMRReviewNoteForm
      patientRecord={patientRecord}
      onSubmit={onSubmit}
      handleCancelCreate={handleCancelCreate}
      storeKey={sessionKey}
      isPending={isPending}
    />
  ) : (
    <CareProgramNoteForm
      patientRecord={patientRecord}
      onSubmit={onSubmit}
      handleCancelCreate={handleCancelCreate}
      storeKey={sessionKey}
      isPending={isPending}
    />
  );
};

const EMRReviewNoteForm = ({
  patientRecord,
  onSubmit,
  handleCancelCreate,
  storeKey,
  isPending,
}) => (
  <StoredForm
    formKey={storeKey}
    onSubmit={onSubmit}
    resource="monitoring-activities"
    record={{}}
  >
    <Card>
      <CardHeader className="pt-3 pb-2">
        <p className="text-lg font-semibold">Add EMR Review Note</p>
        <CardDescription>
          Use this to add a note that references an action taken in the
          patient's external EMR system.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-1 items-stretch">
          <AutocompleteInput
            label="Performed by"
            source="practitioner_id"
            optionText="full_name"
            reference="users"
            filter={{
              'is_active[eq]': true,
              'is_provider[eq]': true,
              'tenant_id[in]': patientRecord.user.tenant_id,
            }}
            filterToQuery={filterUserName}
            validate={required()}
          />
          <DateTimeInput
            label="Performed on"
            source="reviewed_on"
            validate={required()}
            timezone="America/New_York"
          />
          <YesNoRadioInput
            label="Was there been a two-way interaction with the patient?"
            source="patient_contacted"
            validate={required()}
          />
          <TextAreaInput
            label="Details"
            source="description"
            validate={required('Notes are required.')}
          />
        </div>
      </CardContent>
    </Card>
    <FormActions
      handleCancelCreate={handleCancelCreate}
      storeKey={storeKey}
      isPending={isPending}
    />
  </StoredForm>
);

const CareProgramNoteForm = ({
  patientRecord,
  onSubmit,
  handleCancelCreate,
  storeKey,
  isPending,
}) => {
  const serviceInstanceChoices = useMemo(
    () =>
      get(patientRecord, 'service_instances', [])
        .filter((serviceInstance) => serviceInstance.is_active)
        .map((i) => ({
          id: i.id.toString(),
          name: serviceInstanceTypes[i.type],
        })),
    [patientRecord],
  );

  const deviceCheck = checkDeviceMeasurements(
    patientRecord?.has_device,
    patientRecord?.last_device_activity,
  );

  return (
    <StoredForm
      formKey={storeKey}
      onSubmit={onSubmit}
      resource="monitoring-activities"
      record={{}}
    >
      <Card>
        <CardHeader className="pt-3 pb-2">
          <p className="text-lg font-semibold">Add Monitoring Activity</p>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-1 items-stretch">
            {!isEmpty(serviceInstanceChoices) ? (
              <SelectInput
                label="Care Program"
                source="service_instance_id"
                choices={serviceInstanceChoices}
                fullWidth
              />
            ) : null}
            <FormDataConsumer>
              {({ formData }) => {
                const matchedServiceInstance = find(
                  get(patientRecord, 'service_instances', []),
                  {
                    id: Number.parseInt(formData.service_instance_id, 10),
                  },
                );

                if (!matchedServiceInstance) return null;
                const choices = filter(monitoringActivityTypeChoices, (v) =>
                  v.id.startsWith(matchedServiceInstance.type),
                );

                choices.unshift({ name: 'Note', id: 'note' });

                return (
                  <>
                    <SelectInput
                      source="type"
                      choices={choices}
                      fullWidth
                      helperText="Specify which service code this work belongs to."
                      validate={[required()]}
                    />
                    <IncompatibleBillingWarning
                      patientId={patientRecord?.id}
                      className="my-2"
                      hasDevice={patientRecord?.has_device}
                      lastDeviceMeasurement={
                        patientRecord?.last_device_activity
                      }
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <ElapsedTime />
            <div className="flex flex-col gap-2 py-2">
              <YesNoRadioInput
                label="Has physiological data been reviewed?"
                source="data_reviewed"
                validate={required()}
              />
              <YesNoRadioInput
                label="Has there been a two-way interaction with the patient?"
                source="patient_contacted"
                validate={required()}
              />
            </div>
            <FormDataConsumer>
              {({ formData }) =>
                formData?.type?.startsWith('rpm') &&
                deviceCheck.hasWarning &&
                deviceCheck.warningType === 'stale-measurements' ? (
                  <SwitchInput
                    source="stale_measurements"
                    defaultValue={false}
                    helperText={false}
                    label={
                      <p className="text-sm font-semibold text-wrap">
                        Please confirm that you are writing a note for a patient
                        without recent measurements
                      </p>
                    }
                    validate={[mustBeTrue()]}
                  />
                ) : null
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <TextAreaInput
                    label="Notes"
                    source="description"
                    validate={required('Notes are required.')}
                  />
                  <PresetNotesMenu activityType={formData.type} />
                </>
              )}
            </FormDataConsumer>
          </div>
        </CardContent>
      </Card>
      <FormActions
        handleCancelCreate={handleCancelCreate}
        storeKey={storeKey}
        isPending={isPending}
      />
    </StoredForm>
  );
};

const FormActions = ({ handleCancelCreate, storeKey, isPending }) => (
  <div className="flex flex-col justify-start gap-2 items-end mt-4">
    <div className="flex flex-row gap-2">
      <SaveButton
        variant="default"
        type="submit"
        size="sm"
        label="Save"
        className="w-32"
        disabled={isPending}
      />
      <ClearButton storeKey={storeKey} />
    </div>
    <Button variant="link" onClick={handleCancelCreate} type="button">
      Cancel
    </Button>
  </div>
);

const ClearButton = ({ storeKey }) => {
  const { reset } = useFormContext();
  const patientRecord = useRecordContext();

  if (!patientRecord) return null;
  const handleClearForm = () => {
    reset({
      keepDefaultValues: false,
      keepValues: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
    sessionStorage.removeItem(storeKey);
  };

  return (
    <Button variant="outline" onClick={handleClearForm} type="button">
      Clear
    </Button>
  );
};

const PresetNotesMenu = ({ activityType, source = 'description' }) => {
  const { setValue } = useFormContext();

  const handleClickPresetMessage = (event) => {
    const message = event.target.dataset.message;
    setValue(source, message);
  };

  if (isEmpty(activityType) || isEmpty(presetNotes[activityType])) return null;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="w-fit">
          Preset notes
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {activityType
          ? get(presetNotes, activityType, []).map((message, key) => (
              <DropdownMenuItem
                onSelect={handleClickPresetMessage}
                data-message={message.body}
                key={key}
              >
                {message.name}
              </DropdownMenuItem>
            ))
          : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
