import { Badge } from "@/modules/ui/components/badge";
import type { ColumnDef } from "@tanstack/react-table";
import { humanize } from "inflection";
import { get } from "lodash";
import { DateTime } from "luxon";
import { ListContextProvider, useGetList, useRecordContext } from "ra-core";
import React, { useState } from "react";
import { DataTable } from "@/modules/data-table/components/data-table";
import { ValidatePatientDataButton } from "./ValidatePatientDataButton";
import { RevokePatientDataRequestButton } from "./RevokePatientDataRequestButton";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import { InboxIcon } from "lucide-react";

const columns: ColumnDef<any>[] = [
	{
		accessorKey: "type",
		header: "Export Type",
		cell: ({ row }) => {
			const type = get(row.original, "type");
			return (
				<Badge variant="outline" className="w-max">
					{humanize(type)}
				</Badge>
			);
		},
	},
	{
		accessorKey: "format",
		header: "Format",
		cell: ({ row }) => {
			const format = get(row.original, "format");
			return <span className="uppercase">{format}</span>;
		},
	},
	{
		accessorKey: "status",
		header: "Status",
		cell: ({ row }) => {
			const status = get(row.original, "status");
			const statusColors = {
				completed: "bg-green-100",
				failed: "bg-red-100",
				pending: "bg-yellow-100",
				revoked: "bg-gray-100",
			};
			return (
				<Badge
					variant="outline"
					className={`w-max ${statusColors[status] || ""}`}
				>
					{humanize(status)}
				</Badge>
			);
		},
	},
	{
		accessorKey: "created_at",
		header: "Requested At",
		cell: ({ row }) => {
			const createdAt = get(row.original, "created_at");
			if (!createdAt) return "-";
			const date = DateTime.fromISO(createdAt, {
				zone: "utc",
			}).setZone("local");
			return date.toLocaleString(DateTime.DATETIME_MED);
		},
	},
	{
		accessorKey: "completed_at",
		header: "Completed At",
		cell: ({ row }) => {
			const completedAt = get(row.original, "completed_at");
			if (!completedAt) return "-";
			const date = DateTime.fromISO(completedAt, {
				zone: "utc",
			}).setZone("local");
			return date.toLocaleString(DateTime.DATETIME_MED);
		},
	},
	{
		id: "actions",
		header: "Actions",
		cell: ({ row }) => {
			const status = get(row.original, "status");
			const buttons = [];

			if (status === "pending_validation") {
				buttons.push(<ValidatePatientDataButton key="validate" />);
			}

			if (status !== "completed") {
				buttons.push(<RevokePatientDataRequestButton key="revoke" />);
			}

			if (buttons.length === 0) {
				return null;
			}

			return <div className="flex gap-2">{buttons}</div>;
		},
	},
];

export const PatientDataExportTable = () => {
	const record = useRecordContext();
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(5);
	const [sort, setSort] = useState({
		field: "created_at",
		order: "DESC",
	});
	const { data, isLoading, total } = useGetList("data-exports", {
		pagination: { page, perPage },
		filter: {
			"patient_id[eq]": record?.id,
		},
		sort: sort as any,
	});

	return (
		<ListContextProvider
			// @ts-ignore
			value={{ page, perPage, setPage, setPerPage, total, sort, setSort }}
		>
			<DataTable
				columns={columns}
				data={data || []}
				loading={isLoading}
				emptyState={
					<EmptyState
						title="No export requests found"
						className="p-5"
						icons={[InboxIcon]}
					/>
				}
			/>
		</ListContextProvider>
	);
};
