import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { formatPastDateTime } from '@/utils/formatPastDateTime';
import { useNotify } from 'ra-core';
import { useCallback, useState } from 'react';
import { FormProvider, type UseFormReturn, useForm } from 'react-hook-form';
import { usePatientClinicalActivity } from '../context/PatientClinicalActivityContext';
import { useActivityAutosave } from '../hooks/useActivityAutosave';
import { ReviewAndSubmitStep } from './ReviewAndSubmitStep';
import { StepContent } from './StepContent';
import { StepNavigator } from './StepNavigator';

export const ClinicalActivityContainer = () => {
  const {
    inProgressActivityLoading,
    inProgressActivity,
    cancelActivity,
    activityTemplate,
    activityTemplateLoading,
    completeActivity,
    discardActivity,
  } = usePatientClinicalActivity();

  if (inProgressActivityLoading || activityTemplateLoading) {
    return (
      <div className="w-full h-96 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  console.log('inProgressActivity', inProgressActivity);
  console.log('activityTemplate', activityTemplate);

  return (
    <ClinicalActivityContainerUI
      activity={activityTemplate}
      inProgressActivity={inProgressActivity}
      completeActivity={completeActivity}
      cancelActivity={cancelActivity}
      discardActivity={discardActivity}
    />
  );
};

export const ClinicalActivityContainerUI = ({
  activity,
  inProgressActivity = null,
  isPreview = false,
  submitPreview = null,
  previewForm = null,
  completeActivity = null,
  cancelActivity = null,
  discardActivity = null,
}: {
  activity: any;
  isPreview?: boolean;
  inProgressActivity?: any;
  submitPreview?: (data: any) => void;
  previewForm?: UseFormReturn<any>;
  completeActivity?: (data: any) => void;
  cancelActivity?: () => void;
  discardActivity?: () => void;
}) => {
  // Get activity steps without adding review step
  const activitySteps = activity.activity_template_steps.map(
    (step) => step.step_template,
  );
  // Don't add review step to allSteps anymore
  const allSteps = activitySteps;

  const notify = useNotify();

  // Form initialization - only create if not provided as prop
  const defaultForm = useForm({
    defaultValues: {
      ...(inProgressActivity?.data_json || {}),
      review_and_submit: {
        verify_and_submit: false,
      },
    },
  });
  const form = previewForm || defaultForm;

  // State management
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Add a new state to track if we're in the review step
  const [showReviewStep, setShowReviewStep] = useState(false);

  // Set up autosave
  const { lastSavedAt, isSaving, saveNow } = useActivityAutosave(form, {
    enabled: !isPreview && !!inProgressActivity,
    onAutosaveError: (error) => {
      notify(`Failed to autosave: ${error.message}`, { type: 'warning' });
    },
  });

  const validateCurrentStep = useCallback(() => {
    const currentStep = allSteps[activeStep];
    const formValues = form.getValues();

    // Check if all required items in the current step are completed
    const isStepValid = currentStep.activity_items_json.every((item) => {
      const value = formValues[currentStep.step_key][item.item_id];
      return value;
    });

    // Also check if time_taken is recorded for this step
    const hasTimeTaken = !!formValues[currentStep.step_key]?.time_taken;
    const isFullyValid = isStepValid && hasTimeTaken;

    if (!isStepValid) {
      notify('Please complete all items in this step before proceeding', {
        type: 'warning',
      });
    } else if (!hasTimeTaken) {
      notify('Please complete the step to record time taken', {
        type: 'warning',
      });
    }

    return isFullyValid;
  }, [activeStep, allSteps, form, notify]);

  const handleNext = useCallback(() => {
    const isLastStep = activeStep === allSteps.length - 1;

    if (isLastStep) {
      // If we're at the last regular step, show the review step
      if (validateCurrentStep()) {
        if (!isPreview) {
          saveNow();
        }
        setShowReviewStep(true);
      }
    } else if (validateCurrentStep()) {
      // Save current progress before moving to next step
      if (!isPreview) {
        saveNow();
      }
      setActiveStep((prev) => Math.min(prev + 1, allSteps.length - 1));
    }
  }, [activeStep, allSteps.length, validateCurrentStep, isPreview, saveNow]);

  const handleBack = useCallback(() => {
    if (showReviewStep) {
      // If we're in the review step, go back to the last regular step
      setShowReviewStep(false);
    } else if (activeStep === 0 && cancelActivity) {
      // If we're on the first step and cancelActivity is available, use it
      if (!isPreview) {
        saveNow();
      }
      cancelActivity();
    } else {
      setActiveStep((prev) => Math.max(prev - 1, 0));
    }
  }, [showReviewStep, activeStep, cancelActivity, isPreview, saveNow]);

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      // Validate that all required fields are completed
      let hasIncompleteFields = false;
      let incompleteStepIndex = -1;

      // Check each step for completion
      for (let i = 0; i < allSteps.length; i++) {
        const step = allSteps[i];

        // Check if time_taken is recorded for this step
        if (!data[step.step_key]?.time_taken) {
          hasIncompleteFields = true;
          incompleteStepIndex = i;
          break;
        }

        for (const item of step.activity_items_json) {
          const value = data[step.step_key]?.[item.item_id];
          if (value === undefined || value === null || value === '') {
            hasIncompleteFields = true;
            incompleteStepIndex = i;
            break;
          }
        }
        if (hasIncompleteFields) break;
      }

      if (hasIncompleteFields) {
        notify(
          `Please complete all required fields in step "${allSteps[incompleteStepIndex].step_title}"`,
          {
            type: 'error',
          },
        );
        // Go back to the incomplete step
        setShowReviewStep(false);
        setActiveStep(incompleteStepIndex);
        return;
      }

      setIsSubmitting(true);

      try {
        // Format the data for submission
        const submissionData = {
          ...data,
          additional_notes: data.additional_notes,
          completed_at: new Date().toISOString(),
        };

        console.log('Submitting activity:', submissionData);

        if (isPreview) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulated API call
          notify('Successful test submission', { type: 'success' });
          if (submitPreview) {
            submitPreview(submissionData);
          }
        } else {
          // Use the context to complete the activity
          await completeActivity(submissionData);
        }
      } catch (error) {
        console.error('Error submitting activity:', error);
        notify('Failed to submit clinical activity', { type: 'error' });
        throw error;
      }
    } catch (error) {
      console.error('Error in form submission:', error);
      notify('Error submitting clinical activity', {
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  });

  return (
    <div className="mx-4 space-y-6 relative pb-4">
      <Card>
        <CardHeader className="pb-2">
          <div className="flex items-start justify-between">
            <div>
              <CardTitle className="text-xl font-bold">
                {activity.activity_title}
                <Badge variant="secondary" className="text-sm float-end">
                  {activity.activity_type}
                </Badge>
              </CardTitle>
              <CardDescription className="mt-2 text-base">
                {activity.activity_description}
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent className="pb-4">
          <div className="flex flex-col gap-0.5 text-sm text-muted-foreground">
            <div>
              Started on:{' '}
              {inProgressActivity?.created_at
                ? formatPastDateTime(inProgressActivity?.created_at)
                : 'N/A'}
            </div>
            <div>
              Last updated:{' '}
              {inProgressActivity?.updated_at
                ? formatPastDateTime(inProgressActivity?.updated_at)
                : 'N/A'}
            </div>
          </div>
        </CardContent>
      </Card>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-rows gap-2">
            <StepNavigator
              steps={allSteps}
              activeStep={showReviewStep ? allSteps.length : activeStep}
              onStepChange={(step) => {
                if (step === allSteps.length) {
                  // If clicking on the review step in navigator
                  setShowReviewStep(true);
                } else {
                  setShowReviewStep(false);
                  setActiveStep(step);
                }
              }}
            />

            <div className="flex flex-col gap-4">
              {showReviewStep ? (
                <ReviewAndSubmitStep
                  steps={allSteps}
                  isPreview={isPreview}
                  onNavigateToStep={(stepIndex) => {
                    setShowReviewStep(false);
                    setActiveStep(stepIndex);
                  }}
                />
              ) : (
                <StepContent
                  step={allSteps[activeStep]}
                  form={form}
                  isPreview={isPreview}
                />
              )}

              <div className="flex justify-between mt-4">
                <div className="flex flex-row gap-1">
                  <Button
                    type="button"
                    onClick={handleBack}
                    disabled={isSubmitting}
                    variant="outline"
                  >
                    Back
                  </Button>
                  {discardActivity && (
                    <Button
                      type="button"
                      onClick={discardActivity}
                      variant="destructive"
                    >
                      Discard
                    </Button>
                  )}
                  {/* Autosave indicator */}
                  {!isPreview && lastSavedAt && (
                    <span className="text-xs text-muted-foreground self-center ml-2">
                      {isSaving
                        ? 'Saving...'
                        : `Last saved: ${lastSavedAt.toLocaleTimeString()}`}
                    </span>
                  )}
                </div>

                {showReviewStep ? (
                  isPreview ? (
                    <Button type="button" onClick={handleSubmit}>
                      Submit
                    </Button>
                  ) : (
                    <SaveButton
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      variant="default"
                    >
                      {isSubmitting ? 'Submitting...' : 'Complete Activity'}
                    </SaveButton>
                  )
                ) : (
                  <Button
                    type="button"
                    onClick={handleNext}
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
