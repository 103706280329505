import React, { useState } from "react";
import { Button } from "@/modules/ui/components/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/modules/ui/components/card";
import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "@/modules/ui/components/alert";
import { Clock, LogOut, Check } from "lucide-react";
import {
	Form,
	required,
	useCreate,
	useNotify,
	useDataProvider,
} from "react-admin";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import { Skeleton } from "@/modules/ui/components/skeleton";
import { formatPastDateTime } from "@/utils/formatPastDateTime";
import { useAttendance } from "../hooks/UserCheckedIn";
import { debounce } from "lodash";
import { filterLocationName } from "@/utils/filterLocationName";

// Types
interface Coordinates {
	latitude: number;
	longitude: number;
}

const AttendanceCheckInOut: React.FC = () => {
	// Hooks
	const [error, setError] = useState<string | null>(null);
	const [create, { isLoading: createLoading }] = useCreate();
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const {
		isCheckedIn,
		isLoading,
		attendanceData,
		refetch,
		attendanceEnforced,
	} = useAttendance();

	// Helper Functions
	const handleLocationError = () => {
		const errorMessage =
			"Location access is required. Please enable location access and try again.";
		setError(errorMessage);
		notify("Location access denied. Please enable location services.", {
			type: "error",
		});
	};

	// Check-in Handlers
	const submitCheckIn = async (coords: Coordinates, formData: any) => {
		await create(
			"attendances/check-in",
			{
				data: {
					...formData,
					latitude_checked_in: coords.latitude,
					longitude_checked_in: coords.longitude,
				},
			},
			{
				onSuccess: () => {
					notify("Check-in successful");
					refetch();
				},
				onError: (error: any) => {
					const errorMessage =
						error?.status === 409
							? "You're already checked in"
							: error?.message ||
								error?.toString() ||
								"An unknown error occurred";
					notify(errorMessage, { type: "error" });
					if (error?.status !== 409) {
						setError("Failed to save check-in: " + errorMessage);
					}
				},
			},
		);
	};

	// Add submitCheckOut function
	const submitCheckOut = async (coords: Coordinates) => {
		try {
			await dataProvider.post("attendances/check-out", {
				data: {
					latitude_checked_out: coords.latitude,
					longitude_checked_out: coords.longitude,
				},
			});
			notify("Check-out successful");
			refetch();
		} catch (error: any) {
			const errorMessage =
				error?.message || error?.toString() || "An unknown error occurred";
			notify(errorMessage, { type: "error" });
			setError("Failed to save check-out: " + errorMessage);
		}
	};

	// Debounced handlers
	const debouncedCheckIn = debounce(async (formData: any) => {
		setError(null);

		if (!navigator.geolocation) {
			notify("Your browser does not support location services", {
				type: "warning",
			});
			// Proceed without location data
			create(
				"attendances/check-in",
				{ data: formData },
				{
					onSuccess: () => {
						notify("Check-in successful");
						refetch();
					},
					onError: (error: any) => {
						const errorMessage =
							error?.message ||
							error?.toString() ||
							"An unknown error occurred";
						notify(errorMessage, { type: "error" });
						setError("Failed to save check-in: " + errorMessage);
					},
				},
			);
			return;
		}

		navigator.geolocation.getCurrentPosition(async (position) => {
			await submitCheckIn(
				{
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				},
				formData,
			);
		}, handleLocationError);
	}, 1000); // 1 second delay

	const debouncedCheckOut = debounce(async () => {
		setError(null);

		if (!navigator.geolocation) {
			notify("Your browser does not support location services", {
				type: "warning",
			});
			// Proceed without location data
			try {
				await dataProvider.post("attendances/check-out", { data: {} });
				notify("Check-out successful");
				refetch();
			} catch (error: any) {
				const errorMessage =
					error?.message || error?.toString() || "An unknown error occurred";
				notify(errorMessage, { type: "error" });
				setError("Failed to save check-out: " + errorMessage);
			}
			return;
		}

		navigator.geolocation.getCurrentPosition(async (position) => {
			await submitCheckOut({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			});
		}, handleLocationError);
	}, 1000); // 1 second delay

	// Replace the original handlers with the debounced versions
	const handleCheckIn = (formData: any) => {
		debouncedCheckIn(formData);
	};

	const handleCheckOut = () => {
		debouncedCheckOut();
	};

	// Clean up debounced functions on unmount
	React.useEffect(() => {
		return () => {
			debouncedCheckIn.cancel();
			debouncedCheckOut.cancel();
		};
	}, [debouncedCheckIn, debouncedCheckOut]);

	// Render Functions
	if (!attendanceEnforced) {
		return null;
	}

	if (isLoading) {
		return (
			<Card className="w-fit">
				<CardContent className="py-2">
					<Skeleton className="h-20 w-96" />
				</CardContent>
			</Card>
		);
	}

	if (isCheckedIn) {
		return (
			<Card className="w-fit">
				<CardContent className="py-2">
					<div className="flex flex-col gap-4">
						<div className="flex items-center gap-2 text-green-600">
							<Check className="h-5 w-5" />
							<p>
								Checked in {formatPastDateTime(attendanceData.check_in_time)}
							</p>
						</div>
						<Button onClick={handleCheckOut} variant="destructive">
							<LogOut className="mr-2 h-4 w-4" />
							Check Out
						</Button>
					</div>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card className="border-2">
			<CardHeader>
				<CardTitle>Check-In</CardTitle>
				<CardDescription>
					Manage your attendance status. Location access is required.
				</CardDescription>
			</CardHeader>
			<CardContent>
				{error && (
					<Alert variant="destructive">
						<AlertTitle>Error</AlertTitle>
						<AlertDescription>{error}</AlertDescription>
					</Alert>
				)}
				<Form onSubmit={handleCheckIn}>
					<div className="space-y-4 w-fit">
						<AutocompleteInput
							label="Location"
							optionText="name"
							validate={required()}
							parse={(v) => (v === "" ? null : v)}
							helperText="Select your check-in location"
							source="location_id"
							reference="locations"
							filter={{ "is_active[eq]": true }}
							filterToQuery={filterLocationName}
						/>
						<Button type="submit" disabled={isCheckedIn || createLoading}>
							<Clock className="mr-2 h-4 w-4" />
							Check In
						</Button>
					</div>
				</Form>
			</CardContent>
		</Card>
	);
};

export default AttendanceCheckInOut;
