import { DataTablePagination } from '@/modules/data-table/components/data-table-pagination';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/modules/ui/components/table';
import { Skeleton } from '@/modules/ui/components/skeleton';
import { cn } from '@/modules/ui/utils/cn';
import type { ColumnDef, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { InboxIcon, X } from 'lucide-react';
import React from 'react';
import { cloneElement } from 'react';
import { RecordContextProvider } from 'react-admin';
import { useListContext, useStore } from 'react-admin';
import { EmptyState } from './data-table-empty-state';
import { DataTableHeader } from './data-table-header';
import { DataTableViewOptions } from './data-table-view-options-tantable';
import { Button } from '@/modules/ui/components/button';
import get from 'lodash/get';
interface DataTableProps<TData> {
  columns: ColumnDef<TData, any>[];
  data?: TData[];
  loading?: boolean;
  emptyState?: React.ReactNode;
  onRowClick?: (row: TData) => void;
  tableClassName?: string;
  storeKey?: string;
  containerClassName?: string;
  toolbar?: React.ReactElement;
  expandedContent?: React.ReactElement;
  expandMode?: 'single' | 'multiple';
  enableRowSelection?: boolean;
  bulkActionButtons?: React.ReactElement | false;
  hidePaginationIfLessThanTotal?: boolean;
}

const defaultEmptyState = (
  <EmptyState
    title="No records found"
    description="No records found"
    icons={[InboxIcon]}
  />
);

export function DataTable<TData>({
  columns,
  data: propData,
  loading: propLoading,
  onRowClick,
  emptyState = defaultEmptyState,
  tableClassName = '',
  storeKey,
  containerClassName = '',
  toolbar,
  expandedContent,
  expandMode = 'multiple',
  enableRowSelection = false,
  bulkActionButtons = false,
  hidePaginationIfLessThanTotal = false,
}: DataTableProps<TData>) {
  const {
    page,
    perPage,
    setPage,
    setPerPage,
    total,
    sort,
    setSort,
    data: tableData,
    isLoading: tableLoading,
    selectedIds,
    onSelect,
  } = useListContext();

  const data = propData ?? tableData;
  const loading = propLoading ?? tableLoading;

  // Initialize column order with all column IDs if empty
  const initialColumnOrder = React.useMemo(() => {
    return columns.map((column) => {
      // Handle different ways a column might be identified
      if (typeof column.id === 'string') {
        return column.id;
      }
      // For accessorKey, we need to check if it exists and is a string
      if ('accessorKey' in column && typeof column.accessorKey === 'string') {
        return column.accessorKey;
      }
      // Fallback to a string representation of the column index
      return String(columns.indexOf(column));
    });
  }, [columns]);

  const initialColumnVisibility = React.useMemo(() => {
    return columns.reduce((acc, column) => {
      // Get the column ID (either from id or accessorKey)
      const columnId =
        typeof column.id === 'string'
          ? column.id
          : (get(column, 'accessorKey') as string);

      // Check if the column should be shown (default to true if not specified)
      if (columnId) {
        acc[columnId] = get(column, 'show') !== false;
      }
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const [columnOrder, setColumnOrder] = useStore(
    `preferences.${storeKey}.columnOrder`,
    initialColumnOrder,
  );

  const [columnVisibility, setColumnVisibility] = useStore(
    `preferences.${storeKey}.columnVisibility`,
    initialColumnVisibility,
  );

  // Sync column order when columns change
  React.useEffect(() => {
    if (columnOrder.length === 0) {
      // If no order is set, initialize with all columns
      setColumnOrder(initialColumnOrder);
      return;
    }
  }, [columns, columnOrder, initialColumnOrder, setColumnOrder]);

  const [expanded, setExpanded] = React.useState({});

  // Convert selectedIds to TanStack Table's row selection format
  const rowSelection = React.useMemo(() => {
    if (!selectedIds) return {};
    return selectedIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
  }, [selectedIds]);

  // Handle row selection changes
  const onRowSelectionChange = (updater: any) => {
    const newSelection =
      typeof updater === 'function' ? updater(rowSelection) : updater;

    // Convert back to array of IDs for React Admin
    const newSelectedIds = Object.keys(newSelection).filter(
      (key) => newSelection[key],
    );

    // Update React Admin's selection state
    onSelect?.(newSelectedIds);
  };

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: {
        pageIndex: page - 1,
        pageSize: perPage,
      },
      sorting: sort ? [{ id: sort.field, desc: sort.order === 'DESC' }] : [],
      columnVisibility: columnVisibility,
      expanded: expanded,
      columnOrder: columnOrder,
      rowSelection,
    },
    pageCount: Math.ceil(total / perPage),
    rowCount: total,
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater({
          pageIndex: page - 1,
          pageSize: perPage,
        });

        if (newState.pageIndex !== page - 1) {
          setPage(newState.pageIndex + 1);
        }

        if (perPage !== newState.pageSize) {
          setPerPage(newState.pageSize);
        }
      }
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: (updater) => {
      if (typeof updater === 'function') {
        console.log('updater', updater);
        console.log('sort', sort);

        const currentSort = sort
          ? [{ id: sort.field, desc: sort.order === 'DESC' }]
          : [];
        const newSort = updater(currentSort);
        if (newSort.length > 0) {
          setSort({
            field: newSort[0].id,
            order: newSort[0].desc ? 'DESC' : 'ASC',
          });
        } else {
          setSort({ field: 'id', order: 'ASC' });
        }
      }
    },
    getRowCanExpand: () => Boolean(expandedContent),
    enableExpanding: Boolean(expandedContent),
    onExpandedChange: (updater) => {
      if (expandMode === 'single' && typeof updater === 'function') {
        const newExpanded = updater(expanded);
        const keys = Object.keys(newExpanded);

        if (keys.length > 1) {
          const lastKey = keys[keys.length - 1];
          setExpanded({ [lastKey]: true });
        } else {
          setExpanded(newExpanded);
        }
      } else {
        setExpanded(updater);
      }
    },
    manualPagination: true,
    manualSorting: true,
    enableRowSelection,
    enableMultiRowSelection: true,
    onRowSelectionChange: onRowSelectionChange,
    getRowId: (row: any) => row.id,
  });

  const resetColumns = () => {
    // Get all available column IDs from the table
    const availableColumnIds = table.getAllLeafColumns().map((col) => col.id);

    // Create a mapping between accessorKey and actual column ID
    // This is crucial for handling columns with dots in their accessorKey
    const columnMapping: Record<string, string> = {};
    for (const column of table.getAllLeafColumns()) {
      // For columns with accessorKey containing dots, TanStack Table might use a different internal ID
      // We need to map the original accessorKey to the internal ID
      const def = column.columnDef as { accessorKey?: string };
      if (def.accessorKey) {
        columnMapping[def.accessorKey] = column.id;
      }
    }

    // Map initialColumnOrder to actual column IDs using the mapping
    const mappedInitialOrder = initialColumnOrder
      .map((id) => columnMapping[id] || id)
      .filter((id) => availableColumnIds.includes(id));

    // Set column order and visibility
    if (mappedInitialOrder.length > 0) {
      table.setColumnOrder(mappedInitialOrder);
    } else {
      // Fallback to original order if mapping fails
      table.setColumnOrder(initialColumnOrder);
    }

    // For visibility, we need to ensure we're using the correct IDs
    const mappedVisibility = {};
    for (const key of Object.keys(initialColumnVisibility)) {
      const mappedKey = columnMapping[key] || key;
      mappedVisibility[mappedKey] = initialColumnVisibility[key];
    }

    table.setColumnVisibility(mappedVisibility);
  };

  if (data === undefined) {
    return null;
  }

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableBody>
          {Array.from({ length: perPage }).map((_, index) => (
            <TableRow key={`loading-row-${index}`}>
              {columns.map((column, cellIndex) => (
                <TableCell key={`loading-cell-${cellIndex}`}>
                  <Skeleton className="h-6 w-full" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    }

    return (
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <React.Fragment key={row.id}>
            <TableRow
              data-state={row.getIsSelected() && 'selected'}
              onClick={() => {
                if (expandedContent) {
                  row.toggleExpanded();
                } else if (onRowClick) {
                  onRowClick(row.original);
                }
              }}
              className={cn(
                'transition-colors',
                (expandedContent || onRowClick) &&
                'cursor-pointer hover:bg-muted/50',
                row.getIsSelected() && 'bg-muted',
              )}
            >
              <RecordContextProvider value={row.original}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </RecordContextProvider>
            </TableRow>
            {row.getIsExpanded() && expandedContent && (
              <TableRow className="bg-white  hover:bg-white">
                <RecordContextProvider value={row.original}>
                  <TableCell
                    colSpan={row.getVisibleCells().length}
                    className="p-4"
                  >
                    {cloneElement(expandedContent)}
                  </TableCell>
                </RecordContextProvider>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableBody>
    );
  };

  const selectedCount = Object.keys(rowSelection).length;
  const showPagination =
    (!hidePaginationIfLessThanTotal || data.length >= perPage) &&
    data &&
    data.length > 0;

  return (
    <div
      id="data-table"
      className={cn('h-full relative', containerClassName, { 'mb-2': showPagination })}
    >
      <div className="flex flex-col gap-1 justify-between relative">
        {toolbar &&
          cloneElement(toolbar, {
            showViewOptions: (
              <DataTableViewOptions table={table} resetColumns={resetColumns} />
            ),
          })}
        {enableRowSelection &&
          bulkActionButtons &&
          (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
            <div className="flex flex-row justify-between transition-opacity duration-200 w-full px-2 py-2 absolute bottom-0 left-0 right-0 bg-gray-50 z-10 min-h-10">
              <div className="flex flex-row gap-2 items-center">
                <Button
                  variant="outline"
                  onClick={() => {
                    table.resetRowSelection(true);
                  }}
                  className="p-1 h-8 "
                  aria-label="Clear selection"
                >
                  <X className="w-4 h-4" />
                </Button>
                <p className="text-sm text-muted-foreground">
                  {selectedCount} {selectedCount === 1 ? 'item' : 'items'}{' '}
                  selected
                </p>
                {/* {selectedCount < table.getRowCount() && (
									<Button
										variant="outline"
										size="sm"
										className="h-8"
										onClick={() => table.toggleAllRowsSelected(true)}
									>
										Select all
									</Button>
								)} */}
              </div>
              {cloneElement(bulkActionButtons, { selectedIds })}
            </div>
          )}
      </div>
      <div
        className={cn(
          'h-[calc(100%-4rem)]',
          (!data || data.length === 0) && !loading
            ? ''
            : 'rounded-md border bg-white',
          tableClassName,
          { 'mb-2': showPagination },
        )}
      >
        {(!data || data.length === 0) && !loading ? (
          <div className="flex items-center justify-center h-full">
            {emptyState}
          </div>
        ) : (
          <Table>
            <DataTableHeader table={table} />
            {renderTableContent()}
          </Table>
        )}
      </div>
      {!loading && showPagination && (
        <DataTablePagination table={table} currentPage={page} />
      )}
    </div>
  );
}
