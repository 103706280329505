import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Pagination,
  useGetList,
  useRecordContext,
} from 'react-admin';
import DeviceExternalIdField from '@/modules/devices/components/DeviceExternalIdField';
import { ColoredChipField } from '@/modules/ra-ui/components/ColoredChipField';
import { Link } from 'react-router-dom';
import { titleize } from 'inflection';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { Button } from '@/modules/ui/components/button';

export function DevicesDataGrid() {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList('devices', {
    pagination: { page, perPage },
    filter: { 'patient_id[eq]': record?.id },
    sort,
  });
  if (!record) {
    return null;
  }

  return (
    <>
      <Datagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        setSort={setSort}
        rowClick={(id) => `/devices/${id}/show`}
        empty={
          <EmptyDatagrid
            resourceName="Devices"
            textFormatString="No devices found for patient"
            actionButton={
              <Link to={`/devices`}>
                <Button variant="default">Manage devices</Button>
              </Link>
            }
          />
        }
      >
        <DeviceExternalIdField source="external_id" label="IMEI" />
        <FunctionField
          render={(record) =>
            record.device_manufacturer
              ? titleize(record.device_manufacturer)
              : ''
          }
          label="Manufacturer"
        />
        <ColoredChipField source="device_type" label="Type" />
        <ColoredChipField source="status" />
        <BooleanField source="is_shared_device" />
        <DateField source="assigned_on" />
      </Datagrid>
      {total > 10 && (
        <ListContextProvider
          value={{ page, perPage, setPage, setPerPage, total } as any}
        >
          <Pagination />
        </ListContextProvider>
      )}
    </>
  );
}
