import { TextInput } from '@/modules/ui/inputs/text-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import {
  Form,
  useCreate,
  useNotify,
  useUpdate,
  useGetOne,
  useRefresh,
} from 'ra-core';
import { required } from 'ra-core';
import { Button } from '@/modules/ui/components/button';
import { Card, CardContent, CardFooter } from '@/modules/ui/components/card';
import { ArrowLeft, ArrowUp, Copy } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActivityItemForm } from './ActivityItemForm';
import { StepContent } from '@/modules/clinical-activities/components/StepContent';
import { useForm, useFormContext } from 'react-hook-form';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { MultiSelectInput } from '@/modules/ui/inputs/multi-select-input';
import { monitoringActivityTypes } from '@/modules/monitoring-activity/constants/monitoringActivityTypes';

const StepPreview = () => {
  const mainFormContext = useFormContext();
  const values = mainFormContext.watch();
  const previewFormContext = useForm();
  const previewStep = {
    id: values.step_key || 'pending',
    step_key: values.step_key || 'pending',
    step_title: values.step_title || 'Untitled Step',
    step_description: values.step_description,
    expected_time_range:
      values.expected_time_range?.min && values.expected_time_range?.max
        ? {
            min: Number.parseInt(values.expected_time_range.min),
            max: Number.parseInt(values.expected_time_range.max),
          }
        : undefined,
    activity_items_json: values.activity_items_json,
  };

  return (
    <Form onSubmit={() => {}} defaultValues={{}}>
      <StepContent
        step={previewStep}
        form={previewFormContext}
        isPreview
        className="h-[calc(100vh-8rem)] overflow-y-auto"
      />
    </Form>
  );
};

export const StepTemplateForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const notify = useNotify();
  const [create] = useCreate();
  const [update] = useUpdate();

  const { data: record, isLoading } = useGetOne('step-templates', { id });
  const refresh = useRefresh();
  const handleClone = async () => {
    create(
      `step-templates/${id}/clone`,
      {
        data: { id },
      },
      {
        onSuccess: (data) => {
          notify('Step template cloned successfully', { type: 'success' });
          navigate(`/clinical-activities/step/${data.id}`);
          refresh();
        },
        onError: () => {
          notify('Error cloning step template', { type: 'error' });
        },
      },
    );
  };

  const handlePromote = () => {
    create(
      `step-templates/${id}/promote`,
      {
        data: { id },
      },
      {
        onSuccess: () => {
          notify('Step template promoted successfully', {
            type: 'success',
          });
          navigate('/clinical-activities');
          refresh();
        },
        onError: () => {
          notify('Error promoting step template', { type: 'error' });
        },
      },
    );
  };

  const handleSubmit = async (values: any) => {
    const processedValues = {
      ...values,
    };

    if (id) {
      update(
        'step-templates',
        { id, data: processedValues },
        {
          onSuccess: (data) => {
            notify('Step template updated successfully', { type: 'success' });
            refresh();
            navigate(`/clinical-activities/step/${data.id}`);
          },
          onError: (error) => {
            notify(`Error saving step template: ${error.message}`, {
              type: 'error',
            });
          },
        },
      );
    } else {
      create(
        'step-templates',
        { data: processedValues },
        {
          onSuccess: () => {
            notify('Step template created successfully', { type: 'success' });
            navigate('/clinical-activities');
            refresh();
          },
          onError: () => {
            notify('Error saving step template', { type: 'error' });
          },
        },
      );
    }
  };

  if (id && isLoading) {
    return <div>Loading...</div>;
  }

  // Parse time range for initial values
  const initialValues = record
    ? {
        ...record,
        expected_time_range: {
          min: record.expected_time_range?.min || '',
          max: record.expected_time_range?.max || '',
        },
        activity_items_json: record.activity_items_json?.map((item: any) => ({
          ...item,
          options:
            item.type === 'dropdown' && Array.isArray(item.options)
              ? item.options
              : item.options,
        })),
      }
    : {
        activity_items_json: [],
        expected_time_range: { min: '', max: '' },
      };

  return (
    <div className="h-screen flex flex-col">
      <div className="flex items-center justify-between p-4 pb-1">
        <div className="flex items-center space-x-4">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate('/clinical-activities')}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </Button>
          <h2 className="text-2xl font-bold tracking-tight">
            {id ? 'Edit Step Template' : 'Create Step Template'}
          </h2>
        </div>
        {id && (
          <div className="flex flex-row gap-2">
            {record?.is_draft && (
              <Button
                variant="outline"
                size="sm"
                onClick={handlePromote}
                type="button"
              >
                <ArrowUp className="h-4 w-4 mr-2" />
                Promote
              </Button>
            )}
            <Button
              variant="outline"
              size="sm"
              onClick={handleClone}
              type="button"
            >
              <Copy className="h-4 w-4 mr-2" />
              Clone
            </Button>
          </div>
        )}
      </div>
      <Form onSubmit={handleSubmit} defaultValues={initialValues}>
        <div className="grid grid-cols-2 space-x-6 p-4 flex-1 overflow-hidden">
          <div className="overflow-auto">
            <Card>
              <CardContent className="p-4 max-h-[calc(100vh-12rem)] overflow-y-auto">
                <div className="space-y-6">
                  <TextInput
                    source="step_title"
                    label="Title"
                    validate={[required()]}
                  />
                  <TextInput
                    source="step_key"
                    label="Key"
                    validate={[
                      required(),
                      (value) => {
                        const lower = value?.toLowerCase();
                        const clean = lower.replace(/[^a-z0-9_]+/g, '_');
                        if (lower !== clean) {
                          return 'Must be lowercase, unique and separated by underscores';
                        }
                        return undefined;
                      },
                    ]}
                    helperText="Used as ID. Must be lowercase, unique and separated by underscores."
                  />
                  <TextAreaInput
                    source="step_description"
                    label="Description"
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      source="expected_time_range.min"
                      label="Min Time (seconds)"
                      type="number"
                      min={0}
                      helperText="Enter time in seconds"
                    />
                    <TextInput
                      source="expected_time_range.max"
                      label="Max Time (seconds)"
                      type="number"
                      min={0}
                      helperText="Enter time in seconds"
                    />
                  </div>
                  {/* monitoring_activity_rules */}
                  <h3 className="text-lg font-semibold">
                    Monitoring Activity Rules
                  </h3>
                  <SwitchInput
                    source="monitoring_activity_rules.create_note"
                    label="Create Monitoring Note?"
                  />
                  <MultiSelectInput
                    label="Monitoring Types"
                    source="monitoring_activity_rules.monitoring_types"
                    choices={Object.entries(monitoringActivityTypes).map(
                      ([k, v]) => ({
                        name: v,
                        id: k,
                      }),
                    )}
                  />
                  <ActivityItemForm />
                </div>
              </CardContent>
              <CardFooter className="py-2">
                <div className="flex justify-end w-full space-x-2 pt-2">
                  <Button type="submit" size="sm" className="h-8">
                    Save
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
          <div>
            <StepPreview />
          </div>
        </div>
      </Form>
    </div>
  );
};
