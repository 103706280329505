import { fetchUtils } from "react-admin";
import inMemoryJWT from "./inMemoryJWT";
import { processAPIError } from "./processAPIError";

export const httpClient = async (url: any, options: any) => {
	if (!options) {
		options = {};
	}
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}

	const token = await inMemoryJWT.getToken("httpClient");

	options.headers.set("Authorization", `Bearer ${token}`);

	try {
		// Attempt to fetch the JSON response
		const response = await fetchUtils.fetchJson(url, options);
		return response;
	} catch (error: any) {
		if (error.status === 422) {
			// Extract the first error message from the Pydantic error response
			// if .detail is an array, use the first element's .msg, otherwise use .detail

			if (typeof error.body?.message === "string") {
				throw new Error(error.body.message);
			}

			if (error.body?.detail) {
				const message = processAPIError(error);
				throw new Error(message);
			}

			throw error;
		}

		if (error.status === 500) {
			throw new Error("Internal server error");
		}

		// Re-throw the error to allow further handling if necessary
		throw error;
	}
};
