import { DataTableFilterSingle } from "@/modules/data-table/components/data-table-filter-single";
import { DataTableToolbar } from "@/modules/data-table/components/data-table-toolbar";
import { Badge } from "@/modules/ui/components/badge";
import { Button } from "@/modules/ui/components/button";
import { Card } from "@/modules/ui/components/card";
import ConfirmActionButton from "@/modules/ui/components/confirm-action-button";
import { LoadingSpinner } from "@/modules/ui/components/loading-spinner";
import { cn } from "@/modules/ui/utils/cn";
import { Check, RefreshCcw } from "lucide-react";
import { DateTime } from "luxon";
import {
	ListContextProvider,
	RecordContextProvider,
	useGetIdentity,
	useRecordContext,
	useDataProvider,
	useRefresh,
	useUpdate,
} from "ra-core";
import { useQueryClient } from "@tanstack/react-query";
import { TaskEditButton } from "./TaskEditButton";
import { TaskActivities } from "./TaskActivities";
import { Separator } from "@/modules/ui/components/separator";
import { useInfiniteQuery } from "@tanstack/react-query";
import { DataTableFilterButton } from "@/modules/data-table/components/data-table-filter-button";
import { usePatientTableState } from "@/modules/common/hooks/usePatientTableState";
import { TaskFilters } from "./TaskFilters";

// Task model interface
interface Task {
	id: number;
	title: string;
	description?: string;
	due_on?: string;
	created_on?: string;
	task_type: "recurring" | "onboarding" | "one_off";
	complete: boolean;
	completed_at?: string;
	priority?: number;
	task_class?: string;
	assigned_to_id?: number;
	completed_by_id?: number;
	added_by_id?: number;
	added_by_system?: boolean;
	can_be_auto_completed?: boolean;
	assigned_to_name?: string;
	completed_by_name?: string;
	added_by_name?: string;
}

const TaskCompleteButton = () => {
	const task = useRecordContext();
	const { identity } = useGetIdentity();

	const [update, { isLoading }] = useUpdate();
	const refresh = useRefresh();
	const queryClient = useQueryClient();

	const toggleDone = () => {
		update(
			"tasks",
			{
				id: task.id,
				data: { ...task, complete: true, completed_by_id: identity?.id },
			},
			{
				onSuccess: () => {
					refresh();
					queryClient.invalidateQueries({ queryKey: ["tasks", "getList"] });
				},
			},
		);
	};

	if (task.completed_by_id) {
		return null;
	}

	if (task.can_be_auto_completed) {
		return (
			<ConfirmActionButton
				action={toggleDone}
				cancelText="Cancel"
				isLoading={isLoading}
				Icon={Check}
				dialogTitle="Complete Task"
				dialogDescription="This task is marked as completed automatically. Do you want to complete it anyway?"
				buttonVariant="ghost"
				buttonClassName="w-fit"
				iconClassName="h-6 w-6  text-green-500 stroke-[4px]"
				confirmText="Complete"
			/>
		);
	}
	return (
		<Button variant="ghost" onClick={toggleDone} className="w-fit">
			<Check className="h-6 w-6  text-green-500  stroke-[4px]" />
		</Button>
	);
};

// TaskItem component
const TaskItem: React.FC<{ task: Task; toggleDone: (id: number) => void }> = ({
	task,
}) => {
	const record = useRecordContext();

	return (
		<RecordContextProvider value={task}>
			<Card className="mb-2.5 p-4 border rounded-lg mx-2    ">
				<div className="flex flex-col gap-1  ">
					<div className="flex flex-row items-center justify-between ">
						<div className="flex flex-row items-center gap-2">
							<Badge
								variant="outline"
								className={cn(
									task.complete && "border-green-500",
									!task.complete && "border-yellow-500 bg-yellow-50",
								)}
							>
								{task.complete ? "Completed" : "Open"}
							</Badge>
							{task.priority ? (
								<Badge variant="outline">P{task.priority}</Badge>
							) : null}
						</div>
						<div className="flex flex-row items-center ">
							<TaskEditButton patient_tenant_id={record?.user.tenant_id} />
							<TaskCompleteButton />
						</div>
					</div>
					<p className="text-md font-bold">{task.title}</p>
					<p className="text-md">{task.description}</p>
					<Separator />
					<div className="flex flex-col  gap-0.5   ">
						<p className="text-sm">
							Added on: {DateTime.fromISO(task.created_on).toLocaleString()}
						</p>
						<p className="text-sm">
							Due on:{" "}
							{task.due_on
								? DateTime.fromISO(task.due_on).toLocaleString()
								: "No due date"}
						</p>
						<p className="text-sm">
							{`Assigned to: `}
							<span className="font-bold">{task?.assigned_to_name}</span>
						</p>
						{task.completed_by_id && (
							<p className="text-sm">
								{"Completed by: "}
								<span className="font-bold">{task?.completed_by_name}</span>
							</p>
						)}
						{task.added_by_name && (
							<p className="text-sm">
								{"Added by: "}
								<span className="font-bold">{task?.added_by_name}</span>
							</p>
						)}
					</div>
					<TaskActivities showSeperator />
				</div>
			</Card>
		</RecordContextProvider>
	);
};

// TaskList component
export function TaskList() {
	const { identity } = useGetIdentity();
	const record = useRecordContext();
	const dataProvider = useDataProvider();

	const pagination = {
		page: 1,
		perPage: 10,
	};

	const { filterValues, setFilters } = usePatientTableState(
		"tasks",
		record?.id,
		{
			defaultFilterValues: {
				"complete[eq]": false,
				"assigned_to_id[eq]": identity?.id.toString(),
			},
		},
	);

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useInfiniteQuery({
			queryKey: ["tasks", { id: record?.id, ...filterValues }],
			queryFn: ({ pageParam = pagination.page }) =>
				dataProvider
					.getList("tasks", {
						pagination: {
							page: pageParam,
							perPage: pagination.perPage,
						},
						sort: { field: "created_at", order: "DESC" },
						filter: {
							"patient_id[eq]": record?.id,
							...filterValues,
						},
					})
					.then(({ data, total, pageInfo }) => ({
						data,
						total,
						pageInfo,
						pageParam,
					})),
			initialPageParam: pagination.page,
			enabled: !!record?.id,
			getNextPageParam: (lastLoadedPage) => {
				if (lastLoadedPage.pageInfo) {
					return lastLoadedPage.pageInfo.hasNextPage
						? lastLoadedPage.pageParam + 1
						: undefined;
				}
				const totalPages = Math.ceil(
					(lastLoadedPage.total || 0) / pagination.perPage,
				);

				return lastLoadedPage.pageParam < totalPages
					? Number(lastLoadedPage.pageParam) + 1
					: undefined;
			},
		});

	if (!record) {
		return null;
	}

	if (isLoading) {
		return (
			<div className="w-full h-96 flex items-center justify-center">
				<LoadingSpinner />
			</div>
		);
	}

	const tasks = data?.pages.flatMap((page) => page.data) || [];

	return (
		<div className="h-full overflow-y-auto">
			<ListContextProvider
				// @ts-ignore
				value={{ filterValues, setFilters, displayedFilters: {} }}
			>
				<DataTableToolbar
					showViewOptions={false}
					search={false}
					className="px-2"
				>
					<Button
						variant="ghost"
						className="w-fit"
						onClick={() => fetchNextPage()}
						title="Refresh"
					>
						<RefreshCcw
							className={cn("h-4 w-4", isFetchingNextPage && "animate-spin")}
						/>
					</Button>
					<DataTableFilterButton>
						<DataTableFilterSingle
							column="complete[eq]"
							title="Completed"
							options={[
								{ label: "Yes", value: true },
								{ label: "No", value: false },
							]}
						/>
						<DataTableFilterSingle
							column="assigned_to_id[eq]"
							title="My Tasks"
							options={[{ label: "Yes", value: identity?.id.toString() }]}
						/>
						{...TaskFilters}
					</DataTableFilterButton>
				</DataTableToolbar>
				<div className="flex flex-col gap-2 p-2 pt-0 w-fit">
					{tasks.map((task) => (
						<TaskItem key={task.id} task={task} toggleDone={() => {}} />
					))}
					{hasNextPage && (
						<button
							onClick={() => fetchNextPage()}
							disabled={isFetchingNextPage}
							className="w-full text-sm text-blue-600 hover:text-blue-800 mt-2"
						>
							{isFetchingNextPage ? "Loading more..." : "Load more"}
						</button>
					)}
				</div>
			</ListContextProvider>
		</div>
	);
}
