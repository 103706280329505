import React from 'react';
import { Form, SaveButton, Title } from 'react-admin';

export const BaseForm = (props) => {
  const { title, saveButton = <SaveButton />, children, ...rest } = props;
  return (
    <div className="p-4">
      <Title title={title} />
      <Form {...rest}>
        <div className="flex flex-col space-y-4">
          <div>
            <div className="flex justify-end">
              {saveButton && <div>{saveButton}</div>}
            </div>
          </div>
          <div>{children}</div>
        </div>
      </Form>
    </div>
  );
};
