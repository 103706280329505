import React from 'react';
import {
  Form,
  ReferenceInput,
  required,
  useDelete,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import RefreshSubscriptionButton from './RefreshSubscriptionButton';

import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Card, CardContent, CardFooter } from '@/modules/ui/components/card';

import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import ConfirmActionButton from '@/modules/ui/components/confirm-action-button';
import { NumberInput } from '@/modules/ui/inputs/number-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { TextInput } from '@/modules/ui/inputs/text-input';
import { get } from 'lodash';
import { Lightbulb, Trash2 } from 'lucide-react';
import { CreateFeeForServiceButton } from './CreateFeeForServiceButton';
import { FeeForServiceList } from './FeeForServiceList';
import { InvoicesList } from './InvoiceList';
import { PreviewInvoiceButton } from './PreviewInvoice';

const formatPriceLabel = (price) => {
  const price_id_last_four = price.id.slice(-4);
  if (get(price, 'recurring.interval') === 'month') {
    return `${price_id_last_four} - $${
      price.unit_amount / 100
    } ${price.currency.toUpperCase()} / month`;
  }
  return `${price_id_last_four} - $${
    price.unit_amount / 100
  } ${price.currency.toUpperCase()}`;
};

export const BillingSection = () => {
  const record = useRecordContext();
  const [deleteOne, { isLoading }] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  if (!record) {
    return null;
  }

  if (!!!get(permissions, 'tenants.billing')) {
    return (
      <Alert className="border-none w-fit mr-2">
        <Lightbulb className="h-5 w-5 mr-2" />
        <AlertTitle>No access</AlertTitle>
        <AlertDescription>
          You do not have access to billing settings.
        </AlertDescription>
      </Alert>
    );
  }

  const clearSubscriptions = () => {
    deleteOne(
      `providers/${record?.id}`,
      {
        id: 'subscription',
      },
      {
        onSuccess: () => {
          refresh();
          notify('Subscriptions cleared', { type: 'info' });
        },
        onError(error) {
          notify((error as Error)?.message, { type: 'error' });
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4 mb-6">
      <Card>
        <Form sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div>
              <p className="text-md font-medium">Billing Scheme</p>
              <div className="flex flex-row  space-x-4 items-center">
                <SelectInput
                  source="billing_scheme"
                  helperText={false}
                  validate={required()}
                  choices={[
                    { id: 'subscription', name: 'Subscription' },
                    { id: 'fee_for_service', name: 'Fee for Service' },
                    {
                      id: 'hybrid_fee_for_service',
                      name: 'Hybrid Fee for Service',
                    },
                  ]}
                />
                {record?.stripe_subscription_id &&
                  record?.billing_scheme !== 'subscription' && (
                    <ConfirmActionButton
                      action={clearSubscriptions}
                      actionText="Clear Subscriptions"
                      confirmText="Clear Subscriptions"
                      Icon={Trash2}
                      cancelText="Cancel"
                      dialogTitle="Are you sure you want to clear all subscriptions?"
                      dialogDescription="This will remove all subscriptions for this provider. This action cannot be undone."
                      isLoading={isLoading}
                      buttonVariant="destructive"
                      buttonType="button"
                    />
                  )}
              </div>
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>

      <Card>
        <Form sanitizeEmptyValues>
          <CardContent className="mt-4">
            <div>
              <p className="text-md font-medium">Stripe</p>
              <div className="flex flex-1 space-x-4 items-center">
                <SwitchInput
                  source="stripe_billing_enabled"
                  label="Enabled"
                  helperText={false}
                />
                <TextInput
                  source="stripe_customer_id"
                  label="Stripe Customer ID"
                  helperText={false}
                />
              </div>
            </div>
            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </CardContent>
        </Form>
      </Card>

      <Card>
        <CardContent className="mt-4">
          <div className="flex flex-col gap-2 ">
            <p className="text-lg font-medium">Invoices</p>
            {record?.billing_scheme === 'fee_for_service' ||
            record?.billing_scheme === 'hybrid_fee_for_service' ? (
              <div className="flex flex-1 space-x-4 items-center">
                <PreviewInvoiceButton />
              </div>
            ) : null}
            <InvoicesList />
          </div>
        </CardContent>
      </Card>
      {record?.billing_scheme === 'subscription' && (
        <Card>
          <CardContent className="mt-4">
            <Form sanitizeEmptyValues>
              <div className="flex flex-col gap-2">
                <div>
                  <p className="text-md font-medium">Subscription Pricing </p>
                  <p className="text-sm">
                    Keep blank to use default prices for each product on stripe.
                  </p>
                  <p className="text-sm">
                    Configure options by adding prices in stripe for each
                    product.
                  </p>
                </div>
                <div className="flex flex-1 space-x-4 items-center">
                  {['simple', 'complex', 'software_only', 'ccm_only'].map(
                    (item) => (
                      <ReferenceInput
                        source={`stripe_${item}_price_id`}
                        reference={`billing/product-prices/${item}`}
                      >
                        <SelectInput
                          source={`stripe_${item}_price_id`}
                          helperText={false}
                          optionText={formatPriceLabel}
                        />
                      </ReferenceInput>
                    ),
                  )}
                </div>
                <div className="flex flex-1 space-x-4 items-center mt-2">
                  <p>Manually refresh customer's subscription</p>
                  <RefreshSubscriptionButton />
                </div>
              </div>
              <CardFooter className="float-right mt-2">
                <SaveButton variant="default" className="h-8" />
              </CardFooter>
            </Form>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardContent className="mt-4">
          <Form sanitizeEmptyValues>
            <div className="flex flex-col gap-2">
              <div>
                <p className="text-md font-medium">
                  Patient Initiation Fee Price
                </p>
              </div>
              <div className="flex flex-1 space-x-4 items-center">
                <NumberInput source="initiation_fee_price" />
              </div>
            </div>

            <CardFooter className="float-right mt-2">
              <SaveButton variant="default" className="h-8" />
            </CardFooter>
          </Form>
        </CardContent>
      </Card>

      {record?.billing_scheme === 'fee_for_service' ||
      record?.billing_scheme === 'hybrid_fee_for_service' ? (
        <Card>
          <CardContent className="mt-4">
            <div className="flex flex-col gap-2 ">
              <p className="text-lg font-medium">
                Fee for Service Pricing Table
              </p>
              <div className="flex flex-1 space-x-4 items-center">
                <CreateFeeForServiceButton
                  isHybrid={record?.billing_scheme === 'hybrid_fee_for_service'}
                />
              </div>
              <FeeForServiceList />
            </div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};
