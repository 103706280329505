import React from "react";
import {
	required,
	useCreate,
	useGetIdentity,
	useRecordContext,
} from "react-admin";
import EditInDialogButton from "@/modules/ra-ui/components/edit-dialog-button";
import { Button } from "@/modules/ui/components/button";
import { DateTimeInput } from "@/modules/ui/inputs/date-time-input";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
const filterUserName = (searchText) => ({
	q: { field: "full_name", value: searchText },
});

export function EnrollmentInfoEdit() {
	const record = useRecordContext();
	const [create] = useCreate();
	const { identity } = useGetIdentity();

	const transform = (data) => {
		return {
			enrolled_by_id: data.enrolled_by_id,
			user: {
				enrolled_on: data.user.enrolled_on,
			},
		};
	};

	if (!record) return null;

	if (
		!identity?.is_internal ||
		!["internal_admin", "superadmin"].includes(identity?.role)
	) {
		return null;
	}

	const logMonitoringNote = (data) => {
		const patient_id = record?.id;

		create("monitoring-activities", {
			data: {
				patient_id: patient_id,
				practitioner_id: identity?.id,
				type: "activity",
				description: `Patient enrollment details updated: enrolled_by_id: ${data.enrolled_by_id}, enrolled_on: ${data.enrolled_on}`,
			},
		});
	};

	const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	return (
		<EditInDialogButton
			title="Update enrollment details"
			resource="patients"
			transform={transform}
			notifyMessage="Enrollment details updated"
			refreshOnSuccess={true}
			onSuccess={(data) => {
				logMonitoringNote(data);
			}}
			editButton={
				<Button variant="outline" className="w-fit">
					Edit
				</Button>
			}
			dialogVariant="shadcn"
		>
			<div className="flex flex-col gap-4">
				<AutocompleteInput
					source="enrolled_by_id"
					reference="users"
					filter={{
						"role[ne]": "patient",
						"tenant_id[in]": [record?.user?.tenant_id, null],
					}}
					filterToQuery={filterUserName}
					label="Enrolled by"
					optionText={(choice) => {
						if (choice.is_internal) {
							return `${choice.first_name} ${choice.last_name} (Vironix) ${
								choice.credentials ? `(${choice.credentials})` : ""
							}`;
						}
						return `${choice.full_name} ${
							choice.credentials ? `(${choice.credentials})` : ""
						}`;
					}}
					parse={(v) => (v === "" ? null : v)}
					helperText={false}
					validate={required()}
				/>
				<DateTimeInput
					source="user.enrolled_on"
					label="Enrolled on"
					helperText={false}
					validate={required()}
					timezone={localTimezone}
				/>
			</div>
		</EditInDialogButton>
	);
}
