import DeviceExternalIdField from '@/modules/devices/components/DeviceExternalIdField';
import { deviceTypes } from '@/modules/devices/constants/deviceTypes';
import AppTitle from '@/modules/layout/components/app-title';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import ProviderField from '@/modules/ra-ui/components/ProviderField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { ShowSimpleGrid } from '@/modules/ra-ui/components/show-simple-grid';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { cn } from '@/modules/ui/utils/cn';
import { titleize } from 'inflection';
import { get } from 'lodash';
import React, { FC } from 'react';
import {
  BooleanField,
  FunctionField,
  ReferenceField,
  ShowBase,
  TextField,
  useDataProvider,
  useGetList,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useInfiniteQuery } from '@tanstack/react-query';
import DeviceActionsToolbar from '../../modules/devices/components/DeviceActionsToolbar';
import DeviceEventItem from '../../modules/devices/components/DeviceEventItem';
import DeviceLocationState from '../../modules/devices/components/DeviceLocationState';
import { EventListView } from '../../modules/devices/components/EventListView';
import { Battery, Clock, Signal } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { DateTime } from 'luxon';
import { DeviceOneTimeFeeTable } from '@/modules/devices/components/DeviceOneTimeFees';

const GatewayIDField = (props) => {
  const record = useRecordContext();
  if (record.device_manufacturer === 'tenovi') {
    return (
      <TextField
        source="misc_data.device.hardware_uuid"
        label="Tenovi Gateway ID"
      />
    );
  }
  return null;
};

type OneTimeFee = {
  id: string;
  fee_type: string;
  timestamp: string;
  amount: number;
  // Add other relevant fields based on your data structure
};

const DeviceShowUI = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const oneTimeFees = useGetList<OneTimeFee>(
    `devices/${record?.id}/one-time-fees`,
    { pagination: { page: 1, perPage: 100 } },
    { enabled: !!record?.id },
  );

  const latestDeviceFee = React.useMemo(() => {
    if (oneTimeFees.data) {
      return oneTimeFees.data
        .filter((fee) => fee.fee_type === 'device_fee')
        .sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
        )[0];
    }
    return null;
  }, [oneTimeFees.data]);

  return (
    <div className="mb-4">
      <DeviceActionsToolbar />
      <Card className="mb-4 max-h-[40vh]">
        <CardContent>
          <div className="flex flex-row gap-2 py-2">
            <ShowSimpleGrid className="2xl:grid-cols-4">
              <FunctionField
                source="device_manufacturer"
                render={(record) =>
                  titleize(
                    record.device_manufacturer
                      ? record.device_manufacturer
                      : '',
                  )
                }
              />
              <LuxonDateField showTime source="added_on" />
              <BadgeField source="device_type" mapping={deviceTypes} />
              <BadgeField source="status" />
              <DeviceExternalIdField label="IMEI" />
              <ProviderField label="In use by provider" />
              <BooleanField source="is_shared_device" />
              <ReferenceField
                source="patient_id"
                reference="patients"
                link="show"
                emptyText="Not assigned to any patient"
              >
                <TextField source="user.full_name" />
              </ReferenceField>

              <ReferenceField
                source="location_id"
                reference="locations"
                link="show"
                emptyText="Not at any location"
              >
                <TextField source="name" />
              </ReferenceField>
              <DeviceLocationState label="Location State" />
              {record?.device_manufacturer === 'tenovi' && (
                <GatewayIDField
                  source="misc_data.device.hardware_uuid"
                  label="Tenovi Gateway ID"
                />
              )}
              {get(permissions, 'is_internal') && (
                <FunctionField
                  label="Device Invoiced?"
                  render={() => {
                    if (latestDeviceFee) {
                      const formattedDate = DateTime.fromISO(
                        latestDeviceFee.timestamp,
                      ).toLocaleString(DateTime.DATE_SHORT);
                      return `Yes (${formattedDate})`;
                    } else {
                      return 'No';
                    }
                  }}
                />
              )}

              <FunctionField
                label="Device Status"
                source="misc_data.status"
                render={(record) =>
                  record?.misc_data?.status ? (
                    <div className="text-sm">
                      <p className="flex items-center">
                        <Battery className="w-4 h-4 mr-2" />
                        <span>{record.misc_data.status.battery}%</span>
                      </p>
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger>
                            <p className="flex items-center">
                              <Signal className="w-4 h-4 mr-2" />
                              <span>
                                {record.misc_data.status.signal} -{' '}
                                {record.misc_data.status.network}
                              </span>
                            </p>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="text-sm">
                              <p className="font-bold text-xs mb-1">
                                Signal strength:
                              </p>
                              <p>0: No signal</p>
                              <p>1: Poor signal</p>
                              <p>2: Fair signal</p>
                              <p>3: Good signal</p>
                              <p>4: Excellent signal</p>
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <p className="flex items-center">
                        <Clock className="w-4 h-4 mr-2" />
                        <span>
                          {DateTime.fromISO(
                            record.misc_data.status.last_updated,
                            { zone: 'utc' },
                          )
                            .toLocal()
                            .toLocaleString(DateTime.DATETIME_SHORT)}
                        </span>
                      </p>
                    </div>
                  ) : (
                    'Not Reported'
                  )
                }
              />
            </ShowSimpleGrid>
          </div>
        </CardContent>
      </Card>
      <div className={cn('grid gap-4 grid-cols-2')}>
        <Card>
          <CardContent className="">
            <CardHeader>
              <CardTitle>Measurements</CardTitle>
              <CardDescription>
                Please use the patient's chart to view all measurements.
              </CardDescription>
            </CardHeader>
            {record?.first_measurement || record?.last_measurement ? (
              <div className="px-6 mb-4 flex flex-row items-center space-x-4">
                {record.first_measurement && (
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500">
                      First Measurement
                    </span>
                    <LuxonDateField
                      source="first_measurement"
                      showTime
                      className="text-lg font-semibold"
                    />
                  </div>
                )}
                {record.last_measurement && (
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500">
                      Latest Measurement
                    </span>
                    <LuxonDateField
                      source="last_measurement"
                      showTime
                      className="text-lg font-semibold"
                    />
                  </div>
                )}
              </div>
            ) : null}
            <TimelineView
              activityType="measurement"
              filter={{
                'activity_type[eq]': 'measurement',
              }}
              allowFetchMore={false}
            />
          </CardContent>
        </Card>
        {get(permissions, 'is_internal') === true && (
          <Card>
            <CardContent>
              <CardHeader>
                <CardTitle>Events</CardTitle>
              </CardHeader>
              <TimelineView
                activityType="measurement"
                filter={{
                  'activity_type[ne]': 'measurement',
                }}
                itemComponent={DeviceEventItem}
              />
            </CardContent>
          </Card>
        )}
        {get(permissions, 'is_internal') === true && (
          <Card>
            <CardContent>
              <CardHeader>
                <CardTitle>Charges</CardTitle>
              </CardHeader>
              <DeviceOneTimeFeeTable
                data={oneTimeFees.data}
                isLoading={oneTimeFees.isLoading}
                total={oneTimeFees.total}
              />
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export const DeviceShow: FC = () => {
  return (
    <>
      <AppTitle title="Device" />
      <ShowBase>
        <DeviceShowUI />
      </ShowBase>
    </>
  );
};

const TimelineView = (props) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { filter, ...rest } = props;

  const pagination = {
    page: 1,
    perPage: 20,
  };
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['device-activities', { id: record?.id }, filter],
      queryFn: ({ pageParam = pagination.page }) =>
        dataProvider
          .getList(`device-activities`, {
            pagination: {
              page: pageParam,
              perPage: pagination.perPage,
            },
            sort: { field: 'created_on', order: 'DESC' },
            filter: {
              ...filter,
              'device_id[eq]': record?.id,
            },
          })
          .then(({ data, total, pageInfo }) => ({
            data,
            total,
            pageInfo,
            pageParam,
          })),
      initialPageParam: pagination.page,
      enabled: true,
      getNextPageParam: (lastLoadedPage) => {
        if (lastLoadedPage.pageInfo) {
          return lastLoadedPage.pageInfo.hasNextPage
            ? lastLoadedPage.pageParam + 1
            : undefined;
        }
        const totalPages = Math.ceil(
          (lastLoadedPage.total || 0) / pagination.perPage,
        );

        return lastLoadedPage.pageParam < totalPages
          ? Number(lastLoadedPage.pageParam) + 1
          : undefined;
      },
      getPreviousPageParam: (lastLoadedPage) => {
        if (lastLoadedPage.pageInfo) {
          return lastLoadedPage.pageInfo.hasPreviousPage
            ? lastLoadedPage.pageParam - 1
            : undefined;
        }

        return lastLoadedPage.pageParam === 1
          ? undefined
          : lastLoadedPage.pageParam - 1;
      },
    });

  return (
    <EventListView
      items={data?.pages.map((page) => page.data).flat() || []}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      {...rest}
    />
  );
};
