import {
  useCreate,
  useDelete,
  useGetList,
  useGetOne,
  useNotify,
} from 'ra-core';
import { useRecordContext } from 'ra-core';
import type React from 'react';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  type ReactNode,
} from 'react';
import type { UseFormReturn } from 'react-hook-form';

export interface FormValues {
  notes: string;
  isComplete: boolean;
  checklist: Record<string, boolean | { value: boolean }>;
  additional_notes?: string;
  verify_and_submit?: boolean;
  [key: string]: any; // Allow dynamic fields for resource references
}

interface PatientClinicalActivityContextType {
  // Available activities
  availableActivities: any[];
  availableActivitiesLoading: boolean;
  availableActivitiesFetching: boolean;
  availableActivitiesError: Error | null;
  reloadAvailableActivities: () => Promise<any>;

  // Activity template
  activityTemplate: any | null;
  activityTemplateLoading: boolean;
  activityTemplateError: Error | null;

  // In-progress activity
  inProgressActivity: any | null;
  inProgressActivityLoading: boolean;
  inProgressActivityId: any | null;
  inProgressForm: UseFormReturn<FormValues> | null;
  startActivity: (activity: any) => void;
  completeActivity: (formData: any) => void;
  cancelActivity: () => void;
  discardActivity: () => void;
}

// Create the context
const PatientClinicalActivityContext = createContext<
  PatientClinicalActivityContextType | undefined
>(undefined);

// Provider component
export const PatientClinicalActivityProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  // State for available activities
  const record = useRecordContext();

  // State for in-progress activity
  const [inProgressActivityId, setInProgressActivityId] = useState<any | null>(
    null,
  );
  const [inProgressForm, setInProgressForm] =
    useState<UseFormReturn<FormValues> | null>(null);

  const {
    data: availableActivities,
    isLoading: availableActivitiesLoading,
    error: availableActivitiesError,
    refetch: reloadAvailableActivities,
    isFetching: availableActivitiesFetching,
  } = useGetList(
    'clinical-activities/available',
    {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'title', order: 'ASC' },
      meta: {
        patient_ids: [record?.id],
      },
    },
    {
      enabled: !!record?.id,
    },
  );

  const notify = useNotify();

  const { data: inProgressActivity, isLoading: inProgressActivityLoading } =
    useGetOne(
      'clinical-activity-instances',
      {
        id: inProgressActivityId,
      },
      {
        enabled: !!inProgressActivityId,
      },
    );

  const {
    data: activityTemplate,
    isLoading: activityTemplateLoading,
    error: activityTemplateError,
  } = useGetOne(
    'activity-templates',
    {
      id: inProgressActivity?.activity_template_id,
    },
    {
      enabled: !!inProgressActivity?.activity_template_id,
    },
  );

  const [create] = useCreate();
  const [deleteOne] = useDelete();

  // Start a new activity
  const startActivity = (activity: any) => {
    if (!activity) {
      return;
    }

    if (activity.pending_clinical_activity_instance_id) {
      setInProgressActivityId(activity.pending_clinical_activity_instance_id);
    } else {
      create(
        'clinical-activity-instances',
        {
          data: {
            patient_id: record?.id,
            activity_template_family_id:
              activity.activity_template.activity_template_family_id,
            activity_template_id: activity.id,
          },
        },
        {
          onSuccess: (data) => {
            setInProgressActivityId(data.id);
          },
        },
      );
    }

    // You would typically initialize the form here
    // setInProgressForm(useForm<FormValues>({ defaultValues: {} }));
    // Since we can't use hooks in callbacks, this will need to be handled by the component using this context
  };

  // // Complete an activity
  const completeActivity = (formData: FormValues) => {
    if (!inProgressActivity) {
      throw new Error('No activity in progress');
    }

    create(
      `clinical-activity-instances/${inProgressActivity.id}/complete`,
      {
        data: {
          data_json: formData,
        },
      },
      {
        onSuccess: () => {
          setInProgressActivityId(null);
          setInProgressForm(null);
          reloadAvailableActivities();
          notify('Activity completed successfully', { type: 'success' });
        },
        onError: (error) => {
          notify('Error completing activity', { type: 'error' });
        },
      },
    );
  };

  // Discard the current activity
  const discardActivity = () => {
    if (!inProgressActivityId) {
      return;
    }

    deleteOne(
      'clinical-activity-instances',
      {
        id: inProgressActivityId,
      },
      {
        onSuccess: () => {
          setInProgressActivityId(null);
          setInProgressForm(null);
          reloadAvailableActivities();
        },
      },
    );
  };

  // Cancel the current activity
  const cancelActivity = useCallback(() => {
    setInProgressActivityId(null);
    setInProgressForm(null);
    reloadAvailableActivities();
  }, [reloadAvailableActivities]);

  // Context value
  const value = {
    availableActivities,
    availableActivitiesLoading,
    availableActivitiesError,
    availableActivitiesFetching,
    reloadAvailableActivities,
    inProgressActivity,
    inProgressActivityLoading,
    inProgressActivityId,
    activityTemplate,
    activityTemplateLoading,
    activityTemplateError,
    inProgressForm,
    startActivity,
    completeActivity,
    cancelActivity,
    discardActivity,
  };

  return (
    <PatientClinicalActivityContext.Provider value={value}>
      {children}
    </PatientClinicalActivityContext.Provider>
  );
};

// Custom hook to use the context
export const usePatientClinicalActivity = () => {
  const context = useContext(PatientClinicalActivityContext);
  if (context === undefined) {
    console.warn(
      'usePatientClinicalActivity must be used within a PatientClinicalActivityProvider',
    );
    return undefined;
  }
  return context;
};
