import { get } from 'lodash';
import { FolderArchive, RefreshCw } from 'lucide-react';
import { useMemo } from 'react';
import { ClinicalActivitySelectorItem } from './ClinicalActivitySelectorItem';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { usePatientClinicalActivity } from '../context/PatientClinicalActivityContext';
import { Button } from '@/modules/ui/components/button';
import { cn } from '@/modules/ui/utils/cn';
export const ClinicalActivitySelector = () => {
  const {
    availableActivities,
    availableActivitiesLoading,
    availableActivitiesError,
    availableActivitiesFetching,
    startActivity,
    reloadAvailableActivities,
  } = usePatientClinicalActivity();

  const sortedMainActivities = useMemo(() => {
    return availableActivities
      ?.filter((activity) => !activity.is_recommended && activity.is_eligible)
      .sort((a, b) => {
        if (
          a.pending_clinical_activity_instance_id &&
          !b.pending_clinical_activity_instance_id
        ) {
          return -1;
        }
        return 1;
      });
  }, [availableActivities]);
  const sortedRecommendedActivities = useMemo(() => {
    return availableActivities
      ?.filter((activity) => activity.is_recommended && activity.is_eligible)
      .sort((a, b) => {
        if (
          a.pending_clinical_activity_instance_id &&
          !b.pending_clinical_activity_instance_id
        ) {
          return -1;
        }
        return 1;
      });
  }, [availableActivities]);

  const unavailableActivities = useMemo(() => {
    return availableActivities?.filter((activity) => !activity.is_eligible);
  }, [availableActivities]);

  if (availableActivitiesLoading) {
    return (
      <div className="flex py-4 items-center justify-center">
        <LoadingSpinner className="h-6 w-6" />
      </div>
    );
  }

  if (availableActivitiesError) {
    return (
      <div className="flex py-4 items-center justify-center text-sm text-muted-foreground">
        Error loading activities
      </div>
    );
  }

  if (!availableActivities || availableActivities.length === 0) {
    return (
      <div className="flex py-4 flex-col items-center justify-center gap-2 text-muted-foreground">
        <FolderArchive className="h-8 w-8 opacity-70" />
        <p className="text-sm">No activities available</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 py-4 overflow-y-auto p-4">
      <div className="mb-4 flex justify-between items-center">
        <div>
          <h2 className="text-lg font-semibold">Select Activity Type</h2>
          <p className="text-sm text-muted-foreground">
            Choose the type of clinical activity to start
          </p>
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={reloadAvailableActivities}
          title="Reload activities"
        >
          <RefreshCw
            className={cn('h-5 w-5 ', {
              'animate-spin': availableActivitiesFetching,
            })}
          />
        </Button>
      </div>

      {/* Recommended Activities Section */}
      <div>
        <h3 className="text-md font-semibold">Recommended</h3>
        <div className="grid gap-3">
          {sortedRecommendedActivities.length > 0 ? (
            sortedRecommendedActivities
              .filter(
                (activity) => activity.is_recommended && activity.is_eligible,
              )
              .map((activity) => (
                <ClinicalActivitySelectorItem
                  key={activity.id}
                  title={get(activity, 'activity_template.activity_title', '')}
                  description={get(
                    activity,
                    'activity_template.activity_description',
                    '',
                  )}
                  onClick={() => startActivity(activity)}
                  lastCompleted={get(activity, 'last_completed_at', '')}
                  disabled={get(activity, 'disabled', false)}
                  inProgress={get(
                    activity,
                    'pending_clinical_activity_instance_id',
                    false,
                  )}
                />
              ))
          ) : (
            <div className="p-4 border rounded-md text-center text-muted-foreground">
              <p>No further action is recommended</p>
            </div>
          )}
        </div>
      </div>

      {/* Other Activities Section */}
      <div>
        <h3 className="text-md font-semibold">All Activities</h3>
        <div className="grid gap-3">
          {sortedMainActivities.map((activity) => (
            <ClinicalActivitySelectorItem
              key={activity.id}
              title={get(activity, 'activity_template.activity_title', '')}
              description={get(
                activity,
                'activity_template.activity_description',
                '',
              )}
              onClick={() => startActivity(activity)}
              lastCompleted={get(activity, 'last_completed_at', '')}
              disabled={get(activity, 'disabled', false)}
              inProgress={get(
                activity,
                'pending_clinical_activity_instance_id',
                false,
              )}
            />
          ))}
        </div>
      </div>
      {/* Unavailable Activities Section */}
      <div>
        <h3 className="text-md font-semibold">Unavailable</h3>
        <div className="grid gap-3">
          {unavailableActivities?.map((activity) => (
            <ClinicalActivitySelectorItem
              key={activity.id}
              title={get(activity, 'activity_template.activity_title', '')}
              description={get(
                activity,
                'activity_template.activity_description',
                '',
              )}
              lastCompleted={get(activity, 'last_completed_at', '')}
              disabled={true}
              inProgress={get(
                activity,
                'pending_clinical_activity_instance_id',
                false,
              )}
              unavailableReason={get(activity, 'reason', '')}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
