import { ProviderLocationFilter } from '@/modules/data-table/components/ProviderLocationFilter';
import { DataGridScroll } from '@/modules/data-table/components/data-grid-scroll';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { DataTableFilterButton } from '@/modules/data-table/components/data-table-filter-button';
import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { ListUIRoot } from '@/modules/data-table/components/list-ui-root';
import AppTitle from '@/modules/layout/components/app-title';
import { CareProgramDataTableFilter } from '@/modules/patients/components/CareProgramDataTableFilter';
import { PatientCareProgramArray } from '@/modules/patients/components/PatientCareProgramArray';
import { BulkAddToCareTeam } from '@/modules/patients/components/bulk-add-to-care-team';
import { patientStatus } from '@/modules/patients/constants/patientStatus';
import { primaryConditions } from '@/modules/patients/constants/primaryConditions';
import { BulkAssignToProviderButton } from '@/modules/ra-ui/components/BulkAssignToProviderButton';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { CreateButton } from '@/modules/ra-ui/components/create-button';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { get } from 'lodash';
import React, { type FC, Fragment } from 'react';
import {
  FunctionField,
  List,
  ReferenceField,
  TextField,
  usePermissions,
  useStore,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { calcAge } from '../../utils/calcAge';
import { CareTeamMemberFilter } from '@/modules/patients/components/CareTeamMemberFilter';

const Toolbar = ({ setSelectedTab, selectedTab }) => {
  const { permissions } = usePermissions();
  return (
    <div className="w-full">
      <TabbedWrapper
        options={[
          ...patientStatus.map((status) => ({
            title: status.name,
            value: status.id,
          })),
          {
            title: 'All',
            value: '',
          },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        <DataTableToolbar
          showViewOptions={true}
          createButton={
            get(permissions, 'patients.create') && (
              <CreateButton label="Patient" size="sm" />
            )
          }
          search={
            <MultiSearch
              searchFields={[
                { value: 'user.full_name', label: 'Name' },
                { value: 'id', label: 'ID' },
                { value: 'birthdate', label: 'DOB' },
                {
                  value:
                    'user.mobile_phone_number;user.home_phone_number;user.facility_phone_number',
                  label: 'Phone Number',
                },
              ]}
            />
          }
        >
          <DataTableFilterButton>
            <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
            <DataTableFilter
              column="care_setting[in]"
              title="Care Setting"
              options={[
                { label: 'Home', value: 'home' },
                {
                  label: 'Residential Care Facility',
                  value: 'residential_care_facility',
                },
              ]}
            />
            <DataTableFilter
              column="primary_condition[in]"
              title="Primary Condition"
              options={[
                { label: 'Asthma', value: 'Asthma' },
                { label: 'COPD', value: 'COPD' },
                { label: 'Diabetes', value: 'Diabetes' },
                { label: 'General', value: 'general' },
                { label: 'Heart Failure', value: 'HeartFailure' },
                {
                  label: 'Chronic Kidney Disease',
                  value: 'chronic_kidney_disease',
                },
              ]}
            />
            <DataTableReferenceFilter
              resource="users"
              column="billing_provider_id[eq]"
              title="Billing Provider"
              optionText="full_name"
              customFilter={(name) => ({
                ...(name &&
                  name.length > 1 && {
                    q: { field: 'full_name', value: name },
                  }),
                'is_clinician[eq]': true,
              })}
            />
            <DataTableFilterSingle
              column="billing_provider_id[exists]"
              title="Has Billing Provider"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
            <CareProgramDataTableFilter />
            <DataTableFilterSingle
              column="has_device[eq]"
              title="Has Devices"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
            <DataTableFilterSingle
              column="first_device_activity[exists]"
              title="Has Device Activity"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
            <CareTeamMemberFilter />
            <DataTableFilterSingle
              column="user.last_login[exists]"
              title="Has Mobile App"
              options={[
                { label: 'Has logged in', value: true },
                { label: 'Never logged in', value: false },
              ]}
            />
            <ProviderLocationFilter
              source="facility_location_id"
              label="Location"
              mode="show"
            />
          </DataTableFilterButton>
          {selectedTab === 'disabled' || selectedTab === '' ? (
            <DataTableDateRangeFilter
              column="unenrolled_date"
              title="Unenrolled On"
              dateOnly
              align="center"
            />
          ) : null}
          {selectedTab === 'consented' || selectedTab === '' ? (
            <DataTableDateRangeFilter
              column="consented_on"
              title="Consented On"
              dateOnly
              align="center"
            />
          ) : null}
          {selectedTab === 'consented' ? (
            <DataTableReferenceFilter
              resource="users"
              column="consented_by_id[eq]"
              title="Consented By"
              optionText="full_name"
              customFilter={(name) => ({
                ...(name &&
                  name.length > 1 && {
                    q: { field: 'full_name', value: name },
                  }),
              })}
            />
          ) : null}
        </DataTableToolbar>
      </TabbedWrapper>
    </div>
  );
};

const PatientBulkActionButtons = () => (
  <Fragment>
    <BulkAssignToProviderButton resource="users" />
    <BulkAddToCareTeam />
  </Fragment>
);

export const PatientList: FC = (props) => {
  const { permissions } = usePermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useStore(
    'patients.listParams.patientStatus',
    searchParams.get('status') || 'active',
  );

  const handleTabChange = (newTab: string) => {
    setSelectedTab(newTab);
    setSearchParams({ status: newTab });
  };

  return (
    <ListUIRoot>
      <AppTitle title="Patients" />
      <List
        {...props}
        actions={
          <Toolbar setSelectedTab={handleTabChange} selectedTab={selectedTab} />
        }
        exporter={false}
        filter={{
          ...(selectedTab !== '' && { 'patient_status[eq]': selectedTab }),
        }}
        empty={false}
        sort={{ field: 'user.registered_on', order: 'DESC' }}
        sx={{
          width: '100%',
          '& .RaList-main': {
            width: '100%',
          },
        }}
      >
        <DataGridScroll
          rowClick="show"
          bulkActionButtons={<PatientBulkActionButtons />}
          omit={[
            'patient.profile.demographics.age',
            'gender',
            'unenrolled_date',
          ]}
        >
          <BadgeField
            source="patient_status"
            label="Status"
            mapping={patientStatus}
          />
          <TextField
            source="user.full_name"
            label="Name"
            sortBy="user.last_name"
          />
          <TextField source="user.email" label="Email" />
          <TextField source="gender" label="Sex at Birth" />

          <FunctionField
            source="birthdate"
            label="Age"
            render={(record) =>
              record.birthdate ? `${calcAge(record.birthdate)}Y` : ''
            }
          />

          <BadgeField
            source="primary_condition"
            mapping={primaryConditions}
            applyMappedClassName={false}
            humanize={false}
          />

          {['active', 'disabled', ''].includes(selectedTab) ? (
            <LuxonDateField source="user.enrolled_on" label="Enrolled On" />
          ) : null}
          {selectedTab === 'disabled' || selectedTab === '' ? (
            <LuxonDateField source="unenrolled_date" label="Unenrolled On" />
          ) : null}
          {selectedTab === 'consented' ? (
            <LuxonDateField source="consented_on" label="Consented On" />
          ) : null}
          {selectedTab === 'declined' ? (
            <LuxonDateField source="declined_on" label="Declined On" />
          ) : null}
          {selectedTab === 'unseen' ? (
            <LuxonDateField source="unseen_on" label="Unseen On" />
          ) : null}
          {selectedTab === 'pending_enrollment' || selectedTab === '' ? (
            <LuxonDateField source="user.registered_on" label="Registered On" />
          ) : null}
          <ReferenceField
            label="Billing Provider"
            source="billing_provider_id"
            reference="users"
            link={false}
            emptyText="Not set"
          >
            <TextField source="full_name" />
          </ReferenceField>
          {get(permissions, 'is_internal') === true ? (
            <ReferenceField
              label="Provider"
              source="user.tenant_id"
              reference="providers"
              link={false}
              emptyText="None"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          ) : null}

          <PatientCareProgramArray label="Care Programs" />
        </DataGridScroll>
      </List>
    </ListUIRoot>
  );
};
