import React from 'react';
import { useRecordContext } from 'react-admin';
import { Badge } from '@/modules/ui/components/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';

const formatICD10Code = (value) => {
  // add dot after 3rd character ie. I501 -> I50.1
  const id = value.id.slice(0, 3) + '.' + value.id.slice(3);
  return `${id}`;
};

export const ICD10Chip = () => {
  const record = useRecordContext();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Badge variant="outline" className="mx-1">
            {formatICD10Code(record)}
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <p>{record.description}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
