import React from 'react';
import { cn } from '@/modules/ui/utils/cn';
import { useWatch } from 'react-hook-form';

interface TimeRangeIndicatorProps {
  minTime: number;
  maxTime: number;
  className?: string;
  source: string;
  onOutOfRangeChange?: (isOutOfRange: boolean) => void;
}

export function TimeRangeIndicator({
  minTime,
  maxTime,
  className,
  source,
  onOutOfRangeChange,
}: TimeRangeIndicatorProps) {
  // Convert seconds to minutes for display
  const minTimeMin = minTime / 60;
  const maxTimeMin = maxTime / 60;

  // Calculate warning zones (30 seconds = 0.5 minutes)
  const warningBefore = Math.max(0, minTimeMin - 0.5);
  const warningAfter = maxTimeMin + 0.5;
  // Adjust total width to include both warning zones
  const totalWidth = warningAfter - warningBefore;

  // Format numbers to 1 decimal place
  const formatTime = (timeInMinutes: number) => {
    return Number(timeInMinutes.toFixed(1));
  };

  //watch
  const watchTimeTaken = useWatch({
    name: source,
  });

  // Convert watchTimeTaken to minutes for positioning and clamp to valid range
  const currentTimeMin = watchTimeTaken ? watchTimeTaken / 60 : 0;
  const clampedTimeMin = Math.min(
    Math.max(currentTimeMin, warningBefore),
    warningAfter,
  );

  // Calculate percentages for positioning relative to the actual range
  const minPercent = ((minTimeMin - warningBefore) / totalWidth) * 100;
  const maxPercent = ((maxTimeMin - warningBefore) / totalWidth) * 100;
  const currentTimePercent =
    ((clampedTimeMin - warningBefore) / totalWidth) * 100;
  const warningBeforePercent = 0;
  const warningAfterPercent = 100; // Will always extend to the end

  // Check if time is out of range
  const isOutOfRange =
    currentTimeMin < warningBefore || currentTimeMin > warningAfter;

  // Notify parent component when isOutOfRange changes
  React.useEffect(() => {
    onOutOfRangeChange?.(isOutOfRange);
  }, [isOutOfRange, onOutOfRangeChange]);

  return (
    <div className={cn('space-y-1 mx-2', className)}>
      {/* Reference Time label */}
      <div className="text-sm font-medium text-gray-700 mb-1">
        Reference Time
      </div>
      {/* Current time text */}
      <div className="relative w-full h-5">
        {watchTimeTaken > 0 && (
          <span
            className={cn(
              'absolute text-sm -translate-x-1/2 bottom-0 font-bold',
              isOutOfRange ? 'text-red-500' : 'text-blue-500',
            )}
            style={{ left: `${currentTimePercent}%` }}
          >
            {formatTime(currentTimeMin)} min
          </span>
        )}
      </div>
      {/* Time range indicator */}
      <div className="relative w-full h-2 rounded-full">
        {/* Warning zone before */}
        <div
          className="absolute h-full bg-orange-200 rounded-full"
          style={{
            left: `${warningBeforePercent}%`,
            width: `${minPercent - warningBeforePercent}%`,
          }}
        />
        {/* Target range */}
        <div
          className="absolute h-full bg-green-500 rounded-full"
          style={{
            left: `${minPercent}%`,
            width: `${maxPercent - minPercent}%`,
          }}
        />
        {/* Warning zone after */}
        <div
          className="absolute h-full bg-orange-200 rounded-full"
          style={{
            left: `${maxPercent}%`,
            width: `${warningAfterPercent - maxPercent}%`,
          }}
        />
        {/* Current time tick */}
        {watchTimeTaken > 0 && (
          <div
            className="absolute w-[2px] h-full bg-blue-500"
            style={{ left: `${currentTimePercent}%` }}
          />
        )}
        {/* Ticks */}
        <div
          className="absolute w-[2px] h-3 bg-gray-400 -bottom-1"
          style={{ left: `${minPercent}%` }}
        />
        <div
          className="absolute w-[2px] h-3 bg-gray-400 -bottom-1"
          style={{ left: `${maxPercent}%` }}
        />
      </div>
      {/* Min/max time text */}
      <div className="relative w-full h-5">
        <span
          className="absolute text-sm text-gray-500 -translate-x-1/2"
          style={{ left: `${minPercent}%` }}
        >
          {formatTime(minTimeMin)} min
        </span>
        <span
          className="absolute text-sm text-gray-500 -translate-x-1/2"
          style={{ left: `${maxPercent}%` }}
        >
          {formatTime(maxTimeMin)} min
        </span>
      </div>
    </div>
  );
}
