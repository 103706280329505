import AppTitle from '@/modules/layout/components/app-title';
import { ListBase } from 'react-admin';

import { DataTable } from '@/modules/data-table/components/data-table';
import React, { type FC } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { CallInfoDialog } from '@/modules/voice-calls/components/CallInfoDialog';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { humanize } from 'inflection';
import { Badge } from '@/modules/ui/components/badge';
import { DirectionBadge } from '@/modules/voice-calls/components/DirectionBadge';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import { EmptyState } from '@/modules/data-table/components/data-table-empty-state';
import { PhoneCallIcon } from 'lucide-react';
import {
  VoiceCallCallerName,
  VoiceCallRecipientName,
} from '@/modules/voice-calls/components/VoiceCallName';
import { ListUIRoot } from '@/modules/data-table/components/list-ui-root';

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'start_time',
    header: 'Time',
    cell: ({ row }) => {
      const startTime = get(row.original, 'start_time');
      if (!startTime) return '-';
      const date = DateTime.fromISO(startTime, {
        zone: 'utc',
      }).setZone('local');
      return date.toLocaleString(DateTime.DATETIME_MED);
    },
  },
  {
    accessorKey: 'recipient_phone_number',
    header: 'Recipient Phone Number',
    cell: ({ row }) => {
      const phoneNumber = get(row.original, 'recipient_phone_number');
      return <span>{phoneNumber || '-'}</span>;
    },
  },
  {
    accessorKey: 'recipient_name',
    header: 'Recipient Name',
    cell: ({ row }) => {
      return <VoiceCallRecipientName record={row.original} />;
    },
  },
  {
    accessorKey: 'caller_phone_number',
    header: 'Caller Phone Number',
    cell: ({ row }) => {
      const phoneNumber = get(row.original, 'caller_phone_number');
      return <span>{phoneNumber || '-'}</span>;
    },
  },
  {
    accessorKey: 'caller_name',
    header: 'Caller Name',
    cell: ({ row }) => {
      return <VoiceCallCallerName record={row.original} />;
    },
  },
  {
    accessorKey: 'direction',
    header: 'Direction',
    cell: ({ row }) => {
      const direction = get(row.original, 'direction');

      return direction ? (
        <DirectionBadge direction={direction} />
      ) : (
        <span>-</span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = get(row.original, 'status');

      return status ? (
        <Badge variant="outline" className="w-max">
          {humanize(status)}
        </Badge>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    cell: ({ row }) => {
      const durationSeconds = get(row.original, 'duration');
      if (durationSeconds == null) return '-';
      const duration = DateTime.fromSeconds(durationSeconds).toFormat('mm:ss');
      return <span>{duration}</span>;
    },
  },

  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <CallInfoDialog
          callId={row.original.id}
          buttonProps={{
            variant: 'default',
            text: 'View',
          }}
        />
      );
    },
  },
];

export const VoiceCallList: FC = () => {
  return (
    <ListUIRoot className="mx-2">
      <AppTitle title="Voice Calls" />
      <ListBase
        resource="voice-calls"
        sort={{ field: 'call_time', order: 'DESC' }}
        perPage={10}
      >
        <DataTable
          toolbar={
            <DataTableToolbar
              search={
                <MultiSearch
                  searchFields={[
                    { value: 'caller.full_name', label: 'Caller Name' },
                    { value: 'patient.full_name', label: 'Recipient Name' },
                    { value: 'caller_phone_number', label: 'Caller Phone' },
                    {
                      value: 'recipient_phone_number',
                      label: 'Recipient Phone',
                    },
                  ]}
                />
              }
            >
              <DataTableFilter
                column="direction[in]"
                title="Direction"
                options={[
                  { label: 'Inbound', value: 'inbound' },
                  { label: 'Outbound', value: 'outbound' },
                ]}
              />
              <DataTableDateRangeFilter
                column="start_time"
                title="Time"
                dateOnly={true}
              />
            </DataTableToolbar>
          }
          columns={columns}
          emptyState={
            <EmptyState
              icons={[PhoneCallIcon]}
              title="No voice calls found"
              description="No voice calls found for the selected filters"
            />
          }
        />
      </ListBase>
    </ListUIRoot>
  );
};
