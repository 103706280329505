import { Textarea } from '@/modules/ui/components/textarea';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useNotify, useRecordContext, useUpdate } from 'react-admin';

export function PatientNotes() {
  const [value, setValue] = useState('');
  const [lastSaved, setLastSaved] = useState(null);
  const record = useRecordContext();
  const notify = useNotify();
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const initialRenderRef = React.useRef(true);

  useEffect(() => {
    if (record) {
      setValue(record?.notes || '');
      initialRenderRef.current = true;
    }
  }, [record]);

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value]);

  const [save] = useUpdate(
    'patients',
    { id: record.id, data: { ...record, notes: value } },
    {
      onSuccess: () => {
        setLastSaved(DateTime.local());
      },
      onError: () => {
        setLastSaved(null);
        notify(`Error saving patient notes`, {
          type: 'warning',
        });
      },
    },
  );

  const handleSave = () => {
    if (record.notes !== value) save();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex flex-col gap-0">
      {lastSaved ? (
        <div className="self-end">
          <span className="text-xs font-normal">Saved notes at </span>
          <span className="text-xs font-normal">
            {lastSaved.toLocaleString(DateTime.TIME_SIMPLE)}
          </span>
        </div>
      ) : null}
      <Textarea
        ref={textareaRef}
        value={value}
        placeholder="Add any notes here"
        onChange={handleChange}
        onBlur={handleSave}
        className="h-[80px] max-h-[200px]"
      />
    </div>
  );
}
