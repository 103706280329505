import { find, get } from 'lodash';
import React, { type FC } from 'react';
import { useRecordContext } from 'react-admin';
import { VitalCardBase } from './VitalCardBase';
import { MetricsDialog } from '../metrics-dialog/components/MetricsDialog';
import { vitalsConfig } from '../constants/vitalsConfig';

export const PatientVitals: FC = () => {
  const patientRecord = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const [measureName, setMeasureName] = React.useState('');

  if (!patientRecord) return null;

  const observations = patientRecord.observations;
  const measureReports = patientRecord.measure_reports;
  const monitoringLimits = get(patientRecord, 'care_plan.monitoring_limits');

  const handleClickIndicator = (chartMeasureName: string) => {
    setMeasureName(chartMeasureName);
    setOpen(true);
  };

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Physiological Measurements</h3>
        <p className="text-sm text-gray-500">
          Measurements taken by patient devices.
        </p>
      </div>

      <div className="flex flex-wrap gap-4">
        <MetricsDialog
          open={open}
          setOpen={setOpen}
          measureName={measureName}
          setMeasureName={setMeasureName}
        />
        {vitalsConfig
          .map((element) => {
            const vitalData = find(
              observations,
              (o) => o.name === element.chartMeasureName,
            );
            const baseline = find(
              measureReports,
              (o) => o.name === element.measureReportName,
            );

            if (vitalData) {
              return (
                <div
                  key={`vital-${element.chartMeasureName}`}
                  className="w-full sm:w-auto"
                >
                  <VitalCardBase
                    value={vitalData}
                    text={element.nameKey}
                    lastUpdated={vitalData.timestamp}
                    baseline={baseline}
                    unit={vitalData.unit?.text}
                    chartName={element.chartMeasureName}
                    faIcon={element.faIcon}
                    limits={monitoringLimits}
                    roundDigits={element.roundDigits}
                    onClick={() =>
                      element.chartMeasureName
                        ? handleClickIndicator(element.chartMeasureName)
                        : null
                    }
                  />
                </div>
              );
            } else if (element.alwaysShow) {
              return (
                <div
                  key={`vital-${element.chartMeasureName}`}
                  className="w-full sm:w-auto"
                >
                  <VitalCardBase
                    text={element.nameKey}
                    chartName={element.chartMeasureName}
                    value="-"
                    handleIndicatorClick={() => {
                      return null;
                    }}
                  />
                </div>
              );
            }
            return null;
          })
          .filter((element) => element !== null)}
      </div>
    </div>
  );
};
