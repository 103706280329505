import { ValidationError } from 'ra-core';
import React from 'react';

export const InputMessage = (props) => {
  const { error, helperText } = props;

  if (error) {
    return (
      <p className={'text-sm font-medium text-destructive'}>
        <ValidationError error={error} />
      </p>
    );
  }

  if (helperText) {
    return <p className={'text-sm text-muted-foreground'}>{helperText}</p>;
  }

  return null;
};
