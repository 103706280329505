import { Button } from '@/modules/ui/components/button';
import { useCreate, useNotify, useRecordContext, useRefresh } from 'ra-core';
import React from 'react';
import { get } from 'lodash';

const PasswordResetButton = ({ className = null, resource = 'users' }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [create, { isLoading }] = useCreate();

  const handleClick = () => {
    create(
      `${resource}/${record.id}/reset-password`,
      { data: {} },
      {
        onSuccess: () => {
          notify(
            'Successfully reset user password. They will receive an email with instructions.',
            { type: 'success' },
          );
          refresh();
        },
        onError: (error) =>
          notify(`${get(error, 'message')}`, {
            type: 'warning',
          }),
      },
    );
  };

  return (
    <Button
      disabled={isLoading}
      variant="outline"
      onClick={handleClick}
      className={className}
      type="button"
    >
      Reset Password
    </Button>
  );
};

export default PasswordResetButton;
