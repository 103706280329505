import React from "react";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/modules/ui/components/dialog-mui";

import { Button } from "@/modules/ui/components/button";
import { Eye, Loader2, FileQuestion } from "lucide-react";
import { useDataProvider, useRecordContext } from "react-admin";
import { useQuery } from "@tanstack/react-query";
import { humanize, titleize } from "inflection";
import { Badge } from "@/modules/ui/components/badge";
import { dateFormatter } from "@/utils/formatedDate";
import { DateTime } from "luxon";
import {
	get,
	find,
	flatMap,
	map,
	isEmpty,
	isObject,
	some,
	isArray,
} from "lodash";
import { triageText } from "@/modules/patients/assessment-summary/constants/triageText";
import Markdown from "react-markdown";
import { MarkAsIncorrect } from "./MarkAsIncorrect";
import { assessmentsNames } from "@/modules/users/constants/assessmentsNames";
export const AssessmentReview = () => {
	const record = useRecordContext();

	const [open, setOpen] = React.useState(false);
	const dataProvider = useDataProvider();

	const { data, isLoading } = useQuery({
		queryKey: ["getAssessment", record?.patientId, record?.id],
		queryFn: () =>
			dataProvider
				.getCustom(`patients/${record?.patientId}/runs/${record.id}`, {})
				.then((response) => response.data),
		enabled: !!record && open,
	});

	const title =
		get(data, "questionnaire.title") ||
		(get(data, "run.name")
			? get(assessmentsNames, get(data, "run.name")) ||
				titleize(get(data, "run.name"))
			: "");

	const renderContent = () => {
		if (get(data, "run.category") === "survey") {
			if (
				get(data, "run.predictions") &&
				get(data, "run.predictions").length > 0
			) {
				return <SurveyView data={data} />;
			}
		}
		if (
			get(data, "run.category") === "prediction" ||
			get(data, "run.category") === "disease_assessment"
		) {
			return <PredictionView data={data} />;
		}
		return (
			<div className="flex flex-col items-center justify-center py-10 px-4 text-center">
				<FileQuestion className="h-16 w-16 text-gray-400 mb-4" />
				<h3 className="text-lg font-medium text-gray-900 mb-2">
					Assessment Details Unavailable
				</h3>
				<p className="text-gray-500 max-w-md">
					The assessment data you're looking for cannot be displayed at this
					time.
				</p>
			</div>
		);
	};

	return (
		<>
			<Button onClick={() => setOpen(true)} variant="ghost">
				<Eye className="h-4 w-4 " />
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogContent
					onClose={() => setOpen(false)}
					className="max-h-[80vh] overflow-y-auto "
				>
					{isLoading && <Loader2 />}
					{!isLoading && open && (
						<>
							<DialogHeader className="mb-2">
								<DialogTitle className="mr-10">{title}</DialogTitle>
								<div className="flex flex-row gap-1 justify-between">
									<div className="flex flex-col gap-1 justify-center">
										<Badge className="w-fit">
											{humanize(get(data, "run.category", ""))}
										</Badge>
										{get(data, "run.status") === "entered-in-error" && (
											<Badge className="w-fit bg-red-100 text-red-500 hover:bg-red-200">
												Entered in error
											</Badge>
										)}
									</div>
									<div className="flex flex-col gap-1 justify-end items-end">
										<p>
											{dateFormatter(
												get(data, "run.created_at", ""),
												DateTime.DATETIME_MED,
											)}
										</p>
										{get(data, "run.status") !== "entered-in-error" && (
											<MarkAsIncorrect />
										)}
									</div>
								</div>
							</DialogHeader>
							{renderContent()}
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};

const SurveyView = ({ data }) => {
	const answerItem = (subItem) => {
		const answerTemp = find(
			flatMap(data.questionnaire_response.items, (item) =>
				item.question === item.linkId ? [item] : item.value,
			),
			{ question: subItem.linkId },
		);

		if (isArray(get(answerTemp, "value"))) {
			// if empty array say None
			if (isEmpty(get(answerTemp, "value"))) {
				return "None";
			}

			return map(get(answerTemp, "value"), (item) => get(item, "text")).join(
				", ",
			);
		}

		console.log("answerTemp", answerTemp);
		return get(answerTemp, "value.text");
	};

	return (
		<>
			{data?.questionnaire?.items?.map((item) => {
				const subItemsContent = item.subItems
					.map((subItem) => {
						const answer = answerItem(subItem);

						if (!answer) return null;

						return (
							<div
								key={subItem.linkId}
								className="border-t-2 border-gray-200  mb-2"
							>
								<h3 className="text-md ">
									<Markdown>{subItem.text}</Markdown>
								</h3>
								<p className="pl-4 font-semibold">{answer}</p>
							</div>
						);
					})
					.filter(Boolean); // Filter out null values to ensure no blank items

				if (subItemsContent.length === 0) return null; // Ensure we don't render a container for items without content

				return (
					<div key={item.linkId} className="mb-2">
						{subItemsContent}
					</div>
				);
			})}
			{get(data, "run.category") === "survey" &&
				!isEmpty(get(data, "run.predictions")) &&
				get(data, "run.predictions[0].response") && (
					<div className="mt-2 border-2 border-gray-200 p-4 rounded-md">
						{map(get(data, "run.predictions[0].response", {}), (value, key) => (
							<p key={key}>
								{humanize(key)}:{" "}
								<span className="font-semibold">
									{typeof value === "string" ? (
										value
									) : (
										<JsonDisplay data={value} />
									)}
								</span>
							</p>
						))}
					</div>
				)}
		</>
	);
};

const PredictionView = ({ data }) => {
	const findAnswer = (subItem) => {
		const answer = find(
			flatMap(data.questionnaire_response.items, (item) =>
				item.question === item.linkId ? [item] : item.value,
			),
			{ question: subItem.linkId },
		);

		if (!answer) {
			return null;
		}

		// if value is an array, then list out all the texts
		if (answer.type === "choice") {
			if (isArray(answer.value) && answer.value.length > 0) {
				return map(answer.value, (item) => get(item, "text")).join(", ");
			}
			return get(answer, "value.text", "");
		}

		if (answer.type === "integer") {
			// if both unit and value is present, then let's format it
			const unit = get(answer, "value.units.text");
			let value = get(answer, "value.value") || get(answer, "value");
			const interval = get(answer, "value.units.uiOptions.interval");

			// Round value based on interval if present
			if (interval && typeof value === "number") {
				if (interval === 0.1) {
					value = Number(value.toFixed(1));
				} else {
					value = Math.round(value);
				}
			}

			return unit ? `${value} ${unit}` : value;
		}

		return get(answer, "value.text");
	};

	return (
		<>
			{data?.questionnaire?.items?.map((item, index) => {
				const subItemsContent = item.subItems
					.map((subItem, index_sub) => {
						const answer = findAnswer(subItem);

						if (answer === null || typeof answer === "object") {
							return null;
						}

						return (
							<div
								key={index_sub}
								className="border-t-2 border-gray-200 mb-2 last:mb-0"
							>
								<h3 className="text-md ">
									<Markdown>
										{item.text
											? `${item.text} - ${subItem.text}`
											: subItem.text}
									</Markdown>
								</h3>
								<p className="pl-4 font-semibold">{answer}</p>
							</div>
						);
					})
					.filter(Boolean);

				if (subItemsContent.length === 0) {
					return null;
				}

				return (
					<div key={index} className="mb-2">
						{subItemsContent}
					</div>
				);
			})}
			{get(data, "run.category") === "prediction" &&
				!isEmpty(get(data, "run.predictions")) &&
				(() => {
					const responses = get(data, "run.predictions[0].response", {});
					const hasNonEmptyResponses = some(
						responses,
						(response) => !isEmpty(response),
					);
					if (!hasNonEmptyResponses) return null;

					return (
						<div className="mt-2 border-2 border-gray-200 p-4 rounded-md">
							{map(responses, (value, key) => (
								<div key={key} className="pb-2 border-b-2 border-gray-200">
									<h4 className="font-semibold">{humanize(key)}</h4>
									{isObject(value) && key !== "triage" ? (
										<div className="flex flex-col">
											<div className="flex items-center my-2">
												<div
													style={{
														backgroundColor: get(value, "donutColor"),
													}}
													className="w-6 h-6 rounded-full mr-2"
												></div>
												<p className="font-bold">
													{Math.round(get(value, "value", 0))}
												</p>
												<p className="text-sm ml-2">
													{get(value, "donutText", "")}
												</p>
											</div>
										</div>
									) : null}
									{key === "triage" && (
										<div className="flex flex-row gap-2 items-center justify-start">
											<Badge variant="outline" className="w-fit">
												{get(triageText, get(value, "key", ""), "")}
											</Badge>
											<p className="text-sm italic">{get(value, "text", "")}</p>
										</div>
									)}
								</div>
							))}
						</div>
					);
				})()}
		</>
	);
};

const JsonDisplay = ({ data, indent = 0 }) => {
	if (typeof data === "string") return data;
	if (typeof data === "number" || typeof data === "boolean")
		return String(data);
	if (data === null) return "null";
	if (data === undefined) return "undefined";

	if (Array.isArray(data)) {
		if (data.length === 0) return "[]";

		return (
			<div className="pl-4">
				{data.map((item, index) => (
					<div key={index} className="flex">
						<span className="text-gray-500 mr-2">•</span>
						<JsonDisplay data={item} indent={indent + 1} />
					</div>
				))}
			</div>
		);
	}

	if (typeof data === "object") {
		return (
			<div className="pl-4">
				{Object.entries(data).map(([key, value]) => (
					<div key={key} className="flex flex-col mb-1">
						<div className="flex">
							<span className="text-gray-700 font-medium mr-2">
								{humanize(key)}:
							</span>
							<JsonDisplay data={value} indent={indent + 1} />
						</div>
					</div>
				))}
			</div>
		);
	}

	return String(data);
};
