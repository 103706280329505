import { FunctionField, ReferenceField, TextField } from 'react-admin';
import { get } from 'lodash';
import React from 'react';
import { humanize } from 'inflection';

export const VoiceCallCallerName = ({ record }) => {
  const direction = get(record, 'direction');

  if (direction === 'inbound') {
    if (get(record, 'patient_contact_id')) {
      return (
        <span className="flex items-center gap-1 bg-orange-100 border border-orange-200 my-2 p-1 rounded-md w-fit">
          Contact:{' '}
          <ReferenceField source="patient_contact_id" reference="contacts">
            <FunctionField
              render={(record) => (
                <span>
                  {record.full_name} (
                  {record.relationship_to_patient
                    ? humanize(record.relationship_to_patient)
                    : '-'}
                  )
                </span>
              )}
            />
          </ReferenceField>
        </span>
      );
    }
    return (
      <ReferenceField
        reference="users"
        source="patient_id"
        link={(record) => `/patients/${record.id}/show`}
      >
        <TextField source="full_name" />
      </ReferenceField>
    );
  }

  return (
    <ReferenceField reference="users" source="caller_id" link={false}>
      <TextField source="full_name" />
    </ReferenceField>
  );
};

export const VoiceCallRecipientName = ({ record }) => {
  const direction = get(record, 'direction');

  if (direction === 'inbound') {
    return <span>-</span>;
  }

  const patientId = get(record, 'patient_id');
  if (!patientId) return <span>-</span>;

  return (
    <ReferenceField
      reference="users"
      source="patient_id"
      link={(record) => `/patients/${record.id}/show`}
    >
      <TextField source="full_name" />
    </ReferenceField>
  );
};
