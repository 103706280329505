import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { titleize } from 'inflection';
import { get } from 'lodash';
import React from 'react';
import {
  DeleteButton,
  useDataProvider,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { TenantUserRoleAdd } from './TenantUserRoleAdd';
import { useQuery } from '@tanstack/react-query';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/modules/ui/components/radio-group';
import { Label } from '@/modules/ui/components/label';

const RolesTab: React.FC = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  if (record.is_internal) {
    return <InternalRolesTab />;
  } else {
    return null;
  }
};

const PatientAccessTypeSelector = () => {
  return (
    <div className="flex flex-row gap-2 w-fit items-center">
      <SelectInput
        className="min-w-[180px]"
        source="patient_access_level"
        label={false}
        choices={[
          { name: 'All', id: 'all' },
          { name: 'Provider', id: 'tenant_scoped' },
        ]}
      />
      <SaveButton variant="default" className="h-8" />
    </div>
  );
};

const InternalRolesTab = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <InternalRoleSection />
      <Separator />
      {!!!['superadmin', 'internal_admin'].includes(get(record, 'role')) ? (
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <div>
                <CardTitle>Patient Access</CardTitle>
                <CardDescription>
                  Configure how this user can access patients.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col items-start gap-4">
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold">Access Level</h2>
                <p className="text-sm text-muted-foreground">
                  Select the type of patient access for this user.
                </p>
                <PatientAccessTypeSelector />
              </div>
              {record.patient_access_level === 'all' && (
                <div className="flex flex-col">
                  <h2 className="text-lg font-semibold flex items-center gap-2">
                    Provider Access
                  </h2>
                  <p className="text-sm text-muted-foreground">
                    This user will have access to all patients and resources
                    across all providers.
                  </p>
                </div>
              )}
              {record.patient_access_level === 'tenant_scoped' && (
                <InternalTenantUserRolesTable />
              )}
            </div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

const InternalRolesTable = () => {
  const record = useRecordContext();

  const notify = useNotify();
  const refresh = useRefresh();

  const { data: roles, isLoading } = useQuery({
    queryKey: ['roles', 'internal'],
    queryFn: () =>
      dataProvider
        .getCustom('roles', {
          meta: { is_internal: true },
        })
        .then(({ data }) => data),
  });

  const dataProvider = useDataProvider();

  if (!record) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isRoleInData = (roleIn) => {
    return roleIn === record.role;
  };

  const handleRoleClick = (roleIn) => {
    if (roleIn === 'superadmin') {
      return notify("You can't set a user as a superadmin.", {
        type: 'warning',
      });
    }

    dataProvider
      .update(`users`, {
        id: record.id,
        data: {
          role: roleIn,
          is_internal: true,
        },
      } as any)
      .then(({ data }) => {
        notify('Role updated to ' + titleize(roleIn));
        refresh();
      })
      .catch((error) => {
        notify(`${get(error, 'message')}`, {
          type: 'warning',
        });
      });
  };

  return (
    <div className="grid gap-4">
      <RadioGroup value={record.role} onValueChange={handleRoleClick}>
        {roles.map((item) => (
          <div
            className={cn(
              '-mx-2 flex items-center  space-x-4 rounded-md p-2 transition-all hover:bg-accent hover:text-accent-foreground  cursor-pointer',
              {
                'bg-accent text-accent-foreground': isRoleInData(item.role),
              },
            )}
            key={item.role}
          >
            <RadioGroupItem value={item.role} id={item.role} />
            <Label htmlFor={item.role} className="space-y-1 cursor-pointer">
              <p className="text-medium font-medium leading-none">
                {titleize(item.role)}
              </p>
              <p className="text-sm text-muted-foreground">
                {item.description}
              </p>
            </Label>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};

const InternalRoleSection = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>User Role</CardTitle>
        <CardDescription>Roles specific to internal users</CardDescription>
      </CardHeader>
      <CardContent>
        <InternalRolesTable />
      </CardContent>
    </Card>
  );
};

const InternalTenantUserRolesTable = () => {
  const record = useRecordContext();
  const sort = { field: null, order: null };

  const { data, isLoading } = useGetList(
    `users/${record.id}/tenant-user-roles`,
    {
      pagination: { page: 1, perPage: 20 },
      filter: {},
      sort,
    },
  );

  if (!record) {
    return null;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid gap-4 w-full">
      <div className="flex flex-row justify-between items-center gap-2">
        <div>
          <h2 className="text-lg font-semibold">Provider Access</h2>
          <p className="text-sm text-gray-600">
            The user will have access to all patients and resources within these
            provider accounts.
          </p>
        </div>
        <TenantUserRoleAdd variant="default" />
      </div>
      {!isLoading &&
        data.map((item) => {
          //   const role = tenantUserRoles.find((role) => role.id === item.role_id);
          return (
            <div
              className={
                '-mx-2 flex items-center space-x-4 rounded-md p-2 transition-all border-gray-200 border '
              }
              key={item.id}
            >
              {/* <BellIcon className="mt-px h-5 w-5" /> */}
              <div className="space-y-1 w-full">
                <p className="text-medium font-medium leading-none">
                  {item.tenant_name}
                </p>
              </div>

              <div className="justify-self-end	">
                <DeleteButton
                  record={item}
                  resource={`users/${record.id}/tenant-user-roles`}
                  redirect={false}
                  label=""
                  sx={{
                    minWidth: 'unset',
                    '& .MuiButton-startIcon': {
                      mr: 0,
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { RolesTab };
