import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { AlertCircle } from 'lucide-react';
import { useRecordContext } from 'ra-core';
import { get } from 'lodash';
import { humanize } from 'inflection';
import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import { useNavigate } from 'react-router-dom';

export const MissingProfileAlert = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  if (!record) {
    return null;
  }

  const missingProfileItems = get(record, 'patient.missing_profile', []) || [];
  const missingExtendedProfileItems =
    get(record, 'patient.missing_extended_profile', []) || [];
  const hasMissingItems =
    missingProfileItems.length > 0 || missingExtendedProfileItems.length > 0;

  if (!hasMissingItems) {
    return null;
  }

  const formatFieldName = (fieldName: string) => {
    try {
      return humanize(fieldName.replace(/\./g, ' - '));
    } catch (e) {
      return fieldName;
    }
  };

  return (
    <Alert variant="warning" className="mt-5">
      <div className="flex items-center gap-2">
        <AlertCircle className="h-5 w-5 text-amber-500" />
        <AlertTitle className="text-lg font-semibold">
          Missing Profile Items
        </AlertTitle>
      </div>
      <AlertDescription className="mt-2">
        <div className="flex justify-between items-center mb-3">
          <div className="text-sm">
            These items are required for assessments. Please add the missing
            items to the patient's profile.
          </div>
          <Button
            variant="default"
            size="sm"
            onClick={() => navigate(`/patients/${record.id}`)}
          >
            Fix
          </Button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {missingProfileItems.map((item) => (
            <Badge
              key={item.loc}
              variant="outline"
              className="px-3 py-1 text-sm bg-amber-50 border-amber-200 text-amber-700 flex items-center justify-between"
            >
              {item.text ? item.text : formatFieldName(item.loc)}
            </Badge>
          ))}
          {missingExtendedProfileItems.map((item) => (
            <Badge
              key={item.loc}
              variant="outline"
              className="px-3 py-1 text-sm bg-amber-50 border-amber-200 text-amber-700 flex items-center justify-between"
            >
              {item.text ? item.text : formatFieldName(item.loc)}
            </Badge>
          ))}
        </div>
      </AlertDescription>
    </Alert>
  );
};
