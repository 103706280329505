import { get } from "lodash";
import React, { useEffect } from "react";
import { required, useGetIdentity, usePermissions } from "react-admin";
import CreateInDialogButton from "@/modules/ra-ui/components/create-dialog-button";
import { measurementDeviceTypes } from "../constants/measurementDeviceTypes";
import { deviceManufacturers } from "../constants/deviceManufacturers";
import { filterTenantName } from "@/utils/filterTenantName";
import { useFormContext, useWatch } from "react-hook-form";
import { DelimitedTextInput } from "@/modules/ui/inputs/delimited-text-input";
import { AutocompleteInput } from "@/modules/ui/inputs/autocomplete-input";
import { SelectInput } from "@/modules/ui/inputs/select-input";
import { TextInput } from "@/modules/ui/inputs/text-input";
import { SwitchInput } from "@/modules/ui/inputs/switch-input";
import { Separator } from "@/modules/ui/components/separator";
import { humanize } from "inflection";
import { filterLocationName } from "@/utils/filterLocationName";
const DeviceLocationInput = () => {
	const tenantId = useWatch({ name: "tenant_id" });
	const patientId = useWatch({ name: "patient_id" });
	const { setValue } = useFormContext();

	useEffect(() => {
		setValue("location_id", null);
	}, [tenantId, patientId, setValue]);

	return (
		<AutocompleteInput
			label="Location"
			optionText="name"
			parse={(v) => (v === "" ? null : v)}
			helperText={
				!!patientId
					? "Can't set location on devices assigned to a patient"
					: false
			}
			disabled={!!patientId}
			source="location_id"
			reference="locations"
			filter={{
				"is_active[eq]": true,
				"tenant_id[eq]": tenantId ? tenantId : null,
			}}
			filterToQuery={filterLocationName}
		/>
	);
};

export const DeviceBulkCreateButton = (props) => {
	const { variant = "text" } = props;

	const { permissions } = usePermissions();
	const { identity } = useGetIdentity();

	const transform = (data) => {
		const dataOut = {
			...data,
			status: !data.device_received ? "pending" : null,
		};
		if (get(permissions, "is_internal") === false) {
			dataOut["tenant_id"] = identity && identity.tenant_id;
		}
		return dataOut;
	};

	const onSuccessNotifyMessage = (data) => {
		return `${data.created_devices} devices created successfully. The following IMEIs are already in use: ${data.existing_imeis}`;
	};

	if (
		!get(permissions, "devices.create") ||
		get(permissions, "role") === "internal_clinician"
	) {
		return null;
	}

	return (
		<CreateInDialogButton
			title="Add devices"
			transform={transform}
			variant={variant}
			label="Bulk Devices"
			saveAndAddAnother
			saveAndAddAnotherClearFields={["external_ids"]}
			dialogVariant="shadcn"
			closeOnClickOutside={false}
			resource="devices/bulk"
			notifyMessage={onSuccessNotifyMessage}
			refreshOnSuccess={true}
		>
			<div className="flex flex-col space-y-4">
				<div className="flex flex-row gap-4">
					<SelectInput
						source="device_manufacturer"
						choices={deviceManufacturers.filter((m) => m.id !== "tenovi")}
						fullWidth
						helperText="Select the manufacturer of the device"
						validate={required()}
					/>
				</div>

				<SelectInput
					source="device_type"
					choices={measurementDeviceTypes}
					optionText={(record) => humanize(record.id)}
					validate={required()}
					helperText={false}
				/>
				<DelimitedTextInput
					label="IMEIs"
					source="external_ids"
					helperText="Paste IMEIs directly from spreadsheet"
				/>

				<TextInput
					source="device_model"
					helperText={false}
					label="Model number"
				/>

				<Separator />
				{get(permissions, "is_internal") === true ? (
					<AutocompleteInput
						label="Provider"
						optionText="name"
						helperText="Select which provider this device belongs to"
						filterToQuery={filterTenantName}
						source="tenant_id"
						reference="providers"
					/>
				) : null}
				<DeviceLocationInput />
				<SwitchInput
					source="device_received"
					defaultValue={false}
					helperText="Has the device been received at the location?"
				/>
			</div>
		</CreateInDialogButton>
	);
};
