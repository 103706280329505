import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { Skeleton } from '@/modules/ui/components/skeleton';
import { DateTime } from 'luxon';
import { useGetOne } from 'ra-core';
import React from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const CustomTooltip = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-1 border-2 rounded-md">
        <p className="label">{`Date: ${DateTime.fromISO(label).toFormat(
          'LLL d, yyyy',
        )}`}</p>
        <p className="intro">{`Enrollments: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

export const DailyEnrollmentPlot = ({
  providerId,
  months,
}: {
  providerId: string | undefined;
  months: number;
}) => {
  const { data, isLoading } = useGetOne(
    'metrics',
    {
      id: 'enrollment-by-day-plot',
      meta: { tenant_id: providerId, months },
    },
    {
      enabled: providerId !== '',
    },
  );

  const formatXAxis = (tickItem) => {
    return DateTime.fromISO(tickItem).toFormat('LLL d');
  };

  return (
    <Card className="max-w-lg w-full">
      <CardContent>
        <div className="flex flex-col justify-between ">
          <CardHeader>
            <CardTitle className="text-sm font-medium mr-4 mb-9">
              Enrollments by day
            </CardTitle>
          </CardHeader>
          <div className="h-64 ">
            {isLoading && (
              <div className="h-64">
                <Skeleton className="w-full h-full" />
              </div>
            )}
            {!isLoading && data?.daily_patient_enrollment.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={data.daily_patient_enrollment}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="enrolled_date" tickFormatter={formatXAxis} />
                  <YAxis allowDecimals={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="count" fill="#8884d8" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            )}
            {!isLoading && data?.daily_patient_enrollment.length === 0 && (
              <div className="h-64 flex items-center justify-center">
                <p className="text-gray-500">No data available</p>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
