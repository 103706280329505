import { titleize } from 'inflection';
import {
  BooleanField,
  FunctionField,
  ListContextProvider,
  TextField,
  useListController,
  useRecordContext,
} from 'react-admin';
import { PatientContactEditButton } from './ContactsCreateEditButton';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { cropText } from '@/modules/patients/utils/cropText';
import { DataTable } from '@/modules/data-table/components/data-table';

const columns = [
  {
    accessorKey: 'full_name',
    header: 'Contact name',
    cell: () => <TextField source="full_name" />,
  },
  {
    accessorKey: 'relationship_to_patient',
    header: 'Relationship',
    cell: () => (
      <FunctionField
        source="relationship_to_patient"
        render={(record) => titleize(record.relationship_to_patient)}
      />
    ),
  },
  {
    accessorKey: 'cell_phone_number',
    header: 'Cell Phone',
    cell: () => <TextField source="cell_phone_number" />,
  },
  {
    accessorKey: 'Home_phone_number',
    header: 'Home Phone',
    cell: () => <TextField source="Home_phone_number" />,
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: () => <TextField source="email" />,
  },
  {
    accessorKey: 'notes',
    header: 'Notes',
    cell: ({ row }) => (
      <FunctionField render={(record) => cropText(record.notes, 120)} />
    ),
  },
  {
    accessorKey: 'is_emergency_contact',
    header: 'Emergency contact',
    cell: () => <BooleanField source="is_emergency_contact" />,
  },
  {
    id: 'actions',
    cell: () => <PatientContactEditButton />,
  },
];

export const PatientContactsGrid = (props) => {
  const record = useRecordContext();
  const listContext = useListController({
    debounce: 500,
    exporter: false,
    filter: { 'patient_id[eq]': record.id },
    perPage: 10,
    resource: 'contacts',
    storeKey: `patient-view-${record.id}-contacts`,
  });

  if (!record) return null;

  return (
    <ListContextProvider value={listContext}>
      <DataTable
        columns={columns}
        emptyState={
          <EmptyDatagrid
            resourceName="Contact"
            textFormatString="No contacts found"
          />
        }
        storeKey={`patient-view-${record.id}-contacts`}
        hidePaginationIfLessThanTotal
      />
    </ListContextProvider>
  );
};
