import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

import { Progress } from '@/modules/ui/components/progress';
import { cptCodeInfo } from '../constants/cptCodeInfo';

type ServiceProgressFieldProps = {
  source?: string;
  label?: string;
};

export function ServiceProgressField(_props: ServiceProgressFieldProps) {
  const record = useRecordContext();
  const codeInfo = get(cptCodeInfo, record.cpt_code);

  if (!record || !codeInfo) {
    return null;
  }

  const primaryText = () => {
    let primaryTextVar = '';
    if (codeInfo.type === 'days') {
      primaryTextVar = `${Math.min(
        Math.round(record.value),
        record.max_value,
      )} / ${Math.round(record.max_value)} ${
        codeInfo.units ? `(${codeInfo.units})` : ''
      }`;
    } else if (codeInfo.type === 'time') {
      primaryTextVar = `${Math.round(record.value / 60)} / ${Math.round(
        record.max_value / 60,
      )} ${codeInfo.units ? `(${codeInfo.units})` : ''}`;
    }
    return primaryTextVar;
  };

  return (
    <div className="p-4">
      <div className="flex flex-col space-y-2">
        <span className="text-sm ">{primaryText()}</span>
        <Progress value={record.progress * 100} className="bg-slate-200" />
      </div>
    </div>
  );
}
