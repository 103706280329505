import { cn } from "@/modules/ui/utils/cn";

// Add these Lucide icons
import { Copy, History, ArrowDown, ArrowRight, ArrowUp } from "lucide-react";

import { find, get } from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import { Button } from "@/modules/ui/components/button";
import { Badge } from "@/modules/ui/components/badge";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/modules/ui/components/tooltip";

export interface VitalConfig {
	measureReportName: string;
	chartMeasureName?: string;
	nameKey: string;
	roundDigits?: number;
	alwaysShow?: boolean;
	faIcon?: any;
}

export interface VitalCardBaseProps {
	value: any;
	text: string;
	chartName?: string;
	unit?: string;
	lastUpdated?: string;
	baseline?: any;
	faIcon?: string | any;
	handleIndicatorClick?: any;
	limits?: any;
	roundDigits?: number;
	onClick?: any;
}

const CopyValueButton = ({ value }) => {
	const notify = useNotify();

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						variant="ghost"
						className="p-1 w-6 h-6"
						onClick={(e) => {
							navigator.clipboard.writeText(value);
							notify("Copied to clipboard", {
								type: "success",
							});
							e.stopPropagation();
						}}
					>
						<Copy size={12} />
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>Copy to clipboard</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export const VitalCardBase = ({
	value,
	text,
	chartName,
	unit,
	lastUpdated,
	baseline,
	faIcon,
	handleIndicatorClick,
	limits,
	roundDigits,
	onClick,
}: VitalCardBaseProps) => {
	const patientRecord = useRecordContext();
	const [highlight, setHighlight] = useState(false);

	const formatTrendArrow = () => {
		const current = formatVitalValue(value);
		const before = formatVitalValue(baseline);

		const delta = Number(current) - Number(before);
		if (Math.abs(delta) < Number(current) * 0.01) {
			return <ArrowRight className="h-6 w-6" />;
		} else if (delta > 0) {
			return <ArrowUp className="h-6 w-6" />;
		} else {
			return <ArrowDown className="h-6 w-6" />;
		}
	};

	const formatUpdateChip = () => {
		const dateToday = DateTime.local();
		const assessmentTime = DateTime.fromISO(lastUpdated, {
			zone: "utc",
		}).setZone("local");

		const diff = dateToday.diff(assessmentTime, ["minutes", "hours", "days"]);

		let text;

		if (diff.days >= 2) {
			text = `${diff.days.toFixed(0)} days ago`;
		} else if (diff.days >= 1 && diff.days < 2) {
			text = `${diff.days.toFixed(0)} day ago`;
		} else if (diff.hours < 24 && diff.hours >= 1) {
			text = `${diff.hours.toFixed(0)} hours ago`;
		} else if (diff.minutes < 60 && diff.minutes > 5) {
			text = `${diff.minutes.toFixed(0)} minutes ago`;
		} else if (diff.minutes <= 5) {
			text = "Just now";
		}

		return (
			<Badge variant="outline" className="bg-gray-100">
				{text}
			</Badge>
		);
	};

	const formatVitalValue = (input) => {
		if (!input || !get(input, "value")) {
			return "";
		}

		if (chartName === "blood_pressure") {
			const systolic =
				roundDigits !== null
					? input.value.systolic.toFixed(roundDigits)
					: input.value.systolic;
			const diastolic =
				roundDigits !== null
					? input.value.diastolic.toFixed(roundDigits)
					: input.value.diastolic;

			return `${systolic} / ${diastolic}`;
		}

		const referenceValues = {
			forced_expiratory_volume_1: "fev1_GLI2012",
			peak_expiratory_flow_rate: "pef_NHANES",
		};

		const chartReferenceKey = referenceValues[chartName];
		if (
			chartReferenceKey &&
			get(
				patientRecord,
				`patient.profile.reference_values.${chartReferenceKey}.value`,
			)
		) {
			const value = input.value;
			const referenceValue = get(
				patientRecord,
				`patient.profile.reference_values.${chartReferenceKey}.value`,
			);
			const percentPredicted = (value / referenceValue) * 100;
			return `${value} (${percentPredicted.toFixed(0)}%)`;
		}

		const rounded =
			roundDigits !== null ? input.value.toFixed(roundDigits) : input.value;

		return rounded;
	};

	const formatLimitChip = () => {
		// const limit = filter(limits, (o) => o.parameter === chartName);

		let limitChip = null;

		find(limits, (limit) => {
			if (limit.parameter === chartName) {
				const currentValue = limit.sub_parameter
					? get(value, ["value", limit.sub_parameter])
					: get(value, "value");

				if (limit.max && currentValue >= limit.max) {
					if (!highlight) setHighlight(true);
					limitChip = (
						<div className="self-start">
							<span
								className={cn(
									"text-lg font-semibold",
									"bg-[rgba(255,255,0,0.5)]",
								)}
							>
								H
							</span>
						</div>
					);
					return true;
				}
				if (limit.min && currentValue <= limit.min) {
					if (!highlight) setHighlight(true);
					limitChip = (
						<div className="self-end">
							<span
								className={cn(
									"text-lg font-semibold",
									"bg-[rgba(255,255,0,0.5)]",
								)}
							>
								L
							</span>
						</div>
					);
					return true;
				}
			}
		});

		return limitChip;
	};

	const formatValue = () => {
		const chip = formatLimitChip();
		return (
			<div className="flex items-center">
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<div className="flex items-center">
								{chip}
								<div>
									<div className="text-xl font-semibold">
										<div
											className={`font-medium ${chip ? "bg-[rgba(255,255,0,0.5)]" : ""}`}
										>
											{formatVitalValue(value)}
										</div>
									</div>
								</div>
							</div>
						</TooltipTrigger>
						<TooltipContent>
							<p>Measurement is outside limits</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
				<CopyValueButton value={formatVitalValue(value).replaceAll(" ", "")} />
			</div>
		);
	};

	const formatDate = () => {
		if (lastUpdated) {
			const dateToday = DateTime.local().startOf("day");
			const assessmentTime = DateTime.fromISO(lastUpdated, {
				zone: "utc",
			})
				.setZone("local")
				.startOf("day");

			const diff = dateToday.diff(assessmentTime, "days").days;

			if (diff >= 1) {
				return DateTime.fromISO(lastUpdated, {
					zone: "utc",
				})
					.setZone("local")
					.toLocaleString(DateTime.DATETIME_SHORT);
			} else {
				return `Today, ${DateTime.fromISO(lastUpdated, {
					zone: "utc",
				})
					.setZone("local")
					.toLocaleString(DateTime.TIME_SIMPLE)}`;
			}
		}
	};

	return (
		<div
			className={cn("bg-white border max-w-fit hover:bg-gray-50", {
				"border-yellow-400 border-2": highlight,
			})}
			onClick={onClick}
		>
			<span onClick={handleIndicatorClick} className="cursor-pointer">
				<div className="p-2">
					<div className="flex flex-row justify-between w-fit space-x-4">
						<div>
							<div className="flex flex-col justify-center items-start">
								<div className="mb-2">
									<div className="flex items-center space-x-4">
										<div>
											{faIcon ? (
												<>{/* <FontAwesomeIcon icon={faIcon} /> */}</>
											) : null}
										</div>
										<div>
											<div className="text-sm font-medium">
												<div className="font-semibold">
													{text} {unit ? ` (${unit})` : null}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="flex flex-col">
										<div>
											<div className="flex items-center space-x-4">
												<div>
													<History className="w-4  h-4" />
												</div>
												<div>
													<p className="text-sm">{formatDate()}</p>
												</div>
											</div>
										</div>
										<div>{formatValue()}</div>
									</div>
								</div>

								{baseline ? (
									<div>
										<div className="flex flex-col">
											<div>
												<div className="flex items-center space-x-4">
													<div>
														<p className="text-sm">7-day moving average</p>
													</div>
												</div>
											</div>
											<div>
												<div className="flex items-center space-x-4">
													<div>
														<div className="text-sm font-medium">
															{formatVitalValue(baseline)}
														</div>
													</div>
													<div>
														<CopyValueButton
															value={formatVitalValue(baseline).replaceAll(
																" ",
																"",
															)}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div>
							<div className="flex flex-col justify-between items-center h-full">
								<div>{formatUpdateChip()}</div>
								<div className="h-full self-center py-2">
									{formatTrendArrow()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>
	);
};
