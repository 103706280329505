import { Button } from '@/modules/ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';
import { FileDown, Loader2 } from 'lucide-react';
import React from 'react';
import {
  Form,
  SelectInput,
  required,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useEnrollmentQueue } from './EnrollmentQueueProvider';
import { formatFilters } from '@/utils/DataProvider';
import { get } from 'lodash';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';

export const EnrollmentExportButton = () => {
  const [open, setOpen] = React.useState(false);
  const { filterValues } = useListContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();
  const { config } = useEnrollmentQueue();
  const dataprovider = useDataProvider();
  const { permissions } = usePermissions();

  if (!get(permissions, 'patients.export_onboarding')) return null;

  const hasTenantFilter =
    Boolean(filterValues?.['tenant_id[eq]']) ||
    get(permissions, 'is_internal') === false;

  const handleClickOpen = () => {
    setIsLoading(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onExport = (values) => {
    setIsLoading(true);

    const filter = {
      ...filterValues,
      'patient_status[in]': config.showEnrolledPatients
        ? ['pending_enrollment', 'active', 'declined']
        : ['pending_enrollment'],
      'onboarding_appointment_datetime[gte]': config.appointmentDate?.from,
      'onboarding_appointment_datetime[lte]': config.appointmentDate?.to,
    };

    dataprovider
      .getFile(`patients/export-onboarding`, {
        meta: {
          filter: JSON.stringify(formatFilters(filter)),
          format: values.export_type,
          orientation: values.orientation,
        },
        download:
          values.export_type === 'csv'
            ? 'enrollment_export.csv'
            : 'enrollment_export.pdf',
        downloadType:
          values.export_type === 'csv' ? 'text/csv' : 'application/pdf',
      })
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => {
        setIsLoading(false);
        setOpen(false);
      })
      .catch(() => {
        notify('Error exporting enrollment data', { type: 'error' });
        setIsLoading(false);
      });
  };

  const getDisabledTooltip = () => {
    if (!hasTenantFilter) {
      return "Please select a tenant to export data";
    }
    return "";
  };

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span>
              <Button
                onClick={handleClickOpen}
                size="sm"
                className="h-8"
                variant="outline"
                disabled={!hasTenantFilter}
              >
                <FileDown className="mr-2 h-4 w-4" />
                Export
              </Button>
            </span>
          </TooltipTrigger>
          {!hasTenantFilter && (
            <TooltipContent>
              <p>{getDisabledTooltip()}</p>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogContent onClose={handleClose}>
          <DialogHeader>
            <DialogTitle>Export Enrollment Data</DialogTitle>
            <DialogDescription>
              Export enrollment data matching the filters set in the list view.
            </DialogDescription>
          </DialogHeader>
          <Form onSubmit={onExport}>
            <div className="grid grid-cols-2 gap-4">
              <SelectInput
                source="export_type"
                label="Export Format"
                choices={[{ name: 'PDF', id: 'pdf' }]}
                validate={required()}
                helperText={false}
                defaultValue={'pdf'}
              />
              <SelectInput
                source="orientation"
                label="Orientation"
                choices={[
                  { name: 'Landscape', id: 'landscape' },
                  { name: 'Portrait', id: 'portrait' },
                ]}
                validate={required()}
                helperText={false}
                defaultValue={'landscape'}
              />
            </div>
            <DialogFooter className="items-center pt-4">
              {config.appointmentDate === null && (
                <p className="text-red-500 text-sm">
                  Please select an appointment date range
                </p>
              )}
              <SubmitButton
                isLoading={isLoading}
                isDisabled={config.appointmentDate === null}
              />
            </DialogFooter>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const SubmitButton = ({ isLoading, isDisabled = false }) => {
  const { isValid, isValidating, isSubmitting } = useFormState();

  const disabled = isValidating || isSubmitting || !isValid || isLoading || isDisabled;
  
  const getSubmitDisabledReason = () => {
    if (isDisabled) return "Please select an appointment date range";
    if (isLoading) return "Export in progress";
    if (isValidating) return "Validating form";
    if (!isValid) return "Please fill in all required fields";
    return "";
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span>
            <Button disabled={disabled} type="submit" className="mt-2">
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Please wait
                </>
              ) : (
                <>
                  <FileDown className="mr-2 h-4 w-4 " />
                  Export
                </>
              )}
            </Button>
          </span>
        </TooltipTrigger>
        {disabled && (
          <TooltipContent>
            <p>{getSubmitDisabledReason()}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
