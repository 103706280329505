import { DataTable } from "@/modules/data-table/components/data-table";
import RangeField from "@/modules/ra-ui/components/RangeField";
import BadgeField from "@/modules/ra-ui/components/badge-field";
import { get } from "lodash";
import { Copy, Pill } from "lucide-react";
import {
	FunctionField,
	TextField,
	useGetList,
	useRecordContext,
	ListContextProvider,
} from "react-admin";
import { MedicationDeleteButton } from "./MedicationDeleteButton";
import { MedicationEditButton } from "./MedicationEditButton";
import { EmptyState } from "@/modules/data-table/components/data-table-empty-state";
import { usePatientTableState } from "@/modules/common/hooks/usePatientTableState";
import { cropText } from "@/modules/patients/utils/cropText";
import { Switch } from "@/modules/ui/components/switch";
import { useState, useMemo } from "react";
import { MedicationsCreateButton } from "./MedicationsCreateButton";
import { Button } from "@/modules/ui/components/button";
const columns = [
	{
		id: "medication.name",
		accessorKey: "medication.name",
		header: "Medication",
		cell: () => <TextField source="medication.name" />,
	},
	{
		accessorKey: "dosage",
		header: "Dosage",
		cell: () => (
			<FunctionField
				render={(record) =>
					get(record, "dosage[0].dose_and_rate[0].dose_quantity.value", "")
				}
			/>
		),
	},
	{
		accessorKey: "frequency",
		header: "Frequency",
		cell: () => (
			<FunctionField
				render={(record) => {
					const frequency = get(record, "dosage[0].timing.repeat.frequency");
					const periodUnit = get(record, "dosage[0].timing.repeat.periodUnit");
					if (frequency && periodUnit) {
						return `${frequency} x ${periodUnit}`;
					} else {
						return "";
					}
				}}
			/>
		),
	},
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		cell: () => (
			<BadgeField
				source="status"
				mapping={[
					{
						id: "stopped",
						value: "Stopped",
						className: "text-red-500 ",
					},
					{
						id: "completed",
						value: "Completed",
						className: "text-red-500 ",
					},
				]}
			/>
		),
	},
	{
		accessorKey: "timestamp",
		header: "Timestamp",
		id: "start_date",
		cell: () => (
			<RangeField
				source_start="start_date"
				source_end="end_date"
				sortBy="start_date"
			/>
		),
	},
	{
		accessorKey: "additional_info",
		header: "Notes",
		id: "additional_info",
		cell: ({ row }) => {
			return (
				<div className="flex flex-col gap-2">
					<p>{cropText(row.getValue("additional_info"), 100)}</p>
				</div>
			);
		},
	},
	{
		id: "actions",
		cell: ({ row }) => {
			const isEditable =
				["active", "in-progress"].includes(row.original.status) &&
				row.original.source === "vironix";

			return isEditable ? (
				<div className="flex gap-2">
					<MedicationEditButton label="" />
					<MedicationDeleteButton />
				</div>
			) : (
				<div className="flex gap-2">
					<MedicationsCreateButton
						createButton={
							<Button
								size="sm"
								className="h-8 hover:bg-gray-300"
								variant="ghost"
							>
								<Copy className="w-4 h-4" />
							</Button>
						}
						initialData={{
							...row.original,
							start_date: null,
							end_date: null,
						}}
						resource={`patients/${row.original.patient_id}/medication-statements`}
					/>
				</div>
			);
		},
	},
];
export const MedicationsList = () => {
	const record = useRecordContext();
	const [showArchivedLast, setShowArchivedLast] = useState(true);

	const {
		page,
		perPage,
		sort,
		filterValues,
		setPage,
		setPerPage,
		setSort,
		setFilters,
	} = usePatientTableState("medications", record?.id, {
		defaultSort: { field: "start_date", order: "DESC" },
	});

	const {
		data: rawData,
		total,
		isLoading,
	} = useGetList(
		`patients/${record?.id}/medication-statements`,
		{
			pagination: { page, perPage },
			sort,
			filter: filterValues,
		},
		{
			enabled: !!record,
		},
	);

	const data = useMemo(() => {
		if (!rawData || !showArchivedLast) return rawData;

		return [...rawData].sort((a, b) => {
			const isAArchived = a.status === "stopped" || a.status === "completed";
			const isBArchived = b.status === "stopped" || b.status === "completed";

			if (isAArchived === isBArchived) return 0;
			return isAArchived ? 1 : -1;
		});
	}, [rawData, showArchivedLast]);

	if (!record) {
		return null;
	}

	return (
		<div className="w-full overflow-x-auto">
			<div className="flex items-center justify-end gap-2 p-2">
				<span className="text-sm text-gray-600">Show stopped last</span>
				<Switch
					checked={showArchivedLast}
					onCheckedChange={setShowArchivedLast}
				/>
			</div>
			<ListContextProvider
				value={
					{
						filterValues,
						setFilters,
						displayedFilters: {},
						page,
						perPage,
						setPage,
						setPerPage,
						total,
						sort,
						setSort,
						isLoading,
					} as any
				}
			>
				<DataTable
					columns={columns}
					data={data}
					loading={isLoading}
					emptyState={
						<EmptyState
							title="No medications"
							description="No medications found"
							icons={[Pill]}
							className="max-w-full"
						/>
					}
					storeKey="medications-list"
					containerClassName="pb-2"
				/>
			</ListContextProvider>
		</div>
	);
};
