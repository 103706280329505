import React from "react";
import {
	DateInput,
	SelectInput,
	TextInput,
	required,
	useRecordContext,
} from "react-admin";
import CreateInDialogButton from "@/modules/ra-ui/components/create-dialog-button";
import { encounterStatus } from "../../constants/encounterStatus";
import { encounterType } from "../../constants/encounterType";
import { encounterClass } from "../../constants/encounterClass";
import parseDate from "@/utils/date-input-parse";

export function EncounterCreateButton() {
	const record = useRecordContext();

	const transform = (data) => {
		return { ...data, patient_id: record.id, source: "vironix" };
	};
	return (
		<CreateInDialogButton
			title="Add patient encounter"
			resource={`patients/${record?.id}/encounters`}
			transform={transform}
			label="Encounter"
			notifyMessage="Encounter created"
			enableLogAction
			refreshOnSuccess
			logActionLabel={() => {
				return `Encounter added for patient.`;
			}}
		>
			<div className="grid grid-col-4 gap-0">
				<DateInput
					source="start_date"
					defaultValue={new Date().toISOString().split("T", 1)[0]}
					parse={parseDate}
					validate={required()}
					helperText={false}
				/>
				<DateInput source="end_date" helperText={false} parse={parseDate} />

				<SelectInput
					source="status"
					choices={encounterStatus}
					helperText={false}
					validate={required()}
				/>
				<SelectInput
					source="encounter_type"
					choices={encounterType}
					helperText={false}
					validate={required()}
				/>
				<SelectInput
					source="encounter_class"
					choices={encounterClass}
					helperText={false}
					validate={required()}
				/>
				<TextInput source="department" helperText={false} />
				<TextInput
					source="notes"
					helperText={false}
					minRows={2}
					multiline
					validate={required()}
				/>
			</div>
		</CreateInDialogButton>
	);
}
