import { Button } from '@/modules/ui/components/button';
import {
  useCreate,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'ra-core';
import React from 'react';
import { get } from 'lodash';

const TwoFAResetButton = ({ className = null }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate();

  const { identity } = useGetIdentity();
  if (
    !identity?.is_internal ||
    !['superadmin', 'internal_admin'].includes(identity?.role)
  ) {
    return null;
  }

  const handleClick = () => {
    create(
      `users/${record?.id}/reset-2fa`,
      { data: {} },
      {
        onSuccess: () => {
          notify('Successfully reset user 2FA. ', { type: 'success' });
          refresh();
        },
        onError: (error) =>
          notify(`${get(error, 'message')}`, {
            type: 'warning',
          }),
      },
    );
  };

  return (
    <Button
      disabled={isLoading}
      variant="outline"
      onClick={handleClick}
      className={className}
      type="button"
    >
      Reset 2FA
    </Button>
  );
};

export default TwoFAResetButton;
