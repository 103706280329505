import { Textarea } from '@/modules/ui/components/textarea';
import { useInput } from 'ra-core';
import {
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
} from '@/modules/ui/components/form';
import { InputMessage } from './input-message';
import { humanize } from 'inflection';
import React, { forwardRef } from 'react';

export const TextAreaInput = forwardRef((props: any, ref) => {
  const {
    className,
    defaultValue,
    label,
    format,
    onBlur,
    onChange,
    helperText,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const initialRenderRef = React.useRef(true);

  const {
    field,
    fieldState: { error, isTouched },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  // Store the initial value in a ref
  const initialValueRef = React.useRef(field.value);

  React.useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      const textarea = textareaRef.current;
      if (textarea && initialValueRef.current) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  }, []); // Now truly clean with no warnings

  let showLabel: string | boolean = label;
  if (label === undefined || label === null) {
    showLabel = humanize(source);
  }

  return (
    <FormItem id={id}>
      {showLabel && (
        <FormLabel>
          {showLabel}
          {isRequired && <span className="">*</span>}
        </FormLabel>
      )}
      <FormControl>
        <Textarea
          {...field}
          className={className}
          ref={(element) => {
            // Handle both refs
            if (typeof ref === 'function') ref(element);
            else if (ref) ref.current = element;
            textareaRef.current = element;
          }}
          {...rest}
        />
      </FormControl>
      {helperText && <FormDescription>{helperText}</FormDescription>}
      <InputMessage touched={isTouched} error={error?.message} />
    </FormItem>
  );
});

TextAreaInput.displayName = 'TextAreaInput';
